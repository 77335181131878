enum LOCAL_STORAGE_KEYS {
  CURRENT_MODAL = 'CURRENT_MODAL',
  EMAIL = 'EMAIL',
  REDIRECT_URL = 'REDIRECT_URL',
  USER_HAS_SEEN_HOW_TO_SLIDES = 'USER_HAS_SEEN_HOW_TO_SLIDES',
  USER_TYPE = 'USER_TYPE',
  APARTMENT_ID = 'APARTMENT_ID',
  TENANT_HOUSEHOLD = 'TENANT_HOUSEHOLD',
  APARTMENT_LIST_SHOW_GRID = 'APARTMENT_LIST_SHOW_GRID',
  APARTMENT_APPLICATION_SHOW_GRID = 'APARTMENT_APPLICATION_SHOW_GRID',
  HAS_SEEN_APPLICATIONS_JOYRIDE = 'HAS_SEEN_APPLICATIONS_JOYRIDE',
  SUBSCRIPTION_DRAWER_TYPE = 'SUBSCRIPTION_DRAWER_TYPE',
}

export default LOCAL_STORAGE_KEYS;
