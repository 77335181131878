import { FC, useState } from 'react';
import { IImage } from 'core/const/images';
import { useTranslation } from 'react-i18next';
import styles from './ResponsiveImage.module.scss';

const ResponsiveImage: FC<{ image: IImage; rounded: boolean; className?: any; loadingAnimation?: boolean }> = ({
  image,
  rounded = false,
  className,
  loadingAnimation,
}) => {
  const { t: i } = useTranslation('images');
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <picture>
      <img
        className={`${styles.image} ${isLoaded || !loadingAnimation ? styles.loaded : ''} ${
          rounded ? styles.rounded : ''
        } ${className}`}
        sizes="(max-width: 1280px) 100vw, 1280px"
        srcSet={`
              ${i(image.sm ?? image.src)} 576w,
              ${i(image.md ?? image.src)} 768w,
              ${i(image.lg ?? image.src)} 992w,
              ${i(image.src ?? image.src)} 1280w`}
        src={`${i(image.src ?? image.src)}`}
        alt={i(image.alt)}
        onLoad={() => setIsLoaded(true)}
      />
    </picture>
  );
};

export default ResponsiveImage;
