import styles from './ApartmentBadge.module.scss';
import Badge, { BADGE_COLORS } from '../Badge';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const ApartmentBadge: FC<{
  isPublished?: boolean;
  isDisabled?: boolean;
  apartmentIsExclusive?: boolean;
}> = ({ isDisabled = false, apartmentIsExclusive = false, isPublished }) => {
  const { t } = useTranslation('common');

  if (isDisabled || apartmentIsExclusive || isPublished) {
    return (
      <div className={styles.wrapper}>
        {isDisabled ? <Badge text={t('deactivated')} color={BADGE_COLORS.DISABLED} /> : null}
        {isPublished ? <Badge text={t('published')} color={BADGE_COLORS.SUCCESS} /> : null}
        {apartmentIsExclusive && <Badge text={`${t('✨ Nur bei Wohnsinn')}`} color={BADGE_COLORS.DARK} />}
      </div>
    );
  }

  return null;
};

export default ApartmentBadge;
