import { useTranslation } from 'react-i18next';
import LogoSlider from '../../../atoms/LogoSlider';
import { IMAGES } from '../../../../core/const/images';
import { Grid } from '@mui/material';
import Text, { TEXT_COLOR, TEXT_TYPE } from '../../../atoms/typographie/Text';

const CustomersSection = () => {
  const { t: a } = useTranslation('common', { keyPrefix: 'view.general.AboutUsView' });

  return (
    <Grid container justifyContent={'center'} alignItems={'center'}>
      <Grid item xs={12}>
        <Text type={TEXT_TYPE.TEXT_TYPE_LANDING} color={TEXT_COLOR.TEXT_COLOR_ACCENT} align={'center'}>
          {a('customers.headline')}
        </Text>
        <LogoSlider items={CLIENT_LOGOS} />
      </Grid>
    </Grid>
  );
};

export default CustomersSection;
const CLIENT_LOGOS = [
  IMAGES.logos.BE,
  IMAGES.logos.Bellasai,
  IMAGES.logos.Burgmeyer,
  IMAGES.logos.Dahmann,
  IMAGES.logos.kittlaus,
  IMAGES.logos.ivvg,
  IMAGES.logos.josefklein,
  IMAGES.logos.larbig,
  IMAGES.logos.beletage,
  IMAGES.logos.metropol,
  IMAGES.logos.mvgm,
  IMAGES.logos.jansen,
  IMAGES.logos.ndi,
  IMAGES.logos.rida,
  IMAGES.logos.skz,
  IMAGES.logos.sps,
];
