import { FC } from 'react';
import { IMAGES } from 'core/const/images';
import styles from './PremiumSubscriptionDrawer.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import Text, { TEXT_TYPE } from 'component/atoms/typographie/Text';
import ResponsiveImage from 'component/atoms/ResponsiveImage';
import RoundIconButton from 'component/atoms/Buttons/RoundIconButton';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import PremiumSubscriptionDrawerBenefits from 'component/atoms/PremiumSubscriptionDrawer/PremiumSubscriptionDrawerBenefits';

const PremiumSubscriptionLandingDrawer: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { t } = useTranslation('common');
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.close}>
          <RoundIconButton onClick={onClose} buttonText={'close'} icon={faTimes} />
        </div>
        <div className={styles.headerImage}>
          <ResponsiveImage image={IMAGES.landing.hero} rounded={false} />
          <div className={styles.overlay}></div>
        </div>
        <div className={styles.badge}>
          <ResponsiveImage image={IMAGES.images.avatarGroup} rounded={false} />
          <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION} bold>
            <Trans
              i18nKey={t('subscription.landing.badge')}
              components={{
                span: <span className={styles.highlight}></span>,
              }}
            />
          </Text>
        </div>
      </div>

      <div className={styles.content}>
        <PremiumSubscriptionDrawerBenefits onClose={onClose} />
      </div>
    </div>
  );
};

export default PremiumSubscriptionLandingDrawer;
