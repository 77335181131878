import { Grid } from '@mui/material';
import Headline, { HEADLINE_COLOR, HEADLINE_SIZE } from '../../../atoms/typographie/Headline';
import Text, { TEXT_COLOR, TEXT_TYPE } from '../../../atoms/typographie/Text';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import Spacer, { SPACER_SIZE } from '../../../atoms/Spacer';
import CTAButton, { BUTTON_STYLE } from '../../../atoms/Buttons/CTAButton';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IImage } from 'core/const/images';
import { useTranslation } from 'react-i18next';
import styles from './HeroSection.module.scss';
import ResponsiveImage from 'component/atoms/ResponsiveImage';

const HeroSection: FC<
  PropsWithChildren<{
    ctaHref?: string;
    ctaLink?: string;
    caption?: any;
    ctaOnClick?: () => any;
    ctaText?: string;
    image: IImage;
    secondaryCtaHref?: string;
    secondaryCTAOnClick?: () => any;
    secondaryCtaIcon?: IconProp;
    secondaryCtaText?: string;
    subTitle?: any;
    subTitleTwo?: string;
    tag?: string;
    title: string;
  }>
> = ({
  children,
  ctaHref,
  ctaLink,
  ctaOnClick,
  ctaText,
  image,
  secondaryCtaHref,
  secondaryCtaIcon,
  secondaryCtaText,
  subTitle,
  subTitleTwo,
  secondaryCTAOnClick,
  tag,
  title,
  caption,
}) => {
  const { t: i } = useTranslation('images');
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = i(image.src);

    img.onload = () => {
      setIsLoaded(true);
    };
  }, [image.src]);

  return (
    <Grid
      container
      spacing={{ xs: 0, md: 4, xl: 0 }}
      paddingY={{ xs: 10, md: 20 }}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Grid item xs={12} lg={6}>
        {tag ? (
          <>
            <Headline mobileAlign={'center'} color={HEADLINE_COLOR.HEADLINE_COLOR_PRIMARY} size={HEADLINE_SIZE.H3}>
              {tag}
            </Headline>
            <Spacer mobileSize={SPACER_SIZE.XS} size={SPACER_SIZE.MD} />
          </>
        ) : null}
        <Headline preline mobileAlign={'center'} size={HEADLINE_SIZE.DISPLAY}>
          {title}
        </Headline>

        {subTitle ? (
          <>
            <Spacer mobileSize={SPACER_SIZE.MD} />

            <Text
              type={TEXT_TYPE.TEXT_TYPE_LANDING}
              mobileAlign={'center'}
              color={TEXT_COLOR.TEXT_COLOR_ACCENT}
              preLine
            >
              {subTitle}
              {subTitleTwo ? (
                <>
                  <br />
                  {subTitleTwo}
                </>
              ) : null}
            </Text>
          </>
        ) : null}

        <Spacer mobileSize={SPACER_SIZE.LG} size={SPACER_SIZE.XL} />

        <div className={styles.buttonWrapper}>
          {children ? children : null}

          {ctaLink || ctaOnClick || ctaHref ? (
            <CTAButton
              expandMobile={true}
              size={'extra'}
              buttonText={ctaText}
              href={ctaHref ?? undefined}
              link={ctaLink ?? undefined}
              rounded={false}
              onClick={ctaOnClick ?? undefined}
            />
          ) : null}

          {secondaryCtaHref || secondaryCTAOnClick ? (
            <CTAButton
              expand={'block'}
              icon={secondaryCtaIcon}
              buttonStyle={BUTTON_STYLE.SECONDARY}
              expandMobile={true}
              size={'extra'}
              onClick={secondaryCTAOnClick ?? undefined}
              buttonText={secondaryCtaText}
              rounded={false}
              href={secondaryCtaHref ?? undefined}
            />
          ) : null}
          {caption ? <Text>{caption}</Text> : null}
        </div>
      </Grid>
      <Grid item xs={12} lg={5}>
        <Grid container justifyContent={'center'}>
          <Grid item xs={10} md={12} paddingTop={{ xs: 4, md: 0 }}>
            <div className={styles.imageWrapper}>
              <ResponsiveImage loadingAnimation image={image} rounded={true} />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HeroSection;
