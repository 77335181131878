import { FC } from 'react';
import ListWithIcons, { IListWithIconItem } from '../../../molecules/ListWithIcons';
import { faCircleEuro, faPercentage, faHouse } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import { ITenantProfile } from '@wohnsinn/ws-ts-lib';
import { formatNumberGermanStyle } from 'core/helper/get-formatted-price';

const TenantFinanceList: FC<{ tenantProfile: ITenantProfile; rent: number }> = ({ tenantProfile, rent }) => {
  const { t } = useTranslation('common');
  const financeList: IListWithIconItem[] = [];
  const ratio = (rent / tenantProfile?.household?.monthlyIncome) * 100;

  financeList.push({
    icon: faCircleEuro,
    text: t('monthlyIncome.text', { income: formatNumberGermanStyle(tenantProfile?.household?.monthlyIncome) }),
  });
  if (rent) {
    financeList.push({
      icon: faHouse,
      text: t('newRent', { rent: formatNumberGermanStyle(rent) }),
    });
  }
  if (ratio) {
    financeList.push({
      icon: faPercentage,
      text: t('rentBurdenRatio', { ratio: `${ratio.toFixed(0)}` }),
    });
  }

  return <ListWithIcons list={financeList} />;
};

export default TenantFinanceList;
