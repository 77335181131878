import CTAButton from 'component/atoms/Buttons/CTAButton';
import LoadingAnimation from 'component/atoms/LoadingAnimation';
import PageLayout from 'component/layouts/PageLayout';
import SearchInput from 'component/atoms/formElement/SearchInput';
import UserContext from 'core/context/user.context';
import styles from './ApartmentListView.module.scss';
import { Grid } from '@mui/material';
import { IApartment } from '@wohnsinn/ws-ts-lib';
import { ISortedApartments, LIST_MODE, useFetchApartments } from 'core/hook/apartment-queries.hook';
import { ROUTES } from 'core/const/routes';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useWindowSize from '../../../../core/hook/windowsize.hook';
import ArrowBox from '../../../../component/molecules/ArrowBox';
import CTACard from '../../../../component/molecules/Cards/CTACard';
import ApartmentsTable from 'component/organisms/Tables/ApartmentsTable';
import ApartmentsGrid from 'component/organisms/ApartmentsGrid';
import GridSwitch from 'component/molecules/GridSwitch';
import LOCAL_STORAGE_KEYS from 'core/enum/local-storage.enum';

const ApartmentListView = () => {
  const { user } = useContext(UserContext);
  const { t: r } = useTranslation('routes');
  const { t } = useTranslation('common');
  const { isSmallerMd } = useWindowSize();
  const [showGrid, setShowGrid] = useState<boolean>(() => {
    const storedValue = localStorage.getItem(LOCAL_STORAGE_KEYS.APARTMENT_LIST_SHOW_GRID);
    return storedValue !== null ? storedValue === 'true' : isSmallerMd;
  });

  const [showOnlManuallyCreated, setShowOnlyManuallyCreatedApartments] = useState<boolean>(!!user?.isAdmin);
  const { data: fetchedApartments, isFetching, isLoading } = useFetchApartments(showOnlManuallyCreated);
  const [listMode, setListMode] = useState<LIST_MODE>(LIST_MODE.PUBLISHED);

  const [searchKey, setSearchKey] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const handlePageChange = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset pagination when changing rows per page
  };

  useEffect(() => {
    if (searchKey && page !== 0) {
      setPage(0);
    }
  }, [searchKey]);

  useEffect(() => {
    if (showGrid) {
      setRowsPerPage(9);
    } else {
      setRowsPerPage(10);
    }
  }, [showGrid]);

  const currentApartments = useMemo(() => {
    return findMatchingObjects(searchKey, fetchedApartments, listMode, page, rowsPerPage);
  }, [searchKey, fetchedApartments, listMode, page, rowsPerPage]);

  function findMatchingObjects(
    searchString: string,
    fetchedApartments: ISortedApartments | undefined,
    listMode: LIST_MODE,
    page: number,
    rowsPerPage: number
  ): IApartment[] {
    let newApartments = [...getCurrentApartmentList(fetchedApartments, listMode)];

    if (searchString) {
      const searchComponents = searchString
        .toLowerCase()
        .split(/[ ,.]+/)
        .map((component) => component.trim());

      newApartments = newApartments.filter((apartment) => {
        return searchComponents.every((component) => {
          const fieldsToSearch = [
            apartment.mainInformation.address.street,
            apartment.mainInformation.address.houseNumber,
            apartment.mainInformation.address.postalCode,
            apartment.mainInformation.address.city,
          ];

          if (user?.isAdmin) {
            fieldsToSearch.push(apartment.creatorId);
            fieldsToSearch.push(apartment?.contactPerson?.company);
          }

          return fieldsToSearch.some((field) => field?.toLowerCase().includes(component));
        });
      });
    }

    // Pagination logic
    const startIndex = page * rowsPerPage;
    return newApartments.slice(startIndex, startIndex + rowsPerPage);
  }

  const totalCount = useMemo(() => {
    return searchKey
      ? getCurrentApartmentList(fetchedApartments, listMode).filter((apartment) =>
          searchKey
            .toLowerCase()
            .split(/[ ,.]+/)
            .every((component) =>
              [apartment.mainInformation.address.street, apartment.mainInformation.address.houseNumber]
                .map((field) => field?.toLowerCase().includes(component))
                .includes(true)
            )
        ).length
      : getCurrentApartmentList(fetchedApartments, listMode).length;
  }, [searchKey, fetchedApartments, listMode]);

  return (
    <PageLayout
      pageTitle={r(ROUTES.landlordRoutes.apartment.list.title)}
      titleButton={
        <GridSwitch
          defaultState={showGrid}
          onClick={(state: boolean) => {
            setShowGrid(state);
            localStorage.setItem(LOCAL_STORAGE_KEYS.APARTMENT_LIST_SHOW_GRID, `${state}`);
          }}
        />
      }
    >
      <Grid container spacing={2} rowSpacing={4} justifyContent={'space-between'}>
        {!isFetching && !isLoading && fetchedApartments?.allApartmentsCount < 1 ? null : (
          <>
            <Grid item xs={12}>
              <ArrowBox
                showAsRectangle
                boxes={[
                  {
                    label: t('published'),
                    count: fetchedApartments?.publishedApartmentList?.length ?? 0,
                    active: listMode === LIST_MODE.PUBLISHED,
                    state: LIST_MODE.PUBLISHED,
                    notification: fetchedApartments?.publishedApartmentList.reduce((acc, obj) => {
                      return acc + (obj.newApplicationsRef ? obj.newApplicationsRef.length : 0);
                    }, 0),
                  },
                  {
                    label: t('deactivated'),
                    count: fetchedApartments?.deactivatedApartmentList?.length ?? 0,
                    active: listMode === LIST_MODE.DEACTIVATED,
                    state: LIST_MODE.DEACTIVATED,
                    notification: fetchedApartments?.deactivatedApartmentList.reduce((acc, obj) => {
                      return acc + (obj.newApplicationsRef ? obj.newApplicationsRef.length : 0);
                    }, 0),
                  },
                  {
                    label: t('all'),
                    count: fetchedApartments?.allApartmentList?.length ?? 0,
                    active: listMode === LIST_MODE.ALL,
                    state: LIST_MODE.ALL,
                    notification: fetchedApartments?.allApartmentList.reduce((acc, obj) => {
                      return acc + (obj.newApplicationsRef ? obj.newApplicationsRef.length : 0);
                    }, 0),
                  },
                ]}
                onClick={(listMode) => setListMode(listMode)}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <SearchInput name="search" placeholder={t('search')} onChange={setSearchKey} />
            </Grid>

            <Grid item xs={12} md={user?.isAdmin ? 6 : 3} display={'flex'} gap={2}>
              {user?.isAdmin && (
                <CTAButton
                  expand={'block'}
                  rounded={false}
                  icon={faPlus}
                  buttonText={showOnlManuallyCreated ? 'Zeig alle' : 'nur Private'}
                  onClick={() => setShowOnlyManuallyCreatedApartments(!showOnlManuallyCreated)}
                />
              )}
              <CTAButton
                expand={'block'}
                rounded={false}
                icon={faPlus}
                buttonText={t('view.ApartmentListView.createNew')}
                link={r(ROUTES.landlordRoutes.apartment.create.new.path)}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          {isFetching || isLoading ? (
            <LoadingAnimation />
          ) : fetchedApartments?.allApartmentsCount < 1 ? (
            <div className={styles.emptyList}>{renderEmptyApartments(t, r, 'noObjects')}</div>
          ) : currentApartments?.length ? (
            showGrid ? (
              <ApartmentsGrid
                handlePageChange={handlePageChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
                totalCount={totalCount}
                apartments={currentApartments}
                page={page}
                rowsPerPage={rowsPerPage}
              />
            ) : (
              <ApartmentsTable
                handlePageChange={handlePageChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
                totalCount={totalCount}
                apartments={currentApartments}
                page={page}
                rowsPerPage={rowsPerPage}
              />
            )
          ) : (
            renderEmptyApartments(
              t,
              r,
              searchKey
                ? 'noSearchResults'
                : listMode === LIST_MODE.DEACTIVATED
                ? 'noDeactivated'
                : listMode === LIST_MODE.PUBLISHED
                ? 'noPublished'
                : 'noObjects'
            )
          )}
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default ApartmentListView;

function renderEmptyApartments(
  t: any,
  r: any,
  type: 'noSearchResults' | 'noObjects' | 'noPublished' | 'noDeactivated'
) {
  const getTitle = () => {
    switch (type) {
      case 'noObjects':
        return t('apartment.ctaCard.noObjects.title');
      case 'noSearchResults':
        return t('apartment.ctaCard.noSearchResults.title');
      case 'noPublished':
        return t('apartment.ctaCard.noPublishedObjects.title');
      case 'noDeactivated':
        return t('apartment.ctaCard.noDeactivatedObjects.title');
      default:
        return t('apartment.ctaCard.noObjects.title');
    }
  };
  const title = getTitle();
  return (
    <Grid container spacing={0} justifyContent={'center'}>
      <Grid item xs={12} md={4}>
        <CTACard
          title={title}
          text={t('apartment.ctaCard.noObjects.text')}
          imgSrc={t('pictogram.object')}
          imgAltText={t('noObjectsAvailable')}
          ctaText={t('view.ApartmentListView.createNew')}
          link={r(ROUTES.landlordRoutes.apartment.create.new.path)}
        />
      </Grid>
    </Grid>
  );
}

function getCurrentApartmentList(fetchedApartments: ISortedApartments | undefined, listMode: LIST_MODE): IApartment[] {
  switch (listMode) {
    case LIST_MODE.PUBLISHED:
      return fetchedApartments?.publishedApartmentList ?? [];
    case LIST_MODE.DEACTIVATED:
      return fetchedApartments?.deactivatedApartmentList ?? [];
    case LIST_MODE.ALL:
      return fetchedApartments?.allApartmentList ?? [];
    default:
      return [];
  }
}
