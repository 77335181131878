import ModalWrapper, { IModalWrapperButtonProps } from '../ModalWrapper';
import { FC, useContext, useEffect, useState } from 'react';
import ModalContext from 'core/context/modal.context';
import { useTranslation } from 'react-i18next';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import InfoBox, { INFO_BOX_ICONS } from 'component/atoms/InfoBox';
import { Grid } from '@mui/material';
import UserContext from 'core/context/user.context';
import ProfileAvatar from 'component/molecules/ProfileAvatar';
import { FIRESTORE_COLLECTION_PATH } from '@wohnsinn/ws-ts-lib';
import { wohnsinnServices } from 'App';
import SnackBarContext from 'core/context/snackbar.context';
import { BUTTON_STYLE } from 'component/atoms/Buttons/CTAButton';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import UploadProfileImageButton from '../../atoms/Buttons/UploadProfileImageButton';

const UploadProfileImageModal: FC = () => {
  const { closeModal, openModal, modalData } = useContext(ModalContext);
  const { handleSnackBar } = useContext(SnackBarContext);
  const { tenantProfile, user } = useContext(UserContext);
  const { mixpanelTrackingService } = wohnsinnServices;
  const { tenantService, firebaseStorageService } = wohnsinnServices;
  const { t } = useTranslation('common');
  const PROFILE_IMAGE_PATH = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.root.replace('{uid}', user.uid)}/${
    user.uid
  }/profileImage.png`;

  const [modalButtons, setModalButtons] = useState<IModalWrapperButtonProps>({
    primary: {
      buttonStyle: BUTTON_STYLE.SECONDARY,
      buttonText: t('goOnWithoutImage'),
      onClick: handleNextClick,
    },
  });

  function handleNextClick() {
    closeModal();
    modalData.onNext();
  }

  useEffect(() => {
    mixpanelTrackingService.trackEvent('UploadProfileImageModalOpened', {
      hasPhoto: tenantProfile?.photoUrl,
      uid: tenantProfile?.uid,
      lastName: tenantProfile?.personalInformation?.firstName,
    });
    if (tenantProfile?.photoUrl) {
      setModalButtons({
        primary: {
          buttonStyle: BUTTON_STYLE.PRIMARY,
          buttonText: t('next'),
          onClick: handleNextClick,
        },
      });
    } else {
      setModalButtons((prevState) => ({
        ...prevState,
        primary: {
          buttonStyle: BUTTON_STYLE.SECONDARY,
          buttonText: t('goOnWithoutImage'),
          onClick: handleNextClick,
        },
        custom: (
          <UploadProfileImageButton
            storagePath={PROFILE_IMAGE_PATH}
            onUpload={handleProfileImageUpload}
            onDelete={deleteProfileImage}
            onClose={() =>
              openModal({
                id: MODAL_IDENTIFIER.UPLOAD_PROFILE_IMAGE,
                data: {
                  onNext: modalData.onNext,
                },
              })
            }
          />
        ),
      }));
    }
  }, [tenantProfile]);

  const handleProfileImageUpload = async (photoUrl: string) => {
    try {
      mixpanelTrackingService.trackEvent('handleProfileImageUploadWasCalled', {
        hasPhoto: tenantProfile?.photoUrl,
        uid: tenantProfile?.uid,
        lastName: tenantProfile?.personalInformation?.firstName,
      });
      await tenantService.updateTenantProfilePicture(user.uid, photoUrl);
    } catch (e) {
      mixpanelTrackingService.trackEvent('handleProfileImageUpload_FAILED', {
        hasPhoto: tenantProfile?.photoUrl,
        uid: tenantProfile?.uid,
        lastName: tenantProfile?.personalInformation?.firstName,
      });
      console.error('Error on handleProfileImageUpload: ', e);
    }
  };

  const deleteProfileImage = async () => {
    try {
      await tenantService.updateTenantProfilePicture(user.uid, null);
      await firebaseStorageService.deleteFiles([PROFILE_IMAGE_PATH]);
      handleSnackBar('toast.profileImage.deleted', 'success');
    } catch (e) {
      console.error('Error on deleteProfileImage', e);
    }
  };

  return (
    <ModalWrapper title={'Bewerbung'} buttons={modalButtons}>
      <Grid container justifyContent={'center'} rowSpacing={3}>
        <Grid item>
          <Headline size={HEADLINE_SIZE.H2} align={'center'}>
            {t('uploadProfileImage.title')}
          </Headline>
        </Grid>
        <Grid item>
          <ProfileAvatar
            storagePath={PROFILE_IMAGE_PATH}
            onUpload={handleProfileImageUpload}
            onDelete={deleteProfileImage}
            onClose={() => openModal({ id: MODAL_IDENTIFIER.UPLOAD_PROFILE_IMAGE })}
            photoUrl={tenantProfile?.photoUrl}
          />
        </Grid>

        <Grid item>
          <InfoBox highlightComponent={<b />} text={t('uploadProfileImage.info')} icon={INFO_BOX_ICONS.LIGHT_BULB} />
        </Grid>
      </Grid>
    </ModalWrapper>
  );
};

export default UploadProfileImageModal;
