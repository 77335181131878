import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageLayout from 'component/layouts/PageLayout';
import useApartment from 'core/hook/apartment.hook';
import { ROUTES } from 'core/const/routes';
import UserContext from 'core/context/user.context';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import ModalContext from 'core/context/modal.context';
import {
  faAddressCard,
  faBolt,
  faCamera,
  faChevronDown,
  faCircleInfo,
  faCoins,
  faHouseHeart,
  faMessageDots,
  faPause,
  faSpinner,
  faUserPlus,
} from '@fortawesome/pro-light-svg-icons';
import { faChevronRight, faPlay } from '@fortawesome/pro-solid-svg-icons';
import CTAButton, { BUTTON_STYLE } from 'component/atoms/Buttons/CTAButton';
import { TEXT_COLOR } from 'component/atoms/typographie/Text';
import { Grid, MenuItem } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { ICreateApartmentTunnel } from 'view/landlord/apartment/ApartmentCreateView';
import FormNavigation from 'component/molecules/FormNavigation';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { wohnsinnServices } from 'App';
import SnackBarContext from 'core/context/snackbar.context';
import LinkElement from 'component/atoms/LinkElement';
import Menu from '@mui/material/Menu';
import LOCAL_STORAGE_KEYS from 'core/enum/local-storage.enum';
import ProgressBar from 'component/atoms/ProgressBar';
import FormHeader from 'component/atoms/FormHeader';
import FB_FUNCTION_URLS from 'core/const/fb-function-names';
import useWindowSize from 'core/hook/windowsize.hook';
import { APARTMENT_RENT_STATUS } from '@wohnsinn/ws-ts-lib';
import ActiveTenantCard from 'component/molecules/ActiveTenantCard';

const ApartmentOverviewView: FC<ICreateApartmentTunnel> = ({ isTunnelView, progress = 10 }) => {
  const { user, landlordProfile } = useContext(UserContext);
  const { openModal } = useContext(ModalContext);
  const { handleSnackBar } = useContext(SnackBarContext);
  const { apartmentService, firebaseFunctionsService, applicationService } = wohnsinnServices;
  const queryClient = useQueryClient();
  const { apartment } = useApartment();
  const navigate = useNavigate();
  const { closeModal } = useContext(ModalContext);
  const { t: c } = useTranslation('common');
  const { t } = useTranslation();
  const { t: r } = useTranslation('routes');
  const { isSmallerMd } = useWindowSize();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data: activeTenantApplication } = useQuery({
    queryKey: ['activeTenantApplication', apartment?.id],
    queryFn: () =>
      apartment.activeTenantId
        ? applicationService.getApplication({
            landlordId: apartment?.creatorId,
            apartmentId: apartment?.id,
            tenantId: apartment?.activeTenantId,
          })
        : null,
  });

  const { mutate: updatePublishedState, isPending } = useMutation({
    mutationFn: () => apartmentService.updateApartmentPublishState(apartment, !apartment?.isPublished),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['apartments'] });
      await firebaseFunctionsService.callFbFunction(FB_FUNCTION_URLS.apartments.publishedChangeNotification, {
        body: {
          apartments: [apartment],
          landlordProfile,
          isPublished: apartment?.isPublished,
        },
      });
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.REDIRECT_URL,
        r(ROUTES.landlordRoutes.apartment.overview.path).replace(':apartmentId', apartment?.id)
      );
      navigate(`${r(ROUTES.staticRoutes.redirect.path)}/created-apartment`);
    },
    onError: () => handleSnackBar('toast.error.unknown', 'error'),
    onSettled: closeModal,
  });

  if (!apartment) {
    return (
      <PageLayout pageTitle={'Apartment wird geladen..'}>
        <FontAwesomeIcon spin={true} width={'23px'} height={'23px'} icon={faSpinner} size={'1x'} />
      </PageLayout>
    );
  }

  const handleApartmentPublish = async (): Promise<void> => {
    openModal({ id: MODAL_IDENTIFIER.PUBLISH_APARTMENT, data: { apartment } });
  };

  const getRentStatusText = () => {
    switch (apartment.rentStatus) {
      case APARTMENT_RENT_STATUS.RENTED:
        return c('rented');
      case APARTMENT_RENT_STATUS.ACQUISITION:
        return c('acquisition');
      case APARTMENT_RENT_STATUS.NONE:
        return '';
    }
  };

  return (
    <PageLayout
      pageTitle={
        isTunnelView
          ? 'Veröffentlichung deiner Anzeige'
          : `${apartment?.mainInformation?.address.street} ${apartment?.mainInformation?.address.houseNumber}`
      }
      showBackButton={isSmallerMd && !isTunnelView}
      backButtonRoute={r(ROUTES.landlordRoutes.apartment.list.path)}
      showPageTitle={!isTunnelView}
      hideMenu={isTunnelView}
      badge={getRentStatusText()}
      showTitleBackButton={!isTunnelView && !isSmallerMd}
    >
      {isTunnelView && <ProgressBar progress={progress} />}
      {isTunnelView && (
        <FormHeader title={t('Veröffentlichung deiner Anzeige')} subtitle={'Nur noch ein Schritt.'}></FormHeader>
      )}

      <Grid
        container
        spacing={2}
        direction={{ xs: 'column', md: 'row' }}
        justifyContent={'space-between'}
        className={'form-with-navigation'}
      >
        <Grid item xs={12} md={6} className={'form-with-navigation'}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <LinkElement
                routerLink={r(ROUTES.landlordRoutes.apartment.address.path).replace(':apartmentId', apartment?.id)}
                text={r(ROUTES.landlordRoutes.apartment.address.title)}
                icon={faChevronRight}
                iconFront={faCircleInfo}
              />
            </Grid>
            <Grid item xs={12}>
              <LinkElement
                routerLink={r(ROUTES.landlordRoutes.apartment.mainInformation.path).replace(
                  ':apartmentId',
                  apartment?.id
                )}
                text={r(ROUTES.landlordRoutes.apartment.mainInformation.title)}
                icon={faChevronRight}
                iconFront={faCircleInfo}
              />
            </Grid>
            <Grid item xs={12}>
              <LinkElement
                onClick={() =>
                  navigate(r(ROUTES.landlordRoutes.apartment.cost.path).replace(':apartmentId', apartment?.id))
                }
                text={r(ROUTES.landlordRoutes.apartment.cost.title)}
                icon={faChevronRight}
                iconFront={faCoins}
              />
            </Grid>
            <Grid item xs={12}>
              <LinkElement
                routerLink={r(ROUTES.landlordRoutes.apartment.equipment.path).replace(':apartmentId', apartment?.id)}
                text={r(ROUTES.landlordRoutes.apartment.equipment.title)}
                icon={faChevronRight}
                iconFront={faHouseHeart}
              />
            </Grid>
            <Grid item xs={12}>
              <LinkElement
                routerLink={r(ROUTES.landlordRoutes.apartment.desiredTenant.path).replace(
                  ':apartmentId',
                  apartment?.id
                )}
                text={r(ROUTES.landlordRoutes.apartment.desiredTenant.title)}
                icon={faChevronRight}
                iconFront={faUserPlus}
              />
            </Grid>
            <Grid item xs={12}>
              <LinkElement
                routerLink={r(ROUTES.landlordRoutes.apartment.energyPass.path).replace(':apartmentId', apartment?.id)}
                text={r(ROUTES.landlordRoutes.apartment.energyPass.title)}
                icon={faChevronRight}
                iconFront={faBolt}
              />
            </Grid>
            <Grid item xs={12}>
              <LinkElement
                routerLink={r(ROUTES.landlordRoutes.apartment.freeTexts.path).replace(':apartmentId', apartment?.id)}
                text={r(ROUTES.landlordRoutes.apartment.freeTexts.title)}
                icon={faChevronRight}
                iconFront={faMessageDots}
              />
            </Grid>
            <Grid item xs={12}>
              <LinkElement
                routerLink={r(ROUTES.landlordRoutes.apartment.media.path).replace(':apartmentId', apartment?.id)}
                text={r(ROUTES.landlordRoutes.apartment.media.title)}
                icon={faChevronRight}
                iconFront={faCamera}
              />
            </Grid>
            <Grid item xs={12}>
              <LinkElement
                routerLink={r(ROUTES.landlordRoutes.apartment.contactPerson.path).replace(
                  ':apartmentId',
                  apartment?.id
                )}
                text={r(ROUTES.landlordRoutes.apartment.contactPerson.title)}
                icon={faChevronRight}
                iconFront={faAddressCard}
              />
            </Grid>
          </Grid>
        </Grid>

        {!isTunnelView ? (
          <Grid item xs={12} md={5}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CTAButton
                  rounded={false}
                  expand={'block'}
                  buttonText={apartment.isPublished ? c('unPublishApartment') : c('publishApartment')}
                  onClick={handleApartmentPublish}
                  icon={apartment.isPublished ? faPause : faPlay}
                  iconPosition={'end'}
                  color={apartment.isPublished ? TEXT_COLOR.TEXT_COLOR_DARK : TEXT_COLOR.TEXT_COLOR_LIGHT}
                  buttonStyle={apartment.isPublished ? BUTTON_STYLE.DANGER_INVERTED : BUTTON_STYLE.PRIMARY}
                />
              </Grid>
              {!activeTenantApplication && !apartment.isPublished ? (
                <Grid item xs={12}>
                  <CTAButton
                    rounded={false}
                    disabled={!!activeTenantApplication}
                    expand={'block'}
                    buttonText={c('addTenant')}
                    onClick={() =>
                      openModal({
                        id: MODAL_IDENTIFIER.FOUND_TENANT,
                        data: { apartmentId: apartment.id, landlordId: apartment.creatorId },
                      })
                    }
                  />
                </Grid>
              ) : null}
              <Grid item xs={9}>
                <CTAButton
                  rounded={false}
                  expand={'block'}
                  buttonText={c('view.ApartmentOverviewView.goToApplications')}
                  link={`${r(ROUTES.landlordRoutes.apartment.applications.path)}/`.replace(
                    ':apartmentId',
                    apartment.id
                  )}
                  icon={faMessageDots}
                  iconPosition={'end'}
                  color={TEXT_COLOR.TEXT_COLOR_DARK}
                  buttonStyle={BUTTON_STYLE.SECONDARY}
                />
              </Grid>
              <Grid item xs={3}>
                <CTAButton
                  rounded={false}
                  expand={'block'}
                  buttonText={c('more')}
                  onClick={handleClick}
                  icon={faChevronDown}
                  iconPosition={'end'}
                  color={TEXT_COLOR.TEXT_COLOR_DARK}
                  buttonStyle={BUTTON_STYLE.SECONDARY}
                />
                <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
                  <MenuItem
                    onClick={() =>
                      navigate(r(ROUTES.landlordRoutes.apartment.preview.path).replace(':apartmentId', apartment.id))
                    }
                  >
                    {c('preview')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => openModal({ id: MODAL_IDENTIFIER.SHARE_LINK, data: { apartmentId: apartment.id } })}
                  >
                    {c('share')}
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      openModal({
                        id: MODAL_IDENTIFIER.DELETE_MODAL,
                        data: {
                          text: t('deleteObjectQuestion'),
                          deleteHandler: async () => {
                            await apartmentService.deleteApartmentList([apartment]);
                            await queryClient.invalidateQueries({ queryKey: ['apartments'] });
                            await firebaseFunctionsService.callFbFunction(
                              FB_FUNCTION_URLS.apartments.deletedNotification,
                              {
                                body: {
                                  apartments: [apartment],
                                  landlordProfile,
                                },
                              }
                            );
                            navigate(r(ROUTES.landlordRoutes.apartment.list.path));
                          },
                        },
                      })
                    }
                  >
                    {c('delete')}
                  </MenuItem>
                </Menu>
              </Grid>

              {activeTenantApplication ? (
                <Grid item xs={12}>
                  <ActiveTenantCard application={activeTenantApplication} />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        ) : null}
      </Grid>
      {isTunnelView && (
        <FormNavigation
          showThirdButton={!isSmallerMd}
          buttonSubmitMode={isPending ? SUBMIT_BUTTON_MODE.SUBMITTING : SUBMIT_BUTTON_MODE.NONE}
          disabled={false}
          primaryAction={updatePublishedState}
          submitButtonText={c('publish')}
          tertiaryButtonAction={() =>
            navigate(r(ROUTES.landlordRoutes.apartment.overview.path).replace(':apartmentId', apartment.id))
          }
          secondaryButtonAction={
            isSmallerMd
              ? () => navigate(r(ROUTES.landlordRoutes.apartment.overview.path).replace(':apartmentId', apartment.id))
              : () => navigate(r(ROUTES.landlordRoutes.apartment.preview.path).replace(':apartmentId', apartment.id))
          }
          secondaryButtonColor={TEXT_COLOR.TEXT_COLOR_ACCENT}
          secondaryButtonText={isSmallerMd ? c('saveAndFinish') : c('preview')}
        />
      )}
    </PageLayout>
  );
};

export default ApartmentOverviewView;
