import { useEffect, useContext, useMemo } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  collection,
  where,
  orderBy,
  onSnapshot,
  getFirestore,
  QuerySnapshot,
  DocumentData,
  query,
} from 'firebase/firestore';
import { INews } from '@wohnsinn/ws-ts-lib';
import UserContext from 'core/context/user.context';

export interface INewsResponse {
  allNotifications: INews[];
  unreadNotifications: INews[];
}

export const useNews = () => {
  const queryClient = useQueryClient();
  const { landlordProfile } = useContext(UserContext);
  const db = getFirestore();

  const newsQueryRef = useMemo(() => {
    if (!landlordProfile) return null;
    const newsCollection = collection(db, 'news');
    return query(
      newsCollection,
      where('editorList', 'array-contains', landlordProfile.uid),
      orderBy('createdAt', 'desc')
    );
  }, [landlordProfile]);

  // Set up the listener to keep the query cache updated in real-time
  useEffect(() => {
    if (!newsQueryRef) return;

    const unsubscribe = onSnapshot(
      newsQueryRef,
      (snapshot: QuerySnapshot<DocumentData>) => {
        const updatedNews = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as INews[];

        // Update the TanStack Query cache with real-time data
        queryClient.setQueryData(['news'], updatedNews);
      },
      (error) => {
        console.error('Error fetching news updates:', error);
      }
    );

    return () => unsubscribe(); // Cleanup on unmount
  }, [newsQueryRef, queryClient]);

  // Use `useQuery` to access the `news` data from the cache
  return useQuery({
    queryKey: ['news'],
    queryFn: () => Promise.resolve([] as INews[]), // Use a dummy function as queryFn is not used here
    select: (data): INewsResponse => {
      const allNotifications = data;
      const unreadNotifications = data.filter((news) => !news.seenByLandlord);

      return {
        allNotifications,
        unreadNotifications,
      };
    },
    enabled: !!landlordProfile, // Only enable when landlordProfile is present
    staleTime: Infinity, // Data stays fresh as long as snapshot listener is active
  });
};
