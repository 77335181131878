import styles from './MegaMenu.module.scss';
import { MAIN_MENU_ITEMS, MEGA_MENU_ITEM } from 'component/molecules/MegaMenu/items';
import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Text, { TEXT_COLOR, TEXT_TYPE, TEXT_VARIANT } from 'component/atoms/typographie/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';

const MegaMenu = () => {
  return (
    <ul className={styles.mainMenu}>
      {MAIN_MENU_ITEMS.map((item, index) => (
        <li key={index}>
          <MegaMenuItem item={item} />
        </li>
      ))}
    </ul>
  );
};

const MegaMenuItem: FC<{ item: MEGA_MENU_ITEM }> = ({ item }) => {
  const { t: r } = useTranslation('routes');
  const location = useLocation();
  const isActive = location.pathname === r(item.link);

  return (
    <>
      <Link className={`${styles.mainMenuLink} ${isActive ? styles.isActive : ''}`} to={r(item.link)}>
        <Text variant={TEXT_VARIANT.TEXT_VARIANT_SMALL} bold tag={'span'}>
          {r(item.title)}{' '}
          {item.children && (
            <span className={styles.mainMenuLinkIcon}>
              <FontAwesomeIcon icon={faChevronDown} size={'xs'} />
            </span>
          )}
        </Text>
      </Link>
      {item.children && (
        <div className={styles.megaMenuWrapper}>
          <Text
            color={TEXT_COLOR.TEXT_COLOR_PRIMARY}
            type={TEXT_TYPE.TEXT_TYPE_CAPTION}
            bold
            align={'right'}
            tag={'span'}
          >
            {item.title}
          </Text>
          <ul className={styles.megaMenu}>
            {item.children.map((child: MEGA_MENU_ITEM, index) => (
              <li key={index}>
                <MegaMenuChildItem child={child} />
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

const MegaMenuChildItem: FC<{ child: MEGA_MENU_ITEM }> = ({ child }) => {
  const { t: r } = useTranslation('routes');
  const { t: i } = useTranslation('images');
  const location = useLocation();
  const isActive = location.pathname === r(child.link);
  return (
    <Link className={`${styles.megaMenuLink} ${isActive ? styles.isActive : ''}`} to={r(child.link)}>
      <div className={styles.megaMenuLinkIcon}>
        {child.icon && <img src={i(child.icon.src)} alt={i(child.icon.alt)} />}
      </div>
      <div className={styles.megaMenuLinkText}>
        <Text variant={TEXT_VARIANT.TEXT_VARIANT_SMALL} bold tag={'span'}>
          {r(child.title)}
        </Text>
        <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION} color={TEXT_COLOR.TEXT_COLOR_ACCENT} tag={'span'}>
          {child.subtitle}
        </Text>
      </div>
    </Link>
  );
};

export default MegaMenu;
