import { FC, useContext } from 'react';
import { IMedia } from '@wohnsinn/ws-ts-lib';
import styles from './ApartmentMediaItem.module.scss';
import Text from '../../atoms/typographie/Text';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTrash, faEdit } from '@fortawesome/pro-light-svg-icons';
import { MODAL_IDENTIFIER } from '../../../core/enum/modals.enum';
import ModalContext from '../../../core/context/modal.context';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import useApartment from '../../../core/hook/apartment.hook';
import Badge from 'component/atoms/Badge';

export interface IApartmentMediaUploadItem {
  media: IMedia;
  changeDescriptionHandler: any;
  deleteImageHandler: any;
  imageIndex: number;
  previewHandler: (index: number) => void;
  badge?: string;
}

const ApartmentMediaItem: FC<IApartmentMediaUploadItem> = ({
  media,
  changeDescriptionHandler,
  deleteImageHandler,
  imageIndex,
  previewHandler,
  badge,
}) => {
  const { openModal, closeModal } = useContext(ModalContext);
  const { apartment } = useApartment();
  const { t } = useTranslation('common');
  const { t: m } = useTranslation('common', { keyPrefix: 'component.molecules.ApartmentMediaItem' });
  const sortable = useSortable({ id: media.id });
  const { active, attributes, listeners, setNodeRef, transform, transition } = sortable;

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div
      className={`${styles.apartmentMediaItem} ${active?.id === media.id ? styles.active : ''}`}
      {...attributes}
      {...listeners}
      ref={setNodeRef}
      style={style}
    >
      {badge ? (
        <div className={styles.badge}>
          <Badge text={badge} />
        </div>
      ) : null}
      <div className={styles.actionButtonWrapper}>
        <button className={styles.actionButton} onPointerDown={() => previewHandler(imageIndex)}>
          <span className="sr-only">{t('delete')}</span>
          <FontAwesomeIcon icon={faEye} />
        </button>
        <button
          className={`${styles.actionButton} ${styles.actionDelete}`}
          onPointerDown={() =>
            openModal({
              id: MODAL_IDENTIFIER.DELETE_MODAL,
              data: {
                deleteHandler: () => deleteImageHandler(imageIndex, media, apartment.id).then(() => closeModal()),
                title: m('delete.title'),
                text: m('delete.text'),
              },
            })
          }
        >
          <span className="sr-only">{t('delete')}</span>
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>
      <div className={styles.image} style={{ backgroundImage: `url(${media?.thumbnailUrl ?? media.url})` }} />

      <button onPointerDown={changeDescriptionHandler} className={styles.description}>
        <Text overFlowEllipsis>{media.alt}</Text>
        <span className="sr-only">{t('edit')}</span>
        <FontAwesomeIcon icon={faEdit} />
      </button>
    </div>
  );
};

export default ApartmentMediaItem;
