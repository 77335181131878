import DrawerWrapper from 'component/molecules/DrawerWrapper';
import { FC, useContext, useState } from 'react';
import { ROUTES } from 'core/const/routes';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './LPDrawerMenu.module.scss';
import Text from '../../../atoms/typographie/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons';
import Badge, { BADGE_COLORS } from '../../../atoms/Badge';
import CTAButton, { BUTTON_STYLE } from '../../../atoms/Buttons/CTAButton';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import ModalContext from 'core/context/modal.context';
import Spacer, { SPACER_SIZE } from '../../../atoms/Spacer';
import SplitLine from '../../../atoms/SplitLine';
import { useLocation } from 'react-router-dom';

const getActiveMenu = (r: any) => {
  if (location?.pathname === '/') {
    return '';
  }

  if (
    location?.pathname?.includes(r(ROUTES.landingPageRoutes.landlord.path)) ||
    location?.pathname?.includes(r(ROUTES.landingPageRoutes.propertyManagement.path)) ||
    location?.pathname?.includes(r(ROUTES.landingPageRoutes.cooperative.path)) ||
    location?.pathname?.includes(r(ROUTES.landingPageRoutes.realEstateAgent.path))
  ) {
    return 'anbieter';
  }

  return 'about';
};

const LPDrawerMenu: FC<{ onClose: any }> = ({ onClose }) => {
  const location = useLocation();
  const { openModal } = useContext(ModalContext);
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const [isHovered, setIsHovered] = useState('');
  const [showMenu, setShowMenu] = useState(getActiveMenu(r));

  return (
    <DrawerWrapper logo={t('logo.black.logoSrc')} onClose={onClose} route={r(ROUTES.landingPageRoutes.tenant.path)}>
      <ul className={styles.wrapper}>
        <li>
          <button
            className={styles.parentItem}
            onClick={() => {
              if (showMenu === 'anbieter') {
                setShowMenu('');
              } else {
                setShowMenu('anbieter');
              }
            }}
            onMouseEnter={() => setIsHovered('anbieter')}
            onMouseLeave={() => setIsHovered('')}
          >
            <Text tag={'span'}>Anbieter</Text>
            <FontAwesomeIcon icon={isHovered === 'anbieter' ? faChevronUp : faChevronDown} />
          </button>
          <div className={`${styles.menu} ${showMenu === 'anbieter' ? styles.show : ''}`}>
            <ul className={styles.subMenu}>
              <li>
                <Link
                  onClick={onClose}
                  className={`${styles.linkWithIcon} ${
                    location?.pathname?.includes(r(ROUTES.landingPageRoutes.landlord.path)) ? styles.active : ''
                  }`}
                  to={r(ROUTES.landingPageRoutes.landlord.path)}
                >
                  <img src="/assets/icon/navigation/Für_private_Vermieter_Icon.svg" alt="fuer vermieter" />
                  <Text tag={'span'}>Für private Vermieter</Text>
                </Link>
              </li>
              <li>
                <Link
                  onClick={onClose}
                  className={`${styles.linkWithIcon} ${
                    location?.pathname?.includes(r(ROUTES.landingPageRoutes.propertyManagement.path))
                      ? styles.active
                      : ''
                  }`}
                  to={r(ROUTES.landingPageRoutes.propertyManagement.path)}
                >
                  <img src="/assets/icon/navigation/Für_Hausverwaltungen_Icon.svg" alt="fuer hausverwaltungen" />
                  <Text tag={'span'}>Für Hausverwaltungen</Text>
                </Link>
              </li>
              <li>
                <Link
                  onClick={onClose}
                  className={`${styles.linkWithIcon} ${
                    location?.pathname?.includes(r(ROUTES.landingPageRoutes.realEstateAgent.path)) ? styles.active : ''
                  }`}
                  to={r(ROUTES.landingPageRoutes.realEstateAgent.path)}
                >
                  <img src="/assets/icon/navigation/Für_Makler_Icon.svg" alt="fuer makler" />
                  <Text tag={'span'}>Für Makler</Text>
                </Link>
              </li>
              <li>
                <Link
                  onClick={onClose}
                  className={`${styles.linkWithIcon} ${
                    location?.pathname?.includes(r(ROUTES.landingPageRoutes.cooperative.path)) ? styles.active : ''
                  }`}
                  to={r(ROUTES.landingPageRoutes.cooperative.path)}
                >
                  <img src="/assets/icon/navigation/Für_Genossenschaften_Icon.svg" alt="fuer genossenschaften" />
                  <Text tag={'span'}>Für Genossenschaften</Text>
                </Link>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <button
            className={styles.parentItem}
            onMouseEnter={() => setIsHovered('about')}
            onMouseLeave={() => setIsHovered('')}
            onClick={() => {
              if (showMenu === 'about') {
                setShowMenu('');
              } else {
                setShowMenu('about');
              }
            }}
          >
            <Text tag={'span'}>Über uns</Text>
            <FontAwesomeIcon icon={isHovered === 'about' ? faChevronUp : faChevronDown} />
          </button>

          <div className={`${styles.menu} ${showMenu === 'about' ? styles.show : ''}`}>
            <ul>
              <li>
                <Link
                  onClick={onClose}
                  className={`${styles.linkWithIcon} ${
                    location?.pathname?.includes(r(ROUTES.landingPageRoutes.about.path)) ? styles.active : ''
                  }`}
                  to={r(ROUTES.landingPageRoutes.about.path)}
                >
                  <img src="/assets/icon/navigation/Über_Wohnsinn_Icon.svg" alt="ueber wohnsinn Icon" />
                  <Text tag={'span'}>Über Wohnsinn</Text>
                </Link>
              </li>
              <li>
                <Link
                  onClick={onClose}
                  className={`${styles.linkWithIcon} ${
                    location?.pathname?.includes(r(ROUTES.landingPageRoutes.contact.path)) ? styles.active : ''
                  }`}
                  to={r(ROUTES.landingPageRoutes.contact.path)}
                >
                  <img src="/assets/icon/navigation/Kontakt_Icon.svg" alt="kontakt Icon" />
                  <Text tag={'span'}>Kontakt</Text>
                </Link>
              </li>
              <li>
                <Link
                  onClick={onClose}
                  className={`${styles.linkWithIcon} ${
                    location?.pathname?.includes(r(ROUTES.landingPageRoutes.press.path)) ? styles.active : ''
                  }`}
                  to={r(ROUTES.landingPageRoutes.press.path)}
                >
                  <img src="/assets/icon/navigation/Presse_Icon.svg" alt="Presse Icon" />
                  <Text tag={'span'}>Presse</Text>
                </Link>
              </li>
              <li>
                <Link
                  onClick={onClose}
                  className={`${styles.linkWithIcon} ${
                    location?.pathname?.includes(r(ROUTES.landingPageRoutes.jobs.path)) ? styles.active : ''
                  }`}
                  to={r(ROUTES.landingPageRoutes.jobs.path)}
                >
                  <img src="/assets/icon/navigation/Jobs_Icon.svg" alt="Jobs Icon" />
                  <Text tag={'span'}>Jobs</Text>{' '}
                  <Badge text={'Wir stellen ein!'} color={BADGE_COLORS.SUCCESS} bold={false} />
                </Link>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <Link onClick={onClose} className={styles.parentItem} to={r(ROUTES.landingPageRoutes.prices.path)}>
            <Text tag={'span'}>Preise</Text>
          </Link>
        </li>
      </ul>

      <SplitLine />

      <CTAButton
        expand={'block'}
        onClick={() => openModal({ id: MODAL_IDENTIFIER.SELECT_USER_TYPE })}
        rounded={false}
        buttonStyle={BUTTON_STYLE.PRIMARY}
        buttonText={t('createAccount')}
      />
      <Spacer size={SPACER_SIZE.SM} />
      <CTAButton
        expand={'block'}
        customStyling={styles.hideMobile}
        onClick={() => openModal({ id: MODAL_IDENTIFIER.SIGN_IN })}
        rounded={false}
        buttonStyle={BUTTON_STYLE.SECONDARY}
        buttonText={t('signIn')}
      />
    </DrawerWrapper>
  );
};

export default LPDrawerMenu;
