import { FC } from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';
import styles from 'component/organisms/Tables/ApplicantsTable/ApplicantsTable.module.scss';
import Text, { TEXT_TYPE, TEXT_VARIANT } from 'component/atoms/typographie/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownArrowUp, faArrowDownWideShort, faArrowUpShortWide } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const ApplicantsTableHead: FC<{
  sortApplicantByIncome: () => void;
  sortAsc: boolean;
}> = ({ sortApplicantByIncome, sortAsc }) => {
  const { t: a } = useTranslation('common', { keyPrefix: 'view.ApplicationOverviewView' });
  return (
    <TableHead>
      <TableRow>
        <TableCell className={styles.applicantCell}>
          <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION} variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}>
            {a('tableHead.applicant')}
          </Text>
        </TableCell>
        <TableCell align={'center'}>
          <Text align={'center'} type={TEXT_TYPE.TEXT_TYPE_CAPTION} variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}>
            {a('tableHead.rating')}
          </Text>
        </TableCell>
        <TableCell align={'left'}>
          <Text align={'left'} type={TEXT_TYPE.TEXT_TYPE_CAPTION} variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}>
            {a('tableHead.chat')}
          </Text>
        </TableCell>
        <TableCell align={'right'} onClick={sortApplicantByIncome}>
          <div className={styles.sortIncome}>
            <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION} variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}>
              {a('tableHead.income')}
            </Text>

            <div className={styles.sortIcons}>
              <FontAwesomeIcon
                icon={sortAsc === null ? faArrowDownArrowUp : sortAsc ? faArrowDownWideShort : faArrowUpShortWide}
                size={'sm'}
              />
            </div>
          </div>
        </TableCell>
        <TableCell align={'right'}>
          <Text align={'right'} type={TEXT_TYPE.TEXT_TYPE_CAPTION} variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}>
            {a('tableHead.householdSize')}
          </Text>
        </TableCell>
        <TableCell align={'center'}>
          <Text align={'center'} type={TEXT_TYPE.TEXT_TYPE_CAPTION} variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}>
            {a('tableHead.selfDisclosure')}
          </Text>
        </TableCell>
        <TableCell align={'center'}>
          <Text align={'center'} type={TEXT_TYPE.TEXT_TYPE_CAPTION} variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}>
            {a('tableHead.action')}
          </Text>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default ApplicantsTableHead;
