import { Drawer } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { useNews } from '../../../core/hook/news.hook';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import DrawerWrapper from '../DrawerWrapper';
import ChatListItem from '../ChatListItem';
import { INews, USER_TYPE } from '@wohnsinn/ws-ts-lib';
import { format, isToday, isYesterday } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../core/const/routes';
import CTAButton, { BUTTON_STYLE } from '../../atoms/Buttons/CTAButton';
import styles from './NewsDrawer.module.scss';
import { TEXT_COLOR } from '../../atoms/typographie/Text';
import { wohnsinnServices } from '../../../App';
import UserContext from '../../../core/context/user.context';

const NewsDrawer: FC<{ isOpen: boolean; onClose: () => void }> = ({ isOpen, onClose }) => {
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const { data } = useNews();
  const navigate = useNavigate();
  const [selectedListType, setSelectedListType] = useState<'ALL' | 'UNREAD'>('ALL');
  const [selectedNews, setSelectedNews] = useState<INews[]>([]);
  const { newsService } = wohnsinnServices;
  const { landlordProfile } = useContext(UserContext);

  useEffect(() => {
    setSelectedNews(selectedListType === 'ALL' ? data?.allNotifications : data?.unreadNotifications);
  }, [data, selectedListType]);

  // Mutation for updating seenByLandlord
  const markAsSeenMutation = useMutation({
    mutationFn: async (newsId: string) => {
      await newsService.updateNews(newsId, { seenByLandlord: true });
    },

    onError: (error) => {
      console.error("Error updating 'seenByLandlord':", error);
    },
  });

  // Mutation for updating seenByLandlord for all news
  const markAllAsSeenMutation = useMutation({
    mutationFn: async () => {
      await newsService.batchUpdateNewsSeenByLandlord(landlordProfile.uid);
    },

    onError: (error) => {
      console.error("Error updating 'all seenByLandlord':", error);
    },
  });

  // Function to handle marking news as seen
  const handleMarkAsSeen = (newsId: string) => {
    markAsSeenMutation.mutate(newsId);
    onClose();
  };

  const handleClickOnNews = (news: INews) => {
    handleMarkAsSeen(news?.id);

    const route =
      news.type === 'NEW_MESSAGE'
        ? r(ROUTES.landlordRoutes.chat.pathWithParams)
            .replace(':apartmentId', news.apartmentId)
            .replace(':tenantId', news.tenantId)
        : r(ROUTES.landlordRoutes.apartment.applications.path).replace(':apartmentId', news.apartmentId);

    navigate(route);
    onClose();
  };

  let lastMessageDateLabel = '';

  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose}>
      <DrawerWrapper onClose={onClose} title={t('notifications')}>
        <div className={styles.mobileButtonWrapper}>
          <div className={styles.buttonWrapper}>
            <CTAButton
              expand={'block'}
              buttonStyle={selectedListType === 'ALL' ? BUTTON_STYLE.PRIMARY : BUTTON_STYLE.SECONDARY}
              onClick={() => setSelectedListType('ALL')}
              buttonText={'Alle'}
            />
            <CTAButton
              expand={'block'}
              buttonStyle={selectedListType === 'UNREAD' ? BUTTON_STYLE.PRIMARY : BUTTON_STYLE.SECONDARY}
              onClick={() => setSelectedListType('UNREAD')}
              buttonText={'Ungelesen'}
            />
          </div>
          <CTAButton
            buttonStyle={BUTTON_STYLE.TERTIARY}
            buttonText={t('markAllAsRead')}
            onClick={() => markAllAsSeenMutation.mutate()}
            color={TEXT_COLOR.TEXT_COLOR_DARK}
          />
        </div>
        <ul>
          {selectedNews?.map((news) => {
            const createdAtDate = news.createdAt.toDate();
            let currentMessageDateLabel = '';

            if (isToday(createdAtDate)) {
              currentMessageDateLabel = t('today');
            } else if (isYesterday(createdAtDate)) {
              currentMessageDateLabel = t('yesterday');
            } else {
              currentMessageDateLabel = t('older');
            }

            const showDateLabel = lastMessageDateLabel !== currentMessageDateLabel;
            lastMessageDateLabel = currentMessageDateLabel;
            return (
              <>
                {showDateLabel && <h3 style={{ fontSize: '1rem', margin: '1rem 0' }}>{currentMessageDateLabel}</h3>}

                <ChatListItem
                  key={news.id}
                  senderName={news.type === 'APPLICATION' ? '💌 Neue Bewerbung' : '📬 Neue Nachricht'}
                  mediaUrl={news.tenantPhotoUrl}
                  showNotificationBadge={!news.seenByLandlord}
                  date={
                    !isToday(createdAtDate) && !isYesterday(createdAtDate)
                      ? format(news.createdAt.toDate(), 'dd.MM.yyyy')
                      : format(news.createdAt.toDate(), 'HH:mm')
                  }
                  onClick={() => handleClickOnNews(news)}
                  userType={USER_TYPE.LANDLORD}
                  message={`von ${news.tenantName}`}
                  subline={`${news.address.street} ${news.address.houseNumber}, ${news.address.city}`}
                />
              </>
            );
          })}
        </ul>
      </DrawerWrapper>
    </Drawer>
  );
};

export default NewsDrawer;
