import { FC } from 'react';
import styles from './NotificationBadge.module.scss';
import Text, { TEXT_COLOR, TEXT_TYPE, TEXT_VARIANT } from '../typographie/Text';

export enum NOTIFICATION_BADGE_SIZE {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  REGULAR = 'REGULAR',
}
export interface INotificationBadgeProps {
  count?: number | string;
  size?: NOTIFICATION_BADGE_SIZE;
}

const NotificationBadge: FC<INotificationBadgeProps> = ({ count, size = NOTIFICATION_BADGE_SIZE.REGULAR }) => {
  return (
    <div className={`${styles.badge} ${styles[size]} ${count ? '' : styles.empty}`}>
      <Text
        tag={'span'}
        color={TEXT_COLOR.TEXT_COLOR_LIGHT}
        type={
          size === NOTIFICATION_BADGE_SIZE.SMALL
            ? TEXT_TYPE.TEXT_TYPE_CAPTION
            : size === NOTIFICATION_BADGE_SIZE.MEDIUM
            ? TEXT_TYPE.TEXT_TYPE_CAPTION
            : TEXT_TYPE.TEXT_TYPE_BODY
        }
        variant={
          size === NOTIFICATION_BADGE_SIZE.SMALL ? TEXT_VARIANT.TEXT_VARIANT_SMALL : TEXT_VARIANT.TEXT_VARIANT_LARGE
        }
        bold
      >
        {count ? count : null}
      </Text>
    </div>
  );
};

export default NotificationBadge;
