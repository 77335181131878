import { FC } from 'react';
import { IApplication } from '@wohnsinn/ws-ts-lib';
import { Grid } from '@mui/material';
import ApplicationCard from 'component/molecules/Cards/ApplicationCard';

const ApartmentApplicationsGrid: FC<{ applications: IApplication[] }> = ({ applications }) => {
  return (
    <Grid container spacing={2}>
      {applications.map((application) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={`${application.apartmentId}-${application.tenantProfile.uid}`}>
          <ApplicationCard application={application} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ApartmentApplicationsGrid;
