import { FC } from 'react';
import styles from './CheckList.module.scss';
import Text, { TEXT_COLOR, TEXT_TYPE } from '../typographie/Text';

export interface ICheckListItem {
  text: string;
  bold?: boolean;
  invert?: boolean;
}

export interface ICheckListProps {
  items: ICheckListItem[];
  accentColor?: boolean;
}

const CheckList: FC<ICheckListProps> = ({ items, accentColor }) => {
  const renderCheckListItems = () =>
    items.map((item, index) => (
      <li className={styles.item} key={index}>
        <img
          data-testid={`checkList.img-${index}`}
          src={item.invert ? '/assets/icon/check.svg' : '/assets/icon/check_alt.svg'}
          alt=""
        />
        <Text
          color={accentColor ? TEXT_COLOR.TEXT_COLOR_ACCENT : null}
          type={TEXT_TYPE.TEXT_TYPE_LANDING}
          bold={item.bold}
        >
          {item.text}
        </Text>
      </li>
    ));
  return <ul className={styles.list}>{renderCheckListItems()}</ul>;
};

export default CheckList;
