import { FormEvent, useContext, useRef } from 'react';
import CTAButton from '../CTAButton';
import { ICropImageModalProps } from '../../../modals/CropImageModal';
import { MODAL_IDENTIFIER } from '../../../../core/enum/modals.enum';
import { useTranslation } from 'react-i18next';
import SnackBarContext from '../../../../core/context/snackbar.context';
import ModalContext from '../../../../core/context/modal.context';
import UserContext from '../../../../core/context/user.context';
import { wohnsinnServices } from '../../../../App';
import { ITenantProfileInformationProps } from '../../../molecules/ProfileAvatar';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

const UploadProfileImageButton = ({ onUpload, storagePath, onClose }: ITenantProfileInformationProps) => {
  const { t } = useTranslation('common');
  const inputRef = useRef<HTMLInputElement>(null);
  const { handleSnackBar } = useContext(SnackBarContext);
  const { openModal } = useContext(ModalContext);
  const { user } = useContext(UserContext);
  const { firebaseStorageService } = wohnsinnServices;

  const handleProfileImageUpload = async (blobPng: Blob) => {
    try {
      const downloadUrl = await firebaseStorageService.uploadBlob(blobPng, storagePath, user.uid, 'image/png');
      onUpload(downloadUrl);

      handleSnackBar('toast.profileImage.uploaded', 'info');
    } catch (e) {
      console.error(e);
    }
  };

  const checkImageDimensions = (file: File): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        if (img.width >= 350 && img.height >= 350) {
          resolve(true);
        } else {
          handleSnackBar(t('toast.profileImage.dimensionsError'), 'error');
          resolve(false);
        }
      };
      img.onerror = () => {
        reject(new Error('Image loading error'));
      };
      img.src = URL.createObjectURL(file);
    });
  };

  const onImageChange = async (fileList: File[]) => {
    const isValid = await checkImageDimensions(fileList[0]);
    if (isValid) {
      openModal<ICropImageModalProps>({
        id: MODAL_IDENTIFIER.CROP_IMAGE,
        data: { image: fileList[0], onCropFinish: handleProfileImageUpload, onClose },
      });
    }
    inputRef.current.value = '';
  };

  const onChangeCapture = (e: FormEvent<HTMLInputElement>) => {
    const fileList: FileList | null = (e?.target as HTMLInputElement)?.files;
    if (fileList?.length) {
      return onImageChange(Array.from(fileList));
    }
  };

  return (
    <>
      <CTAButton icon={faPlus} buttonText={'Profilbild hochladen'} onClick={() => inputRef?.current?.click()} />
      <input
        hidden
        type="file"
        accept={'image/jpeg, image/png, image/heic'}
        ref={inputRef}
        onChangeCapture={onChangeCapture}
      />
    </>
  );
};

export default UploadProfileImageButton;
