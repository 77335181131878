import { FC } from 'react';
import styles from './ApartmentCardApartmentInformation.module.scss';
import Text, { TEXT_COLOR } from 'component/atoms/typographie/Text';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import { useTranslation } from 'react-i18next';
import { getFormattedPrice } from 'core/helper/get-formatted-price';
import { IApartmentCardInformation } from '../../ApartmentCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/pro-light-svg-icons';
import truncateText from 'core/helper/truncate-text';

const ApartmentCardApartmentInformation: FC<{
  apartmentCardInformation: IApartmentCardInformation;
  clickHandler: () => void;
  showFullAddress?: boolean;
}> = ({ apartmentCardInformation, clickHandler, showFullAddress = false }) => {
  const { cost, rooms, address, size } = apartmentCardInformation;
  const { t } = useTranslation('common');
  return (
    <button onClick={clickHandler} type={'button'} className={styles.content}>
      <div className={styles.bar}>
        <p>
          <FontAwesomeIcon icon={faLocationDot} />{' '}
          <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
            {truncateText(
              `${address.postalCode} ${address.city}${
                showFullAddress ? `, ${address.street} ${address.houseNumber}` : ''
              }`,
              36
            )}
          </Text>
        </p>
      </div>

      <div className={styles.bar}>
        <Headline size={HEADLINE_SIZE.H3}>{getFormattedPrice(cost?.warmRent, cost?.coldRent)}</Headline>
        <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
          {size ? `${size} ${t('qm')}` : ''}
        </Text>
        <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
          {rooms ? `${rooms} ${t('rooms')}` : ''}
        </Text>
      </div>
    </button>
  );
};

export default ApartmentCardApartmentInformation;
