import { Dispatch, FC, SetStateAction } from 'react';
import { Grid } from '@mui/material';
import CTAButton, { BUTTON_STYLE } from 'component/atoms/Buttons/CTAButton';
import styles from './ApplicationFilter.module.scss';
import SelectNumber from 'component/atoms/SelectNumber';
import WsCheckbox from 'component/atoms/WsCheckbox';
import { useTranslation } from 'react-i18next';
import Spacer, { SPACER_SIZE } from 'component/atoms/Spacer';
import Text, { TEXT_COLOR } from 'component/atoms/typographie/Text';
import SelectRange from 'component/molecules/SelectRange';

export interface IRange {
  low: number;
  high: number;
}

export interface APPLIED_FILTER {
  ageRange: IRange;
  hasPledge: boolean;
  numberOfPeopleMovingIn: number;
  petsForbidden: boolean;
  sharedUsageForbidden: boolean;
  hasIncomeProof: boolean;
  hasSchufa: boolean;
  hasIntroductionVideo: boolean;
  jobs: {
    NONE: boolean;
    SELF_EMPLOYED: boolean;
    STUDENT: boolean;
    EMPLOYED: boolean;
    TRAINEE: boolean;
    CUSTOM: boolean;
    FREELANCER: boolean;
    PHD_STUDENT: boolean;
    SEEKING: boolean;
    HOUSEKEEPER: boolean;
    RENT: boolean;
    OFFICIAL: boolean;
  };
}

export const INITIAL_FILTER_STATE: APPLIED_FILTER = {
  hasPledge: false,
  hasIncomeProof: false,
  hasIntroductionVideo: false,
  hasSchufa: false,
  numberOfPeopleMovingIn: null,
  petsForbidden: false,
  sharedUsageForbidden: false,
  jobs: {
    NONE: false,
    SELF_EMPLOYED: false,
    STUDENT: false,
    EMPLOYED: false,
    TRAINEE: false,
    CUSTOM: false,
    FREELANCER: false,
    PHD_STUDENT: false,
    SEEKING: false,
    HOUSEKEEPER: false,
    RENT: false,
    OFFICIAL: false,
  },
  ageRange: { low: 0, high: 100 },
};

const ApplicationFilters: FC<{
  appliedFilters: APPLIED_FILTER;
  setAppliedFilters: Dispatch<SetStateAction<APPLIED_FILTER>>;
  onClose: () => void;
  resetFilter?: () => void;
}> = ({ appliedFilters, setAppliedFilters, onClose, resetFilter }) => {
  const { t } = useTranslation('common');

  const handleOptionChange = (key: any, value: any) => {
    setAppliedFilters({
      ...appliedFilters,
      [key]: value,
    });
  };

  const handleJobChange = (key: any, value: any) => {
    handleOptionChange('jobs', {
      ...appliedFilters.jobs,
      [key]: value,
    });
  };

  return (
    <div className={styles.wrapper}>
      <Grid container>
        <Grid item xs={12}>
          <Spacer />
          <Text underline>Altersspanne</Text>
          <Spacer size={SPACER_SIZE.SM} />
        </Grid>
        <Grid item xs={12}>
          <SelectRange
            value={appliedFilters.ageRange}
            onChange={(value: any) => handleOptionChange('ageRange', value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Spacer />
          <Text underline>Max. Personenanzahl</Text>
          <Spacer size={SPACER_SIZE.SM} />
        </Grid>
        <Grid item xs={12}>
          <SelectNumber
            step={1}
            disabled={false}
            placeHolder={'Max. Personenanzahl'}
            max={99}
            min={0}
            value={appliedFilters.numberOfPeopleMovingIn ?? 0}
            onChange={(value: any) => handleOptionChange('numberOfPeopleMovingIn', value)}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Spacer />
        <Text underline>Beschäftigungsart</Text>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <WsCheckbox
            checked={appliedFilters.jobs.SEEKING}
            label={t('jobStatus.SEEKING')}
            onChange={(event: any) => handleJobChange('SEEKING', event.target.checked)}
          />
        </Grid>
        <Grid item xs={6}>
          <WsCheckbox
            checked={appliedFilters.jobs.STUDENT}
            label={t('jobStatus.STUDENT')}
            onChange={(event: any) => handleJobChange('STUDENT', event.target.checked)}
          />
        </Grid>
        <Grid item xs={6}>
          <WsCheckbox
            checked={appliedFilters.jobs.PHD_STUDENT}
            label={t('jobStatus.PHD_STUDENT')}
            onChange={(event: any) => handleJobChange('PHD_STUDENT', event.target.checked)}
          />
        </Grid>
        <Grid item xs={6}>
          <WsCheckbox
            checked={appliedFilters.jobs.EMPLOYED}
            label={t('jobStatus.EMPLOYED')}
            onChange={(event: any) => handleJobChange('EMPLOYED', event.target.checked)}
          />
        </Grid>
        <Grid item xs={6}>
          <WsCheckbox
            checked={appliedFilters.jobs.OFFICIAL}
            label={t('jobStatus.OFFICIAL')}
            onChange={(event: any) => handleJobChange('OFFICIAL', event.target.checked)}
          />
        </Grid>
        <Grid item xs={6}>
          <WsCheckbox
            checked={appliedFilters.jobs.SELF_EMPLOYED}
            label={t('jobStatus.SELF_EMPLOYED')}
            onChange={(event: any) => handleJobChange('SELF_EMPLOYED', event.target.checked)}
          />
        </Grid>
        <Grid item xs={6}>
          <WsCheckbox
            checked={appliedFilters.jobs.NONE}
            label={t('jobStatus.NONE')}
            onChange={(event: any) => handleJobChange('NONE', event.target.checked)}
          />
        </Grid>
        <Grid item xs={6}>
          <WsCheckbox
            checked={appliedFilters.jobs.TRAINEE}
            label={t('jobStatus.TRAINEE')}
            onChange={(event: any) => handleJobChange('TRAINEE', event.target.checked)}
          />
        </Grid>
        <Grid item xs={6}>
          <WsCheckbox
            checked={appliedFilters.jobs.CUSTOM}
            label={t('jobStatus.CUSTOM')}
            onChange={(event: any) => handleJobChange('CUSTOM', event.target.checked)}
          />
        </Grid>
        <Grid item xs={6}>
          <WsCheckbox
            checked={appliedFilters.jobs.FREELANCER}
            label={t('jobStatus.FREELANCER')}
            onChange={(event: any) => handleJobChange('FREELANCER', event.target.checked)}
          />
        </Grid>
        <Grid item xs={6}>
          <WsCheckbox
            checked={appliedFilters.jobs.HOUSEKEEPER}
            label={t('jobStatus.HOUSEKEEPER')}
            onChange={(event: any) => handleJobChange('HOUSEKEEPER', event.target.checked)}
          />
        </Grid>
        <Grid item xs={6}>
          <WsCheckbox
            checked={appliedFilters.jobs.RENT}
            label={t('jobStatus.RENT')}
            onChange={(event: any) => handleJobChange('RENT', event.target.checked)}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Spacer />
          <Text underline>Weitere Filter</Text>
        </Grid>

        <Grid item xs={12}>
          <WsCheckbox
            checked={appliedFilters.hasIntroductionVideo}
            label={'Mit Vorstellungsvideo'}
            onChange={(event: any) => handleOptionChange('hasIntroductionVideo', event.target.checked)}
          />
        </Grid>

        <Grid item xs={12}>
          <WsCheckbox
            checked={appliedFilters.hasIncomeProof}
            label={'Mit Einkommensnachweis'}
            onChange={(event: any) => handleOptionChange('hasIncomeProof', event.target.checked)}
          />
        </Grid>
        <Grid item xs={12}>
          <WsCheckbox
            checked={appliedFilters.hasSchufa}
            label={'Mit Schufa-Auskunft'}
            onChange={(event: any) => handleOptionChange('hasSchufa', event.target.checked)}
          />
        </Grid>
        <Grid item xs={12}>
          <WsCheckbox
            checked={appliedFilters.hasPledge}
            label={'Mit Bürgschaft'}
            onChange={(event: any) => handleOptionChange('hasPledge', event.target.checked)}
          />
        </Grid>

        <Grid item xs={12}>
          <WsCheckbox
            checked={appliedFilters.petsForbidden}
            label={'Keine Haustier'}
            onChange={(event: any) => handleOptionChange('petsForbidden', event.target.checked)}
          />
        </Grid>
        <Grid item xs={12}>
          <WsCheckbox
            checked={appliedFilters.sharedUsageForbidden}
            label={'Keine WG-Nutzung'}
            onChange={(event: any) => handleOptionChange('sharedUsageForbidden', event.target.checked)}
          />
        </Grid>
        <Grid item xs={12}>
          {resetFilter ? (
            <CTAButton
              buttonStyle={BUTTON_STYLE.TERTIARY}
              color={TEXT_COLOR.TEXT_COLOR_DARK}
              customStyling={styles.submit}
              buttonText={'Filter zurücksetzen'}
              onClick={resetFilter}
              rounded={false}
              expand={'block'}
            />
          ) : (
            <Spacer />
          )}
          <CTAButton
            customStyling={styles.submit}
            buttonText={'Ergebnisse anzeigen'}
            onClick={onClose}
            rounded={false}
            expand={'block'}
          />
          <Spacer />
        </Grid>
      </Grid>
    </div>
  );
};

export default ApplicationFilters;
