import { FC, useEffect } from 'react';
import PageLayout from '../../../../component/layouts/PageLayout';
import { ROUTES } from '../../../../core/const/routes';
import AccountBreadcrumbs from '../../../../component/molecules/Breadcrumbs/AccountBreadcrumbs';
import { useTranslation } from 'react-i18next';
import TenantAboutMeForm from '../../../../component/forms/tenant/TenantAboutMeForm';
import TunnelStepper, {
  ITunnelStepperStepProps,
  TUNNEL_STEP_STATE,
} from '../../../../component/organisms/Tunnels/TunnelStepper';
import { wohnsinnServices } from 'App';
import useWindowSize from 'core/hook/windowsize.hook';

export interface IAccountStepperViewProps {
  isApplicationFolderTunnel?: boolean;
}

const APPLICATION_FOLDER_STEPS_ABOUT_ME: ITunnelStepperStepProps[] = [
  { label: 'Profil', state: TUNNEL_STEP_STATE.ACTIVE, link: ROUTES.tenantRoutes.account.applicationFolder.profile },
  { label: 'Haushalt', state: TUNNEL_STEP_STATE.ACTIVE, link: ROUTES.tenantRoutes.account.applicationFolder.household },
  {
    label: 'Vorstellungsvideo',
    state: TUNNEL_STEP_STATE.ACTIVE,
    link: ROUTES.tenantRoutes.account.applicationFolder.introductionVideo,
  },
  { label: 'Über mich', state: TUNNEL_STEP_STATE.CURRENT, link: ROUTES.tenantRoutes.account.applicationFolder.aboutMe },
  { label: 'Schufa', state: TUNNEL_STEP_STATE.INACTIVE, link: ROUTES.tenantRoutes.account.applicationFolder.schufa },
  {
    label: 'Einkommensnachweis',
    state: TUNNEL_STEP_STATE.INACTIVE,
    link: ROUTES.tenantRoutes.account.applicationFolder.incomeProof,
  },
];

const AboutMeView: FC<IAccountStepperViewProps> = ({ isApplicationFolderTunnel = false }) => {
  const { t: r } = useTranslation('routes');
  const { mixpanelTrackingService } = wohnsinnServices;
  const { isSmallerMd } = useWindowSize();

  useEffect(() => {
    mixpanelTrackingService.trackEnterPage('AboutMeView');
  }, []);

  return (
    <PageLayout
      showPageTitle={false}
      pageTitle={r(ROUTES.tenantRoutes.account.aboutMe.title)}
      showBackButton={isSmallerMd}
    >
      {isApplicationFolderTunnel ? (
        <TunnelStepper steps={APPLICATION_FOLDER_STEPS_ABOUT_ME} />
      ) : (
        <AccountBreadcrumbs page={ROUTES.tenantRoutes.account.aboutMe} />
      )}

      <TenantAboutMeForm isApplicationFolderTunnel={isApplicationFolderTunnel} />
    </PageLayout>
  );
};

export default AboutMeView;
