import { FC } from 'react';
import { USER_TYPE } from '@wohnsinn/ws-ts-lib';
import styles from './ChatListItem.module.scss';
import { useTranslation } from 'react-i18next';
import Avatar, { AVATAR_SIZE } from 'component/atoms/Avatar';
import Text, { TEXT_COLOR, TEXT_TYPE } from 'component/atoms/typographie/Text';
import SanitizedHTML from 'component/atoms/SanitzedHtml';

export interface IChatListItemProps {
  onClick: any;
  isActive?: boolean;
  showNotificationBadge?: boolean;
  userType: USER_TYPE;
  mediaUrl?: string;
  senderName?: string;
  text?: string;
  message?: string;
  date?: any;
  preMessage?: string;
  subline?: string;
}

const ChatListItem: FC<IChatListItemProps> = ({
  isActive,
  onClick,
  mediaUrl,
  showNotificationBadge,
  userType,
  senderName,
  date,
  message,
  preMessage,
  subline,
}) => {
  const { t } = useTranslation('common');

  return (
    <li className={`${styles.wrapper} ${isActive && styles.isActive}`} onClick={onClick}>
      <div className={styles.avatar}>
        <div className={`${styles.newMessageIndicator} ${showNotificationBadge ? styles.show : ''}`}>
          {showNotificationBadge && <span className="sr-only">{t('newMessage')}</span>}
        </div>
        <Avatar size={AVATAR_SIZE.lg} avatarUrl={mediaUrl} isTenant={userType !== USER_TYPE.TENANT} />
      </div>

      <div className={styles.content}>
        <div className={styles.top}>
          <Text tag={'span'} bold={showNotificationBadge} type={TEXT_TYPE.TEXT_TYPE_BODY}>
            {senderName}
          </Text>
          <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION}>{date}</Text>
        </div>
        <div className={styles.bottom}>
          <div className={styles.message}>
            {message && (
              <div className={styles.text}>
                <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION} color={TEXT_COLOR.TEXT_COLOR_ACCENT} tag={'span'}>
                  {preMessage ? (
                    <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION} color={TEXT_COLOR.TEXT_COLOR_ACCENT} tag={'span'} bold>
                      {preMessage}
                    </Text>
                  ) : null}

                  <SanitizedHTML dirty={message} removeNewLines />
                </Text>
              </div>
            )}
          </div>
          {subline ? (
            <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT} type={TEXT_TYPE.TEXT_TYPE_CAPTION} tag="span">
              {subline}
            </Text>
          ) : null}
        </div>
      </div>
    </li>
  );
};

export default ChatListItem;
