import { FC } from 'react';
import styles from './ToggleButton.module.scss';
import Text, { TEXT_TYPE } from 'component/atoms/typographie/Text';

export interface IToggleButtonOption {
  text: string;
  onClick: () => void;
  active: boolean;
}

const ToggleButton: FC<{ option1: IToggleButtonOption; option2: IToggleButtonOption }> = ({ option1, option2 }) => {
  return (
    <div className={styles.wrapper}>
      <button
        className={`${styles.option} ${option1.active ? styles.active : ''}`}
        type={'button'}
        onClick={option1.onClick}
      >
        <Text tag={'span'} bold type={TEXT_TYPE.TEXT_TYPE_LANDING}>
          {option1.text}
        </Text>
      </button>
      <button
        className={`${styles.option} ${option2.active ? styles.active : ''}`}
        type={'button'}
        onClick={option2.onClick}
      >
        <Text tag={'span'} bold type={TEXT_TYPE.TEXT_TYPE_LANDING}>
          {option2.text}
        </Text>
      </button>
    </div>
  );
};

export default ToggleButton;
