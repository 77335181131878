import {
  FIRESTORE_COLLECTION_PATH,
  ISelectOption,
  ITenantPersonalInformation,
  JOB_STATUS,
  TENANT_PERSONAL_INFORMATION_SCHEMA,
} from '@wohnsinn/ws-ts-lib';
import { FormContext } from 'core/context/form.context';
import InputText from 'component/atoms/formElement/InputText';
import UserContext from 'core/context/user.context';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { useTranslation } from 'react-i18next';
import { FC, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import FormSectionTitle from 'component/atoms/FormSectionTitle';
import Dropdown from 'component/atoms/formElement/Dropdown';
import InputOptionList, { RADIO_INPUT_COLUMNS_COUNT } from 'component/atoms/formElement/InputOptionList';
import { BOOLEAN_SELECT_OPTIONS, getTranslatedOptions } from 'core/const/select-options';
import { ROUTES } from 'core/const/routes';
import PageTitle from 'component/molecules/PageTitle';
import ProfileAvatar from 'component/molecules/ProfileAvatar';
import { wohnsinnServices } from 'App';
import { Grid } from '@mui/material';
import SnackBarContext from 'core/context/snackbar.context';
import InfoText from 'component/molecules/InfoText';
import InfoTextContent from '../InfoTextContent';
import LOCAL_STORAGE_KEYS from 'core/enum/local-storage.enum';
import DatePicker from 'component/atoms/formElement/DateInput';
import { useNavigate } from 'react-router-dom';
import FormLayout from 'component/organisms/FormLayout';
import InfoBox, { INFO_BOX_ICONS } from 'component/atoms/InfoBox';

export const DEFAULT_TENANT_PERSONAL_INFORMATION_DATA: ITenantPersonalInformation = {
  dateOfBirth: null,
  firstName: null,
  hasInsolvencyProceedings: null,
  hadEvictions: null,
  jobStatus: null,
  employerName: null,
  customJobDescription: null,
  lastName: null,
  phoneNumber: null,
};

export const TENANT_JOB_SELECT_OPTION_LIST: ISelectOption<JOB_STATUS>[] = [
  { value: JOB_STATUS.EMPLOYED, label: 'Angestellte/r' },
  { value: JOB_STATUS.SEEKING, label: 'Arbeitssuchende/r' },
  { value: JOB_STATUS.TRAINEE, label: 'Auszubildene/r' },
  { value: JOB_STATUS.OFFICAL, label: 'Beamte/r' },
  { value: JOB_STATUS.PHD_STUDENT, label: 'Doktorand/in' },
  { value: JOB_STATUS.HOUSEKEEPER, label: 'Hausfrau/mann' },
  { value: JOB_STATUS.FREELANCER, label: 'Freiberufler/in' },
  { value: JOB_STATUS.RENT, label: 'Rentner/in' },
  { value: JOB_STATUS.SELF_EMPLOYED, label: 'Selbstständige/r' },
  { value: JOB_STATUS.STUDENT, label: 'Studierende/r' },
  { value: JOB_STATUS.CUSTOM, label: 'Eigene Angabe' },
];

export interface ITenantFormProps {
  isApplicationFolderTunnel?: boolean;
}

const TenantProfileForm: FC<ITenantFormProps> = ({ isApplicationFolderTunnel }) => {
  const { tenantProfile, user } = useContext(UserContext);
  const PROFILE_IMAGE_PATH = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.root.replace('{uid}', user.uid)}/${
    user.uid
  }/profileImage.png`;
  const { tenantService, firebaseStorageService } = wohnsinnServices;
  const { handleSnackBar } = useContext(SnackBarContext);
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const { t: p } = useTranslation('common', { keyPrefix: 'view.AccountView.TenantProfileView' });
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const [employerNamePlaceholder, setEmployerNamePlaceholder] = useState<{ placeholder: string }>(null);
  const [showJobDescription, setShowJobDescription] = useState<{ showField: boolean; required: boolean }>({
    showField: true,
    required: true,
  });
  const { mixpanelTrackingService } = wohnsinnServices;
  const navigate = useNavigate();
  const redirectUrl = localStorage.getItem(LOCAL_STORAGE_KEYS.REDIRECT_URL);
  const [jobDescription, setJobDescription] = useState<{ placeholder: string; label: string }>({
    placeholder: 'Beruf',
    label: 'Beruf',
  });

  const { control, reset, watch, setValue, handleSubmit } = useForm<ITenantPersonalInformation>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: joiResolver(Joi.object(TENANT_PERSONAL_INFORMATION_SCHEMA)),
    defaultValues: DEFAULT_TENANT_PERSONAL_INFORMATION_DATA,
  });

  useEffect(() => {
    mixpanelTrackingService.trackEnterPage('TenantProfileForm');
  }, []);

  const onSuccess = async (): Promise<void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
    try {
      handleSnackBar('toast.profileUpdated', 'success');
      if (isApplicationFolderTunnel) {
        navigate(r(ROUTES.tenantRoutes.account.applicationFolder.household));
      } else {
        navigate(r(ROUTES.tenantRoutes.account.overview.path));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const updateTenantProfile = async (): Promise<boolean | void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    try {
      await tenantService.updateTenantPersonalInformation(tenantProfile.uid, watch() as ITenantPersonalInformation);
      await onSuccess();
    } catch (e) {
      console.error('Error on updateTenantProfile: ', e);
    }
  };

  useEffect(() => {
    if (tenantProfile?.personalInformation) {
      reset(tenantProfile.personalInformation);
      handleJobLabel(tenantProfile.personalInformation.jobStatus, true);
    }
  }, [tenantProfile]);

  const handleJobLabel = (jobStatus: JOB_STATUS, isInitial = true) => {
    setJobDescription({ placeholder: 'Beruf', label: 'Beruf' });
    setEmployerNamePlaceholder(null);
    if (isInitial === false && jobStatus !== tenantProfile?.personalInformation?.jobStatus) {
      setValue('employerName', null);
      setValue('customJobDescription', null);
    }
    switch (jobStatus) {
      case JOB_STATUS.FREELANCER:
      case JOB_STATUS.OFFICAL:
        setShowJobDescription({ showField: true, required: true });
        setEmployerNamePlaceholder({ placeholder: 'Arbeitgeber' });

        break;
      case JOB_STATUS.SEEKING:
        setShowJobDescription({ showField: true, required: false });
        break;
      case JOB_STATUS.TRAINEE:
      case JOB_STATUS.EMPLOYED:
        setShowJobDescription({ showField: true, required: true });
        setEmployerNamePlaceholder({ placeholder: 'Arbeitgeber' });
        break;
      case JOB_STATUS.SELF_EMPLOYED:
        setShowJobDescription({ showField: true, required: true });
        setEmployerNamePlaceholder({ placeholder: 'Firma' });
        break;
      case JOB_STATUS.PHD_STUDENT:
      case JOB_STATUS.STUDENT:
        setShowJobDescription({ showField: true, required: true });
        setEmployerNamePlaceholder({ placeholder: 'Universität' });
        break;
      case JOB_STATUS.CUSTOM:
        setShowJobDescription({ showField: true, required: true });
        setJobDescription({ placeholder: 'Eigene Angabe', label: 'Eigene Angabe' });
        break;
      default:
        setShowJobDescription({ showField: false, required: true });
        setEmployerNamePlaceholder(null);
    }
  };

  const handleError = (e: any) => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
    console.error(e);
  };

  const handleRoute = () => {
    if (redirectUrl) {
      navigate(redirectUrl);
      localStorage.removeItem(LOCAL_STORAGE_KEYS.REDIRECT_URL);
    } else {
      navigate('/');
    }
  };

  const CANCEL_BUTTON = {
    text: isApplicationFolderTunnel ? t('back') : t('cancel'),
    action: () => (isApplicationFolderTunnel ? handleRoute() : navigate(r(ROUTES.tenantRoutes.account.overview.path))),
  };

  const handleProfileImageUpload = async (photoUrl: string) => {
    try {
      await tenantService.updateTenantProfilePicture(user.uid, photoUrl);
    } catch (e) {
      console.error('Error on handleProfileImageUpload: ', e);
    }
  };

  const deleteProfileImage = async () => {
    try {
      await tenantService.updateTenantProfilePicture(user.uid, null);
      await firebaseStorageService.deleteFiles([PROFILE_IMAGE_PATH]);
      handleSnackBar('toast.profileImage.deleted', 'success');
    } catch (e) {
      console.error('Error on deleteProfileImage', e);
    }
  };

  return (
    <FormContext.Provider value={{ control, setValue, watch }}>
      <form onSubmit={handleSubmit(updateTenantProfile, handleError)}>
        <FormLayout isTunnel={isApplicationFolderTunnel} submitMode={buttonSubmitMode} notSubmitButton={CANCEL_BUTTON}>
          <PageTitle
            pageTitle={r(ROUTES.tenantRoutes.account.profile.title)}
            notSubmitButton={CANCEL_BUTTON}
            showSubmitButton
            submitMode={buttonSubmitMode}
            submitText={isApplicationFolderTunnel ? 'next' : 'save'}
          />
          <Grid container spacing={4}>
            <Grid item xs={12} md={7} lg={8}>
              <Grid container rowSpacing={4}>
                <Grid item xs={12}>
                  <FormSectionTitle isFirst title={p('profilePicture')} />

                  <ProfileAvatar
                    storagePath={PROFILE_IMAGE_PATH}
                    onUpload={handleProfileImageUpload}
                    onDelete={deleteProfileImage}
                    photoUrl={tenantProfile?.photoUrl}
                  />
                </Grid>

                <Grid item xs={12}>
                  <InfoBox
                    highlightComponent={<b />}
                    text={t('uploadProfileImage.info')}
                    icon={INFO_BOX_ICONS.LIGHT_BULB}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <FormSectionTitle title={p('personalInformation')} />
                  <InputText label={t('firstName.label')} name={'firstName'} required />
                  <InputText label={t('lastName.label')} name={'lastName'} required />
                  <InputText type={'tel'} label={t('phoneNumber.label')} name={'phoneNumber'} required />
                </Grid>

                <Grid item xs={12}>
                  <FormSectionTitle title={`${t('dateOfBirth.label')}*`} />
                  <DatePicker name={'dateOfBirth'} required />
                </Grid>
                <Grid item xs={12}>
                  <FormSectionTitle title={t('currentAddress.label')} />
                  <InputText label={t('address.city.label')} name={'address.city'} required />
                  <InputText label={t('address.street.label')} name={'address.street'} required />
                  <InputText label={t('address.houseNumber.label')} name={'address.houseNumber'} required />
                  <InputText label={t('address.postalCode.label')} name={'address.postalCode'} required />
                </Grid>
                <Grid item xs={12}>
                  <FormSectionTitle title={p('jobSection')} />
                  <Dropdown
                    name="jobStatus"
                    label={p('jobStatus.label')}
                    placeholder={p('jobStatus.placeholder')}
                    optionList={TENANT_JOB_SELECT_OPTION_LIST}
                    onChange={(e: any) => handleJobLabel(e, false)}
                    required
                  />
                  {showJobDescription?.showField && (
                    <InputText
                      required={showJobDescription?.required}
                      label={jobDescription.label}
                      name={'customJobDescription'}
                    />
                  )}
                  {employerNamePlaceholder && (
                    <InputText label={employerNamePlaceholder.placeholder} name={'employerName'} required />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FormSectionTitle title={p('legalSection')} />
                  <InputOptionList
                    columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
                    options={getTranslatedOptions(BOOLEAN_SELECT_OPTIONS, t)}
                    name={'hasInsolvencyProceedings'}
                    label={p('hasInsolvencySubHeader')}
                    mode={'radio'}
                  />
                  <InputOptionList
                    columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
                    options={getTranslatedOptions(BOOLEAN_SELECT_OPTIONS, t)}
                    name={'hadEvictions'}
                    label={p('hadEvictionsSubHeader')}
                    mode={'radio'}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={5} lg={4} display={{ xs: 'none', md: 'block' }}>
              <InfoText>
                <InfoTextContent />
              </InfoText>
            </Grid>
          </Grid>
        </FormLayout>
      </form>
    </FormContext.Provider>
  );
};

export default TenantProfileForm;
