import { FC } from 'react';
import { FieldError } from 'react-hook-form';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { FormHelperText } from '@mui/material';
import Text, { TEXT_COLOR } from 'component/atoms/typographie/Text';

interface IFormErrorMessageProps {
  error: FieldError;
  align?: 'center' | 'left';
  showName?: boolean;
  isErrorBox?: boolean;
}

const FormErrorMessage: FC<IFormErrorMessageProps> = ({ error, showName = false, isErrorBox = false }) => {
  const { t } = useTranslation('common', { keyPrefix: 'component.atoms.formElement.FormErrorMessage' });
  const { t: c } = useTranslation('common');

  if (!error) {
    return <div style={{ paddingBottom: '2rem' }}> </div>;
  }

  if (isErrorBox) {
    return (
      <>
        <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_ACCENT} bold>
          {error?.ref?.name && showName ? `${c(error?.ref?.name) + ': '}` : ''}
        </Text>
        <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
          {error ? errorHandler(error, t) : ' '}
        </Text>
      </>
    );
  }

  return <FormHelperText error={!!error}>{error ? errorHandler(error, t) : ' '}</FormHelperText>;
};

export const errorHandler = (error: FieldError, translate: TFunction): string => {
  let errorString = error.message;
  if (!error?.message) {
    const newError: FieldError = Object.values(error)[0] as unknown as FieldError;
    errorString = newError?.message;
  }

  if (errorString.includes('firstName')) {
    return translate('firstName');
  }
  if (errorString.includes('name')) {
    return translate('name');
  }
  if (errorString.includes('directEmail')) {
    return translate('directEmail');
  }
  if (errorString.includes('mobile')) {
    return translate('mobile');
  }
  if (errorString.includes('addressRelease')) {
    return translate('inputOptionListBoolean');
  }

  if (errorString.includes('mainInformation.address.hasPublishedAddress')) {
    return translate('inputOptionListBoolean');
  }
  if (errorString.includes('coldRent')) {
    return translate('coldRent');
  }
  if (errorString.includes('areas.numberOfRooms')) {
    return translate('areas.numberOfRooms');
  }
  if (errorString.includes('mainInformation.typeOfUsage')) {
    return translate('dropdownMenu');
  }
  if (errorString.includes('mainInformation.typeOfMarketing')) {
    return translate('dropdownMenu');
  }
  if (errorString.includes('mainInformation.typeOfImmo')) {
    return translate('dropdownMenu');
  }
  if (errorString.includes('mainInformation.address.houseNumber')) {
    return translate('mainInformation.address.houseNumber');
  }
  if (errorString.includes('mainInformation.address.city')) {
    return translate('mainInformation.address.city');
  }
  if (errorString.includes('mainInformation.address.postalCode')) {
    return translate('mainInformation.address.postalCode');
  }
  if (errorString.includes('createLandlordAccount" is required')) {
    return translate('chooseUserType');
  }
  if (errorString.includes('isExclusive" is required')) {
    return translate('isExclusive');
  }
  if (errorString.includes('verificationCode')) {
    if (errorString.includes('pattern')) {
      return translate('verificationCodeWrongPattern');
    }
  }

  if (errorString.includes('must be a string') && errorString.includes('text')) {
    return translate('messageToShort');
  }
  if (errorString.includes('is not allowed to be empty') && errorString.includes('email')) {
    return translate('noEmptyEmail');
  }
  if (errorString.includes('must be a valid email') && errorString.includes('email')) {
    return translate('invalidEmail');
  }

  if (errorString.includes('length must be at least') && errorString.includes('text')) {
    return translate('messageToShort');
  }

  if (errorString.includes('auth/wrong-password')) {
    return translate('incorrectPasswort');
  }

  if (errorString.includes('match the required pattern: /\\d/')) {
    return translate('passwordRequiresNumber');
  }

  if (errorString.includes('fails to match the required pattern: /\\W|_/')) {
    return translate('passwordRequiresSpecialCharacter');
  }

  if (errorString.includes('"passwordRepeat" must be [ref:password]')) {
    return translate('notMatchingPasswords');
  }

  if (errorString.includes('monthlyIncome" must be greater than or equal to 100')) {
    return translate('incomeToLow');
  }

  if (errorString.includes('auth/user-not-found')) {
    return translate('userNotFound');
  }

  if (errorString.includes('toManyAttempts')) {
    return translate('toManyAttempts');
  }

  if (errorString.includes('length must be at least')) {
    return translate('toLessCharacters');
  }

  if (errorString.includes('must be a valid date') && errorString.includes('earliestMoveIn')) {
    return translate('selectEarliestMoveIn');
  }

  if (errorString.includes('must be one of') && errorString.includes('apartmentType')) {
    return translate('selectApartmentType');
  }

  if (errorString.includes('must be a positive number')) {
    return translate('positiveNumber');
  }

  if (errorString.includes('must be a number')) {
    return translate('mustBeNumber');
  }

  if (errorString.includes('must be greater than or equal to 1000')) {
    return translate('selectYear');
  }

  if (errorString.includes('must be one of') || errorString.includes('must be a boolean')) {
    return translate('pleaseSelect');
  }

  if (
    errorString.includes('is required') ||
    errorString.includes('must be a string') ||
    errorString.includes('not allowed to be empty') ||
    errorString.includes('contains an invalid value')
  ) {
    return translate('completeRequest');
  }

  if (errorString.includes('did not seem to be a phone number')) {
    return translate('enterValidPhone');
  }

  if (errorString.includes('dateOfBirth') && errorString.includes('must be a valid date')) {
    return translate('minBirthYear');
  }

  if (errorString.includes('postalCode" length must be 5 characters')) {
    return translate('enterPostalCode');
  }

  if (errorString.includes('website" must be a valid uri')) {
    return translate('enterValidUri');
  }
  return ' ';
};

export default FormErrorMessage;
