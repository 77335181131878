import { ROUTES } from 'core/const/routes';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import LandingPageLayout from '../../../component/layouts/LandingPageLayout';
import FirstSection from 'component/organisms/landing-page-sections/FirstSection';
import ContactSection from '../../../component/organisms/landing-page-sections/ContactSection';
import FAQSection from '../../../component/organisms/landing-page-sections/FAQSection';
import { USER_TYPE } from '@wohnsinn/ws-ts-lib';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import Text, { TEXT_COLOR } from 'component/atoms/typographie/Text';
import { IMAGES } from 'core/const/images';
import CTACard from 'component/molecules/Cards/CTACard';
import ProSectionPropertyManagement from 'component/organisms/landing-page-sections/ProSection/ProSectionPropertyManagement';
import Step from 'component/atoms/Step';
import Spacer, { SPACER_SIZE } from 'component/atoms/Spacer';
import MaxReachSection from 'component/organisms/landing-page-sections/MaxReachSection';
import CustomerTestimonialsSection from 'component/organisms/landing-page-sections/CustomerTestimonialsSection';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import { useContext } from 'react';
import ModalContext from 'core/context/modal.context';

const PropertyManagementView = () => {
  const { t: i } = useTranslation('images');
  const { t: r } = useTranslation('routes');
  const { t: l } = useTranslation('common', { keyPrefix: 'view.PropertyManagementView.hero' });
  const { openModal } = useContext(ModalContext);

  return (
    <LandingPageLayout pageTitle={r(ROUTES.landingPageRoutes.propertyManagement.title)}>
      <Grid
        item
        xs={12}
        paddingTop={{ xs: 4, md: 10 }}
        paddingBottom={{ xs: 4, md: 10 }}
        className={'ws-full-width-bg'}
      >
        <FirstSection
          image={IMAGES.landing.propertyManagement}
          title={l('headline_one')}
          titleTwo={l('headline_two')}
          ctaText={'Kostenlos Objekt inserieren'}
          ctaOnClick={() => openModal({ id: MODAL_IDENTIFIER.SELECT_USER_TYPE, data: { isProbablyTenant: false } })}
        />
      </Grid>

      <Grid item xs={12} paddingTop={{ xs: 4, md: 10 }} paddingBottom={{ xs: 4, md: 10 }}>
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} md={8}>
            <Headline size={HEADLINE_SIZE.DISPLAY} align={'center'}>
              Kommen dir diese Probleme bekannt vor?
            </Headline>
          </Grid>
        </Grid>

        <Grid container spacing={4} paddingTop={{ xs: 4, md: 10 }} paddingBottom={{ xs: 4, md: 10 }}>
          <Grid item xs={12} md={4}>
            <CTACard
              imgHeight={48}
              headlineSize={HEADLINE_SIZE.H3}
              title={'Zu viele unqualifizierte Mietanfragen'}
              text={
                'Mietinteressenten bewerben sich auf deine Wohnung, obwohl sie nicht deinen Anforderungen entsprechen.'
              }
              textColor={TEXT_COLOR.TEXT_COLOR_ACCENT}
              imgSrc={i(IMAGES.pictograms.thumbDown.src)}
              imgAltText={i(IMAGES.pictograms.thumbDown.alt)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CTACard
              imgHeight={48}
              headlineSize={HEADLINE_SIZE.H3}
              title={'Überfülltes E-Mail Postfach'}
              text={
                'Kurz nach der Veröffentlichung läuft dein Postfach über und du hast Mühe, den Überblick zu behalten.'
              }
              textColor={TEXT_COLOR.TEXT_COLOR_ACCENT}
              imgSrc={i(IMAGES.pictograms.openMail.src)}
              imgAltText={i(IMAGES.pictograms.openMail.alt)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CTACard
              imgHeight={48}
              headlineSize={HEADLINE_SIZE.H3}
              title={'Hohe Inseratskosten auf anderen Portalen'}
              text={'Andere Portale verlangen hohe Inseratskosten, ohne die Vermietung effizient zu unterstützen.'}
              textColor={TEXT_COLOR.TEXT_COLOR_ACCENT}
              imgSrc={i(IMAGES.pictograms.dollarSign.src)}
              imgAltText={i(IMAGES.pictograms.dollarSign.alt)}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        className={`ws-full-width-bg `}
        paddingTop={{ xs: 4, md: 10 }}
        paddingBottom={{ xs: 4, md: 10 }}
      >
        <ProSectionPropertyManagement />
      </Grid>

      <Grid item xs={12}>
        <Grid container paddingTop={{ xs: 4, md: 10 }}>
          <Grid item xs={12}>
            <Headline size={HEADLINE_SIZE.DISPLAY} align={'center'}>
              Und so einfach geht&apos;s
            </Headline>
          </Grid>
        </Grid>

        <Grid container spacing={4} paddingTop={{ xs: 4, md: 10 }} paddingBottom={{ xs: 4, md: 10 }}>
          <Grid item xs={12} md={3}>
            <Step number={1} />
            <Spacer size={SPACER_SIZE.MD} />
            <Headline size={HEADLINE_SIZE.H2}>Definiere deinen Wunschmieter</Headline>
            <Spacer size={SPACER_SIZE.MD} />
            <Text>
              Definiere bei der Inseratserstellung deine Wunschmieter-Kriterien, um Mietinteressenten zu erreichen, die
              genau zu deinen Vorstellungen passen.
            </Text>
          </Grid>
          <Grid item xs={12} md={3}>
            <Step number={2} />
            <Spacer size={SPACER_SIZE.MD} />
            <Headline size={HEADLINE_SIZE.H2}>Veröffentliche dein Inserat</Headline>
            <Spacer size={SPACER_SIZE.MD} />
            <Text>
              Nach Veröffentlichung wird unsere umfangreiche Mieter-Datenbank informiert. Zudem veröffentlichen wir dein
              Inserat auf Kleinanzeigen, um die Reichweite zu maximieren.
            </Text>
          </Grid>
          <Grid item xs={12} md={3}>
            <Step number={3} />
            <Spacer size={SPACER_SIZE.MD} />
            <Headline size={HEADLINE_SIZE.H2}>Erhalte passende Anfragen</Headline>
            <Spacer size={SPACER_SIZE.MD} />
            <Text>
              Unser Matching-Algorithmus übernimmt automatisch die Vorauswahl für dich, sodass du nur noch passende
              Bewerbungen bearbeiten musst.
            </Text>
          </Grid>
          <Grid item xs={12} md={3}>
            <Step number={4} />
            <Spacer size={SPACER_SIZE.MD} />
            <Headline size={HEADLINE_SIZE.H2}>Koordiniere Besichtigungen</Headline>
            <Spacer size={SPACER_SIZE.MD} />
            <Text>
              Mit Wohnsinn kannst du ganz einfach Besichtigungstermine vereinbaren. Zusätzlich erinnern wir alle
              eingeladenen Mieter, um die Zahl der Nichterscheinungen zu verringern.
            </Text>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        className={`ws-full-width-bg `}
        paddingTop={{ xs: 4, md: 10 }}
        paddingBottom={{ xs: 4, md: 10 }}
      >
        <MaxReachSection />
      </Grid>

      <CustomerTestimonialsSection />

      <Grid
        item
        xs={12}
        paddingTop={{ xs: 4, md: 10 }}
        paddingBottom={{ xs: 4, md: 10 }}
        className={'ws-full-width-bg ws-full-width-bg--white'}
      >
        <CTACard
          headlineSize={HEADLINE_SIZE.H1}
          alternativeStyle
          title={'Jetzt kostenlos inserieren'}
          text={'Über 150 Verwalter nutzen bereits Wohnsinn.'}
          ctaText={'Objekt inserieren'}
          shrinkButton
          onClick={() => openModal({ id: MODAL_IDENTIFIER.SELECT_USER_TYPE, data: { isProbablyTenant: false } })}
        />
      </Grid>

      <Grid
        item
        xs={12}
        paddingTop={{ xs: 4, md: 10 }}
        paddingBottom={{ xs: 4, md: 10 }}
        className={'ws-full-width-bg ws-full-width-bg--white'}
      >
        <ContactSection />
      </Grid>

      <Grid
        item
        className={'ws-full-width-bg ws-full-width-bg--white'}
        xs={12}
        paddingTop={{ xs: 4, md: 10 }}
        paddingBottom={{ xs: 8, md: 20 }}
      >
        <FAQSection userType={USER_TYPE.LANDLORD} />
      </Grid>
    </LandingPageLayout>
  );
};

export default PropertyManagementView;
