import styles from './ChatMessage.module.scss';
import { FC } from 'react';
import { IChatMessage } from '@wohnsinn/ws-ts-lib';
import Text, { TEXT_COLOR, TEXT_TYPE } from '../../atoms/typographie/Text';
import SanitizedHTML from '../../atoms/SanitzedHtml';
import { useTranslation } from 'react-i18next';
import { getTimeFromDate } from 'core/helper/nice-date-chat';

export interface IChatMessageProps {
  message: IChatMessage;
  isSender?: boolean;
  showName?: boolean;
}

const ChatMessage: FC<IChatMessageProps> = ({ message, isSender = false, showName = true }) => {
  const { t } = useTranslation('common');

  return (
    <div className={`${styles.chatMessage} ${isSender ? styles.chatMessageRight : ''}`}>
      {showName ? (
        <div className={`${styles.chatHeader} ${isSender ? styles.isSender : ''}`}>
          <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION} tag={'span'}>
            {isSender ? t('you') : message.senderName}
          </Text>
        </div>
      ) : null}

      <div className={styles.text}>
        <SanitizedHTML dirty={message.text} />

        <Text
          className={styles.time}
          color={isSender ? TEXT_COLOR.TEXT_COLOR_LIGHT : TEXT_COLOR.TEXT_COLOR_DARK}
          type={TEXT_TYPE.TEXT_TYPE_CAPTION}
          tag={'span'}
        >
          {getTimeFromDate(message?.createdAt)}
        </Text>
      </div>
    </div>
  );
};

export default ChatMessage;
