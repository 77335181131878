import { FC, PropsWithChildren } from 'react';
import styles from './Text.module.scss';

export enum TEXT_COLOR {
  TEXT_COLOR_DARK = 'TEXT_COLOR_DARK',
  TEXT_COLOR_LIGHT = 'TEXT_COLOR_LIGHT',
  TEXT_COLOR_ACCENT = 'TEXT_COLOR_ACCENT',
  TEXT_COLOR_DANGER = 'TEXT_COLOR_DANGER',
  TEXT_COLOR_PRIMARY = 'TEXT_COLOR_PRIMARY',
}

export enum TEXT_TYPE {
  TEXT_TYPE_LANDING = 'TEXT_TYPE_LANDING',
  TEXT_TYPE_BODY = 'TEXT_TYPE_BODY',
  TEXT_TYPE_CAPTION = 'TEXT_TYPE_CAPTION',
}

export enum TEXT_VARIANT {
  TEXT_VARIANT_SMALL = 'TEXT_VARIANT_SMALL',
  TEXT_VARIANT_LARGE = 'TEXT_VARIANT_LARGE',
}

export interface ITextProps {
  tag?: 'p' | 'span' | 'div';
  bold?: boolean;
  type?: TEXT_TYPE;
  align?: 'left' | 'center' | 'right';
  mobileAlign?: 'left' | 'center' | 'right';
  color?: TEXT_COLOR;
  variant?: TEXT_VARIANT;
  className?: string;
  overFlowEllipsis?: boolean;
  underline?: boolean;
  uppercase?: boolean;
  id?: string;
  italic?: boolean;
  noWrap?: boolean;
  preLine?: boolean;
}

const Text: FC<PropsWithChildren<ITextProps>> = ({
  children,
  tag: Tag = 'p',
  bold = false,
  type = TEXT_TYPE.TEXT_TYPE_BODY,
  align = 'left',
  color = TEXT_COLOR.TEXT_COLOR_DARK,
  variant = TEXT_VARIANT.TEXT_VARIANT_LARGE,
  className,
  id,
  overFlowEllipsis = false,
  noWrap = false,
  uppercase = false,
  underline = false,
  mobileAlign,
  italic = false,
  preLine = false,
}) => {
  return (
    <Tag
      id={id}
      className={`
      ${styles.text} 
      ${italic ? styles.italic : ''} 
      ${bold ? styles.bold : ''} 
      ${preLine ? styles.preLine : ''} 
      ${styles[type]} 
      ${styles[variant]} 
      ${styles[align]} 
      ${styles[color]} 
      ${className} 
      ${overFlowEllipsis ? styles.overFlowEllipsis : ''} 
      ${noWrap ? styles.noWrap : ''} 
      ${uppercase ? styles.uppercase : ''} 
      ${underline ? styles.underline : ''}
      ${mobileAlign ? styles[`mobile-${mobileAlign}`] : ''}
      `}
    >
      {children}
    </Tag>
  );
};

export default Text;
