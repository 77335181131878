import { FC } from 'react';
import { IApartment } from '@wohnsinn/ws-ts-lib';
import { Grid, TablePagination } from '@mui/material';
import ApartmentCard from 'component/molecules/Cards/ApartmentCard';
import { getApartmentCardInformationFromApartment } from 'core/helper/get-apartment-card-information';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'core/const/routes';

const ApartmentsGrid: FC<{
  apartments: IApartment[];
  totalCount: number;
  handlePageChange: any;
  handleRowsPerPageChange: any;
  page: number;
  rowsPerPage: number;
}> = ({ apartments, totalCount, handlePageChange, handleRowsPerPageChange, rowsPerPage, page }) => {
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const navigate = useNavigate();

  const navigateToApartment = (apartmentId: string) => {
    navigate(r(ROUTES.landlordRoutes.apartment.overview.path).replace(':apartmentId', apartmentId));
  };

  return (
    <Grid container spacing={2}>
      {apartments.map((apartment) => (
        <Grid item xs={12} sm={6} md={6} lg={4} key={apartment.id}>
          <ApartmentCard
            clickHandler={() => navigateToApartment(apartment.id)}
            apartmentCardInformation={getApartmentCardInformationFromApartment(apartment)}
            landlordContent={{
              apartmentState: apartment?.isPublished,
              applicationsCount: apartment?.applicationRefList?.length,
              apartmentId: apartment.id,
              notificationBadge: !!apartment.newApplicationsRef?.length,
              applicationLink: r(ROUTES.landlordRoutes.apartment.applications.path).replace(
                ':apartmentId',
                apartment.id
              ),
            }}
          />
        </Grid>
      ))}
      <Grid item xs={12}>
        <TablePagination
          component={'div'}
          count={totalCount}
          page={page}
          onPageChange={handlePageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleRowsPerPageChange}
          rowsPerPageOptions={[9, 15, 27, 48]}
          labelRowsPerPage={t('objectsPerPage')}
          labelDisplayedRows={({ from, to, count }) => `${from} ${t('to')} ${to} ${t('from')} ${count}`}
        />
      </Grid>
    </Grid>
  );
};

export default ApartmentsGrid;
