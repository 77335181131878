import { FC } from 'react';
import styles from 'component/atoms/PremiumSubscriptionDrawer/PremiumSubscriptionLandingDrawer/PremiumSubscriptionDrawer.module.scss';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import ResponsiveImage from 'component/atoms/ResponsiveImage';
import { IMAGES } from 'core/const/images';
import Spacer, { SPACER_SIZE } from 'component/atoms/Spacer';
import Text, { TEXT_COLOR, TEXT_TYPE } from 'component/atoms/typographie/Text';
import CTAButton from 'component/atoms/Buttons/CTAButton';
import SplitLine from 'component/atoms/SplitLine';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import LogoSlider from 'component/atoms/LogoSlider';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PremiumSubscriptionDrawerBenefits: FC<{ onClose: () => void; hideCTA?: boolean }> = ({
  onClose,
  hideCTA = false,
}) => {
  const { t } = useTranslation('common');
  return (
    <>
      {hideCTA ? null : (
        <>
          <div className={styles.headlineContainer}>
            <Headline size={HEADLINE_SIZE.H2} align={'center'}>
              {t('subscription.landing.title')}
              <ResponsiveImage className={styles.proIcon} image={IMAGES.logos.wsPro} rounded={false} />
            </Headline>
          </div>

          <Spacer size={SPACER_SIZE.SM} />

          <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT} align={'center'}>
            {t('subscription.landing.subTitle')}
          </Text>

          <Spacer size={SPACER_SIZE.LG} />

          <CTAButton expand={'block'} rounded={false} buttonText={t('next')} onClick={onClose} />
        </>
      )}

      <SplitLine size={'big'} />

      <Headline size={HEADLINE_SIZE.H2} align={'center'}>
        {t('subscription.landing.rentedApartments.title')}
      </Headline>

      <Spacer size={SPACER_SIZE.LG} />
      <Swiper
        className={'swiper-continuous'}
        modules={[Autoplay]}
        slidesPerView={2.5}
        spaceBetween={26}
        speed={4000}
        autoplay={{
          delay: 0,
        }}
        loop={true}
      >
        <SwiperSlide>
          <ResponsiveImage image={IMAGES.apartments.one} rounded />
        </SwiperSlide>
        <SwiperSlide>
          <ResponsiveImage image={IMAGES.apartments.two} rounded />
        </SwiperSlide>
        <SwiperSlide>
          <ResponsiveImage image={IMAGES.apartments.three} rounded />
        </SwiperSlide>
        <SwiperSlide>
          <ResponsiveImage image={IMAGES.apartments.four} rounded />
        </SwiperSlide>
        <SwiperSlide>
          <ResponsiveImage image={IMAGES.apartments.five} rounded />
        </SwiperSlide>
      </Swiper>

      <SplitLine size={'big'} />

      <Headline size={HEADLINE_SIZE.H2} align={'center'}>
        {t('subscription.landing.landlords.title')}
      </Headline>
      <LogoSlider items={CLIENT_LOGOS} />

      <SplitLine size={'big'} />

      <ResponsiveImage className={styles.fullWidth} image={IMAGES.graphics.exclusiveApartments} rounded={false} />
      <Headline size={HEADLINE_SIZE.H2} align={'center'}>
        {t('subscription.landing.exclusive.title')}
      </Headline>
      <Text align={'center'}>{t('subscription.landing.exclusive.subtitle')}</Text>

      <SplitLine size={'big'} />

      <ResponsiveImage image={IMAGES.icons.offer} className={styles.fullWidth} rounded={false} />
      <Spacer size={SPACER_SIZE.MD} />
      <Headline size={HEADLINE_SIZE.H2} align={'center'}>
        {t('subscription.landing.offer.title')}
      </Headline>
      <Text align={'center'}>{t('subscription.landing.offer.subtitle')}</Text>

      <SplitLine size={'big'} />

      <ResponsiveImage className={styles.fullWidth} image={IMAGES.graphics.lessCompetition} rounded={false} />
      <Headline size={HEADLINE_SIZE.H2} align={'center'}>
        {t('subscription.landing.lessCompetition.title')}
      </Headline>
      <Text align={'center'}>{t('subscription.landing.lessCompetition.subtitle')}</Text>

      <Spacer size={SPACER_SIZE.LG} />

      {hideCTA ? null : <CTAButton expand={'block'} rounded={false} buttonText={t('next')} onClick={onClose} />}

      <SplitLine size={'big'} />

      <Headline size={HEADLINE_SIZE.H2} align={'center'}>
        {t('subscription.landing.partner.title')}
      </Headline>

      <Spacer size={SPACER_SIZE.MD} />

      <ResponsiveImage image={IMAGES.logos.hug} rounded />

      <SplitLine size={'big'} />
      <Grid container spacing={3} direction="column">
        <Grid item>
          <div className={styles.logoWrapper}>
            <img src={t('logos.wohnsinn_svg')} alt="wohnsinn logo" width={108} />
          </div>
        </Grid>
        <Grid item>
          <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT} type={TEXT_TYPE.TEXT_TYPE_BODY}>
            {t('revoluteTogether')}
          </Text>
        </Grid>
        <Grid item>
          <div className={styles.logoWrapper}>
            <img src={t('logos.madeInGermany')} alt="madeInGermany logo" width={100} />
            <img src={t('logos.dsgvo')} alt="wohnsinn logo" width={100} />
          </div>
        </Grid>
      </Grid>
      <SplitLine size={'small'} />
      <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT}>{t('wohnsinnCopyright', { year: new Date().getFullYear() })}</Text>
    </>
  );
};

const CLIENT_LOGOS = [
  IMAGES.logos.Burgmeyer,
  IMAGES.logos.kittlaus,
  IMAGES.logos.ivvg,
  IMAGES.logos.metropol,
  IMAGES.logos.mvgm,
  IMAGES.logos.jansen,
  IMAGES.logos.skz,
  IMAGES.logos.sps,
];

export default PremiumSubscriptionDrawerBenefits;
