import { useEffect, useState } from 'react';
import { onSnapshot, Unsubscribe } from 'firebase/firestore';
import { ChatService } from '../service/chat.service';
import { IChatMessage } from '@wohnsinn/ws-ts-lib';
import { wohnsinnServices } from '../../App';

const useChat = (
  tenantId: string,
  landlordId: string,
  apartmentId: string
): {
  chatService: ChatService;
  chatMessageList: IChatMessage[];
  isLoadingMessages: boolean;
  isTyping: boolean;
  setIsTyping: (isTyping: boolean) => void;
} => {
  const [chatMessageList, setMessageList] = useState<IChatMessage[]>([]);
  const [isLoadingMessages, setIsLoadingMessages] = useState<boolean>(true);
  const [isTyping, setIsTyping] = useState<boolean>(true);
  const { chatService } = wohnsinnServices;

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if (apartmentId && tenantId) {
      setIsLoadingMessages(true);
      const chatListRef = chatService.getChatMessageListRef(tenantId, apartmentId as string, landlordId as string);

      (async () => {
        unsubscribe = onSnapshot(
          chatListRef,
          (chatListSnap) => {
            const messages = chatListSnap.docs.map((doc) => doc.data() as IChatMessage);
            setMessageList(messages);

            setIsLoadingMessages(false);
          },
          (err) => {
            setIsLoadingMessages(false);
            console.error('Error on useChatMessageListHook ', err);
          }
        );
      })();
    }
    return unsubscribe;
  }, [apartmentId, tenantId, landlordId]);

  return { chatService, chatMessageList, isLoadingMessages, isTyping, setIsTyping };
};

export default useChat;
