import { FC, useContext, useEffect } from 'react';
import { Grid } from '@mui/material';
import CallToActionScreen from '../../../molecules/CallToActionScreen';
import { useNavigate } from 'react-router-dom';
import { REGISTRATION_STATES } from '@wohnsinn/ws-ts-lib';
import { wohnsinnServices } from 'App';
import UserContext from 'core/context/user.context';
import LOCAL_STORAGE_KEYS from 'core/enum/local-storage.enum';

const WelcomeScreen: FC<{
  redirectUrl?: string;
  headline?: string;
  hideText?: boolean;
  onFinish?: () => any;
}> = ({ redirectUrl }) => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { userService, mixpanelTrackingService } = wohnsinnServices;
  const apartmentId = localStorage.getItem(LOCAL_STORAGE_KEYS.APARTMENT_ID);

  useEffect(() => {
    mixpanelTrackingService.trackEnterPage('WelcomeScreen', !!apartmentId ? apartmentId : undefined);
  }, []);

  const onClick = async () => {
    await userService.updateUserData({ registrationState: REGISTRATION_STATES.FINISHED }, user.uid);
    if (!!apartmentId) {
      mixpanelTrackingService.trackEnterPage('REDIRECT', redirectUrl);
    }
    navigate(redirectUrl ?? '/');
  };

  return (
    <Grid container maxWidth={null} marginX={'auto'} paddingTop={{ xs: 6, md: 8 }}>
      <Grid item xs={12}>
        <CallToActionScreen
          headline={'👋 Willkommen bei Wohnsinn'}
          text={
            'Vergiss Immoscout & Co. – wir sind dein neues Portal und geben alles, damit du hier schon bald deine Traumwohnung findest! 💪 🏡'
          }
          buttonText={'Bewerbung fortsetzen'}
          onClick={() => onClick()}
        />
      </Grid>
    </Grid>
  );
};

export default WelcomeScreen;
