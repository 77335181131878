import { FC } from 'react';
import styles from './ApartmentCard.module.scss';
import { useInView } from 'react-intersection-observer';
import ApartmentCardMedia from './ApartmentCardMedia';
import { APPLICATION_STATE, IApartmentAddress, IApartmentCost, IApplication, IMedia } from '@wohnsinn/ws-ts-lib';
import ApartmentCardApartmentInformation from './ApartmentCardApartmentInformation';
import ApartmentBadge from '../../../atoms/ApartmentBadge';
import StatusBadge from 'component/atoms/StatusBadge';
import CTAButton, { BUTTON_STYLE } from 'component/atoms/Buttons/CTAButton';
import { faEdit, faUser } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';

export interface IApartmentCardInformation {
  address: IApartmentAddress;
  apartmentId: string;
  cost: IApartmentCost;
  isDisabled?: boolean;
  media: IMedia[] | IMedia;
  rooms: number;
  size: number;
}

export interface ILandlordContent {
  apartmentId: string;
  applicationsCount: number;
  applicationLink: string;
  apartmentState: boolean;
  notificationBadge: boolean;
}

const ApartmentCard: FC<{
  apartmentCardInformation: IApartmentCardInformation;
  clickHandler: () => void;
  isExclusive?: boolean;
  notificationBadge?: boolean;
  application?: IApplication;
  landlordContent?: ILandlordContent;
}> = ({ apartmentCardInformation, clickHandler, isExclusive = false, application, landlordContent }) => {
  const { apartmentId, media, isDisabled } = apartmentCardInformation;
  const { ref, entry } = useInView({ trackVisibility: true, delay: 100, triggerOnce: true });
  const mediaIsArray = Array.isArray(media);
  const { t } = useTranslation('common');

  const applicationIsDeleted =
    application?.applicationState === APPLICATION_STATE.DELETED ||
    application?.applicationState === APPLICATION_STATE.WILL_BE_DELETED;

  return (
    <div
      ref={ref}
      className={`${styles.wrapper} ${
        !landlordContent && (isDisabled || applicationIsDeleted) ? styles.isDisabled : ''
      }`}
    >
      <ApartmentBadge apartmentIsExclusive={isExclusive} />
      {application?.applicationState ? <StatusBadge status={application.applicationState} /> : null}
      {landlordContent ? (
        <ApartmentBadge isPublished={landlordContent.apartmentState} isDisabled={!landlordContent.apartmentState} />
      ) : null}

      <div className={styles.mediaWrapper}>
        <ApartmentCardMedia
          apartmentId={apartmentId}
          media={media ? (mediaIsArray ? media : [media]) : null}
          isIntersecting={entry?.isIntersecting}
          onGalleryClick={clickHandler}
        />
      </div>

      <div className={styles.content}>
        <ApartmentCardApartmentInformation
          clickHandler={clickHandler}
          apartmentCardInformation={apartmentCardInformation}
          showFullAddress={!!landlordContent}
        />
      </div>

      {landlordContent ? (
        <div className={styles.landlordButtonsWrapper}>
          <CTAButton
            expand={'block'}
            size={'small'}
            rounded={false}
            buttonStyle={BUTTON_STYLE.SECONDARY}
            icon={faUser}
            link={landlordContent.applicationLink}
            notificationBadge={landlordContent.notificationBadge}
            buttonText={`${landlordContent.applicationsCount} ${t('applications', {
              count: landlordContent.applicationsCount,
            })}`}
          />
          <CTAButton
            expand={'block'}
            size={'small'}
            rounded={false}
            buttonStyle={BUTTON_STYLE.SECONDARY}
            icon={faEdit}
            iconPosition={'start'}
            buttonText={t('edit')}
            onClick={clickHandler}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ApartmentCard;
