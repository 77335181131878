import { FC } from 'react';
import Text, { TEXT_COLOR, TEXT_TYPE } from 'component/atoms/typographie/Text';
import Avatar, { AVATAR_SIZE } from 'component/atoms/Avatar';
import { USER_TYPE } from '@wohnsinn/ws-ts-lib';
import styles from './ChatSender.module.scss';

const ChatSender: FC<{
  senderName: string;
  fullAddress: string;
  imageUrl?: string;
  userType?: USER_TYPE;
  clickHandler: () => void;
}> = ({ senderName, fullAddress, imageUrl, userType, clickHandler }) => {
  return (
    <button onClick={clickHandler} className={styles.wrapper}>
      <Avatar size={AVATAR_SIZE.md} avatarUrl={imageUrl} isTenant={userType !== USER_TYPE.TENANT} />
      <div className={styles.sender}>
        <Text className={styles.name} tag={'span'} bold type={TEXT_TYPE.TEXT_TYPE_BODY}>
          {senderName}
        </Text>
        <br />
        <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT} type={TEXT_TYPE.TEXT_TYPE_CAPTION} tag="span">
          {fullAddress}
        </Text>
      </div>
    </button>
  );
};

export default ChatSender;
