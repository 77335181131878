import styles from '../Chat.module.scss';
import ChatMessage from '../../../molecules/ChatMessage';
import { IChatMessage, USER_TYPE } from '@wohnsinn/ws-ts-lib';
import { FC, useContext, useEffect } from 'react';
import UserContext from '../../../../core/context/user.context';
import { convertToNiceChatDate } from '../../../../core/helper/nice-date-chat';
import Badge, { BADGE_COLORS } from '../../../atoms/Badge';
import CTACard from '../../../molecules/Cards/CTACard';
import { useTranslation } from 'react-i18next';

export interface IChatMessagesProps {
  messageList: IChatMessage[];
  tenantId?: string;
}

const ChatMessageList: FC<IChatMessagesProps> = ({ messageList }) => {
  const { user, activeUserType } = useContext(UserContext);
  const { t } = useTranslation('common');
  let lastMessageDate: string;
  let lastSenderName: string;
  let showMessageDate = true;
  let showName = true;

  // Scroll down everytime messageList changes
  useEffect(() => {
    const messageList = document.getElementById('messageList');
    messageList.scrollTop = messageList.scrollHeight;
  }, [messageList]);

  return (
    <ul className={styles.chatMessagesList} id={'messageList'}>
      {messageList && messageList.length ? (
        messageList.map((message, index) => {
          showMessageDate = lastMessageDate !== convertToNiceChatDate(message?.createdAt);
          lastMessageDate = convertToNiceChatDate(message?.createdAt);
          showName = message.senderName !== lastSenderName;
          lastSenderName = message.senderName;

          return (
            <li key={message.apartmentId + '-' + index}>
              {showMessageDate && lastMessageDate ? (
                <div className={styles.dateWrapper}>
                  <Badge bold={false} text={convertToNiceChatDate(message.createdAt)} color={BADGE_COLORS.MEDIUM} />
                </div>
              ) : null}
              <ChatMessage
                showName={showName}
                message={message}
                isSender={
                  activeUserType === USER_TYPE.TENANT
                    ? message.senderId === user.uid
                    : message.senderId !== message.tenantId
                }
              />
            </li>
          );
        })
      ) : (
        <div className={styles.noMessagesWrapper}>
          <CTACard
            imgSrc={t('pictogram.chatMessage')}
            imgAltText={t('bookmarkIcon')}
            title={t('start.chat.landlord')}
          />
        </div>
      )}
    </ul>
  );
};

export default ChatMessageList;
