import { FC } from 'react';
import { IOrganization } from '@wohnsinn/ws-ts-lib';
import styles from './ApartmentOrganizationCard.module.scss';
import Text, { TEXT_COLOR } from 'component/atoms/typographie/Text';
import SplitLine from 'component/atoms/SplitLine';
import Badge, { BADGE_COLORS } from 'component/atoms/Badge';
import { useTranslation } from 'react-i18next';
import { ensureHttps } from 'core/context/ensure-https';

const ApartmentOrganizationCard: FC<{ organization?: IOrganization }> = ({ organization }) => {
  const { t } = useTranslation('common');
  return (
    <div className={styles.outerWrapper}>
      <div className={styles.wrapper}>
        {organization ? (
          <>
            {organization?.photoUrl && (
              <img className={styles.image} src={organization.photoUrl} alt={`${organization?.title} - Logo`} />
            )}
            {organization?.title && <Text tag={'span'}>{organization.title}</Text>} <br />
            {organization.address ? (
              <>
                <Text tag={'span'}>
                  {organization.address.street ?? organization.address.street}{' '}
                  {organization.address.houseNumber ?? organization.address.houseNumber}
                </Text>
                <br />
                <Text tag={'span'}>
                  {organization.address.postalCode ?? organization.address.postalCode}{' '}
                  {organization.address.city ?? organization.address.city}
                </Text>
              </>
            ) : null}
            <SplitLine size={'small'} />
            {organization?.website && (
              <a href={ensureHttps(organization.website)} target={'_blank'} rel="noreferrer">
                <Text color={TEXT_COLOR.TEXT_COLOR_PRIMARY} tag={'span'}>
                  {t('website')}
                </Text>
              </a>
            )}
            {organization?.imprintLink && (
              <a href={ensureHttps(organization.imprintLink)} target={'_blank'} rel="noreferrer">
                <br />
                <Text color={TEXT_COLOR.TEXT_COLOR_PRIMARY} tag={'span'}>
                  {t('imprint')}
                </Text>
              </a>
            )}
            {organization?.dataProtectionLink && (
              <a href={ensureHttps(organization.dataProtectionLink)} target={'_blank'} rel="noreferrer">
                <br />
                <Text color={TEXT_COLOR.TEXT_COLOR_PRIMARY} tag={'span'}>
                  {t('dataProtection')}
                </Text>
              </a>
            )}
          </>
        ) : (
          <Badge color={BADGE_COLORS.PRIMARY} text={t('privateLandlord')} />
        )}
      </div>
    </div>
  );
};

export default ApartmentOrganizationCard;
