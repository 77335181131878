import { FC, useContext } from 'react';
import Text, { TEXT_COLOR, TEXT_TYPE } from '../../atoms/typographie/Text';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Spacer, { SPACER_SIZE } from '../../atoms/Spacer';
import useLogout from '../../../core/hook/logout.hook';
import { ROUTES } from '../../../core/const/routes';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import ModalContext from 'core/context/modal.context';

const ChangeUser: FC<{ email: string; align?: 'left' | 'center' }> = ({ email, align = 'left' }) => {
  const { t: r } = useTranslation('routes');
  const { logout } = useLogout();
  const navigate = useNavigate();
  const { openModal } = useContext(ModalContext);

  return (
    <>
      <Spacer size={SPACER_SIZE.MD} />
      <Text align={align} type={TEXT_TYPE.TEXT_TYPE_CAPTION} color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
        Du bist nicht {email}?{' '}
        <button
          type={'button'}
          onClick={async () => {
            await logout();
            navigate(r(ROUTES.landingPageRoutes.tenant.path));
            setTimeout(() => {
              openModal({ id: MODAL_IDENTIFIER.SELECT_USER_TYPE });
            }, 100);
          }}
        >
          <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION} color={TEXT_COLOR.TEXT_COLOR_PRIMARY}>
            Neu anmelden
          </Text>
        </button>
      </Text>
    </>
  );
};

export default ChangeUser;
