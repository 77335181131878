import PageLayout from 'component/layouts/PageLayout';
import { ROUTES } from 'core/const/routes';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import UserContext from 'core/context/user.context';
import FinishedTunnelRedirectScreen from 'component/organisms/registration/FinishedTunnelRedirectScreen';
import { REGISTRATION_STATES } from '@wohnsinn/ws-ts-lib';
import { wohnsinnServices } from 'App';
import { createUrlFromTenantSearch } from 'core/helper/create-url-from-tenant-search';
import { useQuery } from '@tanstack/react-query';
import { IMAGES } from 'core/const/images';
import CallToActionScreen from 'component/molecules/CallToActionScreen';
import LOCAL_STORAGE_KEYS from 'core/enum/local-storage.enum';
import WelcomeScreen from 'component/organisms/registration/WelcomeScreen';

const CreateTenantTunnel = () => {
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const { user, tenantProfile } = useContext(UserContext);
  const { userService, tenantService } = wohnsinnServices;

  const { data, isLoading } = useQuery({
    queryKey: ['fetchTenantProfile', tenantProfile?.uid],
    queryFn: () => (tenantProfile ? tenantService.getTenantSearchProfiles(tenantProfile.uid) : null),
    staleTime: 1,
  });
  const apartmentId = localStorage.getItem(LOCAL_STORAGE_KEYS.APARTMENT_ID);
  const renderCreateTenantScreen = () => {
    switch (user?.registrationState) {
      case REGISTRATION_STATES.WELCOME_SCREEN:
        return (
          <WelcomeScreen
            redirectUrl={
              apartmentId
                ? r(ROUTES.tenantRoutes.account.applicationFolder.profile)
                : data?.length
                ? createUrlFromTenantSearch(data[0])
                : '/'
            }
          />
        );

      case REGISTRATION_STATES.FINISH_LOADING_SCREEN:
        return (
          <FinishedTunnelRedirectScreen
            onFinish={async () =>
              await userService.updateUserData({ registrationState: REGISTRATION_STATES.FINISHED }, user.uid)
            }
            redirectUrl={
              apartmentId
                ? r(ROUTES.tenantRoutes.account.applicationFolder.profile)
                : data?.length
                ? createUrlFromTenantSearch(data[0])
                : '/'
            }
          />
        );
      default:
        return (
          <FinishedTunnelRedirectScreen
            onFinish={async () =>
              await userService.updateUserData({ registrationState: REGISTRATION_STATES.FINISHED }, user.uid)
            }
            redirectUrl={
              apartmentId
                ? r(ROUTES.tenantRoutes.account.applicationFolder.profile)
                : data?.length
                ? createUrlFromTenantSearch(data[0])
                : '/'
            }
          />
        );
    }
  };

  return (
    <PageLayout showPageTitle={false} pageTitle={r(ROUTES.userRoutes.registration.createTenantTunnel.title)}>
      {isLoading ? (
        <CallToActionScreen text={t('redirectToMatches')} img={IMAGES.icons.heart} animateImg={true} />
      ) : user ? (
        renderCreateTenantScreen()
      ) : null}
    </PageLayout>
  );
};

export default CreateTenantTunnel;
