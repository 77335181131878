import Joi from 'joi';

export const APARTMENT_DESIRED_TENANT_SCHEMA = {
  arePetsAllowed: Joi.boolean().optional().allow(null),
  isHousingEntitlementCertificateMandatory: Joi.boolean().optional().allow(null),
  isSharedUsagePossible: Joi.boolean().optional().allow(null),
  numberOfPeopleMovingIn: Joi.number().sign('positive').optional().allow(null).allow(''),
  shouldIncomeBeDoubleWarmRent: Joi.boolean().optional().allow(null),
  personalDescription: Joi.any(),
};
