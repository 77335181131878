import { Dispatch, FC, PropsWithChildren, SetStateAction, useState } from 'react';
import styles from './FormTabs.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Text, { TEXT_TYPE } from '../../atoms/typographie/Text';
import { Grid } from '@mui/material';

export interface IFormTab {
  label: string;
  value: string;
  icon?: IconProp;
  status?: IconProp;
}

const FormTabsWrapper: FC<
  PropsWithChildren<{ tabs: IFormTab[]; activeTab: any; setActiveTab: Dispatch<SetStateAction<any>> }>
> = ({ children, tabs, activeTab, setActiveTab }) => {
  const [selectedTab, setSelectedTab] = useState<string>(tabs[0].label);
  const [selectedIcon, setSelectedIcon] = useState<IconProp>(tabs[0]?.icon ?? null);
  const [showTabs, setShowTabs] = useState<boolean>(false);

  const handleTabClick = (tab: IFormTab) => {
    setActiveTab(tab.value);
    setSelectedTab(tab.label);
    setSelectedIcon(tab.icon);
    setShowTabs(false);
  };

  const renderTabs = () =>
    tabs.map((tab) => (
      <li key={tab.value}>
        <button
          onClick={() => handleTabClick(tab)}
          className={`${styles.tabItem} ${activeTab === tab.value ? styles.active : ''} `}
        >
          <Text className={styles.label} type={TEXT_TYPE.TEXT_TYPE_BODY} tag={'span'}>
            {tab.icon ? <FontAwesomeIcon icon={tab.icon} /> : null}
            {tab.label}
          </Text>
          {tab.status ? <FontAwesomeIcon className={styles.status} icon={tab.status} /> : null}
        </button>
      </li>
    ));

  return (
    <>
      {showTabs ? <button onClick={() => setShowTabs(false)} className={styles.backdrop} /> : null}
      <div className={styles.mobileTabsWrapper}>
        <button className={styles.tabToggleButton} onClick={() => setShowTabs(!showTabs)}>
          <Text className={styles.label} type={TEXT_TYPE.TEXT_TYPE_BODY} tag={'span'}>
            {selectedIcon && <FontAwesomeIcon icon={selectedIcon} />}
            {selectedTab}
          </Text>
          <FontAwesomeIcon icon={showTabs ? faChevronUp : faChevronDown} />
        </button>
        <ul className={`${styles.tabs} ${showTabs ? '' : styles.hide} ${styles.hideDesktop} ${styles.tabsMobile}`}>
          {renderTabs()}
        </ul>
      </div>
      <Grid container>
        <Grid item xs={12} md={4} lg={3}>
          <ul className={`${styles.tabs} ${styles.hideMobile}`}>{renderTabs()}</ul>
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <div className={styles.panel}>{children}</div>
        </Grid>
      </Grid>
    </>
  );
};

export default FormTabsWrapper;
