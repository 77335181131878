import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IApplication } from '@wohnsinn/ws-ts-lib';
import { wohnsinnServices } from 'App';
import UserContext from 'core/context/user.context';
import { useContext } from 'react';

export interface IMutationParams {
  application: IApplication;
  data?: Partial<IApplication>;
}

export const useUpdateApplicationMutation = () => {
  const queryClient = useQueryClient();
  const { chatService, applicationService } = wohnsinnServices;
  const { landlordProfile } = useContext(UserContext);

  return useMutation({
    mutationFn: async (params: IMutationParams) => {
      const { application, data } = params;

      if (data) {
        await applicationService.updateApplication({
          landlordId: application.landlordId,
          apartmentId: application.apartmentId,
          tenantId: application.tenantProfile.uid,
          data,
        });
      }

      await chatService.clearUnreadTenantMessagesRefOnApplication(
        application.landlordId,
        application.apartmentId,
        application.tenantProfile.uid
      );

      await chatService.clearUnreadTenantChatsRefOnApartment(
        application.apartmentId,
        application.landlordId,
        application.tenantProfile.uid
      );
    },
    onSuccess: async (_, { application }) => {
      await queryClient.invalidateQueries({ queryKey: ['apartments-applications', application.apartmentId] });
      await queryClient.invalidateQueries({ queryKey: ['landlord-chats', landlordProfile?.uid] });
    },
  });
};
