import { useState } from 'react';
import ApplicationListContext from 'core/context/application-list.context';
import BackButton from 'component/atoms/Buttons/BackButton';
import BurgerMenu from './BurgerMenu';
import CTAButton, { BUTTON_STYLE } from '../../atoms/Buttons/CTAButton';
import DesktopMenu from './DesktopMenu';
import MegaMenu from 'component/molecules/MegaMenu';
import ModalContext from 'core/context/modal.context';
import UserContext from 'core/context/user.context';
import styles from './AppHeader.module.scss';
import useLandlordApartmentListHook from 'core/hook/landlord-apartment-list.hook';
import useWindowSize from 'core/hook/windowsize.hook';
import { FC, useContext } from 'react';
import { IMenuNotifications } from 'core/const/menus';
import { Link, useLocation } from 'react-router-dom';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import { REGISTRATION_REFERRER, REGISTRATION_STATES, USER_TYPE } from '@wohnsinn/ws-ts-lib';
import { getAdminMenuList, getGuestMenuList, getLandlordMenuList, getTenantMenuList } from 'core/helper/get-menu';
import { useTranslation } from 'react-i18next';
import NewsDrawer from '../NewsDrawer';
import NotificationBell from '../../atoms/NotificationBell';
import { useNews } from '../../../core/hook/news.hook';

export interface IAppHeader {
  backButtonRoute?: string;
  headerEndButton?: any;
  hideMenu?: boolean;
  showBackButton?: boolean;
  showStaticMenu?: boolean;
  tunnelMode?: boolean;
  referrer?: REGISTRATION_REFERRER;
  customBackButton?: any;
}

const AppHeader: FC<IAppHeader> = ({
  headerEndButton,
  backButtonRoute,
  showBackButton = false,
  hideMenu = false,
  showStaticMenu = false,
  referrer,
  tunnelMode = false,
  customBackButton,
}) => {
  const [isNewsDrawerOpen, setIsNewsDrawerOpen] = useState(false);
  const { isGreaterMd } = useWindowSize();
  const { openModal } = useContext(ModalContext);
  const { t } = useTranslation('common');
  const { unreadNotifications: unreadTenantChatNotifications } = useContext(ApplicationListContext);
  const { unreadTenantChatsCount, newApplicationsCount } = useLandlordApartmentListHook();
  const { user, activeUserType, isPremiumUser } = useContext(UserContext);
  const isLandlord = user?.activeUserType === USER_TYPE.LANDLORD;
  const isWogeEnvironment = process.env.REACT_APP_ENVIRONMENT === 'woge';
  const location = useLocation();
  const { data } = useNews();

  const createMenu = (notifications: IMenuNotifications) => {
    const menu = user
      ? activeUserType === USER_TYPE.LANDLORD
        ? getLandlordMenuList(notifications)
        : getTenantMenuList(notifications)
      : getGuestMenuList();
    if (user && user.isAdmin) {
      menu.push(getAdminMenuList());
    }

    return menu;
  };

  const MENU = createMenu({
    account: !user?.isEmailVerified,
    landlordChat: unreadTenantChatsCount,
    landlordNewApplication: newApplicationsCount,
    tenantChat: unreadTenantChatNotifications,
  });

  const renderLogo = () => (
    <div
      className={`${styles.appLogo} ${isWogeEnvironment ? styles.wogeLogo : ''} ${
        isWogeEnvironment ? 'woge-logo' : isPremiumUser ? 'app-logo-pro' : 'app-logo'
      }`}
    />
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        {customBackButton ? customBackButton : null}
        {showBackButton ? <BackButton backRoute={backButtonRoute} /> : null}
        {tunnelMode ? (
          renderLogo()
        ) : location.pathname === '/' ? (
          <button onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>{renderLogo()}</button>
        ) : (
          <Link to={'/'}>{renderLogo()}</Link>
        )}

        {!hideMenu && showStaticMenu ? (
          <div className={styles.megaMenu}>
            <MegaMenu />
          </div>
        ) : null}
      </div>
      {!hideMenu &&
      isGreaterMd &&
      MENU &&
      MENU.length &&
      activeUserType === USER_TYPE.LANDLORD &&
      user?.registrationState === REGISTRATION_STATES.FINISHED ? (
        <DesktopMenu menuItemList={MENU} />
      ) : null}
      <div className={styles.accountSection}>
        {headerEndButton ? headerEndButton : null}
        {(!user && !tunnelMode) || hideMenu ? (
          <div className={styles.buttonWrapper}>
            <CTAButton
              buttonStyle={BUTTON_STYLE.SECONDARY}
              expand={'block'}
              buttonText={t('signIn')}
              onClick={() => openModal({ id: MODAL_IDENTIFIER.SIGN_IN })}
            />

            {tunnelMode || hideMenu ? null : (
              <CTAButton
                expand={'block'}
                buttonText={t('createAccount')}
                onClick={() => openModal({ id: MODAL_IDENTIFIER.SELECT_USER_TYPE, data: { referrer } })}
              />
            )}
          </div>
        ) : null}

        {!hideMenu && MENU && !tunnelMode ? (
          <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
            {isLandlord ? (
              <NotificationBell count={data?.unreadNotifications?.length} onClick={() => setIsNewsDrawerOpen(true)} />
            ) : null}
            <BurgerMenu menu={MENU} />
          </div>
        ) : null}
      </div>

      <NewsDrawer onClose={() => setIsNewsDrawerOpen(false)} isOpen={isNewsDrawerOpen} />
    </div>
  );
};

export default AppHeader;
