import ModalWrapper from 'component/modals/ModalWrapper';
import { useContext, useState } from 'react';
import SnackBarContext from 'core/context/snackbar.context';
import ModalContext from 'core/context/modal.context';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { getSubmitIcon } from 'component/atoms/Buttons/CTAButton';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import Text, { TEXT_COLOR } from 'component/atoms/typographie/Text';
import Spacer from 'component/atoms/Spacer';
import { useTranslation } from 'react-i18next';
import { APPLICATION_STATE } from '@wohnsinn/ws-ts-lib';
import { wohnsinnServices } from 'App';
import { useQueryClient } from '@tanstack/react-query';

const DeleteApplicationsModal = () => {
  const [submitButtonMode, setSubmitButtonMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { handleSnackBar } = useContext(SnackBarContext);
  const { closeModal, modalData } = useContext(ModalContext);
  const [rejectionSent, setRejectionSent] = useState(false);
  const { t } = useTranslation('common');
  const { applicationService } = wohnsinnServices;
  const queryClient = useQueryClient();

  const onSuccess = async (deleteNow: boolean) => {
    setSubmitButtonMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    try {
      await applicationService.changeApplicationState({
        applications: modalData?.applicationList,
        applicationState: deleteNow ? APPLICATION_STATE.DELETED : APPLICATION_STATE.WILL_BE_DELETED,
      });

      setSubmitButtonMode(SUBMIT_BUTTON_MODE.NONE);
      handleSnackBar('toast.messageSend', 'success');
      setRejectionSent(true);
      await queryClient.invalidateQueries({ queryKey: ['apartments'] });
    } catch (e) {
      setSubmitButtonMode(SUBMIT_BUTTON_MODE.ERROR);

      handleSnackBar('toast.error.unknown', 'error');
      console.error('Error on onSuccess in RequestSalesModal', e);
    }
  };

  return (
    <ModalWrapper
      buttons={
        rejectionSent
          ? {
              primary: {
                buttonText: t('backToObject'),
                onClick: () => closeModal(),
              },
            }
          : {
              primary: {
                icon: getSubmitIcon(submitButtonMode),
                spinIcon: submitButtonMode === SUBMIT_BUTTON_MODE.SUBMITTING,
                buttonText: t('deleteApplications.primary.button'),
                onClick: () => onSuccess(true),
              },
              third: {
                onClick: () => onSuccess(false),
                buttonText: t('deleteApplications.secondary.button'),
                color: TEXT_COLOR.TEXT_COLOR_DARK,
              },
            }
      }
      title={t('deleteApplications')}
    >
      {rejectionSent ? (
        <Headline size={HEADLINE_SIZE.H3}>{t('deleteApplications.done')}</Headline>
      ) : (
        <>
          <Headline align={'center'} size={HEADLINE_SIZE.H2}>
            {t('deleteApplications.title')}
          </Headline>

          <Spacer />
          <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT} align={'center'}>
            {t('deleteApplications.text')}
          </Text>
          <Spacer />
        </>
      )}
    </ModalWrapper>
  );
};

export default DeleteApplicationsModal;
