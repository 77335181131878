import { FC } from 'react';
import SanitizedHTML from 'component/atoms/SanitzedHtml';
import styles from './LastTenantMessagePreview.module.scss';
import { Tooltip } from '@mui/material';

const LastTenantMessagePreview: FC<{
  lastMessageSenderId: string;
  tenantProfileId: string;
  unreadTenantMessagesRefLength: number;
  lastMessage: string;
}> = ({ lastMessageSenderId, tenantProfileId, unreadTenantMessagesRefLength, lastMessage }) => {
  return (
    <div className={styles.message}>
      <Tooltip title={<SanitizedHTML dirty={lastMessage} removeNewLines />}>
        <div className={styles.text}>
          <SanitizedHTML
            className={`${
              unreadTenantMessagesRefLength && lastMessageSenderId === tenantProfileId ? styles.bold : styles.light
            }`}
            dirty={lastMessage}
            removeNewLines
          />
        </div>
      </Tooltip>
    </div>
  );
};

export default LastTenantMessagePreview;
