import { FC, ReactElement, ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulbOn, faShieldHalved } from '@fortawesome/pro-solid-svg-icons';
import styles from './InfoBox.module.scss';
import Text, { TEXT_TYPE } from '../typographie/Text';
import CTAButton from '../Buttons/CTAButton';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

export interface IInfoBox {
  text: string;
  icon?: string;
  bold?: boolean;
  onClick?: () => void;
  textType?: TEXT_TYPE;
  action?: any;
  size?: 'small' | 'big';
  linkTo?: string;
  children?: ReactNode;
  highlightComponent?: ReactElement;
}

export enum INFO_BOX_ICONS {
  LIGHT_BULB = 'LIGHT_BULB',
  SHIELD = 'SHIELD',
}

const getIcon = (icon: string) => {
  switch (icon) {
    case INFO_BOX_ICONS.SHIELD:
      return faShieldHalved;
    case INFO_BOX_ICONS.LIGHT_BULB:
      return faLightbulbOn;
  }
};

const InfoBox: FC<IInfoBox> = ({
  text,
  icon = INFO_BOX_ICONS.LIGHT_BULB,
  textType = TEXT_TYPE.TEXT_TYPE_BODY,
  action,
  size = 'small',
  bold = false,
  linkTo,
  children,
  onClick,
  highlightComponent,
}) => {
  return (
    <button onClick={onClick ?? undefined} className={`${styles.infoBox} ${styles[size]}`}>
      <div className={styles.content}>
        <div className={styles.textWrapper}>
          {icon ? <FontAwesomeIcon icon={getIcon(icon)} size={size === 'small' ? 'lg' : '2x'} /> : null}

          <div style={{ display: 'flex', gap: '5px' }}>
            <Text bold={bold} type={textType} tag={'span'}>
              <Trans components={{ span: highlightComponent ?? <span />, a: <Link to={linkTo} /> }}>{text}</Trans>
            </Text>
            {children && children}
          </div>
        </div>
        {action ? <CTAButton onClick={action} expand={'block'} buttonText={'Ich will benachrichtigt werden'} /> : null}
      </div>
    </button>
  );
};

export default InfoBox;
