import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Text, { TEXT_COLOR } from '../typographie/Text';
import styles from './ContextMenu.module.scss';
import { FC, SetStateAction, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEllipsis } from '@fortawesome/pro-light-svg-icons';
import CTAButton, { BUTTON_STYLE } from 'component/atoms/Buttons/CTAButton';
import { useTranslation } from 'react-i18next';

export interface IContextMenuItem {
  icon?: IconProp;
  text: string;
  onClick: () => any;
  disabled?: boolean;
}

const ContextMenu: FC<{ menu: IContextMenuItem[] }> = ({ menu }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation('common');

  const closeContext = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CTAButton
        buttonText={t('openContext')}
        hideText
        type={'button'}
        buttonStyle={BUTTON_STYLE.SECONDARY}
        color={TEXT_COLOR.TEXT_COLOR_DARK}
        size={'small'}
        rounded
        icon={faEllipsis}
        onClick={(event: { currentTarget: SetStateAction<HTMLElement> }) => setAnchorEl(event.currentTarget)}
      />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeContext} onClick={closeContext}>
        {menu.map((item, index) => (
          <MenuItem
            key={`context-menu-item-${item.text}-${index}`}
            disabled={item.disabled}
            onClick={item.onClick}
            className={styles.menuItem}
          >
            {item.icon ? <FontAwesomeIcon className={styles.icon} icon={item.icon} size={'1x'} /> : null}
            <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>{item.text} </Text>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ContextMenu;
