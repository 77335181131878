import PageLayout from '../../../../component/layouts/PageLayout';
import { ROUTES } from '../../../../core/const/routes';
import WelcomeScreen from 'component/organisms/CreateTenantFromApartmentApplyTunnelSteps/WelcomeScreen';
import NumberOfPeopleMovingInStep from 'component/organisms/CreateTenantFromApartmentApplyTunnelSteps/NumberOfPeopleMovingInStep';
import {
  CREATE_TENANT_FROM_APARTMENT_APPLY_TUNNEL_STEPS,
  TunnelProvider,
  useTunnelContext,
} from 'core/context/create-tenant-from-apartment-apply-tunnel.context';
import NumberOfChildrenMovingInStep from 'component/organisms/CreateTenantFromApartmentApplyTunnelSteps/NumberOfChildrenMovingInStep';
import IsSharedUsageAllowedStep from 'component/organisms/CreateTenantFromApartmentApplyTunnelSteps/IsSharedUsageAllowedStep';
import ArePetsAllowedStep from 'component/organisms/CreateTenantFromApartmentApplyTunnelSteps/ArePetsAllowedAllowedStep';
import CanBeSubsidizedStep from 'component/organisms/CreateTenantFromApartmentApplyTunnelSteps/CanBeSubsidizedStep';
import HasPledgeStep from 'component/organisms/CreateTenantFromApartmentApplyTunnelSteps/HasPledgeStep';
import IncomeStep from 'component/organisms/CreateTenantFromApartmentApplyTunnelSteps/IncomeStep';
import CheckMatchingHouseHoldToApartmentStep from 'component/organisms/CreateTenantFromApartmentApplyTunnelSteps/CheckMatchingHouseHoldToApartmentStep';
import StartSignInStep from 'component/organisms/CreateTenantFromApartmentApplyTunnelSteps/StartSignInStep';
import { wohnsinnServices } from 'App';
import useWindowSize from 'core/hook/windowsize.hook';
import { useTranslation } from 'react-i18next';
import useApartment from 'core/hook/apartment.hook';

const CreateTenantFromApartmentApplyTunnel = () => {
  const { t: r } = useTranslation('routes');
  const { currentStep } = useTunnelContext();
  const { mixpanelTrackingService } = wohnsinnServices;
  const { isSmallerMd } = useWindowSize();
  const { apartment } = useApartment();

  const renderTunnelStep = () => {
    mixpanelTrackingService.trackEnterPage(currentStep, apartment?.id);
    switch (currentStep) {
      case CREATE_TENANT_FROM_APARTMENT_APPLY_TUNNEL_STEPS.WELCOME_SCREEN:
        return <WelcomeScreen />;
      case CREATE_TENANT_FROM_APARTMENT_APPLY_TUNNEL_STEPS.START_SIGN_IN:
        return <StartSignInStep />;
      case CREATE_TENANT_FROM_APARTMENT_APPLY_TUNNEL_STEPS.NUMBER_OF_PEOPLE_MOVING_IN:
        return <NumberOfPeopleMovingInStep />;
      case CREATE_TENANT_FROM_APARTMENT_APPLY_TUNNEL_STEPS.NUMBER_OF_CHILDREN_MOVING_IN:
        return <NumberOfChildrenMovingInStep />;
      case CREATE_TENANT_FROM_APARTMENT_APPLY_TUNNEL_STEPS.IS_SHARED_USAGE_ALLOWED:
        return <IsSharedUsageAllowedStep />;
      case CREATE_TENANT_FROM_APARTMENT_APPLY_TUNNEL_STEPS.ARE_PETS_ALLOWED:
        return <ArePetsAllowedStep />;
      case CREATE_TENANT_FROM_APARTMENT_APPLY_TUNNEL_STEPS.HAS_PLEDGE:
        return <HasPledgeStep />;
      case CREATE_TENANT_FROM_APARTMENT_APPLY_TUNNEL_STEPS.CAN_BE_SUBSIDIZED:
        return <CanBeSubsidizedStep />;
      case CREATE_TENANT_FROM_APARTMENT_APPLY_TUNNEL_STEPS.MONTHLY_INCOME:
        return <IncomeStep />;
      case CREATE_TENANT_FROM_APARTMENT_APPLY_TUNNEL_STEPS.CHECK_MATCHING:
        return <CheckMatchingHouseHoldToApartmentStep />;
      default:
        return <WelcomeScreen />;
    }
  };

  return (
    <PageLayout
      tunnelMode
      showBackButton={isSmallerMd && currentStep !== 'WELCOME_SCREEN'}
      showPageTitle={false}
      pageTitle={r(ROUTES.userRoutes.registration.createLandlordTunnel.title)}
      showStaticMenu={false}
      hideFooter={true}
    >
      {renderTunnelStep()}
    </PageLayout>
  );
};

export default function CreateTenantFromApartmentApplyTunnelWrapper() {
  return (
    <TunnelProvider>
      <CreateTenantFromApartmentApplyTunnel />
    </TunnelProvider>
  );
}
