import { Dispatch, SetStateAction, useState } from 'react';
import { AlertColor } from '@mui/material/Alert/Alert';
import { ISnackBarProps } from 'component/molecules/SnackBarLoader';

const useSnackbarHook = (): {
  isSnackBarOpen: boolean;
  handleSnackBar: (message: string, type: AlertColor) => void;
  snackBarProps: ISnackBarProps;
  setIsSnackBarOpen: Dispatch<SetStateAction<boolean>>;
} => {
  const [isSnackBarOpen, setIsSnackBarOpen] = useState<boolean>(false);
  const [snackBarProps, setSnackBarProps] = useState<ISnackBarProps>({ message: '', type: 'success' });

  const handleSnackBar = (message: string, type: AlertColor) => {
    setIsSnackBarOpen(true);
    setSnackBarProps({ ...snackBarProps, message, type });
  };

  return { isSnackBarOpen, handleSnackBar, snackBarProps, setIsSnackBarOpen };
};

export default useSnackbarHook;
