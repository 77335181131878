import { useQuery, useQueryClient } from '@tanstack/react-query';
import { wohnsinnServices } from 'App';
import { DocumentData, getDocs, onSnapshot, QuerySnapshot } from 'firebase/firestore';
import { IApplication } from '@wohnsinn/ws-ts-lib';
import UserContext from 'core/context/user.context';
import { useContext, useEffect } from 'react';

const useLandlordChats = () => {
  const queryClient = useQueryClient();
  const { applicationService } = wohnsinnServices;
  const { landlordProfile } = useContext(UserContext);

  const fetchChats = async (landlordId: string): Promise<IApplication[]> => {
    if (landlordId) {
      const applicationQuery = applicationService.getLandlordChatsQuery(landlordId);
      const snapshot = await getDocs(applicationQuery);
      return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() } as IApplication));
    }
  };

  const query = useQuery({
    queryKey: ['landlord-chats', landlordProfile?.uid],
    queryFn: () => fetchChats(landlordProfile?.uid),
    staleTime: Infinity,
    enabled: !!landlordProfile,
  });

  useEffect(() => {
    if (!landlordProfile) return;

    const applicationQuery = applicationService.getLandlordChatsQuery(landlordProfile.uid);
    const unsubscribe = onSnapshot(applicationQuery, (snapshot: QuerySnapshot<DocumentData>) => {
      const updatedApplications = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as IApplication[];

      // Update the query cache with the real-time data
      queryClient.setQueryData(['landlord-chats', landlordProfile?.uid], updatedApplications);
    });
    return () => unsubscribe();
  }, [landlordProfile, queryClient]);

  return query;
};

export default useLandlordChats;
