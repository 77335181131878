import { FC, useContext } from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';
import styles from 'view/landlord/apartment/ApartmentListView/ApartmentListView.module.scss';
import UserContext from 'core/context/user.context';
import { useTranslation } from 'react-i18next';
import Text, { TEXT_COLOR, TEXT_TYPE, TEXT_VARIANT } from 'component/atoms/typographie/Text';

const ApartmentsTableHead: FC = () => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation('common');

  return (
    <TableHead>
      <TableRow>
        <TableCell align={'center'} style={{ paddingRight: 0 }}>
          <Text
            align={'center'}
            type={TEXT_TYPE.TEXT_TYPE_CAPTION}
            variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
            color={TEXT_COLOR.TEXT_COLOR_ACCENT}
          >
            {t('image')}
          </Text>
        </TableCell>
        <TableCell>
          <Text
            type={TEXT_TYPE.TEXT_TYPE_CAPTION}
            variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
            color={TEXT_COLOR.TEXT_COLOR_ACCENT}
          >
            {t('address.text')}
          </Text>
        </TableCell>
        {user?.isAdmin && (
          <TableCell align={'right'}>
            <Text
              align={'right'}
              type={TEXT_TYPE.TEXT_TYPE_CAPTION}
              variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
              color={TEXT_COLOR.TEXT_COLOR_ACCENT}
            >
              {t('pro')}
            </Text>
          </TableCell>
        )}

        <TableCell align={'right'}>
          <Text
            align={'right'}
            type={TEXT_TYPE.TEXT_TYPE_CAPTION}
            variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
            color={TEXT_COLOR.TEXT_COLOR_ACCENT}
          >
            {t('rent')}
          </Text>
        </TableCell>
        <TableCell align={'right'}>
          <Text
            align={'right'}
            type={TEXT_TYPE.TEXT_TYPE_CAPTION}
            variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
            color={TEXT_COLOR.TEXT_COLOR_ACCENT}
          >
            {t('size')}
          </Text>
        </TableCell>
        <TableCell>
          <Text
            type={TEXT_TYPE.TEXT_TYPE_CAPTION}
            variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
            align={'right'}
            color={TEXT_COLOR.TEXT_COLOR_ACCENT}
          >
            {t('rooms')}
          </Text>
        </TableCell>
        {user?.isAdmin && (
          <TableCell align={'left'}>
            <Text
              align={'left'}
              type={TEXT_TYPE.TEXT_TYPE_CAPTION}
              variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
              color={TEXT_COLOR.TEXT_COLOR_ACCENT}
            >
              {t('organization.title.label')}
            </Text>
          </TableCell>
        )}
        {user?.isAdmin && (
          <TableCell>
            <Text
              type={TEXT_TYPE.TEXT_TYPE_CAPTION}
              variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
              color={TEXT_COLOR.TEXT_COLOR_ACCENT}
            >
              {'uid'}
            </Text>
          </TableCell>
        )}
        {user?.isAdmin && (
          <TableCell align={'center'}>
            <Text
              align={'center'}
              type={TEXT_TYPE.TEXT_TYPE_CAPTION}
              variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
              color={TEXT_COLOR.TEXT_COLOR_ACCENT}
            >
              {'Veröffentlicht'}
            </Text>
          </TableCell>
        )}
        <TableCell align={'center'}>
          <Text
            align={'center'}
            type={TEXT_TYPE.TEXT_TYPE_CAPTION}
            variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
            color={TEXT_COLOR.TEXT_COLOR_ACCENT}
          >
            {t('status')}
          </Text>
        </TableCell>
        <TableCell align={'center'}>
          <Text
            align={'center'}
            type={TEXT_TYPE.TEXT_TYPE_CAPTION}
            variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
            color={TEXT_COLOR.TEXT_COLOR_ACCENT}
          >
            {t('applications')}
          </Text>
        </TableCell>
        <TableCell align={'center'} className={styles.stickyCell}>
          <Text
            align={'center'}
            type={TEXT_TYPE.TEXT_TYPE_CAPTION}
            variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
            color={TEXT_COLOR.TEXT_COLOR_ACCENT}
          >
            {t('edit')}
          </Text>
        </TableCell>
        <TableCell align={'center'}>
          <Text
            align={'center'}
            type={TEXT_TYPE.TEXT_TYPE_CAPTION}
            variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
            color={TEXT_COLOR.TEXT_COLOR_ACCENT}
          >
            {t('view.ApplicationOverviewView.tableHead.action')}
          </Text>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default ApartmentsTableHead;
