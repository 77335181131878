import { Grid } from '@mui/material';
import CallToActionScreen from '../../../molecules/CallToActionScreen';
import { useTunnelContext } from 'core/context/create-tenant-from-apartment-apply-tunnel.context';

const WelcomeScreen = () => {
  const { goToNextStep } = useTunnelContext();

  return (
    <Grid container maxWidth={{ xs: 'auto', md: 400 }} marginX={'auto'} paddingTop={{ xs: 6, md: 8 }}>
      <Grid item xs={12}>
        <CallToActionScreen
          headline={'Hi! 👋 \nWohnsinn ist dein neues Immobilienportal'}
          text={'Unser Ziel ist eine faire \nund transparente Wohnungssuche. Wir \ndrücken dir die Daumen!'}
          buttonText={'Weiter zur Bewerbung'}
          onClick={() => goToNextStep()}
        />
      </Grid>
    </Grid>
  );
};

export default WelcomeScreen;
