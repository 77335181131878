import { useState } from 'react';
import { SUBMIT_BUTTON_MODE } from '../../../../core/enum/submit-button-mode.enum';
import { useTunnelContext } from 'core/context/create-tenant-from-apartment-apply-tunnel.context';
import SearchTunnelStepLayout from 'component/organisms/searchTunnel/SearchTunnelStepLayout';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import Slider from 'component/atoms/formElement/Slider';
import { useTranslation } from 'react-i18next';
const NUMBER_OF_PEOPLE_MOVING_IN_STEP = 'NUMBER_OF_PEOPLE_MOVING_IN_STEP';

const NumberOfPeopleMovingInStep = () => {
  const { t } = useTranslation('common');
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { household, setHousehold, goToNextStep, goToPreviousStep } = useTunnelContext();

  const { control, handleSubmit, watch, formState } = useForm<{
    numberOfPeopleMovingIn: number;
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      numberOfPeopleMovingIn: 1,
    },
    resolver: joiResolver(Joi.object({ numberOfPeopleMovingIn: Joi.number() })),
  });
  const numberOfPeopleMovingIn = watch('numberOfPeopleMovingIn');

  const handleFormSubmit = () => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    setHousehold({ ...household, numberOfPeopleMovingIn });
    goToNextStep();
  };

  return (
    <SearchTunnelStepLayout
      formId={NUMBER_OF_PEOPLE_MOVING_IN_STEP}
      control={control}
      disabled={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING || !formState.isValid}
      buttonSubmitMode={buttonSubmitMode}
      onSubmit={handleSubmit(handleFormSubmit, console.error)}
      title={'Wie viele Personen ziehen ein?'}
      backAction={() => goToPreviousStep()}
    >
      <Headline size={HEADLINE_SIZE.DISPLAY} align={'center'}>
        {numberOfPeopleMovingIn >= 10 ? '>' : ''}
        {t('numberOfPeopleMovingInText', { count: parseInt(numberOfPeopleMovingIn as unknown as string) })}
      </Headline>
      <Slider defaultValue={1} min={1} step={1} name={'numberOfPeopleMovingIn'} max={10} />
    </SearchTunnelStepLayout>
  );
};

export default NumberOfPeopleMovingInStep;
