import { useState } from 'react';
import { SUBMIT_BUTTON_MODE } from '../../../../core/enum/submit-button-mode.enum';
import { useTunnelContext } from 'core/context/create-tenant-from-apartment-apply-tunnel.context';
import SearchTunnelStepLayout from 'component/organisms/searchTunnel/SearchTunnelStepLayout';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import RadioGroupButtons, { IRadioGroupOption } from 'component/atoms/RadioGroupButtons';

const ARE_PETS_ALLOWED_STEP = 'ARE_PETS_ALLOWED_STEP';

const ArePetsAllowedStep = () => {
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { household, setHousehold, goToNextStep, goToPreviousStep } = useTunnelContext();

  const { control, handleSubmit, formState } = useForm<{
    arePetsAllowed: boolean;
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: joiResolver(
      Joi.object({
        arePetsAllowed: Joi.boolean().required(),
      })
    ),
    defaultValues: {
      arePetsAllowed: household?.arePetsAllowed ?? null,
    },
  });

  const handleFormSubmit = (data: { arePetsAllowed: any }) => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    setHousehold({ ...household, arePetsAllowed: data.arePetsAllowed });
    goToNextStep();
    console.log('Submitting the form');
  };

  const options: IRadioGroupOption[] = [
    { key: 'Ja, ich habe Haustiere', value: true },
    { key: 'Nein, ich habe keine Haustiere', value: false },
  ];

  return (
    <SearchTunnelStepLayout
      formId={ARE_PETS_ALLOWED_STEP}
      control={control}
      disabled={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING || !formState.isValid}
      buttonSubmitMode={buttonSubmitMode}
      onSubmit={handleSubmit(handleFormSubmit, console.error)}
      title={'Hast du Haustiere?'}
      backAction={() => goToPreviousStep()}
    >
      <RadioGroupButtons name={'arePetsAllowed'} options={options} />
    </SearchTunnelStepLayout>
  );
};

export default ArePetsAllowedStep;
