import Text, { TEXT_COLOR } from 'component/atoms/typographie/Text';
import { useTranslation } from 'react-i18next';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import { Dispatch, FC, PropsWithChildren, SetStateAction, useContext } from 'react';
import ModalContext from 'core/context/modal.context';
import CTAButton, { getSubmitIcon } from 'component/atoms/Buttons/CTAButton';
import styles from './RegistrationFormWrapper.module.scss';
import SplitLine from 'component/atoms/SplitLine';
import AuthProviderLoginButtons from 'component/molecules/AuthProviderLoginButtons';
import AcceptPrivacyInfoText from 'component/atoms/AcceptPrivacyInfoText';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import Headline, { HEADLINE_SIZE } from '../../../atoms/typographie/Headline';
import Spacer, { SPACER_SIZE } from '../../../atoms/Spacer';

const RegistrationFormWrapper: FC<
  PropsWithChildren<{
    hideAuthProviderLogin?: boolean;
    formIsValid?: boolean;
    buttonSubmitMode: SUBMIT_BUTTON_MODE;
    createLandlordAccount?: boolean;
    userTypeSelected?: boolean;
    setButtonSubmitMode: Dispatch<SetStateAction<SUBMIT_BUTTON_MODE>>;
    title?: string;
    buttonText?: string;
    subTitle?: string;
    googleButtonText?: string;
  }>
> = ({
  title,
  subTitle,
  children,
  hideAuthProviderLogin = false,
  buttonSubmitMode,
  formIsValid,
  createLandlordAccount,
  setButtonSubmitMode,
  userTypeSelected,
  buttonText,
  googleButtonText,
}) => {
  const { openModal } = useContext(ModalContext);
  const { t } = useTranslation('common');
  const { t: v } = useTranslation('common', { keyPrefix: 'component.organisms.forms.signIn.RegistrationFormWrapper' });

  return (
    <div>
      <div className={styles.titleWrapper}>
        <Headline size={HEADLINE_SIZE.H2} align={'center'}>
          {title ?? v('title')}
        </Headline>
        {subTitle ? (
          <>
            <Spacer size={SPACER_SIZE.SM} />
            <Text align={'center'}>{subTitle}</Text>
          </>
        ) : null}
      </div>

      {children}

      <button type={'submit'} className={'hidden'}>
        submit
      </button>
      <CTAButton
        type={'submit'}
        disabled={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING || !formIsValid}
        icon={getSubmitIcon(buttonSubmitMode)}
        spinIcon={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING}
        expand={'block'}
        buttonText={buttonText ?? t('registerButtonText')}
        rounded={false}
      />

      <div className={styles.splitLineWrapper}>
        <SplitLine size={'small'} text={t('or')}></SplitLine>
      </div>

      {hideAuthProviderLogin ? null : (
        <AuthProviderLoginButtons
          buttonText={googleButtonText ?? undefined}
          isRegistration
          disabled={userTypeSelected}
          createLandlordAccount={createLandlordAccount}
          setButtonSubmitMode={setButtonSubmitMode}
        />
      )}

      <AcceptPrivacyInfoText />

      <div className={styles.login}>
        <Text tag={'span'} align={'center'} color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
          {v('alreadyHaveAnAccount')}
        </Text>
        <button type={'button'} onClick={() => openModal({ id: MODAL_IDENTIFIER.SIGN_IN })}>
          <Text tag={'span'} bold color={TEXT_COLOR.TEXT_COLOR_PRIMARY}>
            {v('loginNow')}
          </Text>
        </button>
      </div>
    </div>
  );
};

export default RegistrationFormWrapper;
