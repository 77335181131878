import { ITenantProfile } from '@wohnsinn/ws-ts-lib';

export enum TENANT_APPLICATION_STATE {
  COMPLETE = 'COMPLETE',
  NOT_READY_FOR_APPLICATION = 'NOT_READY_FOR_APPLICATION',
  MISSING_DOCS = 'MISSING_DOCS',
  READY_FOR_APPLICATION = 'READY_FOR_APPLICATION',
}
const isWogeEnvironment = process.env.REACT_APP_ENVIRONMENT === 'woge';

/**
 * Check users profile and return if the profile is incomplete, complete or not complete but at least ready for application
 * @param tenantProfile
 * @return TENANT_APPLICATION_STATE
 */
export const getTenantApplicationState = (tenantProfile: ITenantProfile): TENANT_APPLICATION_STATE => {
  const hasInvalidForms = !tenantProfile?.personalInformation || !tenantProfile?.household || !tenantProfile?.aboutMe;

  if (hasInvalidForms) {
    return TENANT_APPLICATION_STATE.NOT_READY_FOR_APPLICATION;
  }

  if (isWogeEnvironment && (!tenantProfile?.hasSchufaDocs || !tenantProfile?.hasIncomeProofDocs)) {
    return TENANT_APPLICATION_STATE.MISSING_DOCS;
  }

  // If a user has all of this information, the profile is completed
  if (
    tenantProfile?.hasSchufaDocs &&
    tenantProfile?.hasIncomeProofDocs &&
    tenantProfile?.aboutMe.introductionVideo &&
    tenantProfile?.photoUrl
  ) {
    return TENANT_APPLICATION_STATE.COMPLETE;
  }

  return TENANT_APPLICATION_STATE.READY_FOR_APPLICATION;
};
