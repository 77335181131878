import { FC } from 'react';
import { IApartment } from '@wohnsinn/ws-ts-lib';
import styles from './ApartmentInformationLocation.module.scss';
import Headline, { HEADLINE_SIZE } from '../typographie/Headline';
import Text, { TEXT_COLOR } from '../typographie/Text';
import { getFormattedPrice } from 'core/helper/get-formatted-price';

export interface IApartmentInformationLocationProps {
  apartment: IApartment;
}

const ApartmentInformationLocation: FC<IApartmentInformationLocationProps> = ({ apartment }) => {
  const { mainInformation, cost } = apartment;

  return (
    <div className={styles.wrapper}>
      <div className={styles.address}>
        {cost?.warmRent || cost?.coldRent || cost?.purchasePrice ? (
          <Headline size={HEADLINE_SIZE.H1} noWrap={true}>
            {getFormattedPrice(apartment?.cost?.warmRent, apartment?.cost?.coldRent)}
          </Headline>
        ) : null}

        <Text align={'right'} tag={'span'} color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
          {mainInformation?.address?.hasPublishedAddress && mainInformation?.address.street
            ? `${mainInformation?.address.street} ${mainInformation?.address?.houseNumber}`
            : ''}
          {mainInformation?.address?.hasPublishedAddress && mainInformation?.address.street ? <br /> : null}
          {mainInformation?.address?.postalCode} {mainInformation?.address?.city}
        </Text>
      </div>
    </div>
  );
};
export default ApartmentInformationLocation;
