import FAQSection from 'component/organisms/landing-page-sections/FAQSection';
import HeroSection from 'component/organisms/landing-page-sections/HeroSection';
import LogoSlider from 'component/atoms/LogoSlider';
import SearchAddressForm from 'component/atoms/SearchAddressForm';
import Text, { TEXT_COLOR, TEXT_TYPE } from 'component/atoms/typographie/Text';
import { Grid } from '@mui/material';
import { IMAGES } from 'core/const/images';
import { ROUTES } from 'core/const/routes';
import { useTranslation } from 'react-i18next';
import LandingPageLayout from 'component/layouts/LandingPageLayout';
import CitySlider from '../../../component/organisms/CitySlider';
import ProSection from '../../../component/organisms/landing-page-sections/ProSection';
import Headline, { HEADLINE_SIZE } from '../../../component/atoms/typographie/Headline';
import HeroCard from 'component/atoms/Card/HeroCard';
import { USER_TYPE } from '@wohnsinn/ws-ts-lib';
import { useContext, useState } from 'react';
import useWindowSize from '../../../core/hook/windowsize.hook';
import CTAButton from '../../../component/atoms/Buttons/CTAButton';
import { faBuilding, faHouse, faKey, faUser } from '@fortawesome/pro-regular-svg-icons';
import KnownFromSection from 'component/organisms/landing-page-sections/KnownFromSection';
import { BREAKPOINTS } from 'core/const/breakpoints';
import styles from '../../../component/organisms/landing-page-sections/HeroSection/HeroSection.module.scss';
import CheckList, { ICheckListItem } from 'component/atoms/CheckList';
import ModalContext from 'core/context/modal.context';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import PricingCard from 'component/molecules/Cards/PricingCard';

const LandingPageView = () => {
  const { isSmallerMd } = useWindowSize();
  const [userType, setUserType] = useState(USER_TYPE.LANDLORD);
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const { t: a } = useTranslation('common', { keyPrefix: 'view.general.AboutUsView' });
  const { t: p } = useTranslation('common', { keyPrefix: 'view.general.PricesView' });
  const { openModal } = useContext(ModalContext);

  const CHECK_LIST_BUSINESS: ICheckListItem[] = [
    { text: p('cards.business.one') },
    { text: p('cards.business.two') },
    { text: p('cards.business.three') },
    { text: p('cards.business.four') },
  ];

  const CHECK_LIST_TENANT: ICheckListItem[] = [
    { text: p('cards.tenant.one') },
    { text: p('cards.tenant.two') },
    { text: p('cards.tenant.three') },
    { text: p('cards.tenant.four') },
  ];
  return (
    <LandingPageLayout onlyChildren pageTitle={r(ROUTES.landingPageRoutes.tenant.title)}>
      <div className={styles.background}>
        <Grid container px={3} marginX={'auto'} maxWidth={{ lg: BREAKPOINTS.xl }}>
          <Grid item xs={12}>
            <HeroSection
              title={t('view.landing.hero.title')}
              subTitle={t('view.landing.hero.subTitle')}
              image={IMAGES.landing.hero}
              secondaryCTAOnClick={() => openModal({ id: MODAL_IDENTIFIER.SIGN_UP_USER })}
              secondaryCtaText={'Kostenloses Inserat erstellen'}
              caption={<CheckList items={[{ text: t('view.landing.hero.checklist.free'), invert: true }]} />}
              secondaryCtaIcon={faHouse}
            >
              <SearchAddressForm />
            </HeroSection>
          </Grid>
        </Grid>
      </div>
      <Grid container px={3} marginX={'auto'} maxWidth={{ lg: BREAKPOINTS.xl }}>
        <Grid
          item
          xs={12}
          className={'ws-full-width-bg ws-full-width-bg--white'}
          paddingTop={{ xs: 4, md: 10 }}
          paddingBottom={{ xs: 4, md: 10 }}
        >
          <Text type={TEXT_TYPE.TEXT_TYPE_BODY} color={TEXT_COLOR.TEXT_COLOR_ACCENT} align={'center'}>
            {a('customers.smallHeadline')}
          </Text>
          <KnownFromSection />
        </Grid>
        <Grid
          item
          xs={12}
          paddingTop={{ xs: 4, md: 10 }}
          paddingBottom={{ xs: 4, md: 10 }}
          className={'ws-full-width-bg'}
        >
          <Grid item xs={12} paddingTop={{ xs: 4, md: 10 }} paddingBottom={{ xs: 4, md: 10 }}>
            <Headline size={HEADLINE_SIZE.HERO} align={'center'}>
              {p('header.headline')}
            </Headline>
          </Grid>
          <Grid
            container
            direction={'row'}
            justifyContent={'center'}
            spacing={4}
            alignItems={'stretch'}
            paddingY={4}
            gap={4}
          >
            <Grid item xs={12} md={5} paddingBottom={{ xs: 4, md: 10 }} paddingY={4}>
              <PricingCard
                preHeader={p('cards.business.title')}
                userType={USER_TYPE.LANDLORD}
                subLine={p('cards.business.subLine')}
                price={p('cards.business.price')}
                title={p('cards.business.title')}
                checkListItems={CHECK_LIST_BUSINESS}
              />
            </Grid>
            <Grid item xs={12} md={5} paddingBottom={{ xs: 4, md: 10 }}>
              <PricingCard
                preHeader={p('cards.tenant.title')}
                priceExtra={p('cards.tenant.priceExtra')}
                userType={USER_TYPE.TENANT}
                subLine={p('cards.tenant.subLine')}
                price={p('cards.tenant.price')}
                title={p('cards.tenant.title')}
                checkListItems={CHECK_LIST_TENANT}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          paddingTop={{ xs: 4, md: 10 }}
          paddingBottom={{ xs: 4, md: 10 }}
          className={'ws-full-width-bg ws-full-width-bg--white'}
        >
          <CitySlider />
        </Grid>
        <ProSection userType={userType} setUserType={setUserType} />
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} md={8} justifyContent={'center'} paddingTop={{ xs: 4, md: 10 }}>
            <Headline size={HEADLINE_SIZE.DISPLAY} align={'center'}>
              Vermietest du Wohnungen?
            </Headline>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems={'stretch'}
          display={'flex'}
          justifyContent={'center'}
          paddingTop={{ xs: 4, md: 10 }}
          paddingBottom={{ xs: 4, md: 10 }}
        >
          <Grid alignSelf={'stretch'} item xs={12} md={8}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                {isSmallerMd ? (
                  <CTAButton
                    expand={'block'}
                    rounded={false}
                    icon={faUser}
                    color={TEXT_COLOR.TEXT_COLOR_DARK}
                    link={r(ROUTES.landingPageRoutes.landlord.path)}
                    buttonText={'Für private Vermieter'}
                  />
                ) : (
                  <HeroCard
                    image={IMAGES.forLandlords.private.src}
                    buttonText={'Mehr erfahren'}
                    title={'Für private Vermieter'}
                    link={r(ROUTES.landingPageRoutes.landlord.path)}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                {isSmallerMd ? (
                  <CTAButton
                    expand={'block'}
                    rounded={false}
                    icon={faHouse}
                    color={TEXT_COLOR.TEXT_COLOR_DARK}
                    link={r(ROUTES.landingPageRoutes.propertyManagement.path)}
                    buttonText={'Für Hausverwaltungen'}
                  />
                ) : (
                  <HeroCard
                    image={IMAGES.forLandlords.propertyManagement.src}
                    buttonText={'Mehr erfahren'}
                    title={'Für Hausverwaltungen'}
                    link={r(ROUTES.landingPageRoutes.propertyManagement.path)}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                {isSmallerMd ? (
                  <CTAButton
                    expand={'block'}
                    rounded={false}
                    icon={faKey}
                    color={TEXT_COLOR.TEXT_COLOR_DARK}
                    link={r(ROUTES.landingPageRoutes.realEstateAgent.path)}
                    buttonText={'Für Makler'}
                  />
                ) : (
                  <HeroCard
                    image={IMAGES.forLandlords.realEstateAgent.src}
                    buttonText={'Mehr erfahren'}
                    title={'Für Makler'}
                    link={r(ROUTES.landingPageRoutes.realEstateAgent.path)}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                {isSmallerMd ? (
                  <CTAButton
                    expand={'block'}
                    rounded={false}
                    icon={faBuilding}
                    color={TEXT_COLOR.TEXT_COLOR_DARK}
                    link={r(ROUTES.landingPageRoutes.cooperative.path)}
                    buttonText={'Für Genossenschaften'}
                  />
                ) : (
                  <HeroCard
                    image={IMAGES.forLandlords.cooperative.src}
                    buttonText={'Mehr erfahren'}
                    title={'Für Genossenschaften'}
                    link={r(ROUTES.landingPageRoutes.cooperative.path)}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent={'center'}>
          <Grid
            item
            xs={12}
            md={8}
            className={'ws-full-width-bg ws-full-width-bg--white'}
            justifyContent={'center'}
            paddingTop={{ xs: 4, md: 10 }}
            paddingBottom={{ xs: 4, md: 10 }}
          >
            <Headline size={HEADLINE_SIZE.DISPLAY} align={'center'}>
              Täglich von kleinen und großen Vermietern genutzt
            </Headline>
          </Grid>
        </Grid>
        <Grid item xs={12} className={'ws-full-width-bg ws-full-width-bg--white'} paddingBottom={{ xs: 4, md: 10 }}>
          <LogoSlider items={CLIENT_LOGOS} />
        </Grid>
        <Grid
          item
          className={'ws-full-width-bg ws-full-width-bg--white'}
          xs={12}
          paddingTop={{ xs: 4, md: 10 }}
          paddingBottom={{ xs: 8, md: 20 }}
        >
          <FAQSection setUserType={setUserType} userType={userType} />
        </Grid>
      </Grid>
    </LandingPageLayout>
  );
};

export const CLIENT_LOGOS = [
  IMAGES.logos.BE,
  IMAGES.logos.Bellasai,
  IMAGES.logos.Burgmeyer,
  IMAGES.logos.Dahmann,
  IMAGES.logos.kittlaus,
  IMAGES.logos.ivvg,
  IMAGES.logos.josefklein,
  IMAGES.logos.beletage,
  IMAGES.logos.larbig,
  IMAGES.logos.metropol,
  IMAGES.logos.mvgm,
  IMAGES.logos.jansen,
  IMAGES.logos.ndi,
  IMAGES.logos.rida,
  IMAGES.logos.skz,
  IMAGES.logos.sps,
];

export default LandingPageView;
