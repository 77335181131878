import PageLayout from 'component/layouts/PageLayout';
import { ROUTES } from 'core/const/routes';
import { useTranslation } from 'react-i18next';

import SelectTenantsStep from 'component/forms/apartment/ApartmentSelectTenantsForMessageForm/SelectTenantsStep';
import SendMessageStep from 'component/forms/apartment/ApartmentSelectTenantsForMessageForm/SendMessageStep';
import SuccessStep from 'component/forms/apartment/ApartmentSelectTenantsForMessageForm/SuccessStep';
import { useState } from 'react';
import { IApplication } from '@wohnsinn/ws-ts-lib';
import { useParams } from 'react-router-dom';
import useLandlordApplicationListHook from 'core/hook/landlord-application-list.hook';

const ApartmentSendMessageView = () => {
  const { t: r } = useTranslation('routes');
  const [selectedApplications, setSelectedApplications] = useState<IApplication[]>([]);
  const { apartmentId: apartmentIdFromParams } = useParams<{ apartmentId: string }>();
  const [step, setStep] = useState<1 | 2 | 3>(1);
  const { applicationList } = useLandlordApplicationListHook({ apartmentId: apartmentIdFromParams });

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <SelectTenantsStep
            selectedApplications={selectedApplications}
            setSelectedApplications={setSelectedApplications}
            applicationList={applicationList}
            setStep={setStep}
          />
        );
      case 2:
        return <SendMessageStep selectedApplications={selectedApplications} setStep={setStep} />;
      case 3:
        return <SuccessStep />;
    }
  };

  return (
    <PageLayout
      showPageTitle={false}
      pageTitle={r(ROUTES.landlordRoutes.apartment.mainInformation.title)}
      hideMenu={false}
    >
      {renderStep()}
    </PageLayout>
  );
};
export default ApartmentSendMessageView;
