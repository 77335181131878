import { FC } from 'react';
import PageLayout from '../../../../component/layouts/PageLayout';
import { ROUTES } from '../../../../core/const/routes';
import AccountBreadcrumbs from '../../../../component/molecules/Breadcrumbs/AccountBreadcrumbs';
import TenantProfileForm from '../../../../component/forms/tenant/TenantProfileForm';
import { useTranslation } from 'react-i18next';
import TunnelStepper, {
  ITunnelStepperStepProps,
  TUNNEL_STEP_STATE,
} from '../../../../component/organisms/Tunnels/TunnelStepper';
import { IAccountStepperViewProps } from '../AboutMeView';
import useWindowSize from 'core/hook/windowsize.hook';

const TenantProfileView: FC<IAccountStepperViewProps> = ({ isApplicationFolderTunnel = false }) => {
  const { t: r } = useTranslation('routes');
  const { isSmallerMd } = useWindowSize();

  const APPLICATION_FOLDER_STEPS_PROFILE: ITunnelStepperStepProps[] = [
    {
      label: 'Persönliches',
      state: TUNNEL_STEP_STATE.CURRENT,
      link: ROUTES.tenantRoutes.account.applicationFolder.profile,
    },
    {
      label: 'Haushalt',
      state: TUNNEL_STEP_STATE.INACTIVE,
      link: ROUTES.tenantRoutes.account.applicationFolder.household,
    },
    {
      label: 'Vorstellungsvideo',
      state: TUNNEL_STEP_STATE.INACTIVE,
      link: ROUTES.tenantRoutes.account.applicationFolder.introductionVideo,
    },
    {
      label: 'Über mich',
      state: TUNNEL_STEP_STATE.INACTIVE,
      link: ROUTES.tenantRoutes.account.applicationFolder.aboutMe,
    },
    { label: 'Schufa', state: TUNNEL_STEP_STATE.INACTIVE, link: ROUTES.tenantRoutes.account.applicationFolder.schufa },
    {
      label: 'Einkommensnachweis',
      state: TUNNEL_STEP_STATE.INACTIVE,
      link: ROUTES.tenantRoutes.account.applicationFolder.incomeProof,
    },
  ];

  return (
    <PageLayout
      showPageTitle={false}
      pageTitle={r(ROUTES.tenantRoutes.account.profile.title)}
      showBackButton={isSmallerMd}
    >
      {isApplicationFolderTunnel ? (
        <TunnelStepper steps={APPLICATION_FOLDER_STEPS_PROFILE} />
      ) : (
        <AccountBreadcrumbs page={ROUTES.tenantRoutes.account.profile} />
      )}
      <TenantProfileForm isApplicationFolderTunnel={isApplicationFolderTunnel} />
    </PageLayout>
  );
};

export default TenantProfileView;
