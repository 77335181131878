import CallToActionScreen from 'component/molecules/CallToActionScreen';
import FB_FUNCTION_URLS from 'core/const/fb-function-names';
import Joi from 'joi';
import PageLayout from 'component/layouts/PageLayout';
import SnackBarContext from 'core/context/snackbar.context';
import TextInput from 'component/atoms/formElement/InputText';
import {
  AUTH_PROVIDER,
  EMAIL_SCHEMA,
  ICheckIfUserExistsResponse,
  REGISTRATION_REFERRER,
  USER_TYPE,
} from '@wohnsinn/ws-ts-lib';
import { FormContext } from 'core/context/form.context';
import { Grid } from '@mui/material';
import { IMAGES } from 'core/const/images';
import { ROUTES } from 'core/const/routes';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { joiResolver } from '@hookform/resolvers/joi';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { wohnsinnServices } from 'App';
import TenantFilterParamsContext from 'core/context/tenant-filter-params.context';
import TestimonialSection from 'component/organisms/landing-page-sections/TestimonialSection';
import HeadlineTextBanner from 'component/molecules/HeadlineTextBanner';
import Spacer, { SPACER_SIZE } from 'component/atoms/Spacer';
import Headline from 'component/atoms/typographie/Headline';
import Text from 'component/atoms/typographie/Text';
import Accordion from 'component/atoms/Accordion';
import { faqTenantTunnel } from 'core/const/faq';
import ResponsiveImage from 'component/atoms/ResponsiveImage';
import RegistrationFormWrapper from 'component/forms/signIn/RegistrationFormWrapper';

export const USER_CREDENTIALS_FORM_SCHEMA = Joi.object({
  email: EMAIL_SCHEMA.required(),
  createLandlordAccount: Joi.boolean().required(),
});

const TunnelRegistrationView = () => {
  const [selectedTab, setSelectedTab] = useState(null);
  const [isCreatingAccount, setIsCreatingAccount] = useState<boolean>(false);
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { handleSnackBar } = useContext(SnackBarContext);
  const { tenantFilterParams } = useContext(TenantFilterParamsContext);
  const navigate = useNavigate();
  const { firebaseFunctionsService, registrationRequestService } = wohnsinnServices;
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const { t: i } = useTranslation('images');
  const { t: f } = useTranslation('common', { keyPrefix: 'view.general.CreateAccountView' });
  const { referrer } = useParams<{ referrer: REGISTRATION_REFERRER }>();

  const { control, handleSubmit, watch, formState } = useForm<{
    email: string;
    createLandlordAccount: boolean;
  }>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(USER_CREDENTIALS_FORM_SCHEMA),
    defaultValues: {
      email: undefined,
      createLandlordAccount: false,
    },
  });

  const testimonials = [
    {
      name: 'Anna L.',
      company: 'Mieterin aus Köln',
      avatar: i('testimonials.anna.src'),
      quote: '“Endlich eine faire Alternative zu Immoscout.”',
    },
    {
      name: 'Tobias B.',
      avatar: i('testimonials.tobias.src'),
      quote: '“Keine ausgedruckten Bewerbermappen mehr.”',
      company: 'Mieter aus Bonn ',
    },
    {
      name: 'Katrin H. ',
      avatar: i('testimonials.katrin.src'),
      quote: '“Wohnsinn hat mir Zeit bei der Suche gespart.”',
      company: 'Mieterin aus Leverkusen ',
    },
  ];

  /**
   * Check if account with given email exists
   * If not send create a user registration request
   * If exists, output info message
   */
  const handleFormSubmit = async () => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    const email = watch().email;
    const createLandlordProfile = watch().createLandlordAccount;

    try {
      // Check if account with given email exists
      const response: { data: ICheckIfUserExistsResponse } = (await firebaseFunctionsService.callFbFunction(
        FB_FUNCTION_URLS.user.checkIfUserExists,
        {
          params: { email },
        }
      )) as { data: ICheckIfUserExistsResponse };

      // if no account exists, create a user registration request
      if (!response.data.userExists) {
        setIsCreatingAccount(true);
        await registrationRequestService.createUserRegistrationRequest(
          email,
          createLandlordProfile ? USER_TYPE.LANDLORD : USER_TYPE.TENANT,
          referrer ?? REGISTRATION_REFERRER.NONE,
          tenantFilterParams
        );

        // show cool ui
        setTimeout(() => {
          setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
          navigate(r(ROUTES.staticRoutes.registration.activateAccount.path).replace(':email', email));
        }, 1500);
      } else {
        // if email already exists, check an account provider and output info message
        switch (response.data.provider) {
          case AUTH_PROVIDER.PASSWORD:
            handleSnackBar('toast.signIn.emailAccountExistsAlready', 'error');
            break;
          case AUTH_PROVIDER.GOOGLE:
            handleSnackBar('toast.signIn.googleAccountExistsAlready', 'error');
            break;
          default:
            handleSnackBar('toast.signIn.emailAccountExistsAlready', 'error');
        }
        setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
      }
    } catch (e) {
      console.error('e', e);
      handleSnackBar('toast.signIn.unknown');
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
    }
  };

  return (
    <PageLayout
      tunnelMode
      hideFooter
      hideMenu
      showPageTitle={false}
      pageTitle={r(ROUTES.staticRoutes.registration.tunnel.title)}
      secondPageTitle={'✨ Über 2.000 Mieter nutzen Wohnsinn'}
    >
      <Grid container justifyContent={'center'} alignItems={'center'} rowSpacing={{ xs: 8, md: 12 }}>
        <Grid item xs={12}>
          {isCreatingAccount ? (
            <CallToActionScreen text={f('creatingAccountMessage')} img={IMAGES.icons.heart} animateImg={true} />
          ) : (
            <>
              <>
                <Grid container marginX={'auto'} maxWidth={400}>
                  <FormContext.Provider value={{ control }}>
                    <form onSubmit={handleSubmit(handleFormSubmit, console.error)}>
                      <RegistrationFormWrapper
                        googleButtonText={'Anmelden mit Google'}
                        title={r(ROUTES.staticRoutes.registration.tunnel.title)}
                        subTitle={'✨ Über 2.000 Mieter nutzen Wohnsinn'}
                        userTypeSelected={watch('createLandlordAccount') === undefined}
                        setButtonSubmitMode={setButtonSubmitMode}
                        createLandlordAccount={watch().createLandlordAccount}
                        buttonSubmitMode={buttonSubmitMode}
                        formIsValid={true}
                        buttonText={'Zeig mir meine Treffer 👉'}
                      >
                        <TextInput label={t('email.label')} name={'email'} type={'email'} required />
                      </RegistrationFormWrapper>
                    </form>
                  </FormContext.Provider>
                </Grid>
              </>
              <Grid item xs={12} paddingTop={{ xs: 4, md: 10 }} paddingBottom={{ xs: 4, md: 10 }}>
                <Spacer />
                <Spacer />
                <Spacer />
                <HeadlineTextBanner tag={'Was unsere Nutzer sagen ✨'} />
                <Spacer />
                <TestimonialSection testimonials={testimonials} />
              </Grid>
              <Grid item xs={12} paddingTop={{ xs: 4, md: 10 }} paddingBottom={{ xs: 4, md: 10 }}>
                <HeadlineTextBanner tag={'Deine Vorteile'} headline={'Das erwartet dich bei uns'} />
                <Spacer size={SPACER_SIZE.XS} />
                <Grid container rowGap={2}>
                  <Grid item xs={12} md={6}>
                    <Grid container>
                      <Grid item xs={2} md={1}>
                        <Headline>🏡</Headline>
                      </Grid>
                      <Grid item xs={10} md={11}>
                        <Text>
                          <Text tag={'span'} bold>
                            Exklusive Wohnungen,
                          </Text>{' '}
                          die du sonst auf keinem anderen Immobilienportal findest.
                        </Text>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container>
                      <Grid item xs={2} md={1}>
                        <Headline align={'center'}>🔥</Headline>
                      </Grid>
                      <Grid item xs={10} md={11}>
                        <Text>
                          <Text tag={'span'} bold>
                            Bessere Chancen,
                          </Text>{' '}
                          durch weniger Konkurrenz und persönliche Bewerbung.
                        </Text>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container>
                      <Grid item xs={2} md={1}>
                        <Headline align={'center'}>⚡️</Headline>
                      </Grid>
                      <Grid item xs={10} md={11}>
                        <Text>
                          <Text tag={'span'} bold>
                            Echtzeit-Benachrichtigungen,
                          </Text>{' '}
                          damit du immer zu den Ersten gehörst.
                        </Text>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container>
                      <Grid item xs={2} md={1}>
                        <Headline align={'center'}>🫶🏻</Headline>
                      </Grid>
                      <Grid item xs={10} md={11}>
                        <Text>
                          <Text tag={'span'} bold>
                            Kostenlos,
                          </Text>{' '}
                          denn wir wissen, wie schwer es ist, eine Wohnung zu finden.
                        </Text>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} paddingTop={{ xs: 4, md: 10 }} paddingBottom={{ xs: 4, md: 10 }}>
                <HeadlineTextBanner tag={'Über uns'} headline={'Wer steckt hinter Wohnsinn'} />
                <Spacer size={SPACER_SIZE.SM} />

                <Grid container justifyContent={'center'}>
                  <Grid item xs={12} md={6}>
                    <ResponsiveImage image={IMAGES.images.felixChris} rounded />
                  </Grid>
                </Grid>
                <Spacer />
                <Spacer />

                <Grid container rowGap={2}>
                  <Grid item xs={12} md={4}>
                    <Grid container>
                      <Grid item xs={2}>
                        <Headline align={'center'}>🚀</Headline>
                      </Grid>
                      <Grid item xs={10}>
                        <Text>
                          <Text tag={'span'} bold>
                            Startup aus Köln
                          </Text>
                          <br />
                          Wir sind in 2023 an den Start gegangen
                        </Text>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Grid container>
                      <Grid item xs={2}>
                        <Headline align={'center'}>✨</Headline>
                      </Grid>
                      <Grid item xs={10}>
                        <Text>
                          <Text tag={'span'} bold>
                            +2.000 Mieter nutzen Wohnsinn
                          </Text>
                          <br />
                          Jeden Tag kommen neue Suchende dazu
                        </Text>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Grid container>
                      <Grid item xs={2}>
                        <Headline align={'center'}>💙</Headline>
                      </Grid>
                      <Grid item xs={10}>
                        <Text>
                          <Text tag={'span'} bold>
                            Bei uns ist jeder Premium
                          </Text>
                          <br />
                          Bei uns gibt es keine teuren Abofallen
                        </Text>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <HeadlineTextBanner tag={'FAQ'} headline={'Hast du noch Fragen?'} />
                <Spacer size={SPACER_SIZE.SM} />
                <Accordion isLanding items={faqTenantTunnel} onChange={setSelectedTab} selectedTab={selectedTab} />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default TunnelRegistrationView;
