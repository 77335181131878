import { FC, useContext, useMemo } from 'react';
import { Avatar, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Radio, RadioGroup } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FormContext } from 'core/context/form.context';
import styles from './RadioGroupButtons.module.scss';

export interface IRadioGroupOption {
  key: string;
  value: any;
  meta?: any;
  img?: string;
}

interface RadioGroupButtonsProps {
  options: IRadioGroupOption[];
  name: string;
  showAvatar?: boolean;
}

const RadioGroupButtons: FC<RadioGroupButtonsProps> = ({ options, name, showAvatar = false }) => {
  const memoizedOptions = useMemo(() => options, [options]);
  const { control } = useContext(FormContext);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <RadioGroup style={{ width: '100%' }} aria-label={name} name={name} value={field.value}>
          <List className={styles.list}>
            {memoizedOptions.map(({ key, value: optionValue, meta, img }) => (
              <ListItem key={`${key}-${optionValue}`} className={styles.listItem}>
                <ListItemButton
                  onClick={() => field.onChange(optionValue)}
                  className={`${styles.listItemButton} ${field.value === optionValue ? styles.selected : ''}`}
                >
                  {showAvatar ? (
                    <ListItemAvatar>
                      <Avatar alt={key} src={img} />
                    </ListItemAvatar>
                  ) : null}
                  <ListItemText primary={key} secondary={meta} />
                  <Radio value={optionValue} checked={field.value === optionValue} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </RadioGroup>
      )}
    />
  );
};

export default RadioGroupButtons;
