import { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { IHousehold } from '@wohnsinn/ws-ts-lib';

// Define the steps using an enum
export enum CREATE_TENANT_FROM_APARTMENT_APPLY_TUNNEL_STEPS {
  WELCOME_SCREEN = 'WELCOME_SCREEN',
  NUMBER_OF_PEOPLE_MOVING_IN = 'NUMBER_OF_PEOPLE_MOVING_IN',
  NUMBER_OF_CHILDREN_MOVING_IN = 'NUMBER_OF_CHILDREN_MOVING_IN',
  IS_SHARED_USAGE_ALLOWED = 'IS_SHARED_USAGE_ALLOWED',
  ARE_PETS_ALLOWED = 'ARE_PETS_ALLOWED',
  MONTHLY_INCOME = 'MONTHLY_INCOME',
  HAS_PLEDGE = 'HAS_PLEDGE',
  CAN_BE_SUBSIDIZED = 'CAN_BE_SUBSIDIZED',
  CHECK_MATCHING = 'CHECK_MATCHING',
  START_SIGN_IN = 'START_SIGN_IN',
}

interface TunnelContextType {
  currentStep: CREATE_TENANT_FROM_APARTMENT_APPLY_TUNNEL_STEPS;
  progress: number;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  setStep: (step: CREATE_TENANT_FROM_APARTMENT_APPLY_TUNNEL_STEPS) => void;
  household: IHousehold;
  setHousehold: (household: IHousehold) => void;
}

const CreateTenantFromApplyTunnelContext = createContext<TunnelContextType | undefined>(undefined);

// Utility to get the enum values in order
const enumSteps = Object.values(CREATE_TENANT_FROM_APARTMENT_APPLY_TUNNEL_STEPS);

export const TunnelProvider = ({ children }: { children: ReactNode }) => {
  const [currentStep, setCurrentStep] = useState<CREATE_TENANT_FROM_APARTMENT_APPLY_TUNNEL_STEPS>(
    CREATE_TENANT_FROM_APARTMENT_APPLY_TUNNEL_STEPS.WELCOME_SCREEN
  );
  const [household, setHousehold] = useState<IHousehold>(null);

  useEffect(() => {
    console.log(household);
  }, [household]);

  // Helper to calculate progress based on the current step's position in the enum
  const calculateProgress = (step: CREATE_TENANT_FROM_APARTMENT_APPLY_TUNNEL_STEPS) => {
    const currentIndex = enumSteps.indexOf(step);
    return currentIndex >= 0 ? ((currentIndex + 1) / enumSteps.length) * 100 : 0;
  };

  const goToNextStep = () => {
    const currentIndex = enumSteps.indexOf(currentStep);
    if (currentIndex < enumSteps.length - 1) {
      const nextStep = enumSteps[currentIndex + 1];
      setCurrentStep(nextStep);
    }
  };

  const goToPreviousStep = () => {
    const currentIndex = enumSteps.indexOf(currentStep);
    if (currentIndex > 0) {
      const previousStep = enumSteps[currentIndex - 1];
      setCurrentStep(previousStep);
    }
  };

  const setStep = (step: CREATE_TENANT_FROM_APARTMENT_APPLY_TUNNEL_STEPS) => {
    if (enumSteps.includes(step)) {
      setCurrentStep(step);
    }
  };

  return (
    <CreateTenantFromApplyTunnelContext.Provider
      value={{
        currentStep,
        progress: calculateProgress(currentStep),
        goToNextStep,
        goToPreviousStep,
        setStep,
        household,
        setHousehold,
      }}
    >
      {children}
    </CreateTenantFromApplyTunnelContext.Provider>
  );
};

export const useTunnelContext = () => {
  const context = useContext(CreateTenantFromApplyTunnelContext);
  if (!context) {
    throw new Error('useTunnelContext must be used within a TunnelProvider');
  }
  return context;
};
