import React from 'react';
import styles from './PricingOptions.module.scss';
import { useTranslation } from 'react-i18next';
import { PriceOption } from 'component/atoms/PremiumSubscriptionDrawer';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import Badge, { BADGE_COLORS } from 'component/atoms/Badge';

interface PricingOptionsProps {
  priceList: PriceOption[];
  selectedOption: PriceOption;
  setSelectedOption: (option: PriceOption) => void;
}

const PricingOptions: React.FC<PricingOptionsProps> = ({ priceList, selectedOption, setSelectedOption }) => {
  const { t } = useTranslation('common');

  const handleSelect = (price: PriceOption) => {
    setSelectedOption(price);
  };

  const getWeekPrice = (price: PriceOption) => {
    return (price.unit_amount / price?.interval_count / 100).toFixed(2);
  };

  return (
    <div className={styles.wrapper}>
      {priceList.map((price, index) => (
        <button
          key={index}
          className={`${styles.option}  ${selectedOption?.id === price.id ? styles.selected : ''}`}
          onClick={() => handleSelect(price)}
        >
          <div className={styles.priceWrapper}>
            <div className={styles.firstRow}>
              <Headline size={HEADLINE_SIZE.H2}>
                {price.interval_count} {t(price?.interval, { count: price?.interval_count })}
              </Headline>

              {price?.metadata?.default ? <Badge color={BADGE_COLORS.DARK} text={t('subscription.popular')} /> : null}
            </div>
            <div className={styles.firstRow}>
              <div className={styles.priceValue}>
                <p>{getWeekPrice(price)} € pro Monat</p>
              </div>
              {price?.metadata?.saving && (
                <Badge
                  color={BADGE_COLORS.MEDIUM}
                  text={t('subscription.price.saving', { price: price.metadata.saving })}
                />
              )}
            </div>
          </div>
          <input
            type="radio"
            name="pricing"
            value={price.id}
            checked={selectedOption?.id === price.id}
            onChange={() => handleSelect(price)}
            className={styles.hiddenInput}
          />
        </button>
      ))}
    </div>
  );
};

export default PricingOptions;
