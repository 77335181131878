import { FC } from 'react';
import styles from './FormNavigation.module.scss';
import CTAButton, { BUTTON_STYLE, getSubmitIcon } from 'component/atoms/Buttons/CTAButton';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { TEXT_COLOR } from 'component/atoms/typographie/Text';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { BREAKPOINTS } from 'core/const/breakpoints';
import useWindowSize from 'core/hook/windowsize.hook';

const FormNavigation: FC<{
  formId?: string;
  disabled: boolean;
  hideSubmit?: boolean;
  buttonSubmitMode: SUBMIT_BUTTON_MODE;
  submitButtonText?: string;
  secondaryButtonAction?: any;
  tertiaryButtonAction?: any;
  secondaryButtonIcon?: any;
  showSecondaryButtonMobile?: boolean;
  secondaryButtonText?: string;
  secondaryButtonColor?: TEXT_COLOR;
  primaryAction?: any;
  showThirdButton?: boolean;
}> = ({
  formId,
  disabled,
  buttonSubmitMode,
  submitButtonText,
  secondaryButtonAction,
  tertiaryButtonAction,
  secondaryButtonText,
  secondaryButtonColor,
  showSecondaryButtonMobile = true,
  primaryAction,
  hideSubmit = false,
  showThirdButton = false,
}) => {
  const { t } = useTranslation('common');
  const { isSmallerMd } = useWindowSize();
  const showSecondaryButton = isSmallerMd
    ? showSecondaryButtonMobile && typeof secondaryButtonAction === 'function'
    : typeof secondaryButtonAction === 'function';

  return (
    <div className={styles.wrapper}>
      <Grid
        container
        alignSelf={'center'}
        marginX={'auto'}
        maxWidth={{ lg: BREAKPOINTS.xl }}
        paddingX={{ xs: 0, sm: 2 }}
      >
        <Grid item xs={12}>
          <div className={styles.buttonWrapper}>
            {hideSubmit ? (
              <div />
            ) : (
              <div style={{ display: 'flex', gap: '1rem' }}>
                {showThirdButton && (
                  <CTAButton
                    type={'button'}
                    expandMobile
                    buttonStyle={BUTTON_STYLE.SECONDARY}
                    onClick={tertiaryButtonAction}
                    color={secondaryButtonColor ?? TEXT_COLOR.TEXT_COLOR_ACCENT}
                    buttonText={t('saveAndFinish')}
                    rounded={false}
                  />
                )}

                <CTAButton
                  type={primaryAction ? 'button' : 'submit'}
                  form={formId}
                  expandMobile
                  tabIndex={4}
                  rounded={false}
                  disabled={disabled}
                  onClick={primaryAction ?? undefined}
                  icon={getSubmitIcon(buttonSubmitMode)}
                  spinIcon={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING}
                  buttonText={submitButtonText ?? t('saveAndContinue')}
                />
              </div>
            )}
            {showSecondaryButton ? (
              <CTAButton
                type={'button'}
                expandMobile
                buttonStyle={BUTTON_STYLE.SECONDARY}
                onClick={secondaryButtonAction}
                color={secondaryButtonColor ?? TEXT_COLOR.TEXT_COLOR_ACCENT}
                buttonText={secondaryButtonText ?? t('skip')}
                rounded={false}
              />
            ) : null}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default FormNavigation;
