import Text, { TEXT_COLOR, TEXT_TYPE } from 'component/atoms/typographie/Text';
import Badge, { BADGE_COLORS } from 'component/atoms/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faLocationDot } from '@fortawesome/pro-light-svg-icons';
import styles from './JobItem.module.scss';
import { FC } from 'react';

const JobItem: FC<{ job: string; badgeText: string; badgeColor: BADGE_COLORS }> = ({ job, badgeColor, badgeText }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.top}>
        <Text type={TEXT_TYPE.TEXT_TYPE_LANDING}>{job} (m / w / d)</Text>
        <div>
          <Badge color={badgeColor} text={`• ${badgeText}`} />
        </div>
      </div>
      <div className={styles.bottom}>
        <span className={styles.desk}>
          <FontAwesomeIcon icon={faLocationDot} />
          <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT}>Remote / Kölner Office</Text>
        </span>
        <span className={styles.desk}>
          <FontAwesomeIcon icon={faClock} />
          <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT}>Vollzeit / Teilzeit</Text>
        </span>
      </div>
    </div>
  );
};

export default JobItem;
