import Joi from 'joi';
import {
  APARTMENT_TYPE,
  APARTMENT_TYPE_OF_MARKETING,
  APARTMENT_TYPE_OF_USAGE,
  DATE_SCHEMA,
  HOUSE_TYPE,
  IMMO_TYPE,
} from '@wohnsinn/ws-ts-lib';

export const APARTMENT_MAIN_INFORMATION_FORM_SCHEMA = Joi.object({
  mainInformation: {
    earliestMoveIn: Joi.string().optional().allow(null, ''),
    hasMinPeriod: Joi.boolean().optional().allow(null),
    minPeriod: Joi.when('hasMinPeriod', {
      is: true,
      then: Joi.any(),
      otherwise: [Joi.allow(null), Joi.allow('')],
    }),
    hasFixedTerm: Joi.boolean().optional().allow(null),
    fixedTerm: Joi.when('hasFixedTerm', {
      is: true,
      then: DATE_SCHEMA,
      otherwise: [Joi.allow(null), Joi.allow('')],
    }),
    typeOfMarketing: Joi.string().valid(...Object.keys(APARTMENT_TYPE_OF_MARKETING)),
    typeOfUsage: Joi.string().valid(...Object.keys(APARTMENT_TYPE_OF_USAGE)),
    typeOfImmo: Joi.string().valid(...Object.keys(IMMO_TYPE)),
    address: {
      geoHash: Joi.any(),
      coordinates: Joi.any(),
      apartmentType: Joi.string()
        .valid(...Object.keys(APARTMENT_TYPE), ...Object.keys(HOUSE_TYPE))
        .optional()
        .allow(null),
      hasPublishedAddress: Joi.boolean().required(),
      city: Joi.string().required(),
      postalCode: Joi.string().length(5).required(),
      street: Joi.string().required(),
      houseNumber: Joi.string().required(),
      locationInBuilding: Joi.string().optional().allow(null, ''),
      floor: Joi.string().optional().allow(null).allow(null, ''),
      buildingLevel: Joi.string().optional().allow(null).allow(null, ''),
    },
  },
  areas: {
    numberOfRooms: Joi.number().min(0.5).required(),
    livingArea: Joi.number().required(),
    totalArea: Joi.any(),
  },
  isExclusive: Joi.boolean().required(),
});
