import CTAButton, { BUTTON_STYLE } from '../../atoms/Buttons/CTAButton';
import { TEXT_COLOR } from '../../atoms/typographie/Text';
import { REGISTRATION_STATES, SIGN_IN_METHOD, USER_TYPE } from '@wohnsinn/ws-ts-lib';
import styles from './AuthProviderLoginButtons.module.scss';
import { Dispatch, FC, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { getAdditionalUserInfo, UserCredential } from 'firebase/auth';
import { wohnsinnServices } from 'App';
import { useNavigate } from 'react-router-dom';
import SnackBarContext from 'core/context/snackbar.context';
import ModalContext from 'core/context/modal.context';
import LOCAL_STORAGE_KEYS from 'core/enum/local-storage.enum';

const AuthProviderLoginButtons: FC<{
  disabled?: boolean;
  createLandlordAccount?: boolean;
  setButtonSubmitMode: Dispatch<SetStateAction<SUBMIT_BUTTON_MODE>>;
  isRegistration?: boolean;
  buttonText?: string;
}> = ({ disabled, setButtonSubmitMode, createLandlordAccount, isRegistration = false, buttonText }) => {
  const { t } = useTranslation('common');
  const { firebaseAuthService, userService, landlordService, tenantService } = wohnsinnServices;
  const navigate = useNavigate();
  const { handleSnackBar } = useContext(SnackBarContext);
  const { closeModal } = useContext(ModalContext);

  const handleUserRegistrationWithAuthProvider = async (signInMethod: SIGN_IN_METHOD): Promise<void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    try {
      const credentials: UserCredential = await firebaseAuthService.authProviderLogin(signInMethod);
      const moreInfo = getAdditionalUserInfo(credentials);

      // Check if user registered before with auth provider
      if (moreInfo.isNewUser) {
        await handleNewUser(credentials);
      } else {
        await handleExistingUser();
      }
    } catch (e) {
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
      console.error('Error on handleUserRegistrationWithAuthProvider', e);
    }
  };

  const handleNewUser = async (credentials: UserCredential) => {
    const userType = createLandlordAccount ? USER_TYPE.LANDLORD : USER_TYPE.TENANT;
    const registrationState = createLandlordAccount
      ? REGISTRATION_STATES.SELECT_LANDLORD_TYPE
      : REGISTRATION_STATES.FINISH_LOADING_SCREEN;

    await userService.createDefaultUserProfile(credentials.user.uid, userType, false, registrationState);
    if (createLandlordAccount) {
      await landlordService.createLandlordProfile(credentials.user);
    }

    if (!createLandlordAccount) {
      console.log('createTenantProfile');
      await tenantService.createTenantProfile(credentials.user);
      console.log('household');

      const household = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.TENANT_HOUSEHOLD));
      console.log('householdData', JSON.stringify(household));

      if (household) {
        await tenantService.updateTenantHousehold(credentials.user.uid, household);
        localStorage.removeItem(LOCAL_STORAGE_KEYS.TENANT_HOUSEHOLD);
      }
    }

    closeModal();
  };

  const handleExistingUser = async () => {
    if (isRegistration) {
      setTimeout(() => {
        handleSnackBar('Du hast dich bereits mit Google registriert. Du wurdest eingeloggt', 'info');
      }, 600);
    }
    closeModal();
    navigate('/');
  };

  return (
    <div className={styles.buttonWrapper}>
      <CTAButton
        disabled={disabled}
        type={'button'}
        expand={'block'}
        buttonText={buttonText ?? t('signInWithGoogle.label')}
        buttonStyle={BUTTON_STYLE.SECONDARY}
        color={TEXT_COLOR.TEXT_COLOR_DARK}
        rounded={false}
        img={t('signInWithGoogle.icon')}
        onClick={() => handleUserRegistrationWithAuthProvider(SIGN_IN_METHOD.GOOGLE)}
      />
    </div>
  );
};

export default AuthProviderLoginButtons;
