import { FC } from 'react';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/pro-light-svg-icons';
import styles from './NotificationBell.module.scss';
import NotificationBadge, { NOTIFICATION_BADGE_SIZE } from '../NotificationBadge';

const NotificationBell: FC<{ onClick: () => any; count?: number }> = ({ onClick, count }) => {
  return (
    <div className={styles.wrapper}>
      {count ? (
        <div className={styles.badge}>
          <NotificationBadge size={NOTIFICATION_BADGE_SIZE.SMALL} count={count} />
        </div>
      ) : null}
      <IconButton onClick={onClick}>
        <FontAwesomeIcon icon={faBell} size={'sm'} />
      </IconButton>
    </div>
  );
};

export default NotificationBell;
