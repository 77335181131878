import { Drawer } from '@mui/material';
import { FC, useState } from 'react';
import { IApplication } from '@wohnsinn/ws-ts-lib';
import styles from './ApplicantModal.module.scss';
import Chat from '../Chat';
import { useTranslation } from 'react-i18next';
import Headline, { HEADLINE_COLOR, HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import CloseButton from 'component/atoms/Buttons/CloseButton';
import ApplicantInformationModal from 'component/organisms/ApplicantInformationModal';
import CTAButton from 'component/atoms/Buttons/CTAButton';
import { faMessage, faUser } from '@fortawesome/pro-solid-svg-icons';
import ContextMenu from 'component/atoms/ContextMenu';
import { useUpdateApplicationMutation } from 'core/hook/update-application.hook';
import { createApplicationContextMenu } from 'core/helper/create-context-menu';

const ApplicationModal: FC<{
  isOpen: boolean;
  hideChat?: boolean;
  onClose: () => void;
  application: IApplication;
}> = ({ isOpen, onClose, application, hideChat = false }) => {
  const { t } = useTranslation('common');
  const [showChat, setShowChat] = useState<boolean>(false);
  const { mutate } = useUpdateApplicationMutation();
  const CONTEXT_MENU = createApplicationContextMenu(application, mutate, t);

  return (
    <Drawer anchor={'right'} open={isOpen} onClose={onClose}>
      <div className={`${styles.wrapper} ${hideChat ? styles.hideChat : ''}`}>
        <div className={styles.header}>
          <Headline>{t('component.organisms.ApplicantInformation.selfDisclosure')}</Headline>
          {hideChat ? null : (
            <div className={styles.hideDesktop}>
              <CTAButton
                hideText
                icon={showChat ? faUser : faMessage}
                buttonText={'setShowChat'}
                onClick={() => setShowChat(!showChat)}
              />
            </div>
          )}
          <CloseButton onClose={onClose} color={'dark'} />
        </div>

        <div className={styles.content}>
          <div className={`${styles.left} ${showChat ? styles.showChat : ''}`}>
            <ApplicantInformationModal showHeader={false} application={application} onClose={onClose} />
          </div>

          <div className={`${styles.right} ${showChat ? styles.showChat : ''} ${hideChat ? styles.hideChat : ''}`}>
            <Chat
              header={
                <div className={styles.chatHeader}>
                  <Headline size={HEADLINE_SIZE.H3} color={HEADLINE_COLOR.HEADLINE_COLOR_DARK}>
                    {t('messages')}
                  </Headline>
                  <ContextMenu menu={CONTEXT_MENU} />
                </div>
              }
              customStyling={styles.chat}
              key={application?.apartmentId}
              application={application}
            />
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default ApplicationModal;
