import { Snackbar, Alert } from '@mui/material';
import { useContext } from 'react';
import SnackBarContext from '../../../core/context/snackbar.context';
import { AlertColor } from '@mui/material/Alert/Alert';
import { useTranslation } from 'react-i18next';

export interface ISnackBarProps {
  message: string;
  type?: AlertColor;
}

const SnackBarLoader = () => {
  const { t } = useTranslation('common');
  const { isSnackBarOpen, setIsSnackBarOpen, snackBarProps } = useContext(SnackBarContext);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={3000}
      open={isSnackBarOpen}
      onClose={() => setIsSnackBarOpen(false)}
      message={t(snackBarProps.message)}
    >
      {snackBarProps?.type && (
        <Alert onClose={() => setIsSnackBarOpen(false)} severity={snackBarProps.type} variant={'filled'}>
          {t(snackBarProps.message)}
        </Alert>
      )}
    </Snackbar>
  );
};

export default SnackBarLoader;
