import { FC } from 'react';
import styles from './MetricsBanner.module.scss';
import Text, { TEXT_COLOR, TEXT_TYPE, TEXT_VARIANT } from '../../atoms/typographie/Text';
import Headline, { HEADLINE_COLOR, HEADLINE_SIZE } from '../../atoms/typographie/Headline';
import Spacer, { SPACER_SIZE } from '../../atoms/Spacer';
import HeadlineTextBanner from '../HeadlineTextBanner';
import { useTranslation } from 'react-i18next';

export interface Metric {
  title: string;
  value: string;
  text?: string;
}
export interface IMetricBannerProps {
  headline: string;
  tag?: string;
  image: string;
  imageAlt: string;
  metrics: Metric[];
  headlineTextBanner?: boolean;
}
const MetricsBanner: FC<IMetricBannerProps> = ({ headline, tag, image, metrics, imageAlt, headlineTextBanner }) => {
  const { t: i } = useTranslation('images');
  return (
    <div>
      {headlineTextBanner ? (
        <HeadlineTextBanner
          tag={'Wer wir sind'}
          text={
            'Wir legen großen Wert auf die Bedürfnisse unserer Kunden und entwicklen Produkte, die genau auf die Anforderungen zugeschnitten sind.'
          }
          headline={'Über Wohnsinn'}
        />
      ) : (
        <>
          <Text
            color={TEXT_COLOR.TEXT_COLOR_PRIMARY}
            bold
            type={TEXT_TYPE.TEXT_TYPE_BODY}
            tag={'p'}
            mobileAlign={'center'}
          >
            {tag}
          </Text>
          <Spacer size={SPACER_SIZE.XS} />
          <Headline mobileAlign={'center'} size={HEADLINE_SIZE.H1}>
            {headline}
          </Headline>
        </>
      )}
      <Spacer size={SPACER_SIZE.LG} />
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.metricsWrapper}>
            {metrics.map((metric, index) => (
              <div key={index} className={styles.metric}>
                <Headline color={HEADLINE_COLOR.HEADLINE_COLOR_PRIMARY} size={HEADLINE_SIZE.DISPLAY} tag={'span'}>
                  {metric.value}
                </Headline>
                <Spacer size={SPACER_SIZE.MD} />
                <Text bold type={TEXT_TYPE.TEXT_TYPE_BODY} variant={TEXT_VARIANT.TEXT_VARIANT_LARGE} tag={'span'}>
                  {metric.title}
                </Text>
                <Spacer size={SPACER_SIZE.XS} />
                {metric.text ? (
                  <Text type={TEXT_TYPE.TEXT_TYPE_BODY} tag={'span'}>
                    {metric.text}
                  </Text>
                ) : null}
              </div>
            ))}
          </div>
        </div>
        <img className={styles.image} src={i(image)} alt={i(imageAlt)} />
      </div>
    </div>
  );
};

export default MetricsBanner;
