import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ListWithIcons, { IListWithIconItem } from 'component/molecules/ListWithIcons';
import { faAddressCard, faFileCertificate, faUserDoctor } from '@fortawesome/pro-light-svg-icons';
import { ITenantProfile } from '@wohnsinn/ws-ts-lib';

const TenantAboutMeList: FC<{ tenantProfile: ITenantProfile }> = ({ tenantProfile }) => {
  const { t } = useTranslation('common');
  const aboutMe: IListWithIconItem[] = [];

  if (!tenantProfile?.personalInformation) {
    return null;
  }

  if (tenantProfile?.personalInformation?.customJobDescription) {
    aboutMe.push({
      icon: faUserDoctor,
      text: `${t('job')}: ${tenantProfile.personalInformation.customJobDescription}`,
    });
  }

  if (tenantProfile?.personalInformation?.jobStatus) {
    aboutMe.push({
      icon: faFileCertificate,
      text: `${t('jobType')}: ${t(`jobStatus.${tenantProfile.personalInformation.jobStatus}`)}`,
    });
  }

  if (tenantProfile?.personalInformation?.employerName) {
    aboutMe.push({
      icon: faAddressCard,
      text: `${t('employer')}: ${tenantProfile.personalInformation.employerName}`,
    });
  }

  return <ListWithIcons list={aboutMe} />;
};

export default TenantAboutMeList;
