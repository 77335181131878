import FormSectionTitle from 'component/atoms/FormSectionTitle';
import InputText from 'component/atoms/formElement/InputText';
import ProfileAvatar from 'component/molecules/ProfileAvatar';
import { FormContext } from 'core/context/form.context';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { useForm } from 'react-hook-form';
import {
  FIRESTORE_COLLECTION_PATH,
  ILandlordPersonalInformation,
  LANDLORD_PERSONAL_INFORMATION_SCHEMA,
} from '@wohnsinn/ws-ts-lib';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useTranslation } from 'react-i18next';
import { FC, useContext, useEffect, useState } from 'react';
import UserContext from 'core/context/user.context';
import ModalContext from 'core/context/modal.context';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import CTAButton, { BUTTON_STYLE } from 'component/atoms/Buttons/CTAButton';
import FormFooter from 'component/atoms/FormFooter';
import FormHeader from 'component/atoms/FormHeader';
import SplitLine from 'component/atoms/SplitLine';
import { wohnsinnServices } from 'App';
import SnackBarContext from 'core/context/snackbar.context';
import { useNavigate } from 'react-router-dom';
import { ILandlordAccountFormProps } from 'view/landlord/AccountView';
import FB_FUNCTION_URLS from 'core/const/fb-function-names';

const LandlordProfileForm: FC<ILandlordAccountFormProps> = ({ scrollToTop }) => {
  const { user } = useContext(UserContext);
  const { landlordService, firebaseStorageService, firebaseFunctionsService } = wohnsinnServices;
  const { landlordProfile } = useContext(UserContext);
  const PROFILE_IMAGE_PATH = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.root.replace('{uid}', user.uid)}/${
    user.uid
  }/profileImage.png`;
  const { openModal, closeModal } = useContext(ModalContext);
  const { handleSnackBar } = useContext(SnackBarContext);
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { t } = useTranslation('common');
  const { t: m } = useTranslation('common', { keyPrefix: 'component.molecules.modals.SettingsModal' });
  const navigate = useNavigate();

  useEffect(() => {
    if (landlordProfile?.personalInformation) {
      reset(landlordProfile.personalInformation);
    } else {
      reset();
    }
  }, [landlordProfile]);

  const { control, watch, handleSubmit, reset } = useForm<ILandlordPersonalInformation>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: joiResolver(Joi.object(LANDLORD_PERSONAL_INFORMATION_SCHEMA)),
  });

  const onSuccess = async (): Promise<void> => {
    scrollToTop();
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
    handleSnackBar('toast.profileUpdated', 'success');
  };

  const updateLandlordProfile = async (): Promise<boolean | void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    await landlordService.updateLandlordPersonalInformation(
      landlordProfile.uid,
      watch() as ILandlordPersonalInformation
    );
    await onSuccess();
  };

  const handleError = (err: any) => {
    console.error('Error on LandlordProfileForm: ', err);
  };

  const handleProfileImageUpload = async (photoUrl: string) => {
    try {
      await landlordService.updateLandlordProfilePicture(user.uid, photoUrl);
    } catch (e) {
      console.error('Error on handleProfileImageUpload: ', e);
    }
  };

  const deleteProfileImage = async () => {
    try {
      await landlordService.updateLandlordProfilePicture(user.uid, null);
      await firebaseStorageService.deleteFiles([PROFILE_IMAGE_PATH]);
      handleSnackBar('toast.profileImage.deleted', 'success');
    } catch (e) {
      console.error('Error on deleteProfileImage', e);
    }
  };

  const deleteUser = async (userID: string) => {
    try {
      navigate('/');
      await firebaseFunctionsService.callFbFunction(FB_FUNCTION_URLS.user.delete, {
        params: { userID },
      });
      handleSnackBar(t('account.deleted'));
      closeModal();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <FormContext.Provider value={{ control }}>
      <form onSubmit={handleSubmit(updateLandlordProfile, (err) => handleError(err))}>
        <FormHeader
          subtitle={'Verwalte dein persönliches Profil und hinterlege ein Profilbild.'}
          title={'Kundendaten'}
        />
        <FormSectionTitle title={t('Profilbild')} />
        <ProfileAvatar
          storagePath={PROFILE_IMAGE_PATH}
          onUpload={handleProfileImageUpload}
          onDelete={deleteProfileImage}
          photoUrl={landlordProfile?.photoUrl}
        />

        <SplitLine />

        <FormSectionTitle title={t('generalInformation')} />
        <InputText label={t('firstName.label')} name={'firstName'} required />
        <InputText label={t('lastName.label')} name={'lastName'} required />
        <InputText type={'tel'} label={t('phoneNumber.label')} name={'phoneNumber'} required />
        {!landlordProfile?.isCommercialLandlord && (
          <>
            <SplitLine />
            <InputText label={t('address.street.label')} name={'address.street'} required />
            <InputText label={t('address.houseNumber.label')} name={'address.houseNumber'} required />
            <InputText label={t('address.postalCode.label')} name={'address.postalCode'} required />
            <InputText label={t('address.city.label')} name={'address.city'} required />
          </>
        )}
        <FormFooter buttonSubmitMode={buttonSubmitMode} />
      </form>
      <SplitLine />

      <FormSectionTitle title={t('Kontoverwaltung')} />
      <CTAButton
        buttonText={t('view.AccountView.deleteAccount')}
        onClick={() =>
          openModal({
            id: MODAL_IDENTIFIER.DELETE_MODAL,
            data: {
              deleteHandler: () => deleteUser(user.uid),
              title: m('delete.title'),
              text: m('delete.text'),
            },
          })
        }
        rounded={false}
        type={'button'}
        buttonStyle={BUTTON_STYLE.DANGER}
      />
    </FormContext.Provider>
  );
};

export default LandlordProfileForm;
