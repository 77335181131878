import styles from './ApplicantInformationModal.module.scss';
import { FC } from 'react';

import { IApplication } from '@wohnsinn/ws-ts-lib';
import ApplicantSelfDisclosure from 'component/organisms/ApplicantSelfDisclosure';

const ApplicantInformationModal: FC<{
  onClose: () => void;
  showHeader?: boolean;
  application: IApplication;
}> = ({ onClose, showHeader, application }) => {
  return (
    <div className={styles.wrapper}>
      <ApplicantSelfDisclosure onClose={onClose} showHeader={showHeader} application={application} />
    </div>
  );
};

export default ApplicantInformationModal;
