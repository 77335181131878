export const printAge = (dateOfBirth: Date) => {
  if (!dateOfBirth) {
    return null; // Rückgabe null, wenn das Datum ungültig ist (null oder undefined)
  }

  let geburtsdatum: Date;

  // Falls es ein Timestamp ist (aus Firestore)
  if (typeof dateOfBirth === 'object' && 'seconds' in dateOfBirth) {
    // @ts-ignore
    geburtsdatum = new Date(dateOfBirth.seconds * 1000); // Firebase Timestamp in Date umwandeln
  } else if (typeof dateOfBirth === 'string') {
    geburtsdatum = new Date(dateOfBirth); // String in Date umwandeln
  } else if (dateOfBirth instanceof Date) {
    geburtsdatum = dateOfBirth; // Bereits ein Date-Objekt
  } else {
    return null; // Wenn es nicht umgewandelt werden kann, null zurückgeben
  }

  const heute = new Date();
  let alter = heute.getFullYear() - geburtsdatum.getFullYear();
  const monat = heute.getMonth() - geburtsdatum.getMonth();

  // Wenn der aktuelle Monat vor dem Geburtsmonat liegt oder es der Geburtsmonat ist, aber der aktuelle Tag vor dem Geburtstag, dann ein Jahr abziehen
  if (monat < 0 || (monat === 0 && heute.getDate() < geburtsdatum.getDate())) {
    alter--;
  }

  return alter;
};
