import { CardMedia } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Headline, { HEADLINE_SIZE } from '../../typographie/Headline';
import CTAButton from '../../Buttons/CTAButton';
import { FC } from 'react';
import styles from './HeroCard.module.scss';
import { Link } from 'react-router-dom';

const HeroCard: FC<{ title: string; link: string; image: string; buttonText: string }> = ({
  title,
  link,
  image,
  buttonText,
}) => {
  const { t: i } = useTranslation('images');
  return (
    <Link to={link}>
      <div className={styles.wrapper}>
        <CardMedia className={styles.media} image={i(image)} />
        <div className={styles.content}>
          <Headline size={HEADLINE_SIZE.H2} align={'center'}>
            {title}
          </Headline>
          <CTAButton rounded={false} expand={'block'} buttonText={buttonText} />
        </div>
      </div>
    </Link>
  );
};

export default HeroCard;
