import { FC } from 'react';
import styles from './DownloadItemsList.module.scss';
import { faEye } from '@fortawesome/pro-solid-svg-icons';
import { IUpload } from '@wohnsinn/ws-ts-lib';
import { wohnsinnServices } from '../../../App';
import CTAButton, { BUTTON_STYLE } from 'component/atoms/Buttons/CTAButton';

const DownloadItemsList: FC<{ list: IUpload[]; label: string }> = ({ list, label }) => {
  const { firebaseStorageService } = wohnsinnServices;

  const downloadDocument = (upload: IUpload) => {
    const windowReference = window.open();
    firebaseStorageService.getDownloadUrl(`${upload.uploadPath}`).then((href) => {
      windowReference.location = href;
    });
  };

  return (
    <div className={styles.wrapper}>
      <ul className={styles.list}>
        {list.map((upload, i) => (
          <li key={`incomeProof-${i}`}>
            <CTAButton
              rounded={false}
              buttonStyle={BUTTON_STYLE.SECONDARY}
              buttonText={`${label} Nr. ${i + 1}`}
              onClick={() => downloadDocument(upload)}
              icon={faEye}
              expand={'block'}
              iconPosition={'end'}
              spaceBetween
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DownloadItemsList;
