import ModalContext from 'core/context/modal.context';
import SnackBarContext from 'core/context/snackbar.context';
import UserContext from 'core/context/user.context';
import styles from './ProfileAvatar.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormEvent, useContext, useRef } from 'react';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import { faPlus, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { faUser } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import { wohnsinnServices } from 'App';
import { ICropImageModalProps } from 'component/modals/CropImageModal';

export interface ITenantProfileInformationProps {
  onUpload: (downloadUrl: string) => void;
  onDelete?: () => void;
  photoUrl?: string;
  onClose?: () => void;
  storagePath: string;
}

const ProfileAvatar = ({ onUpload, photoUrl, storagePath, onDelete, onClose }: ITenantProfileInformationProps) => {
  const { t } = useTranslation('common');
  const inputRef = useRef<HTMLInputElement>(null);
  const { handleSnackBar } = useContext(SnackBarContext);
  const { openModal } = useContext(ModalContext);
  const { user } = useContext(UserContext);
  const { firebaseStorageService } = wohnsinnServices;

  const handleProfileImageUpload = async (blobPng: Blob) => {
    try {
      const downloadUrl = await firebaseStorageService.uploadBlob(blobPng, storagePath, user.uid, 'image/png');
      onUpload(downloadUrl);

      handleSnackBar('toast.profileImage.uploaded', 'info');
    } catch (e) {
      console.error(e);
    }
  };

  const checkImageDimensions = (file: File): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        if (img.width >= 350 && img.height >= 350) {
          resolve(true);
        } else {
          handleSnackBar(t('toast.profileImage.dimensionsError'), 'error');
          resolve(false);
        }
      };
      img.onerror = () => {
        reject(new Error('Image loading error'));
      };
      img.src = URL.createObjectURL(file);
    });
  };

  const onImageChange = async (fileList: File[]) => {
    const isValid = await checkImageDimensions(fileList[0]);
    if (isValid) {
      openModal<ICropImageModalProps>({
        id: MODAL_IDENTIFIER.CROP_IMAGE,
        data: { image: fileList[0], onCropFinish: handleProfileImageUpload, onClose },
      });
    }
    inputRef.current.value = '';
  };

  const onChangeCapture = (e: FormEvent<HTMLInputElement>) => {
    const fileList: FileList | null = (e?.target as HTMLInputElement)?.files;
    if (fileList?.length) {
      return onImageChange(Array.from(fileList));
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <button type={'button'} onClick={() => inputRef?.current?.click()} className={styles.tenantInformationWrapper}>
          <div className={styles.imageWrapper}>
            <input
              hidden
              type="file"
              accept={'image/jpeg, image/png, image/heic'}
              ref={inputRef}
              onChangeCapture={onChangeCapture}
            />

            {photoUrl ? (
              <img alt={''} src={photoUrl} />
            ) : (
              <div className={styles.silhouette}>
                <FontAwesomeIcon icon={faUser} size={'2xl'} />
              </div>
            )}

            <div className={styles.camera}>
              <FontAwesomeIcon icon={faPlus} size={'xl'} />
            </div>
          </div>
        </button>

        {photoUrl ? (
          <div className={styles.delete}>
            <button type={'button'} onClick={onDelete}>
              <span className="sr-only">{t('deleteProfileImage')}</span>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ProfileAvatar;
