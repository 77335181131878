import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import ModalContext from 'core/context/modal.context';
import { Grid } from '@mui/material';
import { FormContext } from 'core/context/form.context';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import ModalWrapper, { IModalWrapperButtonProps } from 'component/modals/ModalWrapper';
import RadioGroupButtons, { IRadioGroupOption } from 'component/atoms/RadioGroupButtons';
import Joi from 'joi';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';

const SELECT_USER_TYPE_FORM_ID = 'SELECT_USER_TYPE_FORM_ID';

export const USER_TYPE_FORM_SCHEMA = Joi.object({
  createLandlordAccount: Joi.boolean().required(),
});

const SelectUserTypeModal = () => {
  const { modalData, openModal } = useContext(ModalContext);
  const { t } = useTranslation('common');
  const isWogeEnvironment = process.env.REACT_APP_ENVIRONMENT === 'woge';

  const {
    control,
    handleSubmit,
    getValues,
    formState: { isValid },
  } = useForm<{
    createLandlordAccount: boolean;
  }>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(USER_TYPE_FORM_SCHEMA),
    defaultValues: {
      createLandlordAccount: isWogeEnvironment || modalData?.isProbablyTenant ? false : undefined,
    },
  });

  const MODAL_BUTTONS: IModalWrapperButtonProps = {
    primary: {
      form: SELECT_USER_TYPE_FORM_ID,
      buttonText: t('next'),
      disabled: !isValid,
    },
  };

  const options: IRadioGroupOption[] = [
    { key: 'Ich suche eine Wohnung', value: false },
    { key: 'Ich bin Vermieter', value: true },
  ];

  return (
    <ModalWrapper title={'Wohnung finden oder vermieten'} buttons={MODAL_BUTTONS}>
      <Grid container>
        <Grid item xs={12}>
          <FormContext.Provider value={{ control }}>
            <form
              id={SELECT_USER_TYPE_FORM_ID}
              onSubmit={handleSubmit(() => {
                openModal({
                  id: MODAL_IDENTIFIER.SIGN_UP_USER,
                  data: {
                    isProbablyTenant: !getValues().createLandlordAccount,
                    referrer: modalData?.referrer,
                  },
                });
              }, console.error)}
            >
              <RadioGroupButtons name={'createLandlordAccount'} options={options} />
            </form>
          </FormContext.Provider>
        </Grid>
      </Grid>
    </ModalWrapper>
  );
};

export default SelectUserTypeModal;
