import { useContext } from 'react';
import ModalWrapper, { IModalWrapperButtonProps } from '../ModalWrapper';
import Toggle from 'component/atoms/formElement/Toggle';
import UserContext from 'core/context/user.context';
import { useTranslation } from 'react-i18next';
import { FieldErrorsImpl, useForm } from 'react-hook-form';
import { INotificationSettings } from '@wohnsinn/ws-ts-lib';
import { FormContext } from 'core/context/form.context';
import { wohnsinnServices } from 'App';
import SnackBarContext from 'core/context/snackbar.context';
import ModalContext from 'core/context/modal.context';

const NotificationsModal = () => {
  const { t } = useTranslation('common', { keyPrefix: 'component.molecules.modals.NotificationsModal' });
  const { t: c } = useTranslation('common');
  const { userService } = wohnsinnServices;
  const { user } = useContext(UserContext);
  const { handleSnackBar } = useContext(SnackBarContext);
  const { closeModal } = useContext(ModalContext);

  const { handleSubmit, control } = useForm<{ isMailEnabled: boolean; isWhatsappEnabled: boolean }>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      isMailEnabled: user.notificationSettings.isMailEnabled,
      isWhatsappEnabled: true,
    },
  });

  const onSuccess = async (formValue: Partial<INotificationSettings>): Promise<void> => {
    console.log(formValue);
    await userService.updateUserNotificationSettings(user.uid, {
      isMailEnabled: formValue.isMailEnabled,
      isWhatsappEnabled: true,
    });
    handleSnackBar('toast.notificationSettingsUpdated', 'success');
    closeModal();
  };

  const onError = (err: Partial<FieldErrorsImpl<INotificationSettings>>): void => {
    console.error(err);
  };

  const MODAL_BUTTONS: IModalWrapperButtonProps = {
    primary: {
      buttonText: c('save'),
      type: 'submit',
      form: 'notificationSettingsForm',
    },
  };

  return (
    <ModalWrapper title={'Benachrichtigungseinstellungen'} buttons={MODAL_BUTTONS}>
      <FormContext.Provider value={{ control }}>
        <form id={'notificationSettingsForm'} onSubmit={handleSubmit(onSuccess, onError)} noValidate>
          <div className={'equipmentToggleWrapper'}>
            <Toggle formField={false} label={t('emailNotifications')} name={'isMailEnabled'} />
          </div>
        </form>
      </FormContext.Provider>
    </ModalWrapper>
  );
};

export default NotificationsModal;
