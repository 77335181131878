import { FC, PropsWithChildren, useEffect, useState } from 'react';
import TenantFilterParamsContext from '../context/tenant-filter-params.context';
import { useLocation, useNavigate } from 'react-router-dom';
import { ITenantFilterParams, ITenantURLParams } from '@wohnsinn/ws-ts-lib';
import { convertUrlParamsToTFP } from '../helper/convert-url-params-to-tfp';

export enum TENANT_FILTER {
  CITY = 'location',
  EQUIPMENT = 'equipmentList',
  PERSONS = 'numberOfPeopleMovingIn',
  PETS = 'arePetsAllowed',
  ROOMS = 'rooms',
  SHARED_USAGE = 'isSharedUsagePossible',
  SIZE = 'size',
  WARM_RENT = 'warmRent',
  TYPE_OF_MARKETING = 'typeOfMarketing',
  PURCHASE_PRICE = 'purchasePrice',
  CAN_BE_SUBSIDIZED = 'canBeSubsidized',
}

const TenantFilterParamsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tenantFilterParams, setTenantFilterParams] = useState<ITenantFilterParams>({
    ...convertUrlParamsToTFP(
      Object.fromEntries(new URLSearchParams(window.location.search)) as unknown as ITenantURLParams
    ),
  });

  const resetTenantFilterParams = () => {
    const url = new URL(window.location.origin + window.location.pathname);

    if (tenantFilterParams?.location) {
      url.searchParams.set('location', JSON.stringify(tenantFilterParams.location));
    }

    navigate(`${location.pathname}${url.search}`);
  };

  const deleteTenantFilterParam = (deleteParamKeys: string) => {
    const url = new URL(window.location.href);
    if (url.searchParams.has(deleteParamKeys)) {
      url.searchParams.delete(deleteParamKeys);
    }

    if (url.searchParams.has('searchId')) {
      url.searchParams.delete('searchId');
    }

    navigate(`${location.pathname}${url.search}`);
  };

  const updateTenantFilterParams = (newParams: any) => {
    console.log('new params', newParams);
    const url = new URL(window.location.href);
    // Create Url Param for each key
    Object.keys(newParams).forEach((key) => {
      console.log('update', key);
      if (newParams[key] != null) {
        if (key === 'location' || key === 'equipmentList') {
          url.searchParams.set(key, JSON.stringify(newParams[key]));
        } else {
          url.searchParams.set(key, newParams[key]);
        }
      }
    });

    if (url.searchParams.has('searchId')) {
      url.searchParams.delete('searchId');
    }

    navigate(`${location.pathname}${url.search}`);
  };

  useEffect(() => {
    setTenantFilterParams({
      ...convertUrlParamsToTFP(
        Object.fromEntries(new URLSearchParams(window.location.search)) as unknown as ITenantURLParams
      ),
    } as unknown as ITenantFilterParams);
  }, [location.search]);

  return (
    <TenantFilterParamsContext.Provider
      value={{
        deleteTenantFilterParam,
        resetTenantFilterParams,
        tenantFilterParams,
        updateTenantFilterParams,
      }}
    >
      {children}
    </TenantFilterParamsContext.Provider>
  );
};

export default TenantFilterParamsContextProvider;
