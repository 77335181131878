import styles from './Avatar.module.scss';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faHouse } from '@fortawesome/pro-light-svg-icons';
import NotificationBadge, { NOTIFICATION_BADGE_SIZE } from 'component/atoms/NotificationBadge';

export enum AVATAR_SIZE {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  auto = 'auto',
}

export interface IAvatarProps {
  size?: AVATAR_SIZE;
  avatarUrl?: string;
  badge?: number;
  bordered?: boolean;
  isTenant?: boolean;
  onClick?: () => void;
}

const Avatar: FC<IAvatarProps> = ({
  size = AVATAR_SIZE.md,
  avatarUrl,
  bordered = false,
  onClick,
  badge,
  isTenant = true,
}) => {
  const { t: c } = useTranslation('common');

  return (
    <div
      onClick={onClick ?? undefined}
      role="img"
      aria-label={c('profile.image')}
      className={`
      ${styles.avatar} 
      ${styles[size]}
      ${!!!avatarUrl ? styles.silhouette : ''} 
      ${bordered ? styles.border : ''} 
      ${onClick ? styles.onClick : ''}
      `}
      style={{ backgroundImage: avatarUrl ? `url('${avatarUrl}')` : 'none' }}
    >
      {badge ? (
        <div className={styles.badge}>
          <NotificationBadge size={NOTIFICATION_BADGE_SIZE.SMALL} count={badge} />
        </div>
      ) : null}
      {!!!avatarUrl ? <FontAwesomeIcon icon={isTenant ? faUser : faHouse} /> : null}
    </div>
  );
};

export default Avatar;
