import { FC } from 'react';
import Headline, { HEADLINE_SIZE } from '../../typographie/Headline';
import styles from './ContactCard.module.scss';
import { IImage } from 'core/const/images';
import { useTranslation } from 'react-i18next';
import Text from 'component/atoms/typographie/Text';

const ContactCard: FC<{ title: string; link: string; linkText: string; image: IImage }> = ({
  title,
  link,
  linkText,
  image,
}) => {
  const { t: i } = useTranslation('images');
  return (
    <a href={link} className={styles.link}>
      <div className={styles.wrapper}>
        <img className={styles.pictogram} src={i(image.src)} alt={i(image.alt)} />
        <div className={styles.content}>
          <Headline size={HEADLINE_SIZE.H3}>{title}</Headline>
          <Text className={styles.linkText} tag={'span'}>
            {linkText}
          </Text>
        </div>
      </div>
    </a>
  );
};

export default ContactCard;
