// services/useApartmentService.js
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getDocs } from 'firebase/firestore';
import { wohnsinnServices } from 'App';
import { IApartment } from '@wohnsinn/ws-ts-lib';
import { useContext } from 'react';
import UserContext from '../context/user.context';
import SnackBarContext from '../context/snackbar.context';
import { useTranslation } from 'react-i18next';
import FB_FUNCTION_URLS from 'core/const/fb-function-names';

export enum LIST_MODE {
  ALL = 'ALL',
  PUBLISHED = 'PUBLISHED',
  DEACTIVATED = 'DEACTIVATED',
}

export enum ACTION_TYPE {
  PUBLISH = 'PUBLISH',
  DEPUBLISH = 'DEPUBLISH',
  DELETE = 'DELETE',
}

export interface ISortedApartments {
  allApartmentList: IApartment[];
  allApartmentsCount: number;
  deactivatedApartmentList: IApartment[];
  publishedApartmentList: IApartment[];
}

export function useFetchApartments(onlyManuallyCreated = false) {
  const { apartmentService } = wohnsinnServices;
  const { user } = useContext(UserContext);

  return useQuery({
    queryKey: ['apartments', onlyManuallyCreated ? 'justExclusive' : 'all'],
    queryFn: async () => {
      const allApartmentsListRef = apartmentService.getLandlordApartmentListRef(
        {
          isAdmin: user.isAdmin,
          uid: user.uid,
        },
        onlyManuallyCreated
      );

      const apartmentListSnap = await getDocs(allApartmentsListRef);
      return apartmentListSnap.docs.map((doc) => doc.data());
    },
    select: (data): ISortedApartments => {
      const allApartmentList = data;
      const allApartmentsCount = data.length;
      const deactivatedApartmentList = data.filter((apartment) => !apartment.isPublished);
      const publishedApartmentList = data.filter((apartment) => apartment.isPublished);

      return {
        allApartmentList,
        allApartmentsCount,
        deactivatedApartmentList,
        publishedApartmentList,
      };
    },
  });
}

interface IMutationParams {
  selectedApartments: IApartment[];
  actionType: ACTION_TYPE;
  onSettled?: () => any;
}

export function useHandleApartmentActionMutation() {
  const queryClient = useQueryClient();
  const { apartmentService, firebaseFunctionsService } = wohnsinnServices;
  const { handleSnackBar } = useContext(SnackBarContext);
  const { t } = useTranslation('common');
  const { landlordProfile } = useContext(UserContext);

  return useMutation({
    mutationFn: async (params: IMutationParams) => {
      const { actionType, selectedApartments } = params;
      switch (actionType) {
        case ACTION_TYPE.DELETE:
          await apartmentService.deleteApartmentList(selectedApartments);
          await firebaseFunctionsService.callFbFunction(FB_FUNCTION_URLS.apartments.deletedNotification, {
            body: {
              apartments: selectedApartments,
              landlordProfile,
            },
          });
          break;
        case ACTION_TYPE.DEPUBLISH:
          await apartmentService.updateApartmentsPublishedStates(selectedApartments, false);

          await firebaseFunctionsService.callFbFunction(FB_FUNCTION_URLS.apartments.publishedChangeNotification, {
            body: {
              apartments: selectedApartments,
              landlordProfile,
              published: false,
            },
          });
          break;
        case ACTION_TYPE.PUBLISH:
        default:
          await apartmentService.updateApartmentsPublishedStates(selectedApartments, true);
          await firebaseFunctionsService.callFbFunction(FB_FUNCTION_URLS.apartments.publishedChangeNotification, {
            body: {
              apartments: selectedApartments,
              landlordProfile,
              published: true,
            },
          });
      }
    },
    onSettled: async (data, error, variables) => {
      handleSnackBar(t('toast.success.apartments.updated'), 'success');
      await queryClient.invalidateQueries({ queryKey: ['apartments'] });

      if (variables.onSettled) variables.onSettled();
    },
  });
}
