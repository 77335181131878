import { onSnapshot, WhereFilterOp } from 'firebase/firestore';
import { FC, useContext, useEffect, useState } from 'react';
import UserContext from 'core/context/user.context';
import { wohnsinnServices } from 'App';
import styles from './PremiumSubscriptionDrawer.module.scss';
import RoundIconButton from 'component/atoms/Buttons/RoundIconButton';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import PricingOptions from 'component/atoms/PricingOptions';
import CTAButton, { getSubmitIcon } from 'component/atoms/Buttons/CTAButton';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import PremiumSubscriptionDrawerBenefits from 'component/atoms/PremiumSubscriptionDrawer/PremiumSubscriptionDrawerBenefits';
import ResponsiveImage from 'component/atoms/ResponsiveImage';
import { IMAGES } from 'core/const/images';
import Spacer, { SPACER_SIZE } from 'component/atoms/Spacer';
import { useTranslation } from 'react-i18next';
import Text, { TEXT_COLOR } from 'component/atoms/typographie/Text';

export interface PriceOption {
  interval: string;
  interval_count: number;
  popular?: boolean;
  savings?: string;
  metadata: any;
  id: string;
  unit_amount: number;
}

export enum SUBSCRIPTION_DRAWER_TYPE {
  LANDING = 'LANDING',
  CHECKOUT = 'CHECKOUT',
}

const PremiumSubscriptionDrawer: FC<{ closeAction: () => void }> = ({ closeAction }) => {
  const { user } = useContext(UserContext);
  const { firestoreService } = wohnsinnServices;
  const [prices, setPrices] = useState<any[]>([]);
  const [selectedOption, setSelectedOption] = useState<PriceOption>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { mixpanelTrackingService } = wohnsinnServices;
  const { t } = useTranslation('common');

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        if (!process.env.REACT_APP_WOHNSINN_PREMIUM_ID) {
          throw new Error('REACT_APP_WOHNSINN_PREMIUM_ID is not defined');
        }

        const whereFields = [{ fieldPath: 'active', opStr: '==' as WhereFilterOp, value: true }];
        const colSnap = await firestoreService.getCollectionSnapWithWhere<PriceOption>(
          `products/${process.env.REACT_APP_WOHNSINN_PREMIUM_ID}/prices`,
          whereFields
        );

        let prices = colSnap.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        prices = prices.sort((a, b) => a?.metadata?.position - b?.metadata?.position);
        setPrices(prices);
        const defaultOption = prices.find((price) => price.metadata?.default === 'true');
        setSelectedOption(defaultOption);
      } catch (error) {
        console.error('Error fetching prices:', error);
      }
    };

    fetchPrices();
  }, []);

  const handleCheckOut = async (priceId: string) => {
    try {
      setIsLoading(true);
      if (!user?.uid) {
        throw new Error('User not logged in');
      }
      mixpanelTrackingService.trackPricing(priceId, user?.uid);

      const path = `users/${user.uid}/checkout_sessions`;
      const docRef = await firestoreService.addDbDoc(
        {
          price: priceId,
          success_url: window?.location?.href,
          mode: 'subscription',
          cancel_url: window?.location?.href,
          automatic_tax: { enabled: true },
        },
        path
      );
      onSnapshot(docRef, (snapshot) => {
        // @ts-ignore
        const { error, url } = snapshot.data();
        if (error) {
          console.error(error);
          setIsLoading(false);
        }
        if (url) {
          setIsLoading(false);
          window.location.assign(url);
        }
      });
    } catch (error) {
      console.error('Error during checkout:', error);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.close}>
        <RoundIconButton onClick={closeAction} buttonText={'close'} icon={faTimes} />
      </div>

      <Spacer size={SPACER_SIZE.MD} />

      <ResponsiveImage className={styles.logo} image={IMAGES.logos.wohnsinnPro} rounded={false} />

      <Spacer size={SPACER_SIZE.MD} />

      <Headline tag={'span'} size={HEADLINE_SIZE.H2}>
        {t('subscription.landing.lessCompetition.title')}
      </Headline>

      <Spacer size={SPACER_SIZE.SM} />

      <Spacer size={SPACER_SIZE.MD} />

      <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT}>{t('subscription.choose')}</Text>

      <Spacer size={SPACER_SIZE.SM} />

      <PricingOptions priceList={prices} setSelectedOption={setSelectedOption} selectedOption={selectedOption} />

      <Spacer size={SPACER_SIZE.LG} />

      <PremiumSubscriptionDrawerBenefits hideCTA={true} onClose={closeAction} />

      <div className={styles.submitButton}>
        <CTAButton
          spinIcon={isLoading}
          buttonText={`Weiter - ${(selectedOption?.unit_amount / 100).toFixed(2)} € insgesamt`}
          rounded={false}
          icon={isLoading ? getSubmitIcon(SUBMIT_BUTTON_MODE.SUBMITTING) : undefined}
          disabled={isLoading}
          iconPosition={isLoading ? 'end' : undefined}
          expand={'full'}
          onClick={() => handleCheckOut(selectedOption?.id)}
        />
      </div>
    </div>
  );
};

export default PremiumSubscriptionDrawer;
