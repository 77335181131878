import SnackBarContext from 'core/context/snackbar.context';
import useFormErrorHook from 'core/hook/use-form-error.hook';
import { FC, useContext, useEffect, useState } from 'react';
import { FormContext } from 'core/context/form.context';
import { Grid } from '@mui/material';
import { APARTMENT_TYPE_OF_MARKETING, IApartmentCost, RENT_TYPE } from '@wohnsinn/ws-ts-lib';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { joiResolver } from '@hookform/resolvers/joi';
import { useForm } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { wohnsinnServices } from 'App';
import ApartmentCostFormSale from 'component/forms/apartment/ApartmentCostsForm/ApartmentCostFormSale';
import ApartmentCostFormRent from 'component/forms/apartment/ApartmentCostsForm/ApartmentCostFormRent';
import { APARTMENT_COST_FORM_SCHEMA } from 'core/schemas/apartment';
import Joi from 'joi';
import { ICreateApartmentTunnel } from 'view/landlord/apartment/ApartmentCreateView';
import useApartment from 'core/hook/apartment.hook';
import { ROUTES } from 'core/const/routes';
import { useNavigate } from 'react-router-dom';
import FormNavigation from 'component/molecules/FormNavigation';
import { TEXT_COLOR } from 'component/atoms/typographie/Text';
import FormHeader from 'component/atoms/FormHeader';
import BackButton from 'component/atoms/Buttons/BackButton';
import useWindowSize from 'core/hook/windowsize.hook';
import FormErrorBox from 'component/molecules/FormErrorBox';
import useErrorHandling from 'core/hook/errorhandling.hook';

const ApartmentCostsForm: FC<ICreateApartmentTunnel> = ({ isTunnelView }) => {
  const { apartment } = useApartment();
  const { handleError } = useFormErrorHook('ApartmentCostForm');
  const { apartmentService } = wohnsinnServices;
  const { handleSnackBar } = useContext(SnackBarContext);
  const { isSmallerMd } = useWindowSize();

  const { t: r } = useTranslation('routes');
  const { t } = useTranslation('common');
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { errorObject, onErrorHandler } = useErrorHandling();

  const {
    control,
    watch,
    formState: { errors },
    setValue,
    reset,
    handleSubmit,
  } = useForm<IApartmentCost>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: joiResolver(Joi.object(APARTMENT_COST_FORM_SCHEMA)),
    defaultValues: apartment?.cost ?? null,
  });
  const navigate = useNavigate();
  const isHeatingIncluded = watch('isHeatingIncluded');
  const purchasePriceOnRequest = watch('purchasePrice.onRequest');
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!!apartment?.cost) {
      reset(apartment.cost);
    }
  }, [apartment]);

  useEffect(() => {
    if (isHeatingIncluded) setValue('heatingCosts', undefined);
  }, [isHeatingIncluded]);

  useEffect(() => {
    setValue('purchasePrice.value', undefined);
  }, [purchasePriceOnRequest]);

  const saveDataToDB = async (): Promise<void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);

    try {
      await apartmentService.updateApartment({
        data: { cost: watch() },
        creatorId: apartment.creatorId,
        apartmentId: apartment.id,
      });
      await queryClient.invalidateQueries({ queryKey: ['apartments'] });
      handleSnackBar('toast.success.saved', 'success');
      if (isTunnelView) {
        navigate(r(ROUTES.landlordRoutes.apartment.create.equipment.path).replace(':apartmentId', apartment?.id));
      } else {
        navigate(r(ROUTES.landlordRoutes.apartment.overview.path).replace(':apartmentId', apartment?.id));
      }
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUCCESS);
    } catch (e) {
      handleError(e);
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
    }
  };

  const isSale = apartment?.mainInformation?.typeOfMarketing === APARTMENT_TYPE_OF_MARKETING.SALE;
  const isRent = apartment?.mainInformation?.typeOfMarketing === APARTMENT_TYPE_OF_MARKETING.RENT;

  return (
    <FormContext.Provider value={{ control }}>
      <form
        onSubmit={handleSubmit(saveDataToDB, onErrorHandler)}
        autoComplete={'off'}
        id={'apartmentCostsForm'}
        noValidate
      >
        {!isTunnelView && !isSmallerMd && (
          <div style={{ paddingBottom: '1rem' }}>
            <BackButton />
          </div>
        )}
        {Object.keys(errors).length > 0 && <FormErrorBox errors={errorObject} />}
        <FormHeader title={r(ROUTES.landlordRoutes.apartment.cost.title)} />

        <Grid container columnSpacing={2} className={'form-with-navigation'}>
          {isSale && <ApartmentCostFormSale />}
          {isRent && <ApartmentCostFormRent formValue={watch()} />}
        </Grid>
      </form>
      <FormNavigation
        showSecondaryButtonMobile={isTunnelView}
        buttonSubmitMode={buttonSubmitMode}
        disabled={false}
        formId={'apartmentCostsForm'}
        submitButtonText={isTunnelView ? t('saveAndContinue') : t('save')}
        secondaryButtonAction={() => navigate(-1)}
        secondaryButtonColor={TEXT_COLOR.TEXT_COLOR_ACCENT}
        secondaryButtonText={t('back')}
      />
    </FormContext.Provider>
  );
};

export const RENT_TYPE_SELECT_OPTION_LIST = [
  {
    label: 'apartment.cost.typicalAreaRent',
    value: RENT_TYPE.TYPICAL_AREA_RENT,
  },
  {
    label: 'apartment.cost.graduatedRent.label',
    value: RENT_TYPE.GRADUATED_RENT,
  },
  { label: 'apartment.cost.indexRent.label', value: RENT_TYPE.INDEX_RENT },
];

export default ApartmentCostsForm;
