import { FC } from 'react';
import styles from './ArrowBox.module.scss';
import Text, { TEXT_COLOR, TEXT_TYPE } from 'component/atoms/typographie/Text';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import NotificationBadge, { NOTIFICATION_BADGE_SIZE } from '../../atoms/NotificationBadge';

export interface IArrowBoxInterface<T> {
  label: string;
  img?: string;
  count: number;
  active: boolean;
  state: T;
  notification?: number;
}

const ArrowBox = <T,>({
  boxes,
  onClick,
  showAsRectangle,
}: {
  showAsRectangle?: boolean;
  boxes: IArrowBoxInterface<T>[];
  onClick: (state: T) => void;
}) => (
  <div className={`${styles.wrapper} ${showAsRectangle ? styles.showAsRectangle : ''}`}>
    {boxes.map((box, index) => (
      <button
        onClick={() => onClick(box.state)}
        key={`arrow-box-${index}`}
        className={`${styles.arrowBox} ${box.active ? styles.active : ''} ${
          showAsRectangle ? styles.showAsRectangle : ''
        }`}
      >
        <div className={styles.notificationBadge}>
          <NotificationBadge size={NOTIFICATION_BADGE_SIZE.REGULAR} count={box.notification} />
        </div>
        <div className={styles.content}>
          <div className={styles.label}>
            <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION} color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
              {box.label}
            </Text>
            {box.img ? (
              <img style={{ display: 'inline' }} src={box.img} alt={box.label} className={styles.img} />
            ) : null}
          </div>
          <div className={styles.countWrapper}>
            <Headline size={HEADLINE_SIZE.H2}>{box.count}</Headline>
          </div>
        </div>

        <div className={styles.svgWrapper}>
          {index === boxes.length - 1 ? <ArrowBoxEnd active={box.active} /> : null}
          {index === 0 ? <ArrowBoxStart active={box.active} /> : null}
          {index !== 0 && index !== boxes.length - 1 ? <ArrowBoxProgress active={box.active} /> : null}
        </div>
      </button>
    ))}
  </div>
);

const ArrowBoxStart: FC<{ active?: boolean }> = ({ active }) => (
  <svg
    preserveAspectRatio="none"
    className={`${styles.arrowSvg} ${active ? styles.active : ''}`}
    viewBox="0 0 265 79"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 7.99999C0.5 3.85786 3.85786 0.5 8 0.5H232.202C234.496 0.5 236.665 1.55064 238.087 3.35157L262.726 34.5483C264.863 37.2549 264.88 41.0703 262.765 43.7949L238.085 75.5981C236.664 77.4288 234.477 78.5 232.159 78.5H7.99999C3.85786 78.5 0.5 75.1421 0.5 71V39.1535V7.99999Z"
      fill="white"
      stroke="#D7D8DA"
    />
  </svg>
);

const ArrowBoxProgress: FC<{ active?: boolean }> = ({ active }) => (
  <svg
    preserveAspectRatio="none"
    className={`${styles.arrowSvg} ${active ? styles.active : ''}`}
    viewBox="0 0 256 79"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.03504 12.74C0.0397387 7.841 3.52564 0.5 9.84726 0.5H223.415C225.72 0.5 227.896 1.55942 229.318 3.37311L253.772 34.5697C255.887 37.268 255.903 41.057 253.811 43.7731L229.315 75.5766C227.895 77.42 225.7 78.5 223.373 78.5H8.96156C2.78787 78.5 -0.741793 71.4576 2.9529 66.5116L20.4633 43.0704C22.7973 39.9459 22.7056 35.6339 20.2407 32.6115L4.03504 12.74Z"
      fill="white"
      stroke="#D7D8DA"
    />
  </svg>
);

const ArrowBoxEnd: FC<{ active?: boolean }> = ({ active }) => (
  <svg
    preserveAspectRatio="none"
    className={`${styles.arrowSvg} ${active ? styles.active : ''}`}
    viewBox="0 0 227 79"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.01792 12.7363C0.0272202 7.83641 3.51385 0.5 9.83322 0.5H219C223.142 0.5 226.5 3.85786 226.5 8V37.7675V71C226.5 75.1421 223.142 78.5 219 78.5H8.94897C2.77746 78.5 -0.752797 71.462 2.93746 66.5154L20.431 43.0659C22.7606 39.9432 22.6689 35.6364 20.2086 32.6156L4.01792 12.7363Z"
      fill="white"
      stroke="#D7D8DA"
    />
  </svg>
);

export default ArrowBox;
