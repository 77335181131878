import { FC } from 'react';
import styles from './CloseButton.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';

const CloseButton: FC<{ onClose: any; color?: 'dark' | 'light' }> = ({ onClose, color = 'light' }) => {
  const { t } = useTranslation('common');
  return (
    <button className={`${styles.closeBtn} ${color === 'dark' ? styles.dark : ''}`} onClick={onClose}>
      <FontAwesomeIcon icon={faTimes} size={'xl'} />
      <span className="sr-only">{t('close')}</span>
    </button>
  );
};

export default CloseButton;
