import { FC } from 'react';
import ListWithIcons, { IListWithIconItem } from '../../../molecules/ListWithIcons';
import {
  faFamily,
  faFileContract,
  faPawSimple,
  faPeopleGroup,
  faPeopleSimple,
  faUserShield,
} from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import { ITenantProfile } from '@wohnsinn/ws-ts-lib';

const TenantHouseholdList: FC<{ tenantProfile: ITenantProfile }> = ({ tenantProfile }) => {
  const { t } = useTranslation('common');
  const household: IListWithIconItem[] = [];

  household.push({
    icon: faPeopleGroup,
    text: `${t('WG-Gründung')}: ${tenantProfile?.household?.isSharedUsagePossible ? t('yes') : t('no')}`,
  });

  household.push({
    icon: faPawSimple,
    text: `${t('Haustiere')}: ${tenantProfile?.household?.arePetsAllowed ? t('yes') : t('no')}`,
  });

  household.push({
    icon: faUserShield,
    text: `${t('hasPledge.title')}: ${tenantProfile?.household?.hasPledge ? t('yes') : t('no')}`,
  });

  household.push({
    icon: faFileContract,
    text: `${t('canBeSubsidized.title')}: ${tenantProfile?.household?.canBeSubsidized ? t('yes') : t('no')}`,
  });

  if (tenantProfile?.household?.numberOfPeopleMovingIn && tenantProfile?.household?.numberOfPeopleMovingIn > 0) {
    household.push({
      icon: faPeopleSimple,
      text: `${t('householdSize')}: ${t('numberOfPeopleMovingInText', {
        count: parseInt(tenantProfile?.household?.numberOfPeopleMovingIn as unknown as string),
      })}`,
    });
  }

  if (tenantProfile?.household?.numberOfChildrenMovingIn && tenantProfile?.household?.numberOfChildrenMovingIn > 0) {
    household.push({
      icon: faFamily,
      text: `${t('householdSize.below14')}: ${t('numberOfChildrenMovingInText', {
        count: parseInt(tenantProfile?.household?.numberOfChildrenMovingIn as unknown as string),
      })}`,
    });
  }

  return <ListWithIcons list={household} />;
};

export default TenantHouseholdList;
