import { FC, useState } from 'react';
import { APPLICATION_STATE, IApplication } from '@wohnsinn/ws-ts-lib';
import styles from './ApplicationCard.module.scss';
import LandlordRatingButtons from 'component/molecules/LandlordRatingButtons';
import Headline, { HEADLINE_COLOR, HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import Text, { TEXT_COLOR } from 'component/atoms/typographie/Text';
import ApplicationModal from 'component/organisms/ApplicationModal';
import ListWithIcons, { IListWithIconItem } from 'component/molecules/ListWithIcons';
import {
  faBriefcase,
  faCircleEuro,
  faEnvelope,
  faEnvelopeOpen,
  faPeopleSimple,
} from '@fortawesome/pro-light-svg-icons';
import { formatNumberGermanStyle } from 'core/helper/get-formatted-price';
import { useTranslation } from 'react-i18next';
import { printAge } from 'core/helper/print-age';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NotificationBadge, { NOTIFICATION_BADGE_SIZE } from 'component/atoms/NotificationBadge';
import Badge, { BADGE_COLORS } from 'component/atoms/Badge';
import { useUpdateApplicationMutation } from 'core/hook/update-application.hook';
import ContextMenu from 'component/atoms/ContextMenu';
import { createApplicationContextMenu } from 'core/helper/create-context-menu';
import { IMAGES } from 'core/const/images';
import { wohnsinnServices } from '../../../../App';

const ApplicationCard: FC<{ application: IApplication }> = ({ application }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('common');
  const { t: i } = useTranslation('images');
  const { tenantProfile } = application;
  const tenantInfoList: IListWithIconItem[] = [];
  const { mutate } = useUpdateApplicationMutation();
  const CONTEXT_MENU = createApplicationContextMenu(application, mutate, t);
  const { apartmentService } = wohnsinnServices;

  const handleApplicationClick = async () => {
    setIsOpen(true);
    mutate({
      application,
      data: {
        applicationState:
          application.applicationState === APPLICATION_STATE.NEW
            ? APPLICATION_STATE.OPENED
            : application.applicationState,
      },
    });

    await apartmentService.clearNewApplicationsRefOnApartment(
      application.apartmentId,
      application.landlordId,
      application.tenantProfile.uid
    );
  };

  if (tenantProfile?.household?.numberOfPeopleMovingIn && tenantProfile?.household?.numberOfPeopleMovingIn > 0) {
    tenantInfoList.push({
      icon: faPeopleSimple,
      text: t('numberOfPeopleMovingInTextAlt', {
        count: parseInt(tenantProfile?.household?.numberOfPeopleMovingIn as unknown as string),
      }),
    });
  }

  tenantInfoList.push({
    icon: faCircleEuro,
    text: t('monthlyIncome.netto', {
      income: formatNumberGermanStyle(tenantProfile?.household?.monthlyIncome),
    }),
  });

  return (
    <>
      <ApplicationModal isOpen={isOpen} onClose={() => setIsOpen(false)} application={application} />

      <div className={styles.wrapper}>
        <div className={styles.contextMenu}>
          <ContextMenu menu={CONTEXT_MENU} />
        </div>
        {application?.unreadTenantMessagesRef?.length > 0 ? (
          <div className={styles.notification}>
            <NotificationBadge
              size={NOTIFICATION_BADGE_SIZE.REGULAR}
              count={application?.unreadTenantMessagesRef?.length}
            />
          </div>
        ) : null}
        <button className={styles.imageWrapper} onClick={handleApplicationClick}>
          <div
            style={{ backgroundImage: `url(${tenantProfile.photoUrl ?? i(IMAGES.images.avatarPlaceholder.src)})` }}
            className={styles.image}
          />
          <div className={styles.newBadge}>
            {application.applicationState === APPLICATION_STATE.NEW ? (
              <Badge statusColor={BADGE_COLORS.PRIMARY} textColor={TEXT_COLOR.TEXT_COLOR_DARK} text={t('new')} />
            ) : (
              <div
                className={`${styles.chatState} ${
                  application?.unreadTenantMessagesRef?.length > 0 ? styles.withNewBadge : ''
                }`}
              >
                <FontAwesomeIcon
                  size={'sm'}
                  icon={
                    application.isChatDisabled === null || application.isChatDisabled === undefined
                      ? faEnvelope
                      : faEnvelopeOpen
                  }
                />
              </div>
            )}
          </div>

          <div className={styles.text}>
            <Headline color={HEADLINE_COLOR.HEADLINE_COLOR_LIGHT} size={HEADLINE_SIZE.H2}>
              {`${tenantProfile.personalInformation.firstName?.trimEnd()}, ${printAge(
                tenantProfile.personalInformation.dateOfBirth
              )}`}
            </Headline>

            <div className={styles.job}>
              <FontAwesomeIcon icon={faBriefcase} color={'white'} />{' '}
              <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_LIGHT} overFlowEllipsis>
                {application?.tenantProfile?.personalInformation?.customJobDescription ??
                  t(`jobStatus.${application?.tenantProfile?.personalInformation?.jobStatus}`)}
              </Text>
            </div>
          </div>
        </button>
        <button className={styles.content} onClick={handleApplicationClick}>
          <ListWithIcons list={tenantInfoList} />

          <Text className={styles.button} color={TEXT_COLOR.TEXT_COLOR_ACCENT} underline bold align={'center'}>
            {t('selfDisclosure.watch')}
          </Text>
        </button>
        <div className={styles.ratingButtons}>
          <LandlordRatingButtons
            apartmentId={application?.apartmentId}
            landlordRating={application?.landlordRating}
            tenantProfileId={tenantProfile.uid}
            currentApplicationState={application?.applicationState}
          />
        </div>
      </div>
    </>
  );
};

export default ApplicationCard;
