import ModalWrapper from 'component/modals/ModalWrapper';
import { Grid } from '@mui/material';
import { FormContext } from 'core/context/form.context';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useContext, useState } from 'react';
import SnackBarContext from 'core/context/snackbar.context';
import ModalContext from 'core/context/modal.context';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { wohnsinnServices } from 'App';
import UserContext from 'core/context/user.context';
import { getSubmitIcon } from 'component/atoms/Buttons/CTAButton';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import Text, { TEXT_COLOR } from 'component/atoms/typographie/Text';
import Spacer from 'component/atoms/Spacer';
import { useTranslation } from 'react-i18next';
import MultiMessageFormComponents from 'component/molecules/MultiMessageFormComponents';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';

export enum SALUTATION_TYPES {
  NONE = 'NONE',
  HELLO = 'HELLO',
  POLITE = 'POLITE',
}

export const MULTI_MESSAGE_SCHEMA = {
  message: Joi.string().required().min(5),
  salutation: Joi.valid(...Object.keys(SALUTATION_TYPES)).required(),
};

interface IMultiMessage {
  salutation: SALUTATION_TYPES;
  message: string;
}

const REJECT_APPLICATIONS_FORM_ID = 'REJECT_APPLICATIONS_FORM_ID';

const RejectApplicationsModal = () => {
  const [submitButtonMode, setSubmitButtonMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { handleSnackBar } = useContext(SnackBarContext);
  const { closeModal, modalData, openModal } = useContext(ModalContext);
  const { t } = useTranslation('common');
  const { chatService } = wohnsinnServices;
  const { landlordProfile } = useContext(UserContext);
  const {
    control,
    watch,
    formState: { isValid },
    setValue,
    handleSubmit,
  } = useForm<IMultiMessage>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: joiResolver(Joi.object(MULTI_MESSAGE_SCHEMA)),
    defaultValues: {
      salutation: SALUTATION_TYPES.POLITE,
      message:
        'vielen Dank für Ihre Bewerbung und Ihr Interesse an der Wohnung. Leider müssen wir Ihnen mitteilen, dass die Wohnung bereits anderweitig vergeben wurde.<br>' +
        '<br>' +
        'Wir wünschen Ihnen weiterhin viel Erfolg bei der Wohnungssuche und alles Gute für die Zukunft.<br>' +
        '<br>' +
        `Mit freundlichen Grüßen, <br>${landlordProfile.personalInformation.firstName} ${landlordProfile.personalInformation.lastName}`,
    },
  });

  const message = watch('message');
  const salutation = watch('salutation');

  const onSuccess = async () => {
    setSubmitButtonMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    try {
      await chatService.sendMultiMessageToTenant(modalData?.applicationList, landlordProfile, message, salutation);

      setSubmitButtonMode(SUBMIT_BUTTON_MODE.NONE);

      handleSnackBar('toast.messageSend', 'success');
      openModal({ id: MODAL_IDENTIFIER.DELETE_APPLICATIONS, data: { applicationList: modalData?.applicationList } });
    } catch (e) {
      setSubmitButtonMode(SUBMIT_BUTTON_MODE.ERROR);

      handleSnackBar('toast.error.unknown', 'error');
      console.error('Error on onSuccess in RequestSalesModal', e);
    }
  };

  return (
    <ModalWrapper
      buttons={{
        primary: {
          icon: getSubmitIcon(submitButtonMode),
          spinIcon: submitButtonMode === SUBMIT_BUTTON_MODE.SUBMITTING,
          buttonText: t('send'),
          form: REJECT_APPLICATIONS_FORM_ID,
          disabled: !isValid,
        },
        third: {
          onClick: () => {
            closeModal();
          },
          buttonText: t('notNow'),
          color: TEXT_COLOR.TEXT_COLOR_DARK,
        },
      }}
      title={t('rejectApplication.header')}
    >
      <>
        <Headline align={'center'} size={HEADLINE_SIZE.H2}>
          {t('rejectApplications.title')}
        </Headline>
        <Spacer />
        <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT} align={'center'}>
          {t('rejectApplications.text')}
        </Text>
        <Spacer />
        <FormContext.Provider value={{ control, setValue, watch }}>
          <form
            id={REJECT_APPLICATIONS_FORM_ID}
            onSubmit={handleSubmit(onSuccess, (e) => console.error('Error on RequestSalesModal', e))}
          >
            <Grid item xs={12}>
              <MultiMessageFormComponents />
            </Grid>
          </form>
        </FormContext.Provider>
      </>
    </ModalWrapper>
  );
};

export default RejectApplicationsModal;
