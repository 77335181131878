import FourDigitsInput from 'component/atoms/formElement/FourDigitsInput';
import Joi from 'joi';
import PageLayout from 'component/layouts/PageLayout';
import SnackBarContext from 'core/context/snackbar.context';
import Text, { TEXT_COLOR, TEXT_TYPE } from 'component/atoms/typographie/Text';
import { FormContext } from 'core/context/form.context';
import { IRegistrationRequest } from '@wohnsinn/ws-ts-lib';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { joiResolver } from '@hookform/resolvers/joi/dist/joi';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { wohnsinnServices } from 'App';
import RegistrationLayout from 'component/organisms/registration/RegistrationLayout';

const ACTIVATION_CODE_SCHEMA = Joi.string()
  .min(4)
  .max(4)
  .regex(/\d\d\d\d/)
  .required();

const ACTIVATE_ACCOUNT_FORM_ID = 'ACTIVATE_ACCOUNT_FORM_ID';

const ActivateAccountView = () => {
  const { firebaseAuthService, registrationRequestService } = wohnsinnServices;
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { t } = useTranslation('common', { keyPrefix: 'view.ActivateAccountView' });
  const { handleSnackBar } = useContext(SnackBarContext);
  const { email } = useParams<{ email: string }>();
  const [coolDown, setCoolDown] = useState<number>(0);
  const { control, handleSubmit, watch, formState } = useForm<{
    activationCode: string;
  }>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: joiResolver(Joi.object({ activationCode: ACTIVATION_CODE_SCHEMA })),
  });

  const resendMail = async (email: string) => {
    setCoolDown(30);
    handleSnackBar('Email wurde verschickt', 'success');
    await registrationRequestService.sendRegistrationRequestMail(email);
  };

  const handleVerification = async (email: string, code: number) => {
    const registrationRequest: IRegistrationRequest = await registrationRequestService.verifyRegistrationRequest(
      email,
      code
    );

    if (registrationRequest) {
      await firebaseAuthService.createUserWithoutPassword(
        email,
        registrationRequest.userType,
        false,
        registrationRequest.referrer,
        registrationRequest.tenantFilterParams
      );
      await registrationRequestService.deleteRegistrationRequest(email);
    } else {
      handleSnackBar('Der eingegebene Code stimmt ist leider nicht korrekt!', 'error');
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
    }
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (coolDown > 0) {
      interval = setInterval(() => {
        setCoolDown(coolDown - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [coolDown]);

  const submitSuccess = async () => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    await handleVerification(email, parseInt(watch('activationCode')));
  };

  return (
    <PageLayout hideFooter hideMenu pageTitle={t('title')} secondPageTitle={t('text', { email })}>
      <RegistrationLayout
        formId={ACTIVATE_ACCOUNT_FORM_ID}
        isValidForm={formState.isValid}
        buttonSubmitMode={buttonSubmitMode}
        submitButtonText={t('submit')}
        emailProp={email}
      >
        <FormContext.Provider value={{ control }}>
          <form id={ACTIVATE_ACCOUNT_FORM_ID} onSubmit={handleSubmit(submitSuccess, console.error)}>
            <FourDigitsInput name={'activationCode'} required={true} />
          </form>
        </FormContext.Provider>
        <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION} color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
          Keine E-Mail erhalten?{' '}
          {coolDown ? (
            `${coolDown} Sekunden warten`
          ) : (
            <button onClick={() => resendMail(email)}>
              <Text color={TEXT_COLOR.TEXT_COLOR_PRIMARY}>E-Mail erneut senden</Text>
            </button>
          )}
        </Text>
      </RegistrationLayout>
    </PageLayout>
  );
};

export default ActivateAccountView;
