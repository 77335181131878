import ModalWrapper, { IModalWrapperButtonProps } from '../../ModalWrapper';
import { FC, useContext, useEffect, useState } from 'react';
import ModalContext from 'core/context/modal.context';
import { useTranslation } from 'react-i18next';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import { getSubmitIcon } from 'component/atoms/Buttons/CTAButton';
import { Avatar, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, TextField } from '@mui/material';
import { onSnapshot } from 'firebase/firestore';
import { APARTMENT_RENT_STATUS, APPLICATION_STATE, IApplication } from '@wohnsinn/ws-ts-lib';
import { wohnsinnServices } from 'App';
import UserContext from 'core/context/user.context';
import RadioGroupButtons, { IRadioGroupOption } from 'component/atoms/RadioGroupButtons';
import LoadingAnimation from 'component/atoms/LoadingAnimation';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { FormContext } from 'core/context/form.context';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { TEXT_COLOR } from 'component/atoms/typographie/Text';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import styles from 'component/atoms/RadioGroupButtons/RadioGroupButtons.module.scss';
import { useQueryClient } from '@tanstack/react-query';

enum SELECT_TENANT_STATE {
  FOUND_TENANT = 'FOUND_TENANT',
  FOUND_AT_WS = 'FOUND_AT_WS',
  SELECT_TENANT = 'SELECT_TENANT',
  FINISHED = 'FINISHED',
}

export const TENANT_ID_FORM_SCHEMA = Joi.object({
  tenantId: Joi.string().required(),
});

const ADD_TENANT_FORM_ID = 'ADD_TENANT_FORM_ID';

const FoundTenantModal: FC = () => {
  const [submitButtonMode, setSubmitButtonMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { closeModal, modalData, openModal } = useContext(ModalContext);
  const { t } = useTranslation('common');
  const [status, setStatus] = useState<SELECT_TENANT_STATE>(SELECT_TENANT_STATE.FOUND_TENANT);
  const { applicationService, apartmentService } = wohnsinnServices;
  const { user } = useContext(UserContext);
  const [optionsByApplications, setOptionsByApplications] = useState<IRadioGroupOption[]>([]);
  const [filteredOptions, setFilteredOptions] = useState<IRadioGroupOption[]>([]);
  const [applications, setApplications] = useState<IApplication[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newTenantApplication, setNewTenantApplication] = useState<IApplication>(null);
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    control,
    getValues,
    formState: { isValid },
  } = useForm<{ tenantId: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: joiResolver(TENANT_ID_FORM_SCHEMA),
  });

  useEffect(() => {
    getApplicants();
  }, []);

  const FOUND_TENANT_BUTTONS: IModalWrapperButtonProps = {
    primary: {
      buttonText: t('yes'),
      type: 'button',
      onClick: () => setStatus(SELECT_TENANT_STATE.FOUND_AT_WS),
    },
    third: {
      onClick: () => closeModal(),
      buttonText: t('notYet'),
      color: TEXT_COLOR.TEXT_COLOR_DARK,
    },
  };

  const FOUND_AT_WS_BUTTONS = {
    primary: {
      buttonText: t('yes'),
      onClick: () => setStatus(SELECT_TENANT_STATE.SELECT_TENANT),
    },
    third: {
      onClick: async () => {
        console.log(applications);
        openModal({ id: MODAL_IDENTIFIER.REJECT_APPLICATIONS, data: { applicationList: applications } });
      },
      buttonText: t('unfortunatelyNo'),
      color: TEXT_COLOR.TEXT_COLOR_DARK,
    },
  };

  const SELECT_TENANT_BUTTONS = {
    primary: {
      buttonText: t('selectTenant'),
      disabled: !isValid,
      form: ADD_TENANT_FORM_ID,
      icon: getSubmitIcon(submitButtonMode),
      spinIcon: submitButtonMode === SUBMIT_BUTTON_MODE.SUBMITTING,
    },

    third: {
      onClick: () =>
        openModal({
          id: MODAL_IDENTIFIER.REJECT_APPLICATIONS,
          data: { applicationList: applications },
        }),
      buttonText: t('skip'),
      color: TEXT_COLOR.TEXT_COLOR_DARK,
    },
  };

  const FINISHED_BUTTONS = {
    primary: {
      buttonText: t('next'),
      onClick: () => {
        // remove new tenant from all applications to decline all others
        const declinedApplications = removeTenantByIdFromApplications(applications, getValues().tenantId);
        openModal({ id: MODAL_IDENTIFIER.REJECT_APPLICATIONS, data: { applicationList: declinedApplications } });
      },
    },
  };

  const renderButtons = () => {
    switch (status) {
      case SELECT_TENANT_STATE.FOUND_TENANT:
        return FOUND_TENANT_BUTTONS;
      case SELECT_TENANT_STATE.FOUND_AT_WS:
        return FOUND_AT_WS_BUTTONS;
      case SELECT_TENANT_STATE.SELECT_TENANT:
        return SELECT_TENANT_BUTTONS;
      case SELECT_TENANT_STATE.FINISHED:
        return FINISHED_BUTTONS;
    }
  };

  const renderTitle = () => {
    switch (status) {
      case SELECT_TENANT_STATE.FOUND_TENANT:
        return t('foundTenant.foundTenant.title');
      case SELECT_TENANT_STATE.FOUND_AT_WS:
        return t('foundTenant.foundAtWs.title');
      case SELECT_TENANT_STATE.SELECT_TENANT:
        return t('foundTenant.selectTenant.title');
      case SELECT_TENANT_STATE.FINISHED:
        return t('foundTenant.finished.title');
    }
  };

  function findMatchingObjects(searchString: string) {
    let newApplications = [...optionsByApplications];
    newApplications = newApplications.filter((obj) => obj.key.toLowerCase().includes(searchString.toLowerCase()));
    setFilteredOptions([...newApplications]);
  }

  const getApplicants = async () => {
    setIsLoading(true);
    onSnapshot(
      applicationService.getLandlordApplicationListRef({
        isAdmin: user?.isAdmin,
        landlordId: user.uid,
        apartmentId: modalData?.apartmentId,
        tenantId: null,
      }),
      (snapshot) => {
        const applications = snapshot.docs.map((doc) => doc.data()) as IApplication[];
        const radioOptions = applications.map((application) => {
          return {
            key: `${application.tenantProfile.personalInformation.firstName} ${application.tenantProfile.personalInformation.lastName}`,
            value: application.tenantProfile.uid,
            meta: `${t('numberOfPeople', {
              count: parseInt(application?.tenantProfile?.household?.numberOfPeopleMovingIn as unknown as string),
            })} • ${application.tenantProfile.household.monthlyIncome} €`,
            img: application?.tenantProfile?.photoUrl,
          };
        });

        setOptionsByApplications(radioOptions);
        setApplications(applications);
        setFilteredOptions(radioOptions);
        setIsLoading(false);
      }
    );
  };

  const removeTenantByIdFromApplications = (array: IApplication[], id: string) => {
    return array.filter((obj) => obj.tenantProfile.uid !== id);
  };

  const setActiveTenant = async () => {
    setSubmitButtonMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    try {
      const selectedTenantId = getValues().tenantId;

      const data: Partial<IApplication> = { isActiveTenant: true, applicationState: APPLICATION_STATE.SELECTED_TENANT };
      await applicationService.updateApplication({
        landlordId: modalData?.landlordId,
        apartmentId: modalData?.apartmentId,
        tenantId: selectedTenantId,
        data,
      });

      await apartmentService.updateApartment({
        data: {
          rentStatus: APARTMENT_RENT_STATUS.RENTED,
          activeTenantId: selectedTenantId,
        },
        creatorId: modalData?.landlordId,
        apartmentId: modalData?.apartmentId,
      });
      await apartmentService.removeIdFromApplicantList({
        id: selectedTenantId,
        creatorId: modalData?.landlordId,
        apartmentId: modalData?.apartmentId,
      });

      await queryClient.refetchQueries({ queryKey: ['activeTenantApplication', modalData?.apartmentId] });
      setNewTenantApplication(queryClient.getQueryData(['activeTenantApplication', modalData?.apartmentId]));

      setStatus(SELECT_TENANT_STATE.FINISHED);
      setSubmitButtonMode(SUBMIT_BUTTON_MODE.SUCCESS);
    } catch (e) {
      console.error(e);
      setSubmitButtonMode(SUBMIT_BUTTON_MODE.ERROR);
    }
  };

  return (
    <ModalWrapper buttons={renderButtons()} title={t('addTenant')}>
      <Headline align={'center'} size={HEADLINE_SIZE.H2}>
        {renderTitle()}
      </Headline>
      {status === SELECT_TENANT_STATE.FINISHED ? (
        <div style={{ marginTop: 16 }}>
          <List className={styles.list}>
            <ListItem className={styles.listItem}>
              <ListItemButton className={`${styles.listItemButton} ${styles.selected}`}>
                <ListItemAvatar>
                  <Avatar
                    alt={`${newTenantApplication?.tenantProfile?.personalInformation?.firstName} ${newTenantApplication?.tenantProfile?.personalInformation?.lastName}`}
                    src={newTenantApplication?.tenantProfile?.photoUrl}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={`${newTenantApplication?.tenantProfile?.personalInformation?.firstName} ${newTenantApplication?.tenantProfile?.personalInformation?.lastName}`}
                  secondary={`${t('numberOfPeople', {
                    count: parseInt(
                      newTenantApplication?.tenantProfile?.household?.numberOfPeopleMovingIn as unknown as string
                    ),
                  })} • ${newTenantApplication?.tenantProfile?.household?.monthlyIncome} €`}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </div>
      ) : null}
      {status === SELECT_TENANT_STATE.SELECT_TENANT ? (
        <div>
          <div style={{ marginTop: 16, marginBottom: 16 }}>
            <TextField
              hiddenLabel
              id={'tenant'}
              name={'tenant'}
              fullWidth
              type={'text'}
              placeholder={t('searchName')}
              size="small"
              onChange={(e) => findMatchingObjects(e.target.value)}
              variant={'outlined'}
            />
          </div>
          <div
            style={{
              maxHeight: 280,
              minHeight: 165,
              overflowY: 'auto',
              paddingRight: 8,
            }}
          >
            {isLoading ? (
              <LoadingAnimation />
            ) : (
              <>
                {optionsByApplications.length && !filteredOptions.length ? t('noSearchResults') : null}
                <FormContext.Provider value={{ control }}>
                  <form id={ADD_TENANT_FORM_ID} onSubmit={handleSubmit(setActiveTenant)}>
                    <RadioGroupButtons showAvatar={true} options={filteredOptions} name={'tenantId'} />
                  </form>
                </FormContext.Provider>
              </>
            )}
          </div>
        </div>
      ) : null}
    </ModalWrapper>
  );
};

export default FoundTenantModal;
