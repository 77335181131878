import { FC } from 'react';
import ListWithIcons, { IListWithIconItem } from '../../../molecules/ListWithIcons';
import { faSparkles, faPhoneRotary, faAt } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import { IApplication } from '@wohnsinn/ws-ts-lib';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import { faThumbtack } from '@fortawesome/pro-light-svg-icons';

const TenantGeneralList: FC<{ application: IApplication }> = ({ application }) => {
  const { t } = useTranslation('common');
  const generalInfo: IListWithIconItem[] = [];
  const tenantProfile = application?.tenantProfile;
  if (application?.createdAt) {
    generalInfo.push({
      icon: faSparkles,
      text: t('appliedAt', {
        date: format(new Date(application?.createdAt as unknown as string), 'd. MMMM yyyy', { locale: de }),
      }),
    });
  }
  if (application?.tenantProfile?.personalInformation?.address?.city) {
    generalInfo.push({
      icon: faThumbtack,
      text: `${application?.tenantProfile?.personalInformation?.address?.street}
       ${application?.tenantProfile?.personalInformation?.address?.houseNumber} · ${application?.tenantProfile?.personalInformation?.address?.city}`,
    });
  }
  if (tenantProfile?.email) {
    generalInfo.push({
      icon: faAt,
      text: tenantProfile?.email,
    });
  }

  if (tenantProfile?.personalInformation?.phoneNumber) {
    generalInfo.push({
      icon: faPhoneRotary,
      text: tenantProfile?.personalInformation?.phoneNumber,
    });
  }

  if (tenantProfile?.personalInformation) {
    return (
      <div className={'ion-margin-top ion-padding-bottom'}>
        <ListWithIcons list={generalInfo} />
      </div>
    );
  }

  return null;
};

export default TenantGeneralList;
