import { APPLICATION_STATE, IApartment, IApplication } from '@wohnsinn/ws-ts-lib';

export const getApartmentCardInformationFromApplication = (application: IApplication) => {
  return {
    address: application.address,
    apartmentId: application.apartmentId,
    media: application.media,
    rooms: application.rooms,
    size: application.size,
    cost: application.cost,
    isDisabled: application.isChatDisabled && application.applicationState !== APPLICATION_STATE.SELECTED_TENANT,
  };
};

export const getApartmentCardInformationFromApartment = (apartment: IApartment) => {
  return {
    address: apartment.mainInformation.address,
    apartmentId: apartment.id,
    media: apartment.media,
    rooms: apartment?.areas?.numberOfRooms,
    size: apartment?.areas?.totalArea ?? apartment?.areas?.livingArea,
    cost: apartment.cost,
    isDisabled: !apartment.isPublished,
  };
};
