import { FC, PropsWithChildren } from 'react';
import LoadingScreen from '../../component/atoms/LoadingScreen';
import UserContext from '../context/user.context';
import useAuthUser from '../hook/auth-user.hook';

const UserContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { user, landlordProfile, tenantProfile, activeUserType, isLoadingProfile, authUser, isPremiumUser } =
    useAuthUser();

  if (isLoadingProfile) {
    return <LoadingScreen />;
  }

  return (
    <UserContext.Provider
      value={{
        authUser,
        user,
        tenantProfile,
        landlordProfile,
        activeUserType,
        isPremiumUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
