import styles from './PricingCard.module.scss';
import Text from '../../../atoms/typographie/Text';
import Headline, { HEADLINE_SIZE } from '../../../atoms/typographie/Headline';
import CheckList, { ICheckListItem } from '../../../atoms/CheckList';
import { FC, useContext, useState } from 'react';
import CTAButton, { BUTTON_STYLE } from '../../../atoms/Buttons/CTAButton';
import { PopupModal } from 'react-calendly';
import { USER_TYPE } from '@wohnsinn/ws-ts-lib';
import Spacer, { SPACER_SIZE } from '../../../atoms/Spacer';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import ModalContext from 'core/context/modal.context';
import { faHouse } from '@fortawesome/pro-light-svg-icons';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';

export interface IPricingCardProps {
  checkListItems: ICheckListItem[];
  discount?: string;
  price: string;
  subLine?: string;
  title: string;
  titleExtra?: string;
  userType: USER_TYPE;
  priceExtra?: string;
  preHeader?: string;
}

const PricingCard: FC<IPricingCardProps> = ({
  checkListItems,
  discount,
  preHeader,
  price,
  subLine,
  priceExtra,
  title,
  titleExtra,
  userType,
}) => {
  const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);
  const { openModal } = useContext(ModalContext);

  return (
    <>
      <PopupModal
        url={process.env.REACT_APP_CALENDLY_CATCH_UP_LINK}
        onModalClose={() => setIsCalendlyOpen(false)}
        open={isCalendlyOpen}
        rootElement={document.getElementById('root')}
      />
      {preHeader && (
        <Headline size={HEADLINE_SIZE.H1} align={'center'}>
          {preHeader}
        </Headline>
      )}
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.info}>
            <Text>{title}</Text>
            <Spacer size={SPACER_SIZE.MD} />
            <div style={{ display: 'flex', gap: '2rem', alignItems: 'self-end' }}>
              <Headline tag={'span'} size={HEADLINE_SIZE.H1}>
                {price}
              </Headline>
              {priceExtra && <Text>{priceExtra}</Text>}
            </div>

            {subLine ? <Text>{subLine}</Text> : null}
            {discount ? <Text className={styles.discount}>{discount}</Text> : null}
            {titleExtra ? (
              <Text className={styles.titleExtra} tag={'span'}>
                {titleExtra}
              </Text>
            ) : null}
            <div className={styles.buttonWrapper}>
              <CTAButton
                size={'extra'}
                expand={'block'}
                rounded={false}
                icon={userType === USER_TYPE.LANDLORD ? faHouse : faSearch}
                onClick={() => openModal({ id: MODAL_IDENTIFIER.SELECT_USER_TYPE })}
                buttonText={userType === USER_TYPE.LANDLORD ? 'Kostenlos Objekt inserieren' : 'Wohnungssuche starten'}
                buttonStyle={BUTTON_STYLE.PRIMARY}
              />
            </div>
          </div>
        </div>
        <div className={styles.checkListWrapper}>
          <Headline size={HEADLINE_SIZE.H3}>FUNKTIONEN</Headline>
          <Spacer mobileSize={SPACER_SIZE.XS} />

          <CheckList items={checkListItems} />
        </div>
      </div>
    </>
  );
};

export default PricingCard;
