import {
  Auth,
  getAuth,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updatePassword,
  UserCredential,
  UserInfo,
} from 'firebase/auth';
import UserService from './user.service';
import { getApps } from 'firebase/app';
import { FirebaseFunctionsService } from './firebase-functions.service';
import FB_FUNCTION_URLS from '../const/fb-function-names';
import { HttpsCallableResult } from 'firebase/functions';
import {
  ITenantFilterParams,
  REGISTRATION_REFERRER,
  REGISTRATION_STATES,
  SIGN_IN_METHOD,
  USER_TYPE,
} from '@wohnsinn/ws-ts-lib';
import TenantService from './tenant.service';
import LandlordService from './landlord.service';
import { NavigateFunction } from 'react-router-dom';
import LOCAL_STORAGE_KEYS from 'core/enum/local-storage.enum';

class FirebaseAuthService {
  public readonly GOOGLE_OAUTH_PROVIDER: GoogleAuthProvider;

  constructor(
    public readonly auth: Auth,
    public readonly userService: UserService,
    private firebaseFunctionsService: FirebaseFunctionsService,
    public readonly tenantService: TenantService,
    public readonly landlordService: LandlordService
  ) {
    this.GOOGLE_OAUTH_PROVIDER = new GoogleAuthProvider();
  }

  /**
   * Update the user's password
   * @param password
   */
  public async updateUserPassword(password: string): Promise<void> {
    try {
      await updatePassword(this.auth.currentUser, password);
    } catch (e) {
      console.error('Error on updating password', e);
    }
  }

  /**
   * Create a user backend side
   * Login with given token
   * @param email
   * @param userType
   * @param isPhoneNumberVerified
   * @param referrer
   * @param tenantFilterParams
   * @return uid
   */
  public async createUserWithoutPassword(
    email: string,
    userType: USER_TYPE,
    isPhoneNumberVerified = false,
    referrer: REGISTRATION_REFERRER = REGISTRATION_REFERRER.NONE,
    tenantFilterParams?: ITenantFilterParams
  ): Promise<string> {
    try {
      const response: any = await this.firebaseFunctionsService.callFbFunction(
        FB_FUNCTION_URLS.user.createUserWithoutPassword,
        {
          body: { email },
        }
      );
      const { data: user } = response;
      await signInWithCustomToken(this.auth, response.data.token);

      const userProfileCreated = await this.userService.createDefaultUserProfile(
        response.data.uid,
        userType,
        isPhoneNumberVerified,
        isPhoneNumberVerified ? REGISTRATION_STATES.ADD_PROFILE : REGISTRATION_STATES.SET_PASSWORD,
        referrer
      );

      if (userProfileCreated) {
        if (userType === USER_TYPE.LANDLORD) {
          await this.landlordService.createLandlordProfile(user as UserInfo);
        }

        if (userType === USER_TYPE.TENANT) {
          await this.tenantService.createTenantProfile(user as UserInfo);

          if (tenantFilterParams && tenantFilterParams.location) {
            await this.tenantService.addTenantSearchProfile(user.uid, tenantFilterParams);
          }
        }

        const household = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.TENANT_HOUSEHOLD));
        if (household) {
          await this.tenantService.updateTenantHousehold(user.uid, household);
          localStorage.removeItem(LOCAL_STORAGE_KEYS.TENANT_HOUSEHOLD);
        }
      }

      return response.data.uid;
    } catch (e) {
      console.error('Error on createUserWithoutPassword', e);
    }
  }

  public authProviderLogin(signInMethod: SIGN_IN_METHOD): Promise<UserCredential> {
    try {
      switch (signInMethod) {
        case SIGN_IN_METHOD.GOOGLE:
          return signInWithPopup(this.auth, this.GOOGLE_OAUTH_PROVIDER);
        default:
          return;
      }
    } catch (e) {
      console.error('Error on authProviderLogin', e);
    }
  }

  public signInWithEmailAndPassword(email: string, password: string): Promise<UserCredential | void> {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  public sendPasswordResetMail(email: string): Promise<void> {
    return sendPasswordResetEmail(this.auth, email);
  }

  public async signOut(navigate?: NavigateFunction): Promise<void> {
    return signOut(getAuth(getApps()[0])).then(() => {
      if (navigate) {
        navigate('/');
      }
      localStorage.clear();
      return;
    });
  }

  public async triggerSendVerificationLink(email: string, userType: USER_TYPE): Promise<HttpsCallableResult | void> {
    try {
      await this.firebaseFunctionsService.callFbFunction(FB_FUNCTION_URLS.user.sendUserEmailVerificationMail, {
        body: { email, userType },
      });
    } catch (e) {
      console.error('Error on triggerSendVerificationLink', e);
    }
  }
}

export default FirebaseAuthService;
