import mixpanel, { Dict } from 'mixpanel-browser';
import { IMatchListResponse, MATCHING_MODE } from '@wohnsinn/ws-ts-lib';
interface ITrackEnterSharedViewProps {
  pageTitle: string;
  id: string;
  isExclusive: boolean;
  userId?: string;
  isPro?: boolean;
}
class MixpanelTrackingService {
  constructor() {}

  public userHadMatches = (matchListResponse: IMatchListResponse) => {
    try {
      mixpanel.track('Had Matches', {
        matchListResponse: matchListResponse,
      });
    } catch (e) {
      console.error('Error Mixpanel: ', e);
    }
  };

  public trackEvent = (eventName: string, properties?: Dict) => {
    try {
      mixpanel.track(`${eventName}`, { ...properties });
    } catch (e) {
      console.error('Error Mixpanel: ', e);
    }
  };

  public trackPlaceName = (placeName?: string) => {
    try {
      mixpanel.track('Place Name', {
        placeName: placeName ? placeName : 'ohne Stadt',
      });
    } catch (e) {
      console.error('Error Mixpanel: ', e);
    }
  };

  public userHadPerfectMatches = (numberOfPerfectMatches: number) => {
    try {
      mixpanel.track('userHadPerfectMatches', {
        numberOfPerfectMatches: numberOfPerfectMatches,
      });
    } catch (e) {
      console.error('Error Mixpanel: ', e);
    }
  };

  public createdUser = (uid: string) => {
    try {
      mixpanel.track('Signed Up', {
        uid: uid,
      });
    } catch (e) {
      console.error('Error Mixpanel: ', e);
    }
  };
  public trackEnterPage = (pageTitle: string, apartmentId: string = undefined) => {
    try {
      mixpanel.track(`Entered ${pageTitle}`, {
        pageTitle: pageTitle,
        date: new Date(),
        apartmentId: apartmentId,
      });
    } catch (e) {
      console.error('Error Mixpanel: ', e);
    }
  };

  public trackPricing = (subscription: string, userId: string) => {
    try {
      mixpanel.track(`Wohnsinn Pro`, {
        pageTitle: 'Wohnsinn Pro',
        date: new Date(),
        subscription,
        userId,
      });
    } catch (e) {
      console.error('Error Mixpanel: ', e);
    }
  };

  public trackEnterSharedView = ({ pageTitle, id, isExclusive, userId, isPro }: ITrackEnterSharedViewProps) => {
    try {
      mixpanel.track(`Entered ${pageTitle}`, {
        pageTitle: pageTitle,
        isExclusive,
        apartmentId: id,
        date: new Date().toDateString(),
        userId: !!userId ? userId : undefined,
        isPro,
      });
    } catch (e) {
      console.error('Error Mixpanel: ', e);
    }
  };
  public trackOpenModal = (modalId: string, path?: string) => {
    try {
      mixpanel.track(`Opened ${modalId}`, {
        modalId: modalId,
        path: path,
      });
    } catch (e) {
      console.error('Error Mixpanel: ', e);
    }
  };

  public trackRatingClick = (rating: MATCHING_MODE, apartmentId: string) => {
    try {
      mixpanel.track('Rating Click', {
        Rating: rating,
        ApartmentId: apartmentId,
      });
    } catch (e) {
      console.error('Error Mixpanel: ', e);
    }
  };
}

export default MixpanelTrackingService;
