import { createContext, Dispatch, SetStateAction } from 'react';

export interface ISnackbarContextProps {
  showSubscriptionDrawer: boolean;
  setShowSubscriptionDrawer: Dispatch<SetStateAction<boolean>>;
  showSubscriptionLpDrawer: boolean;
  setShowSubscriptionLpDrawer: Dispatch<SetStateAction<boolean>>;
}

const SubscriptionDrawerContext = createContext<ISnackbarContextProps>({
  showSubscriptionDrawer: false,
  setShowSubscriptionDrawer: null,
  showSubscriptionLpDrawer: false,
  setShowSubscriptionLpDrawer: null,
});

export default SubscriptionDrawerContext;
