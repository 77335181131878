import { FC, useEffect, useState } from 'react';
import PageLayout from 'component/layouts/PageLayout';
import GridSwitch from 'component/molecules/GridSwitch';
import LOCAL_STORAGE_KEYS from 'core/enum/local-storage.enum';
import { useParams } from 'react-router-dom';
import useApartment from 'core/hook/apartment.hook';
import { Drawer, Grid } from '@mui/material';
import ApartmentApplicationsGrid from 'component/organisms/ApartmentApplicationsGrid';
import { APPLICATION_STATE } from '@wohnsinn/ws-ts-lib';
import CTACard from 'component/molecules/Cards/CTACard';
import { useTranslation } from 'react-i18next';
import CTAButton, { BUTTON_STYLE } from 'component/atoms/Buttons/CTAButton';
import { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import ApplicantsTable from 'component/organisms/Tables/ApplicantsTable';
import { sortApplicationsByIncome } from 'core/helper/sort-applications-helper';
import filterByAppliedFilters from 'core/helper/filter-applications';
import ApplicationFilter, { APPLIED_FILTER, INITIAL_FILTER_STATE } from 'component/organisms/ApplicationFilter';
import ArrowBox from 'component/molecules/ArrowBox';
import DrawerWrapper from 'component/molecules/DrawerWrapper';
import useApartmentApplications, { ISortedApplications } from 'core/hook/apartment-applications.hook';
import ReactJoyride, { ACTIONS, CallBackProps, Step } from 'react-joyride';
import LoadingAnimation from '../../../../component/atoms/LoadingAnimation';
import { faPaperPlane } from '@fortawesome/pro-light-svg-icons';
import { ROUTES } from 'core/const/routes';
import useWindowSize from 'core/hook/windowsize.hook';
import SearchInput from 'component/atoms/formElement/SearchInput';

const ApartmentApplicationsView: FC = () => {
  const { apartmentId } = useParams<{ apartmentId: string }>();
  const { apartment } = useApartment(apartmentId);
  const [joyrideActive, setJoyrideActive] = useState<boolean>(false);
  const { data: applications, isLoading } = useApartmentApplications(apartmentId);

  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const [selectedApplicationState, setSelectedApplicationState] = useState<APPLICATION_STATE>(APPLICATION_STATE.NEW);
  const [selectedApplications, setSelectedApplications] = useState(
    getSelectedApplicationList(applications?.sortedApplications, APPLICATION_STATE.NEW)
  );
  const [sortAsc, setSortAsc] = useState(null);
  const { isSmallerMd } = useWindowSize();

  const [showGrid, setShowGrid] = useState<boolean>(() => {
    const storedValue = localStorage.getItem(LOCAL_STORAGE_KEYS.APARTMENT_APPLICATION_SHOW_GRID);
    return storedValue !== null ? storedValue === 'true' : isSmallerMd;
  });

  const [filterMenuIsOpen, setFilterMenuIsOpen] = useState<boolean>(false);
  const [appliedFilter, setAppliedFilter] = useState<APPLIED_FILTER>(INITIAL_FILTER_STATE);
  const HAS_SEEN_APPLICATIONS_JOYRIDE =
    localStorage.getItem(LOCAL_STORAGE_KEYS.HAS_SEEN_APPLICATIONS_JOYRIDE) === 'true';

  useEffect(() => {
    setSelectedApplications(getSelectedApplicationList(applications?.sortedApplications, selectedApplicationState));
  }, [applications?.sortedApplications, selectedApplicationState]);

  const sortApplicantByIncome = () => {
    const sortedList = sortApplicationsByIncome([...selectedApplications], sortAsc);
    setSelectedApplications([...sortedList]);
    setSortAsc(!sortAsc);
  };

  function countDifferentProperties(obj1: any, obj2: any) {
    let count = 0;

    for (const key in obj1) {
      if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
        if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
          count += countDifferentProperties(obj1[key], obj2[key]);
        } else if (obj1[key] !== obj2[key]) {
          count++;
        }
      } else {
        count++;
      }
    }

    return count;
  }

  const APPLIED_FILTER_COUNT = countDifferentProperties(INITIAL_FILTER_STATE, appliedFilter);

  useEffect(() => {
    let list = [...getSelectedApplicationList(applications?.sortedApplications, selectedApplicationState)];
    for (const [key, value] of Object.entries(appliedFilter)) {
      list = filterByAppliedFilters(list, key, value);
    }

    setSelectedApplications(list);
  }, [selectedApplicationState, appliedFilter]);

  function findMatchingObjects(searchString: string) {
    let newApplications = [...getSelectedApplicationList(applications?.sortedApplications, selectedApplicationState)];
    newApplications = newApplications.filter((application) => {
      const name = `${application.tenantProfile.personalInformation.firstName} ${application.tenantProfile.personalInformation.lastName}`;
      return name.toLowerCase().includes(searchString.toLowerCase());
    });

    setSelectedApplications([...newApplications]);
  }

  ///// JOYRIDE
  useEffect(() => {
    if (!isLoading && selectedApplications?.length) {
      setJoyrideActive(true);
    }
  }, [isLoading, selectedApplications]);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action } = data;
    if (action === ACTIONS.RESET) {
      setJoyrideActive(false);
      localStorage.setItem(LOCAL_STORAGE_KEYS.HAS_SEEN_APPLICATIONS_JOYRIDE, 'true');
    }
  };

  const switchJoyRide: Step = {
    target: '#grid-switch',
    content: t('gridSwitch.text'),
    title: t('gridSwitch.title'),
    disableBeacon: true,
    placement: 'bottom',
  };
  const ratingsJoyRide: Step = {
    target: '.landlord-rating-buttons',
    content: t('sortFirst.text'),
    title: t('sortFirst.title'),
    disableBeacon: true,
    placement: 'top',
  };
  const fullTour: Step[] = [switchJoyRide, ratingsJoyRide];
  ///// JOYRIDE END

  return (
    <PageLayout
      showPageTitle={true}
      showTitleBackButton={true}
      pageTitle={`${apartment?.mainInformation?.address.street} ${apartment?.mainInformation?.address.houseNumber}, ${apartment?.mainInformation?.address?.postalCode} ${apartment?.mainInformation?.address?.city}`}
      titleButton={
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <GridSwitch
            defaultState={showGrid}
            onClick={(state: boolean) => {
              setShowGrid(state);
              localStorage.setItem(LOCAL_STORAGE_KEYS.APARTMENT_APPLICATION_SHOW_GRID, `${state}`);
            }}
          />
        </div>
      }
    >
      <ReactJoyride
        continuous={true}
        run={joyrideActive && !HAS_SEEN_APPLICATIONS_JOYRIDE}
        callback={handleJoyrideCallback}
        steps={fullTour}
        hideBackButton={true}
        disableScrolling={true}
        styles={{
          options: {
            primaryColor: '#000',
            textColor: '#000',
          },
        }}
        locale={{
          last: t('understand'),
          next: t('understand'),
        }}
      />
      <Drawer anchor={'right'} open={filterMenuIsOpen} onClose={() => setFilterMenuIsOpen(false)}>
        <DrawerWrapper noTitleMargin onClose={() => setFilterMenuIsOpen(false)} title={'Filter'}>
          <ApplicationFilter
            resetFilter={APPLIED_FILTER_COUNT > 0 ? () => setAppliedFilter(INITIAL_FILTER_STATE) : null}
            setAppliedFilters={setAppliedFilter}
            appliedFilters={appliedFilter}
            onClose={() => setFilterMenuIsOpen(false)}
          />
        </DrawerWrapper>
      </Drawer>

      <Grid container spacing={2} rowSpacing={4} justifyContent={'space-between'}>
        <Grid item xs={12}>
          <ArrowBox
            boxes={[
              {
                label: t('unsorted'),
                count: applications?.sortedApplications?.newAndOpened?.length ?? 0,
                active: selectedApplicationState == APPLICATION_STATE.NEW,
                state: APPLICATION_STATE.NEW,
                notification: applications?.unreadNotifications?.newAndOpened,
              },
              {
                label: t('notInterested'),
                img: t('icons.heart_nope'),
                count: applications?.sortedApplications?.nope?.length ?? 0,
                active: selectedApplicationState == APPLICATION_STATE.SORTED_NOPE,
                state: APPLICATION_STATE.SORTED_NOPE,
                notification: applications?.unreadNotifications?.nope,
              },
              {
                label: t('maybe'),
                img: t('icons.heart_maybe'),
                count: applications?.sortedApplications?.maybe?.length ?? 0,
                active: selectedApplicationState == APPLICATION_STATE.SORTED_MAYBE,
                state: APPLICATION_STATE.SORTED_MAYBE,
                notification: applications?.unreadNotifications?.maybe,
              },
              {
                label: t('favorites'),
                img: t('icons.heart_like'),
                count: applications?.sortedApplications?.like?.length ?? 0,
                active: selectedApplicationState == APPLICATION_STATE.SORTED_LIKE,
                state: APPLICATION_STATE.SORTED_LIKE,
                notification: applications?.unreadNotifications?.like,
              },
              {
                label: t('invited'),
                count: applications?.sortedApplications?.invited?.length ?? 0,
                active: selectedApplicationState == APPLICATION_STATE.INVITED,
                state: APPLICATION_STATE.INVITED,
                notification: applications?.unreadNotifications?.invited,
              },
              {
                label: t('appointmentConfirmed'),
                count: applications?.sortedApplications?.appointmentConfirmed?.length ?? 0,
                active: selectedApplicationState == APPLICATION_STATE.APPOINTMENT_CONFIRMED,
                state: APPLICATION_STATE.APPOINTMENT_CONFIRMED,
                notification: applications?.unreadNotifications?.appointmentConfirmed,
              },
            ]}
            onClick={(applicationState) => setSelectedApplicationState(applicationState)}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container spacing={2} rowSpacing={4} justifyContent={'space-between'}>
            <Grid item xs={12} md={4}>
              <SearchInput onChange={findMatchingObjects} placeholder={t('searchName')} name={'tenant'} />
            </Grid>
            <Grid item xs={12} md={3} display={'flex'} gap={2}>
              {
                //TODO soll momentan ausgeblendet werden, hier kann es allerdings sein, dass wir ihn bald in etwas anderem Design zurückholen.
                /*<CTAButton
                expand={'block'}
                icon={faBarsFilter}
                buttonStyle={BUTTON_STYLE.SECONDARY}
                buttonText={`Filter (${APPLIED_FILTER_COUNT})`}
                onClick={() => setFilterMenuIsOpen(true)}
                rounded={false}
              />*/
              }
              <CTAButton
                expand={'block'}
                icon={faPaperPlane}
                buttonStyle={BUTTON_STYLE.PRIMARY}
                buttonText={`Massennachricht senden`}
                link={r(ROUTES.landlordRoutes.apartment.sendMessage.path).replace(':apartmentId', apartment?.id)}
                rounded={false}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {isLoading ? (
            <LoadingAnimation />
          ) : selectedApplications && selectedApplications.length ? (
            showGrid ? (
              <ApartmentApplicationsGrid applications={selectedApplications} />
            ) : (
              <ApplicantsTable
                sortAsc={sortAsc}
                sortApplicantByIncome={sortApplicantByIncome}
                applications={selectedApplications}
              />
            )
          ) : (
            <Grid container justifyContent={'center'}>
              <Grid item xs={12} md={5} style={{ marginTop: 16 }}>
                <CTACard
                  headlineSize={HEADLINE_SIZE.H3}
                  title={APPLIED_FILTER_COUNT > 0 ? t('noApplications.filter.title') : t('noApplications.title')}
                  text={APPLIED_FILTER_COUNT > 0 ? t('noApplications.filter.text') : t('noApplications.text')}
                  imgSrc={t('pictogram.accountPictogram')}
                  imgAltText={t('bookmarkIcon')}
                  ctaText={APPLIED_FILTER_COUNT > 0 ? 'Filter zurücksetzen' : undefined}
                  onClick={APPLIED_FILTER_COUNT > 0 ? () => setAppliedFilter(INITIAL_FILTER_STATE) : undefined}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </PageLayout>
  );
};

function getSelectedApplicationList(sortedApplications: ISortedApplications, applicationState: APPLICATION_STATE) {
  if (sortedApplications) {
    switch (applicationState) {
      case APPLICATION_STATE.NEW:
        return sortedApplications?.newAndOpened;
      case APPLICATION_STATE.OPENED:
        return sortedApplications?.newAndOpened;
      case APPLICATION_STATE.SORTED_LIKE:
        return sortedApplications?.like;
      case APPLICATION_STATE.SORTED_MAYBE:
        return sortedApplications?.maybe;
      case APPLICATION_STATE.SORTED_NOPE:
        return sortedApplications?.nope;
      case APPLICATION_STATE.INVITED:
        return sortedApplications?.invited;
      case APPLICATION_STATE.APPOINTMENT_CONFIRMED:
        return sortedApplications?.appointmentConfirmed;
    }
  }
  return [];
}

export default ApartmentApplicationsView;
