import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'core/const/routes';
import { Grid } from '@mui/material';
import { IMAGES } from 'core/const/images';
import LandingPageLayout from '../../../component/layouts/LandingPageLayout';
import Text, { TEXT_COLOR, TEXT_TYPE } from '../../../component/atoms/typographie/Text';
import ContactSection from '../../../component/organisms/landing-page-sections/ContactSection';
import { Swiper, SwiperSlide } from 'swiper/react';
import TeamCard, { ITeamMember } from '../../../component/atoms/Card/TeamCard';

const AboutUsView = () => {
  const { t: r } = useTranslation('routes');
  const { t: i } = useTranslation('images');

  return (
    <LandingPageLayout pageTitle={r(ROUTES.landingPageRoutes.about.title)}>
      <Grid container className={'ws-full-width-bg ws-full-width-bg--white'}>
        <Grid item xs={12} paddingTop={{ xs: 4, md: 10 }} paddingBottom={{ xs: 8, md: 20 }}>
          <Headline size={HEADLINE_SIZE.HERO} align={'center'}>
            Über Wohnsinn
          </Headline>
        </Grid>
      </Grid>

      <Grid
        container
        columnSpacing={{ xs: 4, md: 8 }}
        className={'ws-full-width-bg ws-full-width-bg--white'}
        paddingBottom={{ xs: 4, md: 10 }}
      >
        <Grid item xs={12} md={6}>
          <Text
            color={TEXT_COLOR.TEXT_COLOR_PRIMARY}
            bold
            type={TEXT_TYPE.TEXT_TYPE_BODY}
            tag={'span'}
            align={'center'}
          >
            We&apos;re on a mission
          </Text>
          <Headline size={HEADLINE_SIZE.H1}>Wie alles begann...</Headline>
          <Text type={TEXT_TYPE.TEXT_TYPE_LANDING}>
            Während unseres Studiums in China teilten wir, Felix Weiss und Christian Philippi, uns ein Zimmer im
            Studentenwohnheim und entwickelten dort die ersten Ideen für ein gemeinsames Startup. Nach dem Studium
            trennten sich zunächst unsere Wege: Felix, der den zeitaufwendigen Vermietungsprozess aus seiner
            Hausverwalter-Familie kennt, sammelte internationale Vertriebserfahrung in Paris und Zürich. Christian,
            absolvierte seinen Master in Marketing in Boston und kämpfte in vier Jahren mit neun Umzügen. Getrieben von
            unseren persönlichen Erfahrungen und dem Willen, etwas zu verändern, gründeten wir Wohnsinn und sind 2023 in
            Köln an den Start gegangen. 🚀
          </Text>
        </Grid>
        <Grid item xs={12} md={6}>
          <img src={i(IMAGES.images.felixChris.src)} alt={i(IMAGES.images.felixChris.alt)} />
        </Grid>
      </Grid>

      <Grid container paddingTop={{ xs: 4, md: 10 }} className={'ws-full-width-bg ws-full-width-bg--white'}>
        <Grid item xs={12} md={6}>
          <Headline size={HEADLINE_SIZE.H1}>Das Team hinter Wohnsinn</Headline>
          <Text type={TEXT_TYPE.TEXT_TYPE_LANDING}>
            Wir sind stets auf der Suche nach motivierten und leidenschaftlichen Talenten für unser Team.
          </Text>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        className={'ws-full-width-bg ws-full-width-bg--white'}
        paddingTop={{ xs: 4, md: 10 }}
        paddingBottom={{ xs: 4, md: 10 }}
      >
        <Swiper
          slidesPerView={3.5}
          breakpoints={{
            320: { slidesPerView: 1.3, centeredSlides: true },
            576: { slidesPerView: 1.7, centeredSlides: false },
            768: { slidesPerView: 2.3, centeredSlides: false },
            993: { slidesPerView: 3.5, centeredSlides: false },
          }}
          spaceBetween={16}
        >
          {TEAM_MEMBER.map((member, index) => (
            <SwiperSlide key={`${member?.name}-${index}`} style={{ padding: '3px', height: 'auto' }}>
              <TeamCard
                position={member.position}
                name={member.name}
                image={member.image}
                socialLink={member?.socialLink}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Grid>

      <Grid container className={'ws-full-width-bg ws-full-width-bg--white'}>
        <Grid item xs={12} paddingTop={{ xs: 4, md: 10 }} paddingBottom={{ xs: 4, md: 10 }}>
          <ContactSection headline={'Du möchtest Teil von Wohnsinn werden?'} />
        </Grid>
      </Grid>
    </LandingPageLayout>
  );
};

export default AboutUsView;

const TEAM_MEMBER: ITeamMember[] = [
  {
    name: 'Felix Weiss',
    position: 'Gründer',
    image: IMAGES.team.felix,
    socialLink: 'https://www.linkedin.com/in/fxweiss/',
  },
  {
    name: 'Christian Philippi',
    position: 'Gründer',
    image: IMAGES.team.christian,
    socialLink: 'https://www.linkedin.com/in/christian-philippi/',
  },
  {
    name: 'Niklas Breitzmann',
    position: 'Full-Stack Entwickler',
    image: IMAGES.team.niklas,
    socialLink: 'https://www.linkedin.com/in/niklas-breitzmann-b55a26219/',
  },
  {
    name: 'Dominik Amrugiewicz',
    position: 'Full-Stack Entwickler',
    image: IMAGES.team.dom,
    socialLink: 'https://www.linkedin.com/in/amrugiewicz-dom/',
  },
];
