import { useEffect, useState } from 'react';
import { Grid, Slider as MuiSlider, Typography } from '@mui/material';
import { SUBMIT_BUTTON_MODE } from '../../../../core/enum/submit-button-mode.enum';
import { useTunnelContext } from 'core/context/create-tenant-from-apartment-apply-tunnel.context';
import SearchTunnelStepLayout from 'component/organisms/searchTunnel/SearchTunnelStepLayout';
import { useForm, Controller } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useTranslation } from 'react-i18next';

const INCOME_STEP = 'INCOME_STEP';

const IncomeStep = () => {
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { household, setHousehold, goToNextStep, goToPreviousStep } = useTunnelContext();
  const { t } = useTranslation('common');

  const { control, handleSubmit, formState, watch } = useForm<{
    monthlyIncome: number;
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: joiResolver(
      Joi.object({
        monthlyIncome: Joi.number().min(100).max(6000).required(),
      })
    ),
    defaultValues: {
      monthlyIncome: household?.monthlyIncome ?? 500,
    },
  });

  const monthlyIncome = watch('monthlyIncome');

  const handleFormSubmit = (data: { monthlyIncome: any }) => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    setHousehold({ ...household, monthlyIncome: data.monthlyIncome });
    goToNextStep();
    console.log('Submitting the form');
  };

  useEffect(() => {
    const preventSwipeNavigation = (event: TouchEvent) => {
      if (event.touches.length > 1 || event.changedTouches[0].clientX > 0) {
        event.preventDefault();
      }
    };

    window.addEventListener('touchmove', preventSwipeNavigation, { passive: false });

    return () => {
      window.removeEventListener('touchmove', preventSwipeNavigation);
    };
  }, []);

  return (
    <SearchTunnelStepLayout
      formId={INCOME_STEP}
      control={control}
      disabled={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING || !formState.isValid}
      buttonSubmitMode={buttonSubmitMode}
      onSubmit={handleSubmit(handleFormSubmit, console.error)}
      title={'Wie hoch ist dein Einkommen?'}
      backAction={() => goToPreviousStep()}
    >
      <Grid
        container
        maxWidth={null}
        marginX={'auto'}
        paddingTop={{ xs: 6, md: 8 }}
        direction="column"
        alignItems="center"
      >
        <Typography variant="h4" textAlign="center">
          {monthlyIncome} €
        </Typography>

        <Controller
          name="monthlyIncome"
          control={control}
          render={({ field }) => (
            <MuiSlider
              {...field}
              value={field.value}
              min={100}
              step={100}
              max={6000}
              onChange={(event, newValue) => field.onChange(newValue)}
              sx={{ touchAction: 'none' }} // Prevent swipe gesture navigation
            />
          )}
        />
      </Grid>
    </SearchTunnelStepLayout>
  );
};

export default IncomeStep;
