import { collection, doc, getDocs, getFirestore, query, updateDoc, where, writeBatch } from 'firebase/firestore';
import { INews } from '@wohnsinn/ws-ts-lib';

class NewsService {
  constructor() {}

  public async updateNews(newsId: string, data: Partial<INews>): Promise<any> {
    const db = getFirestore();
    const newsDoc = doc(db, 'news', newsId);
    await updateDoc(newsDoc, data);
  }

  public async batchUpdateNewsSeenByLandlord(landlordId: string) {
    const db = getFirestore();
    const batchSize = 500;
    const newsCollection = collection(db, 'news');
    const newsQuery = query(newsCollection, where('editorList', 'array-contains', landlordId));
    const newsSnapshot = await getDocs(newsQuery);
    const documentIds = newsSnapshot.docs.map((doc) => doc.id);

    let currentBatch = writeBatch(db);
    let operationCount = 0;

    for (let i = 0; i < documentIds.length; i++) {
      const docRef = doc(db, 'news', documentIds[i]);
      currentBatch.update(docRef, { seenByLandlord: true });
      operationCount++;

      // Commit and reset the batch if batch size limit is reached or on the last document
      if (operationCount === batchSize || i === documentIds.length - 1) {
        await currentBatch.commit();
        console.log(`Batch committed for ${operationCount} documents`);

        // Reset batch and operation count for next set of documents
        currentBatch = writeBatch(db);
        operationCount = 0;
      }
    }
  }
}

export default NewsService;
