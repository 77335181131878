import ModalWrapper, { IModalWrapperButtonProps } from '../ModalWrapper';
import { useTranslation } from 'react-i18next';
import Text from 'component/atoms/typographie/Text';

const CreateOrUploadSchufaModal = () => {
  const { t } = useTranslation('common', { keyPrefix: 'component.molecules.modals.createOrUploadSchufa' });

  const onSelectSchufaUpload = (): void => {
    document.getElementById('schufaCheckFileInput').click();
  };

  const MODAL_BUTTONS: IModalWrapperButtonProps = {
    primary: {
      buttonText: t('uploadSchufa'),
      onClick: onSelectSchufaUpload,
    },
  };

  return (
    <ModalWrapper title={t('title')} buttons={MODAL_BUTTONS}>
      <Text align={'center'}>
        Wähle aus ob du einen bestehenden Schufa-Auszug hochladen oder einen Bonitätscheck durchführen möchtest.
      </Text>
    </ModalWrapper>
  );
};

export default CreateOrUploadSchufaModal;
