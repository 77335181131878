import Dropdown from 'component/atoms/formElement/Dropdown';
import InputText from 'component/atoms/formElement/InputText';
import ProfileAvatar from 'component/molecules/ProfileAvatar';
import SnackBarContext from 'core/context/snackbar.context';
import TextInput from 'component/atoms/formElement/InputText';
import { FC, useContext, useEffect, useState } from 'react';
import { FormContext } from 'core/context/form.context';
import { Grid, InputLabel } from '@mui/material';
import { FIRESTORE_COLLECTION_PATH, IApartmentContactPerson, ISelectOption, MEDIA_TYPE } from '@wohnsinn/ws-ts-lib';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { BOOLEAN_SELECT_OPTIONS, getTranslatedOptions } from 'core/const/select-options';
import { useForm } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { wohnsinnServices } from 'App';
import InputOptionList, { RADIO_INPUT_COLUMNS_COUNT } from 'component/atoms/formElement/InputOptionList';
import { TEXT_COLOR } from 'component/atoms/typographie/Text';
import useFormErrorHook from 'core/hook/use-form-error.hook';
import { ICreateApartmentTunnel } from 'view/landlord/apartment/ApartmentCreateView';
import useApartment from 'core/hook/apartment.hook';
import { ROUTES } from 'core/const/routes';
import { useNavigate } from 'react-router-dom';
import FormNavigation from 'component/molecules/FormNavigation';
import FormHeader from 'component/atoms/FormHeader';
import BackButton from 'component/atoms/Buttons/BackButton';
import useWindowSize from 'core/hook/windowsize.hook';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { APARTMENT_CONTACT_PERSON_FORM_SCHEMA } from 'core/schemas/apartment/apartment-contact-person-form-schema';
import FormErrorBox from 'component/molecules/FormErrorBox';
import useErrorHandling from 'core/hook/errorhandling.hook';

const ApartmentContactPersonsForm: FC<ICreateApartmentTunnel> = ({ isTunnelView }) => {
  const { apartment } = useApartment();
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const { apartmentService, firebaseStorageService } = wohnsinnServices;
  const queryClient = useQueryClient();
  const { handleSnackBar } = useContext(SnackBarContext);
  const { handleError } = useFormErrorHook('ApartmentEquipmentForm');
  const navigate = useNavigate();
  const { isSmallerMd } = useWindowSize();
  const { errorObject, onErrorHandler } = useErrorHandling();

  const PROFILE_IMAGE_PATH = apartment?.creatorId
    ? `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
        .replace('{uid}', apartment.creatorId)
        .replace('{landlordId}', apartment.creatorId)}/${apartment.id}/contact-person.png`
    : '';

  const {
    control,
    reset,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm<IApartmentContactPerson>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: apartment?.contactPerson ?? null,
    resolver: joiResolver(Joi.object(APARTMENT_CONTACT_PERSON_FORM_SCHEMA)),
  });

  useEffect(() => {
    reset(apartment?.contactPerson);
  }, [apartment]);

  const onSubmit = async (): Promise<boolean | void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);

    try {
      await apartmentService.updateApartment({
        data: { contactPerson: watch() },
        creatorId: apartment.creatorId,
        apartmentId: apartment.id,
      });

      await queryClient.invalidateQueries({ queryKey: ['apartments'] });
      handleSnackBar('toast.success.saved', 'success');
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUCCESS);
      if (isTunnelView) {
        navigate(r(ROUTES.landlordRoutes.apartment.create.overview.path).replace(':apartmentId', apartment?.id));
      } else {
        navigate(r(ROUTES.landlordRoutes.apartment.overview.path).replace(':apartmentId', apartment?.id));
      }
    } catch (e) {
      handleError(e);
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
    }
  };

  const handleProfileImageUpload = async (photoUrl: string) => {
    try {
      const photo = {
        alt: `contact-person-profile-image`,
        creatorId: apartment.creatorId,
        id: '',
        mediaType: MEDIA_TYPE.IMAGE,
        updatedAt: new Date(),
        url: photoUrl,
      };

      await apartmentService.updateApartmentContactPersonsProfileImage(apartment, photo);
      setValue('photo', photo);
    } catch (e) {
      console.error('Error on handleProfileImageUpload: ', e);
    }
  };

  const deleteProfileImage = async () => {
    try {
      await apartmentService.updateApartmentContactPersonsProfileImage(apartment, null);
      setValue('photo', null);
      await firebaseStorageService.deleteFiles([PROFILE_IMAGE_PATH]);
      handleSnackBar('toast.profileImage.deleted', 'success');
    } catch (e) {
      console.error('Error on deleteProfileImage', e);
    }
  };

  return (
    <FormContext.Provider value={{ control }}>
      <form
        onSubmit={handleSubmit(onSubmit, onErrorHandler)}
        autoComplete={'off'}
        id={'apartmentContactPersonsForm'}
        noValidate
      >
        {!isTunnelView && !isSmallerMd && (
          <div style={{ paddingBottom: '1rem' }}>
            <BackButton />
          </div>
        )}
        {Object.keys(errors).length > 0 && <FormErrorBox errors={errorObject} />}
        <FormHeader title={r(ROUTES.landlordRoutes.apartment.contactPerson.title)} />
        <div style={{ paddingBottom: '2rem' }}>
          <InputLabel color={'secondary'}>Profilbild im Inserat</InputLabel>
          <ProfileAvatar
            storagePath={PROFILE_IMAGE_PATH}
            onUpload={handleProfileImageUpload}
            onDelete={deleteProfileImage}
            photoUrl={apartment?.contactPerson?.photo?.url}
          />
        </div>
        <Grid container md={6} className={'form-with-navigation'}>
          <Grid item xs={12}>
            <Dropdown
              label={t('salutation.label')}
              name="salutation"
              optionList={getTranslatedOptions(SALUTATION_TYPES, t)}
            />
          </Grid>

          <Grid item xs={12}>
            <InputText label={t('firstName.label')} name={'firstName'} required={true} />
          </Grid>

          <Grid item xs={12}>
            <InputText label={t('lastName.label')} name={'name'} required={true} />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              required={true}
              label={t('directEmail.label')}
              placeholder={t('directEmail.placeholder')}
              name={'directEmail'}
              type={'email'}
            />
          </Grid>
          <Grid item xs={12}>
            <InputText type={'tel'} label={t('mobileNumber.label') + ' '} name={'mobile'} required={true} />
          </Grid>

          <Grid item xs={12}>
            <InputOptionList
              required={true}
              label={t('addressRelease.label')}
              mode={'radio'}
              name={'addressRelease'}
              options={getTranslatedOptions(BOOLEAN_SELECT_OPTIONS, t)}
              columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
            />
          </Grid>
        </Grid>
      </form>
      <FormNavigation
        buttonSubmitMode={buttonSubmitMode}
        disabled={false}
        formId={'apartmentContactPersonsForm'}
        submitButtonText={isTunnelView ? t('saveAndContinue') : t('save')}
        secondaryButtonAction={() => navigate(-1)}
        secondaryButtonColor={TEXT_COLOR.TEXT_COLOR_ACCENT}
        secondaryButtonText={t('back')}
        showSecondaryButtonMobile={isTunnelView}
      />
    </FormContext.Provider>
  );
};

export const SALUTATION_TYPES: ISelectOption<string>[] = [
  { value: 'Frau', label: 'Frau' },
  { value: 'Herr', label: 'Herr' },
  { value: 'Diverse', label: 'Diverse' },
  { value: 'Firma', label: 'Firma' },
];

export default ApartmentContactPersonsForm;
