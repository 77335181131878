export const convertToNiceChatDate = (date: Date, showTime?: boolean): string => {
  const diff = (new Date().getTime() - date.getTime()) / 1000;
  const dayDiff = Math.floor(diff / 86400);

  if (isNaN(dayDiff) || dayDiff < 0 || dayDiff >= 31) return null;

  // Wenn showTime false ist, nur Daten ab 1 Tag zurückgeben
  if (!showTime && dayDiff < 1) {
    return 'Heute';
  }

  return (
    (dayDiff == 0 &&
      showTime &&
      ((diff < 60 && 'Jetzt') ||
        (diff < 120 && 'vor einer Minute') ||
        (diff < 3600 && 'vor ' + Math.floor(diff / 60) + ' Minuten') ||
        (diff < 7200 && 'vor einer Stunde') ||
        (diff < 86400 && 'vor ' + Math.floor(diff / 3600) + ' Stunden'))) ||
    (dayDiff == 1 && 'Gestern') ||
    (dayDiff < 7 && 'vor ' + dayDiff + ' Tagen') ||
    (dayDiff < 31 && 'vor ' + Math.ceil(dayDiff / 7) + ' Wochen')
  );
};

export const getTimeFromDate = (date: Date): string => {
  const hours = date.getHours().toString().padStart(2, '0'); // Stunden im 24-Stunden-Format
  const minutes = date.getMinutes().toString().padStart(2, '0'); // Minuten

  return `${hours}:${minutes}`; // Uhrzeit im Format HH:mm
};

export const getTimeOrDate = (date: Date): string => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Setzt den heutigen Tag auf Mitternacht, um nur das Datum zu vergleichen

  if (date < today) {
    // Gibt das Datum im Format DD.MM.YY zurück, wenn das Datum älter ist als heute
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(-2); // Letzte zwei Ziffern des Jahres
    return `${day}.${month}.${year}`;
  }

  // Gibt die Uhrzeit im Format HH:mm zurück, wenn das Datum heute ist
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
};
