import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import ModalContext from 'core/context/modal.context';
import { ROUTES } from 'core/const/routes';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import CallToActionScreen from 'component/molecules/CallToActionScreen';
import { IMAGES } from 'core/const/images';
import { FormContext } from 'core/context/form.context';
import TextInput from 'component/atoms/formElement/InputText';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import SnackBarContext from 'core/context/snackbar.context';
import { wohnsinnServices } from 'App';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { AUTH_PROVIDER, EMAIL_SCHEMA, ICheckIfUserExistsResponse, USER_TYPE } from '@wohnsinn/ws-ts-lib';
import FB_FUNCTION_URLS from 'core/const/fb-function-names';
import ModalWrapper from 'component/modals/ModalWrapper';
import TenantFilterParamsContext from 'core/context/tenant-filter-params.context';
import Text, { TEXT_COLOR } from 'component/atoms/typographie/Text';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import AcceptPrivacyInfoText from 'component/atoms/AcceptPrivacyInfoText';
import AuthProviderLoginButtons from 'component/molecules/AuthProviderLoginButtons';
import styles from 'component/forms/signIn/RegistrationFormWrapper/RegistrationFormWrapper.module.scss';
import SplitLine from 'component/atoms/SplitLine';
import CTAButton, { getSubmitIcon } from 'component/atoms/Buttons/CTAButton';
import Joi from 'joi';

export const USER_CREDENTIALS_FORM_SCHEMA = Joi.object({
  email: EMAIL_SCHEMA.required(),
  createLandlordAccount: Joi.boolean().required(),
});

const SignUpUserModal = () => {
  const { closeModal, modalData, openModal } = useContext(ModalContext);
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const { t: v } = useTranslation('common', { keyPrefix: 'component.organisms.forms.signIn.RegistrationFormWrapper' });
  const navigate = useNavigate();
  const [isCreatingAccount, setIsCreatingAccount] = useState<boolean>(false);
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { handleSnackBar } = useContext(SnackBarContext);
  const isWogeEnvironment = process.env.REACT_APP_ENVIRONMENT === 'woge';
  const { tenantFilterParams } = useContext(TenantFilterParamsContext);
  const { firebaseFunctionsService, registrationRequestService } = wohnsinnServices;
  const { t: f } = useTranslation('common', { keyPrefix: 'view.general.CreateAccountView' });

  const {
    control,
    handleSubmit,
    watch,
    formState: { isValid },
  } = useForm<{
    email: string;
    createLandlordAccount: boolean;
  }>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(USER_CREDENTIALS_FORM_SCHEMA),
    defaultValues: {
      email: undefined,
      createLandlordAccount: !(isWogeEnvironment || modalData?.isProbablyTenant),
    },
  });

  /**
   * Check if account with given email exists
   * If not send create a user registration request
   * If exists, output info message
   */
  const handleFormSubmit = async () => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    const email = watch().email;
    const createLandlordProfile = watch().createLandlordAccount;

    try {
      // Check if account with given email exists
      const response: { data: ICheckIfUserExistsResponse } = (await firebaseFunctionsService.callFbFunction(
        FB_FUNCTION_URLS.user.checkIfUserExists,
        {
          params: { email },
        }
      )) as { data: ICheckIfUserExistsResponse };

      // if no account exists, create a user registration request
      if (!response.data.userExists) {
        setIsCreatingAccount(true);
        await registrationRequestService.createUserRegistrationRequest(
          email,
          createLandlordProfile ? USER_TYPE.LANDLORD : USER_TYPE.TENANT,
          modalData?.referrer,
          tenantFilterParams
        );

        // show cool ui
        setTimeout(() => {
          setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
          navigate(r(ROUTES.staticRoutes.registration.activateAccount.path).replace(':email', email));
          closeModal();
        }, 1500);
      } else {
        // if email already exists, check an account provider and output info message
        switch (response.data.provider) {
          case AUTH_PROVIDER.PASSWORD:
            handleSnackBar('toast.signIn.emailAccountExistsAlready', 'info');
            break;
          case AUTH_PROVIDER.GOOGLE:
            handleSnackBar('toast.signIn.googleAccountExistsAlready', 'info');
            break;
          default:
            handleSnackBar('toast.signIn.emailAccountExistsAlready', 'info');
        }
        setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
      }
    } catch (e) {
      console.error('e', e);
      handleSnackBar('toast.signIn.unknown', 'info');
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
    }
  };

  const Footer = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', gap: 8 }}>
        <Text tag={'span'}>{v('alreadyHaveAnAccount')}</Text>
        <button type={'button'} onClick={() => openModal({ id: MODAL_IDENTIFIER.SIGN_IN })}>
          <Text tag={'span'} bold color={TEXT_COLOR.TEXT_COLOR_PRIMARY}>
            {v('loginNow')}
          </Text>
        </button>
      </div>
    );
  };

  return (
    <ModalWrapper
      title={modalData?.isProbablyTenant ? 'Mieterkonto erstellen' : 'Vermieterkonto erstellen'}
      modalFooter={isCreatingAccount ? null : <Footer />}
    >
      <Grid container>
        <Grid item xs={12}>
          {isCreatingAccount ? (
            <CallToActionScreen text={f('creatingAccountMessage')} img={IMAGES.icons.heart} animateImg={true} />
          ) : (
            <FormContext.Provider value={{ control }}>
              <form onSubmit={handleSubmit(handleFormSubmit, console.error)}>
                <TextInput label={t('email.label')} name={'email'} type={'email'} required />

                <button type={'submit'} className={'hidden'}>
                  submit
                </button>
                <CTAButton
                  type={'submit'}
                  disabled={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING || !isValid}
                  icon={getSubmitIcon(buttonSubmitMode)}
                  spinIcon={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING}
                  expand={'block'}
                  buttonText={t('registerButtonText')}
                  rounded={false}
                />
              </form>
              <div className={styles.splitLineWrapper}>
                <SplitLine size={'small'} text={t('or')}></SplitLine>
              </div>
              <AuthProviderLoginButtons
                isRegistration
                disabled={!isValid}
                createLandlordAccount={!isValid}
                setButtonSubmitMode={setButtonSubmitMode}
              />
              <AcceptPrivacyInfoText />
            </FormContext.Provider>
          )}
        </Grid>
      </Grid>
    </ModalWrapper>
  );
};

export default SignUpUserModal;
