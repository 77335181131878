export const formatNumberGermanStyle = (num: number | string, minFractionDigits = 0, maxFractionDigits = 0) => {
  num = typeof num === 'string' ? parseFloat(num) : num;
  if (num) {
    return num.toLocaleString('de-DE', {
      minimumFractionDigits: minFractionDigits,
      maximumFractionDigits: maxFractionDigits,
    });
  }
};

export const getFormattedPrice = (warmRent?: number, coldRent?: number) => {
  if (warmRent) {
    return `${formatNumberGermanStyle(warmRent, 0, 0)} €`;
  } else if (coldRent) {
    return `${formatNumberGermanStyle(coldRent, 0, 0)} €`;
  }

  return 'Keine Angabe';
};
