import MaintenanceModeView from 'view/landing-page/MaintenanceModeView';
import ModalLoader from 'component/modals/ModalLoader';
import ProtectedRoute, { IRouteRestriction } from '../component/atoms/ProtectedRoute';
import ScrollToTop from 'component/atoms/ScrollToTop';
import UserContext from 'core/context/user.context';
import VersionView from 'view/VersionView';
import { ADMIN_ROUTES } from 'core/const/routes/admin-routes';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { FC, useContext } from 'react';
import { LANDING_PAGE_ROUTES } from 'core/const/routes/landing-page-routes';
import { LANDLORD_ROUTES } from 'core/const/routes/landlord-routes';
import { REGISTRATION_STATES, USER_TYPE } from '@wohnsinn/ws-ts-lib';
import { ROUTES } from 'core/const/routes';
import { STATIC_ROUTES } from 'core/const/routes/static-routes';
import { TENANT_ROUTES } from 'core/const/routes/tenant-routes';
import { USER_ROUTES } from 'core/const/routes/user-routes';
import { useTranslation } from 'react-i18next';
import AppDataProvider from 'core/provider/AppData.Provider';

export interface IProtectedRouteProps {
  path: string;
  Component: FC;
  props?: any;
  params?: string[];
}

const AppRouter: FC<{ isMaintenanceMode: boolean }> = ({ isMaintenanceMode }) => {
  const { user, activeUserType } = useContext(UserContext);
  const { t: r } = useTranslation('routes');
  const isWogeEnvironment = process.env.REACT_APP_ENVIRONMENT === 'woge';

  /**
   * Return the maintenance router if app is in maintenance mode
   */
  if (isMaintenanceMode) {
    return <MaintenanceRouter />;
  }

  /**
   * Renders protected routes with a specified restriction type.
   *
   * @param {array} routes - The array of objects containing route details.
   * @param routeRestrictions
   */
  const renderRoutes = (routes: IProtectedRouteProps[], routeRestrictions?: IRouteRestriction[]) => {
    return (
      <Route element={<ProtectedRoute routeRestrictions={routeRestrictions} />}>
        {routes.map((route, index) => (
          <Route
            key={`${route.path}-${index}`}
            path={`${r(route.path)}${route.params?.length ? route.params.map((param) => `/:${param}`) : ''}`}
            element={<route.Component {...route.props} />}
          />
        ))}
      </Route>
    );
  };

  interface IRedirectProps {
    path: string;
    to: string;
    replace: boolean;
    condition: boolean;
  }

  const defaultRedirects = [
    { path: ROUTES.landingPageRoutes.maintenance.path, to: '/', replace: true, condition: true },
    { path: '*', to: ROUTES.staticRoutes.notFound.path, replace: true, condition: true },
    {
      path: '/',
      to: ROUTES.landlordRoutes.apartment.list.path,
      replace: true,
      condition: user && activeUserType === USER_TYPE.LANDLORD,
    },
    {
      path: '/',
      to: ROUTES.staticRoutes.search.path,
      replace: true,
      condition: (user && activeUserType === USER_TYPE.TENANT) || isWogeEnvironment,
    },
  ];

  const renderRedirects = (redirects: IRedirectProps[]) =>
    redirects.map((redirect, index) => {
      if (redirect.condition) {
        return (
          <Route
            key={`${redirect.path}-${index}`}
            path={r(redirect.path)}
            element={<Navigate to={r(redirect.to)} replace={redirect.replace} />}
          />
        );
      }
    });

  const isUserRestriction: IRouteRestriction = {
    shouldRedirect: Boolean(!user),
    redirectPath: ROUTES.staticRoutes.search.path,
    name: 'isUserRestriction',
  };
  const isLandlordRestriction: IRouteRestriction = {
    shouldRedirect: activeUserType !== USER_TYPE.LANDLORD,
    redirectPath: ROUTES.staticRoutes.search.path,
    name: 'isLandlordRestriction',
  };
  const isTenantRestriction: IRouteRestriction = {
    shouldRedirect: activeUserType !== USER_TYPE.TENANT,
    redirectPath: ROUTES.staticRoutes.search.path,
    name: 'isTenantRestriction',
  };
  const isAdminRestriction: IRouteRestriction = {
    shouldRedirect: !user || !user?.isAdmin,
    redirectPath: ROUTES.staticRoutes.search.path,
    name: 'isAdminRestriction',
  };

  const setPasswordRestriction: IRouteRestriction = {
    shouldRedirect: user && user?.registrationState === REGISTRATION_STATES.SET_PASSWORD,
    redirectPath: ROUTES.userRoutes.registration.createPassword.path,
    name: 'setPasswordRestriction',
  };

  const registrationOnboardNotFinished =
    user?.registrationState && user?.registrationState !== REGISTRATION_STATES.FINISHED;
  const registrationStateRestriction: IRouteRestriction = {
    shouldRedirect: registrationOnboardNotFinished,
    redirectPath:
      activeUserType === USER_TYPE.LANDLORD
        ? ROUTES.userRoutes.registration.createLandlordTunnel.path
        : ROUTES.userRoutes.registration.createTenantTunnel.path,
    name: 'registrationOnboardNotFinished',
  };

  const STATIC_ROUTES_RESTRICTION = [setPasswordRestriction, registrationStateRestriction];
  const USER_ROUTES_RESTRICTION = [isUserRestriction];
  const TENANT_ROUTES_RESTRICTION = [...STATIC_ROUTES_RESTRICTION, isUserRestriction, isTenantRestriction];
  const LANDLORD_ROUTES_RESTRICTIONS = [...STATIC_ROUTES_RESTRICTION, isUserRestriction, isLandlordRestriction];

  return (
    <BrowserRouter>
      <ScrollToTop />
      <AppDataProvider>
        <ModalLoader />
        <Routes>
          {renderRedirects(defaultRedirects)}
          {renderRoutes(LANDING_PAGE_ROUTES, STATIC_ROUTES_RESTRICTION)}
          {renderRoutes(STATIC_ROUTES, STATIC_ROUTES_RESTRICTION)}
          {renderRoutes(TENANT_ROUTES, TENANT_ROUTES_RESTRICTION)}
          {renderRoutes(LANDLORD_ROUTES, LANDLORD_ROUTES_RESTRICTIONS)}
          {renderRoutes(USER_ROUTES, USER_ROUTES_RESTRICTION)}
          {renderRoutes(ADMIN_ROUTES, [isAdminRestriction])}

          {process.env.REACT_APP_ENVIRONMENT !== 'production' && (
            <Route path={r(ROUTES.version.path)} Component={VersionView} />
          )}
        </Routes>
      </AppDataProvider>
    </BrowserRouter>
  );
};

export default AppRouter;

const MaintenanceRouter = () => {
  const { t: r } = useTranslation('routes');

  return (
    <BrowserRouter>
      <Routes>
        <Route path={r(ROUTES.landingPageRoutes.maintenance.path)} Component={MaintenanceModeView} />
        <Route path={'*'} element={<Navigate to={r(ROUTES.landingPageRoutes.maintenance.path)} replace={true} />} />
      </Routes>
    </BrowserRouter>
  );
};
