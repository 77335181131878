import Joi from 'joi';

export const APARTMENT_CONTACT_PERSON_FORM_SCHEMA = {
  firstName: Joi.string().required(),
  name: Joi.string().required(),
  directEmail: Joi.string().required(),
  mobile: Joi.string().required(),
  salutation: Joi.string().allow(''),
  addressRelease: Joi.boolean().required(),
  photo: Joi.any(),
  phoneNumber: Joi.any(),
  company: Joi.any(),
};
