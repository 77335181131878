// StepTwoForm.tsx

import { Dispatch, FC, SetStateAction, useContext } from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';

import FormHeader from 'component/atoms/FormHeader';
import FormNavigation from 'component/molecules/FormNavigation';
import MultiMessageFormComponents from 'component/molecules/MultiMessageFormComponents';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { FormContext } from 'core/context/form.context';
import { TEXT_COLOR } from 'component/atoms/typographie/Text';
import UserContext from 'core/context/user.context';
import { wohnsinnServices } from 'App';
import { IApplication } from '@wohnsinn/ws-ts-lib';
import { SALUTATION_TYPES } from '../../../../core/const/salutation-list';

export const MULTI_MESSAGE_SCHEMA = {
  message: Joi.string().required().min(5),
  salutation: Joi.valid(...Object.keys(SALUTATION_TYPES)).required(),
};

interface IMultiMessage {
  salutation: SALUTATION_TYPES;
  message: string;
}

interface StepTwoFormProps {
  selectedApplications: IApplication[];
  setStep: Dispatch<SetStateAction<1 | 2 | 3>>;
}

const SendMessageStep: FC<StepTwoFormProps> = ({ selectedApplications, setStep }) => {
  const { t } = useTranslation('common');
  const { landlordProfile } = useContext(UserContext);
  const { chatService } = wohnsinnServices;
  const {
    control: controlMultiMessage,
    watch: watchMultiMessage,
    handleSubmit,
  } = useForm<IMultiMessage>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: joiResolver(Joi.object(MULTI_MESSAGE_SCHEMA)),
  });

  const message = watchMultiMessage('message');
  const salutation = watchMultiMessage('salutation');

  const onSubmit = async () => {
    try {
      await chatService.sendMultiMessageToTenant(selectedApplications, landlordProfile, message, salutation);
      setStep(3);
    } catch (e) {
      console.error('Error on onSuccess in RequestSalesModal', e);
    }
  };

  return (
    <FormContext.Provider value={{ control: controlMultiMessage, watch: watchMultiMessage }}>
      <form
        id={'sendMessageForm'}
        onSubmit={handleSubmit(onSubmit, (e) => console.error('Error on RequestSalesModal', e))}
      >
        <FormHeader title={t('sendMessageForm.title')} subtitle={t('sendMessageForm.subTitle')} />
        <Grid container rowSpacing={{ xs: 0, md: 0 }} className="form-with-navigation">
          <Grid item xs={12} md={8} className="form-with-navigation">
            <Grid item xs={12}>
              <MultiMessageFormComponents />
            </Grid>
          </Grid>
        </Grid>

        <FormNavigation
          buttonSubmitMode={SUBMIT_BUTTON_MODE.NONE}
          disabled={false}
          showSecondaryButtonMobile={true}
          formId="sendMessageForm"
          submitButtonText={t('sendMessage')}
          secondaryButtonAction={() => setStep(1)}
          secondaryButtonColor={TEXT_COLOR.TEXT_COLOR_ACCENT}
          secondaryButtonText={t('back')}
        />
      </form>
    </FormContext.Provider>
  );
};

export default SendMessageStep;
