import CooperativeProsSection from 'component/organisms/landing-page-sections/CooperativeProsSection';
import { Grid } from '@mui/material';
import { IMAGES } from 'core/const/images';
import { ROUTES } from 'core/const/routes';
import { useTranslation } from 'react-i18next';
import LandingPageLayout from '../../../component/layouts/LandingPageLayout';
import FirstSection from 'component/organisms/landing-page-sections/FirstSection';
import ContactSection from '../../../component/organisms/landing-page-sections/ContactSection';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import CTACard from 'component/molecules/Cards/CTACard';
import { TEXT_COLOR } from 'component/atoms/typographie/Text';
import MediaCard from 'component/atoms/Card/MediaCard';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import { useContext } from 'react';
import ModalContext from 'core/context/modal.context';

const CooperativeLandingView = () => {
  const { t: r } = useTranslation('routes');
  const { t: l } = useTranslation('common', { keyPrefix: 'view.CooperativeLandingView.hero' });
  const { t: i } = useTranslation('images');
  const { openModal } = useContext(ModalContext);

  return (
    <LandingPageLayout pageTitle={r(ROUTES.landingPageRoutes.landlord.title)}>
      <Grid
        item
        xs={12}
        paddingTop={{ xs: 4, md: 10 }}
        paddingBottom={{ xs: 4, md: 10 }}
        className={'ws-full-width-bg'}
      >
        <FirstSection
          image={IMAGES.landing.cooperative}
          title={l('headline_one')}
          titleTwo={l('headline_two')}
          ctaText={'Kostenlos Objekt inserieren'}
          ctaOnClick={() => openModal({ id: MODAL_IDENTIFIER.SELECT_USER_TYPE })}
        />
      </Grid>

      <Grid item xs={12} paddingTop={{ xs: 4, md: 10 }} paddingBottom={{ xs: 4, md: 10 }}>
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} md={8}>
            <Headline size={HEADLINE_SIZE.DISPLAY} align={'center'}>
              Unsere Kunden lieben es mit Wohnsinn zu arbeiten
            </Headline>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} paddingTop={{ xs: 4, md: 10 }}>
            <MediaCard
              wide
              leftBottomInfo={'Vorstand Wohnungsbaugenossenschaft Jülich eG.'}
              leftTopInfo={'Florian A. Gloßner'}
              image={IMAGES.images.florianGlossner}
              logo={IMAGES.logos.woge}
              description={
                '“Mit Wohnsinn haben wir den Bewerbungsprozess um eine Wohnung vollständig digitalisiert. Die (Vor-) Qualifizierung der Interessenten insbesondere auf Basis der digital bereitgestellten Unterlagen spart wertvolle Zeit im Vergabeprozess”'
              }
              logoWidth={200}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} paddingTop={{ xs: 4, md: 10 }} paddingBottom={{ xs: 4, md: 10 }}>
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} md={8}>
            <Headline size={HEADLINE_SIZE.DISPLAY} align={'center'}>
              Kommen dir diese Probleme bekannt vor?
            </Headline>
          </Grid>
        </Grid>

        <Grid container spacing={4} paddingTop={{ xs: 4, md: 10 }} paddingBottom={{ xs: 4, md: 10 }}>
          <Grid item xs={12} md={4}>
            <CTACard
              imgHeight={48}
              headlineSize={HEADLINE_SIZE.H3}
              title={'Keine Übersicht der Gesuche von Mitgliedern'}
              text={
                'Fehlende Übersicht über die Wohnungsanfragen der Mitglieder erschwert die effiziente Vermittlung freier Wohnungen an geeignete Interessenten.'
              }
              textColor={TEXT_COLOR.TEXT_COLOR_ACCENT}
              imgSrc={i(IMAGES.pictograms.thumbDown.src)}
              imgAltText={i(IMAGES.pictograms.thumbDown.src)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CTACard
              imgHeight={48}
              headlineSize={HEADLINE_SIZE.H3}
              title={'Hohe Anzahl unqualifizierter Anfragen'}
              text={
                'Durch das Fehlen einer digitalen Lösung erhältst du unqualifizierte Anfragen, die nicht den Anforderungen der Wohnung entsprechen.'
              }
              textColor={TEXT_COLOR.TEXT_COLOR_ACCENT}
              imgSrc={i(IMAGES.pictograms.openMail.src)}
              imgAltText={i(IMAGES.pictograms.openMail.src)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CTACard
              imgHeight={48}
              headlineSize={HEADLINE_SIZE.H3}
              title={'Überfülltes E-Mail Postfach & Datenschutz'}
              text={
                'Dein Postfach läuft über und es fällt dir schwer, den Überblick zu behalten. Der Mangel an digitalen Lösungen führt zu papierbasierten Prozessen, was das Risiko von Datenschutzverstößen erhöht.'
              }
              textColor={TEXT_COLOR.TEXT_COLOR_ACCENT}
              imgSrc={i(IMAGES.pictograms.lock.src)}
              imgAltText={i(IMAGES.pictograms.lock.src)}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        className={'ws-full-width-bg ws-full-width-bg--tertiary'}
        paddingTop={{ xs: 4, md: 10 }}
        paddingBottom={{ xs: 4, md: 10 }}
      >
        <CooperativeProsSection />
      </Grid>

      <Grid item xs={12} paddingTop={{ xs: 4, md: 10 }} paddingBottom={{ xs: 4, md: 10 }}>
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} md={8}>
            <Headline size={HEADLINE_SIZE.DISPLAY} align={'center'}>
              Datensicherheit
            </Headline>
          </Grid>
        </Grid>

        <Grid container spacing={4} paddingTop={{ xs: 4, md: 10 }} paddingBottom={{ xs: 4, md: 10 }}>
          <Grid item xs={12} md={4}>
            <CTACard
              imgHeight={48}
              headlineSize={HEADLINE_SIZE.H3}
              title={'100% DSGVO-konform'}
              text={
                'Wohnsinn entspricht allen Vorgaben der EU-DSGVO und des BDSG. Intelligente Verfahren helfen zusätzlich dabei, alle Datenschutzverpflichtungen zu erfüllen.'
              }
              textColor={TEXT_COLOR.TEXT_COLOR_ACCENT}
              imgSrc={i(IMAGES.pictograms.checkmark.src)}
              imgAltText={i(IMAGES.pictograms.checkmark.alt)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CTACard
              imgHeight={48}
              headlineSize={HEADLINE_SIZE.H3}
              title={'Schutz der Mieterdaten'}
              text={
                'Mitglieder erhalten volle Kontrolle über ihre Daten. Über das passwortgeschützte Interessentenkonto können sie ihre Daten eigenständig verwalten und löschen.'
              }
              textColor={TEXT_COLOR.TEXT_COLOR_ACCENT}
              imgSrc={i(IMAGES.pictograms.checkmark.src)}
              imgAltText={i(IMAGES.pictograms.checkmark.alt)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CTACard
              imgHeight={48}
              headlineSize={HEADLINE_SIZE.H3}
              title={'Hohe Sicherheitsstandards'}
              text={
                'Alle Daten werden durch SSL-Verschlüsselung gesichert und in einem deutschen Hochsicherheitszentrum gespeichert, das ISO 27001 zertifiziert ist.'
              }
              textColor={TEXT_COLOR.TEXT_COLOR_ACCENT}
              imgSrc={i(IMAGES.pictograms.checkmark.src)}
              imgAltText={i(IMAGES.pictograms.checkmark.alt)}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        paddingTop={{ xs: 4, md: 10 }}
        paddingBottom={{ xs: 4, md: 10 }}
        className={'ws-full-width-bg ws-full-width-bg--white'}
      >
        <CTACard
          headlineSize={HEADLINE_SIZE.H1}
          alternativeStyle
          title={'Jetzt Demo buchen'}
          text={'Wir freuen uns, dir Wohnsinn vorzustellen'}
          ctaText={'Demo buchen'}
          shrinkButton
          link={'https://calendly.com/christian-philippi/kostenlose-demo-vereinbaren'}
        />
      </Grid>

      <Grid
        item
        xs={12}
        paddingTop={{ xs: 4, md: 10 }}
        paddingBottom={{ xs: 4, md: 10 }}
        className={'ws-full-width-bg ws-full-width-bg--white'}
      >
        <ContactSection />
      </Grid>
    </LandingPageLayout>
  );
};

export default CooperativeLandingView;
