import { Grid } from '@mui/material';
import Spacer, { SPACER_SIZE } from 'component/atoms/Spacer';
import FormHeader from 'component/atoms/FormHeader';
import CTAButton from 'component/atoms/Buttons/CTAButton';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const SuccessStep = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  return (
    <>
      <Grid item xs={12}>
        <img data-testid={`checkList.img`} src={'/assets/icon/check.svg'} alt="" width={50} />
      </Grid>
      <Spacer size={SPACER_SIZE.LG} />
      <FormHeader title={t('successFullMessageSent')} />
      <Grid
        container
        rowSpacing={{
          xs: 0,
          md: 0,
        }}
        className="form-with-navigation"
      >
        <Grid item xs={12}>
          <CTAButton buttonText={t('backToOverview')} rounded={false} type={'submit'} onClick={() => navigate(-1)} />
        </Grid>
      </Grid>
    </>
  );
};

export default SuccessStep;
