import {
  APPLICATION_STATE,
  FIRESTORE_COLLECTION_PATH,
  IApartment,
  IApplication,
  IFirestoreQueryParams,
  IGetLandlordApplicationListQueryParams,
  IGetTenantApplicationListQueryParams,
  ITenantProfile,
  IWhereFilterArguments,
  MATCHING_MODE,
  USER_TYPE,
} from '@wohnsinn/ws-ts-lib';
import 'firebase/firestore';
import { arrayRemove, arrayUnion, Query } from 'firebase/firestore';
import FirestoreService from './firestore.service';
import { ChatService } from './chat.service';

export interface IGetLandlordApplicationListQueryParamsWithRating extends IGetLandlordApplicationListQueryParams {
  rating?: MATCHING_MODE;
  isAdmin?: boolean;
}
export interface ChangeApplicationStateParams {
  applications: IApplication[] | IApplication;
  applicationState: APPLICATION_STATE;
}

export interface GetApartmentPathParams {
  landlordId: string;
  apartmentId: string;
}

export interface GetApplicationPathParams {
  apartmentPath: string;
  tenantUid: string;
}

class ApplicationService {
  constructor(private readonly firestoreService: FirestoreService, private readonly chatService: ChatService) {}

  /**
   * Create or update an application rating by tenant
   * @param apartment
   * @param tenantProfile
   * @param rating
   * @param createNewRating
   */

  private getApartmentPath({ landlordId, apartmentId }: GetApartmentPathParams): string {
    return `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
      .replace('{uid}', landlordId)
      .replace('{landlordId}', landlordId)}/${apartmentId}`;
  }

  // Helper function to build the application path using a parameter object
  private getApplicationPath({ apartmentPath, tenantUid }: GetApplicationPathParams): string {
    return `${apartmentPath}/applications/${tenantUid}`;
  }

  /**
   * Create or update an application document
   * @param apartment
   * @param tenantProfile
   * @param rating
   * @param applicationExists
   */
  public async handleApplicationData(
    apartment: IApartment,
    tenantProfile: ITenantProfile,
    rating: MATCHING_MODE,
    applicationExists: boolean
  ) {
    const apartmentPath = this.getApartmentPath({
      landlordId: apartment?.creatorId,
      apartmentId: apartment?.id,
    });

    const applicationPath = this.getApplicationPath({
      apartmentPath,
      tenantUid: tenantProfile?.uid,
    });

    const application = this.parseApplication(apartment, tenantProfile, rating);

    // Check if is a rating update or a new application
    const data = !applicationExists ? application : { rating: application.rating };

    await this.firestoreService.setDbDoc(data, applicationPath, true, {
      setCreatedAt: !applicationExists,
      setUpdatedAt: true,
    });
  }

  /**
   * Apply for an apartment
   * @param apartment
   * @param tenantProfile
   */
  public async applyForApartment(apartment: IApartment, tenantProfile: ITenantProfile) {
    const apartmentPath = this.getApartmentPath({
      landlordId: apartment?.creatorId,
      apartmentId: apartment?.id,
    });

    const applicationPath = this.getApplicationPath({
      apartmentPath,
      tenantUid: tenantProfile?.uid,
    });

    // add id to applicationRefList and newApplicationsRef
    await this.firestoreService.setDbDoc(
      {
        applicationRefList: arrayUnion(tenantProfile.uid),
        newApplicationsRef: arrayUnion(tenantProfile.uid),
      },
      apartmentPath,
      true
    );

    // Add editorList to tenantProfile to grant readPermissions
    const tenantProfilePath = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.root.replace(
      '{uid}',
      tenantProfile.uid
    )}/${tenantProfile.uid}`;

    await this.firestoreService.setDbDoc({ readPermissionGrantedUsers: apartment.editorList }, tenantProfilePath, true);

    await this.firestoreService.setDbDoc(
      {
        unreadTenantMessagesRef: [],
      },
      applicationPath,
      true
    );
  }

  public getLandlordApplicationListRef(params: IGetLandlordApplicationListQueryParamsWithRating): Query<IApplication> {
    const where: IWhereFilterArguments[] = [{ fieldPath: 'rating', opStr: '==', value: MATCHING_MODE.LIKE }];
    //For not admins, push a second where with check for id in editorList
    if (!params.isAdmin) {
      where.push({ fieldPath: 'editorList', opStr: 'array-contains', value: params.landlordId });
    }
    if (params.rating) {
      where.push({ fieldPath: 'landlordRating', opStr: '==', value: params.rating });
    }
    where.push({
      fieldPath: 'applicationState',
      opStr: 'in',
      value: [
        APPLICATION_STATE.NEW,
        APPLICATION_STATE.OPENED,
        APPLICATION_STATE.SORTED_LIKE,
        APPLICATION_STATE.SORTED_MAYBE,
        APPLICATION_STATE.SORTED_NOPE,
        APPLICATION_STATE.INVITED,
        APPLICATION_STATE.APPOINTMENT_CONFIRMED,
      ],
    });

    if (params.tenantId) {
      where.push({ fieldPath: 'tenantProfile.uid', opStr: '==', value: params.tenantId });
    }
    if (params.apartmentId) {
      where.push({ fieldPath: 'apartmentId', opStr: '==', value: params.apartmentId });
    }

    const queryParams: IFirestoreQueryParams = {
      where,
      orderBy: [{ fieldPath: 'updatedAt', directionStr: 'desc' }],
    };

    return this.firestoreService.getCollectionRefWithParams<IApplication>(
      this.firestoreService.getCollectionGroupRef('applications', { fetchWithId: true }),
      queryParams
    );
  }

  public getTenantApplicationListRef(params: IGetTenantApplicationListQueryParams): Query<IApplication> {
    const options: IFirestoreQueryParams = {
      where: [{ fieldPath: 'tenantProfile.uid', opStr: '==', value: params.tenantId }],
      orderBy: { fieldPath: 'updatedAt', directionStr: 'desc' },
    };

    if (params?.matchingMode.length && Array.isArray(options.where)) {
      options.where.push({ fieldPath: 'rating', opStr: 'in', value: params.matchingMode });
    }

    return this.firestoreService.getCollectionRefWithParams<IApplication>(
      this.firestoreService.getCollectionGroupRef('applications'),
      options
    );
  }

  public getApplication(params: { landlordId: string; apartmentId: string; tenantId: string }): Promise<IApplication> {
    const { landlordId, apartmentId, tenantId } = params;

    const apartmentPath = this.getApartmentPath({
      landlordId: landlordId,
      apartmentId: apartmentId,
    });
    const applicationPath = this.getApplicationPath({
      apartmentPath,
      tenantUid: tenantId,
    });

    return this.firestoreService.getDbDoc(applicationPath);
  }

  public updateApplicationLandlordRating(
    landlordId: string,
    apartmentId: string,
    tenantId: string,
    landlordRating: MATCHING_MODE,
    currentApplicationState: APPLICATION_STATE
  ): Promise<void> {
    const apartmentPath = this.getApartmentPath({
      landlordId: landlordId,
      apartmentId: apartmentId,
    });
    const applicationPath = this.getApplicationPath({
      apartmentPath,
      tenantUid: tenantId,
    });

    function getSortedState(landlordRating: MATCHING_MODE) {
      switch (landlordRating) {
        case MATCHING_MODE.LIKE:
          return APPLICATION_STATE.SORTED_LIKE;
        case MATCHING_MODE.MAYBE:
          return APPLICATION_STATE.SORTED_MAYBE;
        case MATCHING_MODE.NOPE:
          return APPLICATION_STATE.SORTED_NOPE;
        default:
          return APPLICATION_STATE.SORTED_LIKE;
      }
    }

    const applicationState =
      currentApplicationState === APPLICATION_STATE.INVITED ||
      currentApplicationState === APPLICATION_STATE.APPOINTMENT_CONFIRMED
        ? currentApplicationState
        : landlordRating !== MATCHING_MODE.NONE
        ? getSortedState(landlordRating)
        : APPLICATION_STATE.OPENED;

    return this.firestoreService.updateDbDoc(
      {
        landlordRating,
        applicationState,
      },
      applicationPath
    );
  }

  public updateApplication(params: {
    landlordId: string;
    apartmentId: string;
    tenantId: string;
    data: Partial<IApplication>;
  }): Promise<void> {
    const { landlordId, apartmentId, tenantId, data } = params;
    const apartmentPath = this.getApartmentPath({
      landlordId: landlordId,
      apartmentId: apartmentId,
    });
    const applicationPath = this.getApplicationPath({
      apartmentPath,
      tenantUid: tenantId,
    });
    return this.firestoreService.updateDbDoc({ ...data }, applicationPath);
  }

  public async markUnread(application: IApplication, readByUserType: USER_TYPE): Promise<void> {
    const apartmentPath = this.getApartmentPath({
      landlordId: application.landlordId,
      apartmentId: application.apartmentId,
    });
    const apartmentDocRef = this.firestoreService.getDocRef(apartmentPath);
    const applicationPath = this.getApplicationPath({
      apartmentPath,
      tenantUid: application.tenantProfile.uid,
    });
    const applicationDocRef = this.firestoreService.getDocRef(applicationPath);
    const batch = this.firestoreService.getBatch();

    switch (readByUserType) {
      case USER_TYPE.TENANT:
        batch.update(applicationDocRef, { unreadLandlordMessagesRef: [] });
        break;
      case USER_TYPE.LANDLORD:
        if (application?.unreadTenantMessagesRef?.length) {
          application.unreadTenantMessagesRef.forEach((unreadTenantMessageId) => {
            batch.update(apartmentDocRef, { unreadTenantMessagesRef: arrayRemove(unreadTenantMessageId) });
          });
        }
        batch.update(applicationDocRef, { unreadTenantMessagesRef: [] });
        break;
      default:
        break;
    }
    return await batch.commit().catch((e) => console.error('Error ApplicationService markUnread', e));
  }

  /**
   * Create and return application from given parameters
   * @param apartment
   * @param tenantProfile
   * @param rating
   * @return TApplicationCreate
   */
  public parseApplication = (
    apartment: IApartment,
    tenantProfile: ITenantProfile,
    rating: MATCHING_MODE
  ): Partial<IApplication> => {
    return {
      applicationState: APPLICATION_STATE.NEW,
      address: apartment.mainInformation.address,
      apartmentId: apartment.id,
      cost: apartment.cost,
      contactPerson: apartment?.contactPerson?.name,
      editorList: apartment.editorList,
      isLandlordTyping: false,
      isTenantTyping: false,
      isChatDisabled: true,
      isActiveTenant: false,
      landlordId: apartment.creatorId,
      landlordRating: MATCHING_MODE.NONE,
      lastMessage: null,
      lastMessageSent: null,
      lastMessageSenderId: null,
      media: apartment?.media?.length ? apartment.media[0] : null,
      rating,
      rooms: apartment?.areas?.numberOfRooms ?? 0,
      seenByLandlord: false,
      size: apartment?.areas?.totalArea ?? 0,
      tenantProfile,
      warmRent: apartment?.cost?.warmRent ?? 0,
    };
  };

  /**
   * Change the application State for multiple or one application
   */
  public async changeApplicationState({ applications, applicationState }: ChangeApplicationStateParams): Promise<void> {
    // Ensure the applications parameter is always treated as an array
    const applicationArray = Array.isArray(applications) ? applications : [applications];

    // Firestore batch size limit
    const batchSize = 500; // Firestore allows a maximum of 500 operations per batch

    try {
      // Split the applications into chunks of 500
      for (let i = 0; i < applicationArray.length; i += batchSize) {
        // Create a new batch for each chunk
        const batch = this.firestoreService.getBatch();
        const batchApplications = applicationArray.slice(i, i + batchSize);

        for (const application of batchApplications) {
          const apartmentPath = this.getApartmentPath({
            landlordId: application.landlordId,
            apartmentId: application.apartmentId,
          });

          const applicationPath = this.getApplicationPath({
            apartmentPath,
            tenantUid: application.tenantProfile.uid,
          });

          // Prepare update data
          const updateData: Partial<IApplication> = { applicationState };

          if (applicationState === APPLICATION_STATE.WILL_BE_DELETED) {
            const deleteDate = new Date();
            deleteDate.setDate(deleteDate.getDate() + 30); // Add 30 days before automated DSGVO Konform deletion
            updateData.deleteDate = deleteDate;
            updateData.isChatDisabled = true;
            batch.update(this.firestoreService.getDocRef(apartmentPath), {
              applicationRefList: arrayRemove(application.tenantProfile.uid),
              unreadTenantChatsRef: arrayRemove(application.tenantProfile.uid),
            });
          }

          if (applicationState === APPLICATION_STATE.DELETED) {
            const deleteDate = new Date();
            deleteDate.setDate(deleteDate.getDate() + 2); // Add 2 days for the tenant to see, then delete
            updateData.deleteDate = deleteDate;
            updateData.isChatDisabled = true;

            // Remove the tenant UID from apartment.applicationRefList
            batch.update(this.firestoreService.getDocRef(apartmentPath), {
              applicationRefList: arrayRemove(application.tenantProfile.uid),
              unreadTenantChatsRef: arrayRemove(application.tenantProfile.uid),
            });
          }

          if (applicationState === APPLICATION_STATE.SELECTED_TENANT) {
            // Remove the tenant UID from apartment.applicationRefList
            batch.update(this.firestoreService.getDocRef(apartmentPath), {
              applicationRefList: arrayRemove(application.tenantProfile.uid),
              unreadTenantChatsRef: arrayRemove(application.tenantProfile.uid),
            });
          }

          const applicationDocRef = this.firestoreService.getDocRef<IApplication>(applicationPath, {
            fetchWithId: true,
          });

          // Add update operation to the batch
          batch.update(applicationDocRef, updateData);
        }

        // Commit the batch operation for this chunk
        await batch.commit();
        console.log(`Batch committed successfully for ${batchApplications.length} applications.`);
      }
    } catch (error) {
      console.error('Error updating application state:', error);
      throw new Error('Failed to update application states. Please try again.');
    }
  } // Helper function to build the apartment path using a parameter object

  public getLandlordChatsQuery(landlordId: string): Query<IApplication> {
    const where: IWhereFilterArguments[] = [{ fieldPath: 'isChatDisabled', opStr: '==', value: false }];
    where.push({ fieldPath: 'editorList', opStr: 'array-contains', value: landlordId });
    where.push({ fieldPath: 'rating', opStr: '==', value: MATCHING_MODE.LIKE });

    const queryParams: IFirestoreQueryParams = {
      where,
      orderBy: [{ fieldPath: 'updatedAt', directionStr: 'desc' }],
    };

    return this.firestoreService.getCollectionRefWithParams<IApplication>(
      this.firestoreService.getCollectionGroupRef('applications', { fetchWithId: true }),
      queryParams
    );
  }
}

export default ApplicationService;
