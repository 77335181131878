import { Dispatch, FC, SetStateAction } from 'react';
import styles from './ImageGallery.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { Navigation } from 'swiper';
import { Link } from 'react-router-dom';
import { IMedia } from '@wohnsinn/ws-ts-lib';
import { useNavigate } from 'react-router-dom';

export interface IImageGalleryProps {
  media: IMedia[];
  handleSlideTap?: any;
  slideShowIndex: number;
  setSlideShowIndex: Dispatch<SetStateAction<number>>;
  noLightBox?: boolean;
  routerLink?: string;
  id: string;
  onClick?: any;
}

const ImageGallery: FC<IImageGalleryProps> = ({
  media,
  handleSlideTap = null,
  slideShowIndex,
  setSlideShowIndex,
  noLightBox = false,
  routerLink,
  id,
  onClick,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.swiperWrapper}>
        {routerLink ? <Link className={routerLink ? styles.link : ''} to={routerLink} /> : null}
        {media.length > 1 ? (
          <>
            <button className={`M-${id}-swiper-prev ${styles.buttons} ${styles.prev}`}>
              <span className={'sr-only'}>prev</span>
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <button className={`M-${id}-swiper-next ${styles.buttons} ${styles.next}`}>
              <span className={'sr-only'}>next</span>
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </>
        ) : null}
        <Swiper
          modules={[Navigation]}
          navigation={{
            nextEl: `.M-${id}-swiper-next`,
            prevEl: `.M-${id}-swiper-prev`,
          }}
          allowTouchMove
          tabIndex={0}
          slidesPerView={1}
          spaceBetween={0}
          onSlideChange={(swiper) => setSlideShowIndex(swiper.activeIndex)}
          className={styles.slides}
          onClick={routerLink ? () => navigate(routerLink) : onClick ? onClick : undefined}
          lazy={{
            enabled: true,
            checkInView: true,
            loadPrevNext: false, // Set to `true` to enable lazy loading for the closest slides images (for previous and next slide images)
            // elementClass?: string; // CSS class name of lazy element -  @default 'swiper-lazy'
            // loadingClass?: string; // CSS class name of lazy loading element - @default 'swiper-lazy-loading'
          }}
        >
          {media.map((img, i) => (
            <SwiperSlide
              onClick={noLightBox ? undefined : () => handleSlideTap(i)}
              key={`${img.id}-${i}`}
              className={styles.slide}
            >
              <img className={styles.image} src={img.thumbnailUrl ? img.thumbnailUrl : img.url} alt="" loading="lazy" />
            </SwiperSlide>
          ))}
        </Swiper>

        {media.length > 1 ? (
          <div className={styles.indexIndicator}>{`${(
            slideShowIndex + 1
          ).toString()} / ${media.length.toString()}`}</div>
        ) : null}
      </div>
    </>
  );
};

export default ImageGallery;
