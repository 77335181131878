import { FC, useState } from 'react';
import { Slider } from '@mui/material';
import styles from './SelectRange.module.scss';
import Text, { TEXT_COLOR } from '../../atoms/typographie/Text';

const SelectRange: FC<{ onChange: any; label?: string; value: any }> = ({ onChange, value, label }) => {
  const [low, setLow] = useState(value.low ?? 0);
  const [high, setHigh] = useState(value.high ?? 100);
  const handleRangeSlider = (event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      onChange({ low: newValue[0], high: newValue[1] });
    }
  };

  return (
    <div className={styles.wrapper}>
      {label ? <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>{label}</Text> : null}

      <div className={styles.box}>
        <span>{low}</span>
        <Slider
          // @ts-ignore
          onChangeCommitted={handleRangeSlider}
          // valueLabelDisplay="on"
          onChange={(e, newVal) => {
            if (Array.isArray(newVal)) {
              setLow(newVal[0]);
              setHigh(newVal[1]);
            }
          }}
          track={'normal'}
          aria-labelledby="track-false-slider"
          value={[low, high]}
        />
        <span>{high}</span>
      </div>
    </div>
  );
};

export default SelectRange;
