import CTAButton, { getSubmitIcon } from 'component/atoms/Buttons/CTAButton';
import ModalWrapper from '../../ModalWrapper';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { AUTH_PROVIDER, ICheckIfUserExistsResponse } from '@wohnsinn/ws-ts-lib';
import LOCAL_STORAGE_KEYS from 'core/enum/local-storage.enum';
import UserContext from 'core/context/user.context';
import { FC, useContext, useEffect, useState } from 'react';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import TextInput from 'component/atoms/formElement/InputText';
import { FormContext } from 'core/context/form.context';
import ModalContext from 'core/context/modal.context';
import Text, { TEXT_COLOR } from 'component/atoms/typographie/Text';
import SplitLine from 'component/atoms/SplitLine';
import AuthProviderLoginButtons from 'component/molecules/AuthProviderLoginButtons';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { wohnsinnServices } from 'App';
import SnackBarContext from 'core/context/snackbar.context';
import FB_FUNCTION_URLS from 'core/const/fb-function-names';
import { useNavigate } from 'react-router-dom';

const SignInModal: FC = () => {
  const { t } = useTranslation('common');
  const { t: m } = useTranslation('common', { keyPrefix: 'component.molecules.modals.signIn.SignInModal' });
  const { firebaseAuthService, firebaseFunctionsService } = wohnsinnServices;
  const { user } = useContext(UserContext);
  const { openModal, closeModal } = useContext(ModalContext);
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { handleSnackBar } = useContext(SnackBarContext);
  const navigate = useNavigate();

  const { control, handleSubmit, watch } = useForm<{ email: string; password: string }>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: { email: localStorage.getItem(LOCAL_STORAGE_KEYS.EMAIL) ?? '', password: '' },
  });

  const email = watch('email');

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.EMAIL, email);
  }, [email]);

  useEffect(() => {
    if (user) {
      navigate('/');
      closeModal();
    }
  }, [user]);

  const onEmailPasswordFormSubmit = async (formData: { email: string; password: string }): Promise<boolean | void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);

    try {
      const response: { data: ICheckIfUserExistsResponse } = (await firebaseFunctionsService.callFbFunction(
        FB_FUNCTION_URLS.user.checkIfUserExists,
        {
          params: { email },
        }
      )) as { data: ICheckIfUserExistsResponse };

      if (response.data.userExists) {
        switch (response.data.provider) {
          case AUTH_PROVIDER.GOOGLE: {
            handleSnackBar('Du hast dich bisher mit Google eingeloggt.', 'info');
            setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
            break;
          }
          default: {
            await onSuccessSubmit(formData.email, formData.password);
            setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
            break;
          }
        }
      } else {
        handleSnackBar(t('toast.signIn.givenEmailAccountDoesNotExist', { replace: { email: formData.email } }), 'info');
        setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onSuccessSubmit = async (email: string, password: string): Promise<boolean | void> => {
    try {
      await firebaseAuthService.signInWithEmailAndPassword(email, password);
    } catch (error) {
      if (JSON.stringify(error).includes('auth/wrong-password')) {
        handleSnackBar('toast.signIn.givenPasswordIsWrong', 'warning');
      } else if (JSON.stringify(error).includes('auth/invalid-email')) {
        handleSnackBar('toast.signIn.invalidEmailAddress', 'warning');
      } else if (JSON.stringify(error).includes('auth/too-many-requests')) {
        handleSnackBar('toast.signIn.userIsLockedBecauseOfTwoManyRequests', 'warning');
      } else if (JSON.stringify(error).includes('auth/internal-error')) {
        handleSnackBar('toast.signIn.unknown', 'warning');
      }
    }
  };

  const Footer = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', gap: 8 }}>
        <Text tag={'span'}>Du hast noch kein Konto? </Text>

        <button type={'button'} onClick={() => openModal({ id: MODAL_IDENTIFIER.SELECT_USER_TYPE })}>
          <Text tag={'span'} bold color={TEXT_COLOR.TEXT_COLOR_PRIMARY}>
            {m('createNow')}
          </Text>
        </button>
      </div>
    );
  };

  return (
    <ModalWrapper title={t('welcomeBack')} modalFooter={<Footer />}>
      <form autoComplete={'off'} onSubmit={handleSubmit((data) => onEmailPasswordFormSubmit(data), console.error)}>
        <FormContext.Provider value={{ control }}>
          <TextInput label={t('email.label')} name={'email'} type={'email'} required />
          <TextInput label={t('password.label')} name={'password'} type={'password'} required />
          <button className={'hidden'}>submit</button>
        </FormContext.Provider>
        <div style={{ marginBottom: 16, display: 'flex', justifyContent: 'center' }}>
          <button type={'button'} onClick={() => openModal({ id: MODAL_IDENTIFIER.RESET_PASSWORD })}>
            <Text align={'right'} color={TEXT_COLOR.TEXT_COLOR_PRIMARY}>
              {m('resetPassword.label')}
            </Text>
          </button>
        </div>

        <CTAButton
          spinIcon={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING}
          icon={getSubmitIcon(buttonSubmitMode)}
          expand={'block'}
          rounded={false}
          buttonText={t('Anmelden')}
        />

        <SplitLine size={'small'} whiteBg text={t('or')}></SplitLine>

        <AuthProviderLoginButtons setButtonSubmitMode={setButtonSubmitMode} />
      </form>
    </ModalWrapper>
  );
};

export default SignInModal;
