import { useTranslation } from 'react-i18next';
import ModalWrapper, { IModalWrapperButtonProps } from '../ModalWrapper';
import Text from 'component/atoms/typographie/Text';
import ModalContext from 'core/context/modal.context';
import { useContext } from 'react';
import { ROUTES } from 'core/const/routes';
import { useNavigate } from 'react-router-dom';

const CompleteApplicationFolderModal = () => {
  const { closeModal } = useContext(ModalContext);
  const navigate = useNavigate();
  const { t: i } = useTranslation('common');
  const { t } = useTranslation('common', {
    keyPrefix: 'component.molecules.modals.applicationFolder.CompleteApplicationFolderRequest',
  });
  const { t: c } = useTranslation('common');
  const { t: r } = useTranslation('routes');

  const handleNextStep = () => {
    closeModal();
    navigate(r(ROUTES.tenantRoutes.account.applicationFolder.profile));
  };

  const MODAL_BUTTONS: IModalWrapperButtonProps = {
    primary: {
      buttonText: c('createProfile'),
      onClick: handleNextStep,
    },
  };

  return (
    <ModalWrapper title={t('title')} icon={i('pictogram.accountPictogram')} buttons={MODAL_BUTTONS}>
      <Text align={'center'} tag={'p'}>
        {t('text')}
      </Text>
    </ModalWrapper>
  );
};

export default CompleteApplicationFolderModal;
