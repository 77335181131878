import { FC, useContext } from 'react';
import { Drawer } from '@mui/material';
import PremiumSubscriptionDrawer from 'component/atoms/PremiumSubscriptionDrawer';
import PremiumSubscriptionLandingDrawer from 'component/atoms/PremiumSubscriptionDrawer/PremiumSubscriptionLandingDrawer';
import SubscriptionDrawerContext from 'core/context/subscription-drawer.context';

const SubscriptionDrawerLoader: FC = () => {
  const { showSubscriptionDrawer, setShowSubscriptionDrawer, showSubscriptionLpDrawer, setShowSubscriptionLpDrawer } =
    useContext(SubscriptionDrawerContext);

  return (
    <div>
      <Drawer open={showSubscriptionDrawer} onClose={() => setShowSubscriptionDrawer(false)} anchor={'right'}>
        <PremiumSubscriptionDrawer closeAction={() => setShowSubscriptionDrawer(false)} />
      </Drawer>

      <Drawer
        open={showSubscriptionLpDrawer}
        onClose={() => {
          setShowSubscriptionLpDrawer(false);
        }}
        anchor={'right'}
      >
        <PremiumSubscriptionLandingDrawer
          onClose={() => {
            setShowSubscriptionLpDrawer(false);
            setShowSubscriptionDrawer(true);
          }}
        />
      </Drawer>
    </div>
  );
};

export default SubscriptionDrawerLoader;
