import sanitize from 'sanitize-html';

const sanitizeWithNewLine = (line: string): string => {
  return sanitize(line, {
    allowedTags: ['p'],
  })
    ?.replace(/<p>/, '') // Replace only the first <p> without adding a new line
    .replace(/<p>/g, '\n') // Replace all subsequent <p> tags with new lines
    .replace(/<\/p>/g, '') // Remove all closing </p> tags
    .replace(/&amp;/g, ' & ');
};
export default sanitizeWithNewLine;
