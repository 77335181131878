import { useContext } from 'react';
import ModalWrapper, { IModalWrapperButtonProps } from '../ModalWrapper';
import ModalContext from 'core/context/modal.context';
import Text from 'component/atoms/typographie/Text';
import { BUTTON_STYLE } from 'component/atoms/Buttons/CTAButton';
import { useTranslation } from 'react-i18next';

const DeleteModal = () => {
  const { closeModal, modalData } = useContext(ModalContext);
  const { t } = useTranslation('common');

  const MODAL_BUTTONS: IModalWrapperButtonProps = {
    primary: {
      onClick: () => {
        modalData.deleteHandler();
        closeModal();
      },
      buttonText: t('delete'),
      buttonStyle: BUTTON_STYLE.DANGER,
    },
    third: {
      onClick: closeModal,
      buttonText: t('cancel'),
    },
  };

  return (
    <ModalWrapper title={modalData?.title} icon={t('pictogram.delete')} buttons={MODAL_BUTTONS}>
      <Text align={'center'} tag={'p'}>
        {modalData?.text}
      </Text>
    </ModalWrapper>
  );
};

export default DeleteModal;
