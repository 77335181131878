import { FC, useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import { getDownloadURL } from 'firebase/storage';
import uuid4 from 'uuid4';
import MediaUploadDropZone from '../../molecules/MediaUploadDropZone';
import UserContext from '../../../core/context/user.context';
import SnackBarContext from '../../../core/context/snackbar.context';
import { wohnsinnServices } from '../../../App';
import { IApartment, IMedia, MEDIA_TYPE } from '@wohnsinn/ws-ts-lib';
import { UPLOAD_TYPE } from '../../molecules/DocumentUploadDropZone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import FB_FUNCTION_URLS from '../../../core/const/fb-function-names';
import styles from './ApartmentMediaUpload.module.scss';
import ApartmentMediaList from 'component/molecules/ApartmentMediaList';
import { Grid } from '@mui/material';

const UploadApartmentMedia: FC<{ apartment: IApartment }> = ({ apartment }) => {
  const { firebaseStorageService, apartmentService, firebaseFunctionsService } = wohnsinnServices;
  const { user } = useContext(UserContext);
  const { handleSnackBar } = useContext(SnackBarContext);

  const uploadFile = async (file: File) => {
    const id = uuid4();
    const upload = await firebaseStorageService.createFileUpload(file, {
      uploadType: UPLOAD_TYPE.APARTMENT_MEDIA,
      id,
      creatorId: user.uid,
      mediaType: MEDIA_TYPE.IMAGE,
      subDirectoryPath: apartment.id,
    });

    await upload.uploadTask;
    const creatorId = apartment.creatorId;
    const apartmentId = apartment.id;
    const imageUploadPath = upload.uploadPath;

    const downloadURL = await getDownloadURL(firebaseStorageService.getRef(imageUploadPath));
    let image;
    try {
      const result = await firebaseFunctionsService.callFbFunction<{
        params: { imageUploadPath: string; creatorId: string; apartmentId: string; imageId: string };
      }>(FB_FUNCTION_URLS.media.resize, {
        params: { imageUploadPath, creatorId, apartmentId, imageId: id },
      });
      const thumbnailUrl = result?.data.thumbnailUrl;
      const uprightUrl = result?.data.uprightUrl;
      image = { ...upload, id, thumbnailUrl, url: downloadURL, uprightUrl };
      return image;
    } catch (e) {
      console.error('Error on uploadFile: ', e);
      image = { ...upload, id, thumbnailUrl: downloadURL, url: downloadURL, uprightUrl: downloadURL };
      return image;
    }
  };

  const uploadMutation = useMutation({
    mutationFn: uploadFile,
    onSuccess: async (data) => {
      const newFile: IMedia = {
        alt: data?.alt,
        updatedAt: new Date(),
        creatorId: user.uid,
        id: data.id,
        url: data.url,
        thumbnailUrl: data?.thumbnailUrl ?? data?.url,
        uprightUrl: data?.uprightUrl ?? data?.thumbnailUrl ?? data?.url,
        mediaType: MEDIA_TYPE.IMAGE,
      };

      await apartmentService.updateApartmentMediaList(apartment, [...apartment.media, newFile], 'media');
      handleSnackBar('component.molecules.UploadApartmentMedia.uploadSuccessMessage', 'success');
    },
    onError: () => {
      handleSnackBar('component.molecules.UploadApartmentMedia.uploadErrorMessage', 'error');
    },
  });

  const processFileQueue = async (files: File[]) => {
    for (const file of files) {
      try {
        await uploadMutation.mutateAsync(file);
      } catch (error) {
        console.error('Error processing file queue:', error);
      }
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} md={2}>
        <div className={styles.wrapper}>
          {uploadMutation.isPending ? (
            <FontAwesomeIcon spin={true} width={'23px'} height={'23px'} icon={faSpinner} size={'1x'} />
          ) : (
            <MediaUploadDropZone multiple={true} onDrop={processFileQueue} />
          )}
        </div>
      </Grid>
      <ApartmentMediaList apartment={apartment} />
    </Grid>
  );
};

export default UploadApartmentMedia;
