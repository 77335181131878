import ApartmentRatingButtons from 'component/atoms/ApartmentRatingButtons';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import { FC, useContext, useEffect, useState } from 'react';
import ModalContext from 'core/context/modal.context';
import styles from './ApartmentDrawer.module.scss';
import ApartmentInformation from 'component/organisms/ApartmentInformation';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import CTAButton, { BUTTON_STYLE } from 'component/atoms/Buttons/CTAButton';
import { faShareNodes } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { Drawer } from '@mui/material';
import { TEXT_COLOR } from 'component/atoms/typographie/Text';
import useApartment from '../../../core/hook/apartment.hook';
import LoadingAnimation from 'component/atoms/LoadingAnimation';
import useWindowSize from 'core/hook/windowsize.hook';

interface IApartmentDrawerProps {
  apartmentId: string;
  closeDrawer: () => void;
  isApplicationView: boolean;
  onClose?: (newData: any) => void;
  show: boolean;
}

const ApartmentDrawer: FC<IApartmentDrawerProps> = ({ apartmentId, closeDrawer, isApplicationView, onClose, show }) => {
  const { apartment, isLoading } = useApartment(apartmentId);
  const { openModal } = useContext(ModalContext);
  const { t } = useTranslation('common');
  const { isSmallerSm } = useWindowSize();
  const [isAddressBarVisible, setIsAddressBarVisible] = useState(true);
  const [initialHeight, setInitialHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      const currentHeight = window.innerHeight;
      // Address bar is visible if the current height is less than the initial height
      setIsAddressBarVisible(currentHeight < initialHeight);
    };

    // Save the initial window height at the component mount
    setInitialHeight(window.innerHeight);

    // Add event listeners for resize and scroll
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleResize);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleResize);
    };
  }, [initialHeight]);

  return (
    <Drawer
      PaperProps={{
        sx: {
          borderTopLeftRadius: { xs: '10px', sm: '0' },
          borderTopRightRadius: { xs: '10px', sm: '10px' },
        },
      }}
      anchor={isSmallerSm ? 'bottom' : 'left'}
      open={show}
      onClose={closeDrawer}
    >
      {isLoading || !apartment ? (
        <div className={`${styles.drawer} ${styles.loadingWrapper}`}>
          <LoadingAnimation />
        </div>
      ) : apartment ? (
        <>
          <div
            className={`${styles.drawer} ${isAddressBarVisible ? styles.addressBarVisible : styles.addressBarHidden} `}
          >
            <div className={styles.header}>
              <Headline size={HEADLINE_SIZE.H3} mobileSize={HEADLINE_SIZE.H2}>
                {apartment?.areas?.numberOfRooms ? `${apartment.areas.numberOfRooms}-Zimmer-` : ''}Wohnung
              </Headline>
              <div className={styles.buttonWrapper}>
                <CTAButton
                  size={'small'}
                  buttonStyle={BUTTON_STYLE.SECONDARY}
                  buttonText={t('share')}
                  rounded
                  hideText={true}
                  color={TEXT_COLOR.TEXT_COLOR_DARK}
                  onClick={() => openModal({ id: MODAL_IDENTIFIER.SHARE_LINK, data: { apartmentId: apartment.id } })}
                  icon={faShareNodes}
                />
                <CTAButton
                  size={'small'}
                  buttonStyle={BUTTON_STYLE.SECONDARY}
                  buttonText={t('close')}
                  rounded
                  color={TEXT_COLOR.TEXT_COLOR_DARK}
                  hideText={true}
                  onClick={closeDrawer}
                  icon={faTimes}
                />
              </div>
            </div>

            <div className={styles.body}>
              <ApartmentInformation apartment={apartment} />
            </div>

            <ApartmentRatingButtons callback={onClose} isApplicationView={isApplicationView} apartment={apartment} />
          </div>
        </>
      ) : null}
    </Drawer>
  );
};

export default ApartmentDrawer;
