import { Dispatch, FC, SetStateAction, useContext, useState } from 'react';
import { APPLICATION_STATE, MATCHING_MODE } from '@wohnsinn/ws-ts-lib';
import styles from './LandlordRatingButtons.module.scss';
import useApartment from '../../../core/hook/apartment.hook';
import { useTranslation } from 'react-i18next';
import { wohnsinnServices } from 'App';
import { useQueryClient } from '@tanstack/react-query';
import useFormErrorHook from '../../../core/hook/use-form-error.hook';
import SnackBarContext from '../../../core/context/snackbar.context';
import { Tooltip } from '@mui/material';

export interface ILandlordRatingButtonsProps {
  landlordRating: MATCHING_MODE;
  tenantProfileId: string;
  apartmentId: string;
  centerButtons?: boolean;
  spaceBetween?: boolean;
  currentApplicationState: APPLICATION_STATE;
}

const LandlordRatingButtons: FC<ILandlordRatingButtonsProps> = ({
  landlordRating,
  tenantProfileId,
  apartmentId,
  centerButtons = true,
  spaceBetween = false,
  currentApplicationState,
}) => {
  const [hoveredHeart, setHoveredHeart] = useState(MATCHING_MODE.NONE);
  const { applicationService } = wohnsinnServices;
  const { apartment } = useApartment(apartmentId);
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();
  const { handleSnackBar } = useContext(SnackBarContext);
  const { handleError } = useFormErrorHook('any');

  const handleLandlordRatingClick = async (newLandlordRating: MATCHING_MODE): Promise<void> => {
    if (landlordRating !== newLandlordRating) {
      try {
        await applicationService.updateApplicationLandlordRating(
          apartment.creatorId,
          apartment.id,
          tenantProfileId,
          newLandlordRating,
          currentApplicationState
        );

        await queryClient.refetchQueries({ queryKey: ['apartments-applications', apartmentId] });

        if (
          !(
            currentApplicationState === APPLICATION_STATE.INVITED ||
            currentApplicationState === APPLICATION_STATE.APPOINTMENT_CONFIRMED
          )
        ) {
          handleSnackBar(t('toast.landlord.rating.success'));
        }
      } catch (e) {
        handleError(e);
      }
    } else {
      console.log('Rating hat sich nicht geändert');
    }
  };

  return (
    <div
      className={`landlord-rating-buttons ${styles.ratings} ${centerButtons ? styles.centerButtons : ''} ${
        spaceBetween ? styles.spaceBetween : ''
      } `}
    >
      <Tooltip placement="top" title={t('landlordRatingButtons.tooltips.like')}>
        {/* Mui Tooltip needs this divs */}
        <div>
          <HeartButton
            landlordRating={landlordRating}
            img={t('icons.heart_like')}
            imgActive={t('icons.heart_like_outlined')}
            buttonMode={MATCHING_MODE.LIKE}
            setHoveredHeart={setHoveredHeart}
            hoveredHeart={hoveredHeart}
            handleLandlordRatingClick={handleLandlordRatingClick}
          />
        </div>
      </Tooltip>
      <Tooltip placement="top" title={t('landlordRatingButtons.tooltips.maybe')}>
        <div>
          <HeartButton
            landlordRating={landlordRating}
            img={t('icons.heart_maybe')}
            imgActive={t('icons.heart_maybe_outlined')}
            buttonMode={MATCHING_MODE.MAYBE}
            setHoveredHeart={setHoveredHeart}
            hoveredHeart={hoveredHeart}
            handleLandlordRatingClick={handleLandlordRatingClick}
          />
        </div>
      </Tooltip>
      <Tooltip placement="top" title={t('landlordRatingButtons.tooltips.nope')}>
        <div>
          <HeartButton
            landlordRating={landlordRating}
            img={t('icons.heart_nope')}
            imgActive={t('icons.heart_nope_outlined')}
            buttonMode={MATCHING_MODE.NOPE}
            setHoveredHeart={setHoveredHeart}
            hoveredHeart={hoveredHeart}
            handleLandlordRatingClick={handleLandlordRatingClick}
          />
        </div>
      </Tooltip>
    </div>
  );
};

const HeartButton: FC<{
  hoveredHeart: MATCHING_MODE;
  landlordRating: MATCHING_MODE;
  setHoveredHeart: Dispatch<SetStateAction<MATCHING_MODE>>;
  handleLandlordRatingClick: (mode: MATCHING_MODE) => void;
  buttonMode: MATCHING_MODE;
  img: string;
  imgActive: string;
}> = ({ hoveredHeart, landlordRating, setHoveredHeart, handleLandlordRatingClick, buttonMode, img, imgActive }) => {
  const { t } = useTranslation('common');
  return (
    <button
      className={styles.button}
      onMouseEnter={() => setHoveredHeart(buttonMode)}
      onMouseLeave={() => setHoveredHeart(MATCHING_MODE.NONE)}
      onClick={() => handleLandlordRatingClick(buttonMode)}
    >
      <img
        width={26}
        src={img}
        alt={`Heart ${buttonMode}`}
        className={styles.heart}
        style={{ opacity: hoveredHeart === buttonMode || landlordRating === buttonMode ? 1 : 0 }}
      />

      <img
        width={26}
        src={imgActive}
        alt={`Heart ${buttonMode}`}
        className={styles.heart}
        style={{ opacity: hoveredHeart === buttonMode || landlordRating === buttonMode ? 0 : 1 }}
      />
      <span className="sr-only">{t('like')}</span>
    </button>
  );
};

export default LandlordRatingButtons;
