import { FC, useContext, useEffect, useState } from 'react';
import useApartment from '../../../core/hook/apartment.hook';
import ApartmentInformation from '../../../component/organisms/ApartmentInformation';
import MapboxMap from '../../../component/molecules/Map';
import { ROUTES } from 'core/const/routes';
import { useTranslation } from 'react-i18next';
import styles from './ShareApartmentView.module.scss';
import useWindowSize from '../../../core/hook/windowsize.hook';
import UserContext from '../../../core/context/user.context';
import { motion } from 'framer-motion';
import { wohnsinnServices } from 'App';
import SnackBarContext from '../../../core/context/snackbar.context';
import { Grid } from '@mui/material';
import CTACard from '../../../component/molecules/Cards/CTACard';
import ApartmentStackPageLayout from '../../../component/layouts/ApartmentStackPageLayout';
import { animateToLeft, animateToRight, initial } from 'core/const/matching-animations';
import ApartmentInformationLoading from '../../../component/molecules/LoadingElements/ApartmentInformationLoading';
import ApartmentRatingButtons from '../../../component/atoms/ApartmentRatingButtons';
import { useNavigate } from 'react-router-dom';
export type TAnimationDirection = 'left' | 'right' | 'initial';

const ShareApartmentView: FC = () => {
  const { apartment, isLoading } = useApartment();
  const { handleSnackBar } = useContext(SnackBarContext);
  const { mixpanelTrackingService } = wohnsinnServices;
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const { t: v } = useTranslation('common', { keyPrefix: 'view.ShareApartmentView' });
  const { isGreaterMd } = useWindowSize();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [animationDirection, setAnimationDirection] = useState<TAnimationDirection>('initial');

  useEffect(() => {
    if (apartment) {
      if (user) {
        mixpanelTrackingService.trackEnterSharedView({
          pageTitle: 'ShareViewWithUser',
          id: apartment.id,
          isExclusive: !!apartment?.isExclusive,
          isPro: !!apartment?.isPro,
        });
      } else {
        mixpanelTrackingService.trackEnterSharedView({
          pageTitle: 'ShareViewAnonym',
          id: apartment.id,
          isExclusive: !!apartment?.isExclusive,
          isPro: !!apartment?.isPro,
        });
      }
    }
  }, [apartment]);

  useEffect(() => {
    if (apartment && !apartment.isPublished) {
      handleSnackBar('toast.apartment.notAvailable', 'warning');
    }
  }, [apartment]);

  if (!isLoading && !apartment) {
    return (
      <ApartmentStackPageLayout backButtonRoute={'/'} pageTitle={r(ROUTES.landlordRoutes.apartment.preview.title)}>
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} md={6}>
            <div className={styles.notExistingApartmentWrapper}>
              <CTACard
                title={v('apartmentNotExisting.title')}
                text={v('apartmentNotExisting.text')}
                imgSrc={t('pictogram.object')}
                imgAltText={t('bookmarkIcon')}
                link={'/'}
                ctaText={'Zur Übersicht'}
              />
            </div>
          </Grid>
        </Grid>
      </ApartmentStackPageLayout>
    );
  }

  if (isLoading) {
    return (
      <ApartmentStackPageLayout backButtonRoute={'/'} pageTitle={r(ROUTES.landlordRoutes.apartment.preview.title)}>
        <ApartmentInformationLoading />
      </ApartmentStackPageLayout>
    );
  }

  return (
    <ApartmentStackPageLayout backButtonRoute={'/'} pageTitle={r(ROUTES.landlordRoutes.apartment.preview.title)}>
      {apartment ? (
        <div className={styles.wrapper}>
          <motion.div
            key={apartment?.id}
            className={styles.apartment}
            style={{
              zIndex: 10,
            }}
            animate={getAnimation(true, animationDirection)}
          >
            <ApartmentInformation apartment={apartment} />
          </motion.div>
          <div className={styles.ratings}>
            <ApartmentRatingButtons
              isApplicationView={false}
              apartment={apartment}
              setAnimationDirection={setAnimationDirection}
            />
          </div>
          <div className={styles.map}>
            {!isLoading && !!apartment && isGreaterMd && <MapboxMap isInteractive={true} apartments={[apartment]} />}
          </div>
        </div>
      ) : null}
    </ApartmentStackPageLayout>
  );
};

/**
 * Get styles for rating animations
 * @param {boolean} canAnimate
 * @param  {TAnimationDirection} animationDirection
 */
export const getAnimation = (canAnimate: boolean, animationDirection: TAnimationDirection) => {
  switch (canAnimate && animationDirection) {
    case 'initial':
      return initial;
    case 'left':
      return animateToLeft;
    case 'right':
      return animateToRight;
  }
};

export default ShareApartmentView;
