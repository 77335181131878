import { TableBody, TableCell, TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'core/const/routes';
import styles from '../ApartmentsTable.module.scss';
import ContextMenu, { IContextMenuItem } from 'component/atoms/ContextMenu';
import { useTranslation } from 'react-i18next';
import { FC, useContext } from 'react';
import UserContext from 'core/context/user.context';
import { IApartment } from '@wohnsinn/ws-ts-lib';
import CTAButton, { BUTTON_STYLE } from 'component/atoms/Buttons/CTAButton';
import Badge, { BADGE_COLORS } from 'component/atoms/Badge';
import { faPause, faPencil, faPlay, faShare, faTrash, faEdit, faRectanglePro } from '@fortawesome/pro-light-svg-icons';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import ModalContext from 'core/context/modal.context';
import { ACTION_TYPE, useHandleApartmentActionMutation } from 'core/hook/apartment-queries.hook';
import Avatar, { AVATAR_SIZE } from 'component/atoms/Avatar';
import Text, { TEXT_COLOR } from 'component/atoms/typographie/Text';
import truncateText from 'core/helper/truncate-text';
import { formatNumberGermanStyle } from 'core/helper/get-formatted-price';
import { wohnsinnServices } from 'App';
import { useQueryClient } from '@tanstack/react-query';

const ApartmentsTableBody: FC<{
  apartments: IApartment[];
}> = ({ apartments }) => {
  const { t: r } = useTranslation('routes');
  const { t } = useTranslation('common');
  const { user } = useContext(UserContext);
  const { openModal } = useContext(ModalContext);
  const navigate = useNavigate();
  const { mutate } = useHandleApartmentActionMutation();
  const { apartmentService } = wohnsinnServices;
  const queryClient = useQueryClient();

  const createContextMenu = (apartment: IApartment): IContextMenuItem[] => {
    const handleEdit = () => {
      navigate(r(ROUTES.landlordRoutes.apartment.overview.path).replace(':apartmentId', apartment.id));
    };

    const contextMenu = [
      {
        onClick: handleEdit,
        icon: faPencil,
        text: t('edit'),
      },
      {
        onClick: () => openModal({ id: MODAL_IDENTIFIER.PUBLISH_APARTMENT, data: { apartment } }),
        icon: apartment.isPublished ? faPause : faPlay,
        text: apartment.isPublished ? t('deactivateListing') : t('publish'),
      },
      {
        onClick: () => openModal({ id: MODAL_IDENTIFIER.SHARE_LINK, data: { apartmentId: apartment.id } }),
        icon: faShare,
        text: t('shareApartment'),
        disabled: apartment?.isPublished,
      },
      {
        onClick: () =>
          openModal({
            id: MODAL_IDENTIFIER.DELETE_MODAL,
            data: {
              title: 'Objekt löschen?',
              text: 'Bist du dir sicher dass du dieses Objekt mit allen Bewerbungen löschen möchtest?',
              deleteHandler: async () => {
                mutate({ selectedApartments: [apartment], actionType: ACTION_TYPE.DELETE });
              },
            },
          }),
        icon: faTrash,
        text: t('deleteListing'),
      },
    ];
    if (user?.isAdmin) {
      contextMenu.push({
        onClick: async () => {
          await apartmentService.updateApartment({
            data: { isPro: !apartment?.isPro },
            apartmentId: apartment?.id,
            creatorId: apartment?.creatorId,
          });
          await queryClient.invalidateQueries({ queryKey: ['apartments'] });
        },
        icon: faRectanglePro,
        text: apartment?.isPro ? t('deletePro') : t('addPro'),
      });
    }
    return contextMenu;
  };

  const navigateToApartment = (apartmentId: string) => {
    navigate(r(ROUTES.landlordRoutes.apartment.overview.path).replace(':apartmentId', apartmentId));
  };

  return (
    <TableBody>
      {apartments.map((apartment, index) => {
        const CONTEXT_MENU = createContextMenu(apartment);

        return (
          <TableRow style={{ cursor: 'pointer' }} key={apartment.id + index} hover={true}>
            <TableCell
              align={'center'}
              style={{ paddingRight: 0 }}
              width={70}
              onClick={() => navigateToApartment(apartment.id)}
            >
              <Avatar
                size={AVATAR_SIZE.md}
                avatarUrl={
                  apartment?.media[0]?.url ? apartment?.media[0]?.url : 'assets/images/apartment-placeholder.webp'
                }
              />
            </TableCell>
            <TableCell className={styles.apartmentsCell} onClick={() => navigateToApartment(apartment.id)}>
              <div className={styles.apartment}>
                <Text bold className={styles.name}>
                  {truncateText(`${apartment?.mainInformation?.address?.street}`, 25)}
                  {` ${apartment?.mainInformation?.address?.houseNumber}`}
                </Text>
                <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
                  {truncateText(
                    `${apartment?.mainInformation?.address?.postalCode} ${apartment?.mainInformation?.address?.city}`,
                    25
                  )}
                </Text>
              </div>
            </TableCell>
            {user?.isAdmin && (
              <TableCell align={'right'} onClick={() => navigateToApartment(apartment.id)}>
                <Text align={'right'} noWrap color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
                  {apartment?.isPro ? `Pro` : '-'}
                </Text>
              </TableCell>
            )}
            <TableCell align={'right'} onClick={() => navigateToApartment(apartment.id)}>
              <Text align={'right'} noWrap color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
                {apartment?.cost?.warmRent
                  ? `${formatNumberGermanStyle(apartment?.cost?.warmRent)} €`
                  : apartment?.cost?.coldRent
                  ? `${formatNumberGermanStyle(apartment?.cost?.coldRent)} €`
                  : '-'}
              </Text>
            </TableCell>
            <TableCell align={'right'} onClick={() => navigateToApartment(apartment.id)}>
              <Text align={'right'} noWrap color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
                {apartment?.areas?.livingArea ? `${formatNumberGermanStyle(apartment?.areas?.livingArea)} m²` : '-'}
              </Text>
            </TableCell>
            <TableCell
              onClick={() => navigateToApartment(apartment.id)}
              align={'right'}
              color={TEXT_COLOR.TEXT_COLOR_ACCENT}
            >
              <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT} align={'right'}>
                {apartment?.areas?.numberOfRooms ?? '-'}
              </Text>
            </TableCell>

            {user?.isAdmin && (
              <TableCell onClick={() => navigator.clipboard.writeText(apartment?.creatorId)}>
                <div className={styles.minWidth}>
                  <Text>{apartment?.contactPerson?.company ?? '-'}</Text>
                </div>
              </TableCell>
            )}

            {user?.isAdmin && (
              <TableCell onClick={() => navigator.clipboard.writeText(apartment?.contactPerson?.name)}>
                <Text bold>{`${apartment?.contactPerson?.firstName} ${apartment?.contactPerson?.name}`}</Text>
                <Text>{`${apartment?.creatorId}`}</Text>
              </TableCell>
            )}
            {user?.isAdmin && (
              <TableCell>
                {apartment?.publishedAt
                  ? `${new Date(apartment?.publishedAt).toLocaleDateString()} - ${new Date(
                      apartment?.publishedAt
                    ).toLocaleTimeString()}`
                  : '-'}
              </TableCell>
            )}
            <TableCell onClick={() => navigateToApartment(apartment.id)}>
              <Badge
                bold={false}
                color={apartment.isPublished ? BADGE_COLORS.SUCCESS : BADGE_COLORS.DISABLED}
                text={apartment.isPublished ? 'Veröffentlicht' : 'Deaktiviert'}
              />
            </TableCell>
            <TableCell>
              <CTAButton
                buttonStyle={BUTTON_STYLE.SECONDARY}
                notificationBadge={!!apartment.newApplicationsRef?.length}
                rounded={false}
                onClick={() =>
                  navigate(r(ROUTES.landlordRoutes.apartment.applications.path).replace(':apartmentId', apartment.id))
                }
                size={'small'}
                buttonText={`${apartment?.applicationRefList?.length} ${t('applications')}`}
              />
            </TableCell>
            <TableCell id={apartment.id} align={'left'}>
              <CTAButton
                buttonStyle={BUTTON_STYLE.SECONDARY}
                icon={faEdit}
                iconPosition={'start'}
                size={'small'}
                rounded={false}
                buttonText={t('edit')}
                onClick={() => navigateToApartment(apartment.id)}
              />
            </TableCell>
            <TableCell id={apartment.id} align={'left'} className={styles.stickyCell}>
              <ContextMenu menu={CONTEXT_MENU} />
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};
export default ApartmentsTableBody;
