import { Dispatch, FC, SetStateAction, useState } from 'react';
import {
  Checkbox,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import FormHeader from 'component/atoms/FormHeader';
import FormNavigation from 'component/molecules/FormNavigation';
import Avatar, { AVATAR_SIZE } from 'component/atoms/Avatar';
import Text, { TEXT_COLOR, TEXT_TYPE } from 'component/atoms/typographie/Text';
import LandlordRatingButtons from 'component/molecules/LandlordRatingButtons';
import { APPLICATION_STATE, IApplication, MATCHING_MODE } from '@wohnsinn/ws-ts-lib';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import styles from './ApartmentSelectTenantsForMessage.module.scss';
import truncateText from 'core/helper/truncate-text';
import { printAge } from 'core/helper/print-age';
import { useNavigate } from 'react-router-dom';
import CTAButton, { BUTTON_STYLE } from 'component/atoms/Buttons/CTAButton';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';

interface SelectTenantsStepProps {
  selectedApplications: IApplication[];
  setSelectedApplications: Dispatch<SetStateAction<IApplication[]>>;
  applicationList: IApplication[];
  setStep: Dispatch<SetStateAction<1 | 2 | 3>>;
}

const SelectTenantsStep: FC<SelectTenantsStepProps> = ({
  selectedApplications,
  setSelectedApplications,
  applicationList,
  setStep,
}) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectFavorites, setSelectFavorites] = useState(false);
  const [selectMaybes, setSelectMaybes] = useState(false);
  const [selectNopes, setSelectNopes] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const handleDropdownClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfirmSelection = () => {
    if (selectAll) {
      setSelectedApplications(applicationList);
    } else {
      const selectedRatings: MATCHING_MODE[] = [];
      if (selectFavorites) selectedRatings.push(MATCHING_MODE.LIKE);
      if (selectMaybes) selectedRatings.push(MATCHING_MODE.MAYBE);
      if (selectNopes) selectedRatings.push(MATCHING_MODE.NOPE);

      setSelectedApplications(
        selectedRatings.length > 0
          ? applicationList.filter((application) => selectedRatings.includes(application.landlordRating))
          : []
      );
    }
    handleClose();
  };

  const handleCheckboxChange = (application: IApplication) => {
    setSelectedApplications((prevSelected) => {
      const isAlreadySelected = prevSelected.some(
        (selected) => selected.tenantProfile.uid === application.tenantProfile.uid
      );

      return isAlreadySelected
        ? prevSelected.filter((selected) => selected.tenantProfile.uid !== application.tenantProfile.uid)
        : [...prevSelected, application];
    });
  };

  const getTenantDescription = (personalInformation: IApplication['tenantProfile']['personalInformation']) => {
    return personalInformation.customJobDescription || 'Job description';
  };

  return (
    <>
      <FormHeader title={t('multiMessage.header')} />
      <Grid container rowSpacing={{ xs: 0, md: 0 }} className="form-with-navigation">
        <Grid item xs={12} md={8} className="form-with-navigation">
          <div className={styles.selectMenu}>
            <CTAButton
              icon={faChevronDown}
              iconPosition={'end'}
              onClick={handleDropdownClick}
              buttonText={t('select')}
              rounded={false}
              buttonStyle={BUTTON_STYLE.SECONDARY}
            />
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
              <MenuItem
                onClick={() => {
                  setSelectAll((prev) => {
                    const newValue = !prev;
                    if (newValue) {
                      setSelectFavorites(false);
                      setSelectMaybes(false);
                      setSelectNopes(false);
                    }
                    return newValue;
                  });
                }}
              >
                <Checkbox checked={selectAll} inputProps={{ 'aria-label': t('selectAll.all') }} />
                {t('selectAll.all')}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setSelectFavorites((prev) => !prev);
                  if (selectAll) setSelectAll(false);
                }}
              >
                <Checkbox
                  checked={selectFavorites}
                  disabled={selectAll}
                  inputProps={{ 'aria-label': t('selectAll.likes') }}
                />
                {t('selectAll.likes')}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setSelectMaybes((prev) => !prev);
                  if (selectAll) setSelectAll(false);
                }}
              >
                <Checkbox
                  checked={selectMaybes}
                  disabled={selectAll}
                  inputProps={{ 'aria-label': t('selectAll.maybe') }}
                />
                {t('selectAll.maybe')}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setSelectNopes((prev) => !prev);
                  if (selectAll) setSelectAll(false);
                }}
              >
                <Checkbox
                  checked={selectNopes}
                  disabled={selectAll}
                  inputProps={{ 'aria-label': t('selectAll.nopes') }}
                />
                {t('selectAll.nopes')}
              </MenuItem>
              <MenuItem>
                <CTAButton
                  rounded={false}
                  expand={'full'}
                  buttonText={t('apply.doit')}
                  onClick={handleConfirmSelection}
                />
              </MenuItem>
            </Menu>
            <Text tag={'p'}>
              {selectedApplications.length} {t('ausgewählt')}
            </Text>
          </div>

          <TableContainer component={Paper} className={styles.tableContainer}>
            <Table>
              <TableBody>
                {applicationList.map((application) => (
                  <TableRow
                    className={styles.row}
                    key={`${application.apartmentId}-${application.tenantProfile.uid}`}
                    onClick={() => handleCheckboxChange(application)}
                    style={{ cursor: 'pointer' }}
                    selected={selectedApplications.some(
                      (selected) => selected.tenantProfile.uid === application.tenantProfile.uid
                    )}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedApplications.some(
                          (selected) => selected.tenantProfile.uid === application.tenantProfile.uid
                        )}
                        onChange={() => handleCheckboxChange(application)}
                        onClick={(e) => e.stopPropagation()} // Prevents event bubbling
                      />
                    </TableCell>
                    <TableCell align="left">
                      <div className={styles.applicant}>
                        <Avatar size={AVATAR_SIZE.md} avatarUrl={application.tenantProfile.photoUrl} />
                        <div>
                          <Text className={styles.name} bold tag="span">
                            {`${application.tenantProfile.personalInformation.firstName} ${
                              application.tenantProfile.personalInformation.lastName
                            }, ${printAge(application.tenantProfile.personalInformation.dateOfBirth)}`}
                          </Text>
                          <br />
                          <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION} color={TEXT_COLOR.TEXT_COLOR_ACCENT} tag="span">
                            {truncateText(getTenantDescription(application.tenantProfile.personalInformation), 25)} ·{' '}
                            {application.tenantProfile.household?.monthlyIncome} € ·{' '}
                            {t('numberOfPeopleMovingInText', {
                              count: parseInt(
                                application.tenantProfile.household?.numberOfPeopleMovingIn as unknown as string
                              ),
                            })}
                          </Text>
                        </div>
                      </div>
                      {application.applicationState === APPLICATION_STATE.WILL_BE_DELETED && (
                        <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT} tag="span">
                          {`(Löschung: ${
                            application.deleteDate
                              ? new Date(application.deleteDate).toLocaleDateString('de-DE', {
                                  day: '2-digit',
                                  month: '2-digit',
                                  year: 'numeric',
                                })
                              : ''
                          })`}
                        </Text>
                      )}
                    </TableCell>
                    <TableCell>
                      <LandlordRatingButtons
                        apartmentId={application.apartmentId}
                        landlordRating={application.landlordRating}
                        tenantProfileId={application.tenantProfile.uid}
                        currentApplicationState={application?.applicationState}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <FormNavigation
        primaryAction={() => setStep(2)}
        disabled={!selectedApplications?.length}
        showSecondaryButtonMobile={true}
        submitButtonText={t('next')}
        secondaryButtonAction={() => navigate(-1)}
        secondaryButtonColor={TEXT_COLOR.TEXT_COLOR_ACCENT}
        secondaryButtonText={t('cancel')}
        buttonSubmitMode={SUBMIT_BUTTON_MODE.NONE}
      />
    </>
  );
};

export default SelectTenantsStep;
