import { FC, useContext, useState } from 'react';
import useFormErrorHook from 'core/hook/use-form-error.hook';
import { wohnsinnServices } from 'App';
import SnackBarContext from 'core/context/snackbar.context';
import { useTranslation } from 'react-i18next';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { useFieldArray, useForm } from 'react-hook-form';
import { IApartment, ILink, MEDIA_TYPE } from '@wohnsinn/ws-ts-lib';
import { useQueryClient } from '@tanstack/react-query';
import { FormContext } from 'core/context/form.context';
import { Grid } from '@mui/material';
import CTAButton, { getSubmitIcon } from 'component/atoms/Buttons/CTAButton';
import Spacer, { SPACER_SIZE } from 'component/atoms/Spacer';
import TextInput from 'component/atoms/formElement/InputText';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import LinkListItem from 'component/atoms/LinkListItem';

export const LINK_FORM_SCHEMA = Joi.object({
  link: Joi.object({
    url: Joi.string().required(),
    alt: Joi.string().required(),
  }),
});

const ApartmentLinksUpload: FC<{ apartment: IApartment }> = ({ apartment }) => {
  const { handleError } = useFormErrorHook('ApartmentCostForm');
  const { apartmentService } = wohnsinnServices;
  const { handleSnackBar } = useContext(SnackBarContext);
  const queryClient = useQueryClient();
  const { t } = useTranslation('common');
  const { t: ac } = useTranslation('common', { keyPrefix: 'apartment.links' });
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);

  const { control, watch } = useForm<{ links: ILink[] }>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: {
      links: apartment?.links ?? [],
    },
  });

  const {
    control: linkControl,
    handleSubmit,
    formState: { isValid },
    reset,
    getValues,
  } = useForm<{ link: ILink }>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: joiResolver(LINK_FORM_SCHEMA),
  });

  const { fields, append, remove } = useFieldArray({
    name: 'links',
    control,
  });

  const onError = (err: any): void => {
    console.error(err);
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
  };

  const saveDataToDB = async (): Promise<void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);

    try {
      addLink();

      const formValue = watch();
      await apartmentService.updateApartment({
        data: { links: formValue.links },
        creatorId: apartment.creatorId,
        apartmentId: apartment.id,
      });

      reset();

      await queryClient.invalidateQueries({ queryKey: ['apartments'] });
      handleSnackBar('toast.success.saved', 'success');
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
    } catch (e) {
      handleError(e);
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
    }
  };

  const deleteLink = async (index: number) => {
    try {
      const newLinks = apartment?.links;
      newLinks?.splice(index, 1);
      await apartmentService.updateApartment({
        data: { links: newLinks },
        creatorId: apartment.creatorId,
        apartmentId: apartment.id,
      });
      remove(index);
    } catch (error) {
      console.error('Error on deleteLink', error);
    }
  };

  const addLink = () => {
    const link = getValues().link;

    append({
      id: `link-${fields.length}`,
      alt: link.alt,
      url: link.url,
      mediaType: MEDIA_TYPE.LINK,
      updatedAt: new Date(),
    });
  };

  return (
    <div>
      <FormContext.Provider value={{ control: linkControl }}>
        <form onSubmit={handleSubmit(saveDataToDB, onError)} noValidate autoComplete="off">
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <ul>
                {apartment?.links?.map((item, index) => (
                  <li key={item.id}>
                    <LinkListItem link={item} deleteHandler={() => deleteLink(index)} />
                  </li>
                ))}
              </ul>
              <Spacer size={SPACER_SIZE.MD} />

              <Grid container columnSpacing={2} rowSpacing={1}>
                <Grid item xs={12} md={6}>
                  <TextInput
                    disabled={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING}
                    label={ac('link.name.label')}
                    placeholder={ac('link.name.placeholder')}
                    name={`link.alt`}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput
                    disabled={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING}
                    label={ac('link.url.label')}
                    placeholder={ac('link.url.placeholder')}
                    name={`link.url`}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CTAButton
                    size="small"
                    rounded={false}
                    disabled={!isValid}
                    icon={getSubmitIcon(buttonSubmitMode)}
                    buttonText={t('Link hinzufügen')}
                    expand={'block'}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormContext.Provider>
    </div>
  );
};

export default ApartmentLinksUpload;
