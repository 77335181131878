import { useTranslation } from 'react-i18next';
import styles from './FilterMenu.module.scss';
import TextInput from '../../atoms/formElement/InputText';
import InputOptionList, { RADIO_INPUT_COLUMNS_COUNT } from '../../atoms/formElement/InputOptionList';
import {
  BOOLEAN_SELECT_OPTIONS,
  getTranslatedOptions,
  SEARCH_RENT_OBJECT_SELECT_OPTIONS,
} from 'core/const/select-options';
import { FormContext } from 'core/context/form.context';
import { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  APARTMENT_TYPE_OF_MARKETING,
  EQUIPMENT,
  ILocationSelection,
  ISelectOption,
  ITenantFilterParams,
} from '@wohnsinn/ws-ts-lib';
import Text, { TEXT_COLOR } from '../../atoms/typographie/Text';
import SplitLine from '../../atoms/SplitLine';
import ApartmentInLocationFinder from '../../molecules/ApartmentInLocationFinder';
import CTAButton, { BUTTON_STYLE } from '../../atoms/Buttons/CTAButton';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import ModalContext from 'core/context/modal.context';
import DrawerWrapper from '../../molecules/DrawerWrapper';
import TenantFilterParamsContext from 'core/context/tenant-filter-params.context';
import UserContext from 'core/context/user.context';
import SearchProfiles from '../SearchProfiles';
import { wohnsinnServices } from 'App';
import { faBookmark, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import Tabs, { TABS_TAB_PROPS } from '../../atoms/Tabs';
import InfoBox from 'component/atoms/InfoBox';
import { ROUTES } from 'core/const/routes';

export const numberOptions: ISelectOption[] = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '+6' },
];

export const EQUIPMENT_LIST_SELECT_OPTIONS: ISelectOption<EQUIPMENT>[] = [
  {
    label: 'component.organisms.searchTunnel.selectEquipmentStep.equipmentList.HAS_BASEMENT',
    value: EQUIPMENT.HAS_BASEMENT,
  },
  {
    label: 'component.organisms.searchTunnel.selectEquipmentStep.equipmentList.HAS_PARKING_SPOT',
    value: EQUIPMENT.HAS_PARKING_SPOT,
  },
  {
    label: 'component.organisms.searchTunnel.selectEquipmentStep.equipmentList.HAS_GARDEN',
    value: EQUIPMENT.HAS_GARDEN,
  },
  {
    label: 'component.organisms.searchTunnel.selectEquipmentStep.equipmentList.HAS_GUEST_TOILET',
    value: EQUIPMENT.HAS_GUEST_TOILET,
  },
  {
    label: 'component.organisms.searchTunnel.selectEquipmentStep.equipmentList.HAS_ELEVATOR',
    value: EQUIPMENT.HAS_ELEVATOR,
  },
  {
    label: 'component.organisms.searchTunnel.selectEquipmentStep.equipmentList.HAS_KITCHEN',
    value: EQUIPMENT.HAS_KITCHEN,
  },
  {
    label: 'component.organisms.searchTunnel.selectEquipmentStep.equipmentList.HAS_BALCONY_OR_TERRACE',
    value: EQUIPMENT.HAS_BALCONY_OR_TERRACE,
  },
];

enum FILTER_TYPE {
  NEW = 'NEW',
  SAVED = 'SAVED',
}

const FilterMenu: FC<{ onClose: Dispatch<SetStateAction<boolean>> }> = ({ onClose }) => {
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const { t: f } = useTranslation('common', { keyPrefix: 'component.molecules.modals.MatchesFilterModal' });
  const { tenantFilterParams, updateTenantFilterParams } = useContext(TenantFilterParamsContext);
  const { openModal } = useContext(ModalContext);
  const { user, tenantProfile } = useContext(UserContext);
  const [filterType, setFilterType] = useState<FILTER_TYPE>(FILTER_TYPE.NEW);
  const params = new URLSearchParams(location.search);
  const [isSavingSearch, setIsSavingSearch] = useState<boolean>(false);
  const [searchIsSaved, setSearchIsSaved] = useState(!!params.get('searchId'));

  const { control, setValue, watch, handleSubmit } = useForm<ITenantFilterParams>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: tenantFilterParams,
  });
  const value = watch();
  const updateTenantFilterParamsHandler = async () => {
    onClose(false);
    updateTenantFilterParams(value);
  };

  useEffect(() => {
    setSearchIsSaved(!!params.get('searchId'));
  }, [location.search]);

  useEffect(() => {
    if (tenantFilterParams) {
      setValue('location', tenantFilterParams?.location);
    }
  }, [tenantFilterParams]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: FILTER_TYPE) => {
    switch (newValue) {
      case FILTER_TYPE.NEW:
        setFilterType(FILTER_TYPE.NEW);
        break;
      case FILTER_TYPE.SAVED:
        setFilterType(FILTER_TYPE.SAVED);
        break;
      default:
        setFilterType(FILTER_TYPE.NEW);
    }
  };

  async function addTenantSearchProfile() {
    updateTenantFilterParams(value);
    setIsSavingSearch(true);

    try {
      await wohnsinnServices.tenantService.addTenantSearchProfile(tenantProfile.uid, value);
    } catch (e) {
      console.error('Error on addTenantSearchProfile', e);
    }

    setSearchIsSaved(true);
    setIsSavingSearch(false);
    setFilterType(FILTER_TYPE.SAVED);
  }

  const FILTER_MENU_TABS: TABS_TAB_PROPS[] = [
    {
      value: FILTER_TYPE.NEW,
      label: t('newSearch'),
      id: 'filter-tab-new-search',
    },
    {
      value: FILTER_TYPE.SAVED,
      label: t('savedSearch'),
      id: 'filter-tab-saved-search',
    },
  ];

  return (
    <DrawerWrapper noTitleMargin onClose={onClose} title={f('title')}>
      {user ? (
        <Tabs tabs={FILTER_MENU_TABS} onChange={handleTabChange} value={filterType} id={'save-search-filter-tabs'} />
      ) : null}
      {filterType === FILTER_TYPE.NEW ? (
        <FormContext.Provider value={{ control }}>
          <form id={'filterModal'} onSubmit={handleSubmit(updateTenantFilterParamsHandler)}>
            {process.env.REACT_APP_SHOW_SALE === 'true' && (
              <div className={styles.filterSection}>
                <Text>{t('Was suchst du?')}</Text>
                <InputOptionList
                  centerLabel
                  mode={'radio'}
                  columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
                  options={getTranslatedOptions(SEARCH_RENT_OBJECT_SELECT_OPTIONS, t)}
                  name={'typeOfMarketing'}
                />
              </div>
            )}

            <div className={styles.filterSection}>
              <Text>{t('component.molecules.ApartmentInLocationFinder.title')}</Text>
              <ApartmentInLocationFinder
                hideTitle
                withRedirect={false}
                onChange={(location: ILocationSelection) => setValue('location', location)}
              />
            </div>
            <div className={styles.filterSection}>
              {watch('typeOfMarketing') === APARTMENT_TYPE_OF_MARKETING.SALE ? (
                <>
                  <Text>{t('Wie viel willst du maximal ausgeben?')}</Text>
                  <TextInput
                    name={'purchasePrice'}
                    unit={t('currency')}
                    pattern="[0-9]*"
                    min={10}
                    type={'number'}
                    label={t('Kaufpreis')}
                  />
                </>
              ) : (
                <>
                  <Text>{t('component.organisms.searchTunnelSlides.WarmRent.title')}</Text>
                  <TextInput
                    name={'warmRent'}
                    unit={t('currency')}
                    pattern="[0-9]*"
                    min={10}
                    type={'number'}
                    label={t('component.organisms.searchTunnelSlides.WarmRent.placeholder')}
                  />
                </>
              )}
            </div>
            {!user && (
              <div className={styles.filterSection}>
                <Text>{t('component.organisms.searchTunnelSlides.NumberOfPeopleMovingIn.title')}</Text>
                <InputOptionList
                  mode={'radio'}
                  name={'numberOfPeopleMovingIn'}
                  options={numberOptions}
                  columns={RADIO_INPUT_COLUMNS_COUNT.THREE}
                />
              </div>
            )}
            {!user && (
              <div className={styles.filterSection}>
                <Text>{t('component.organisms.searchTunnelSlides.ArePetsAllowed.title')}</Text>
                <InputOptionList
                  mode={'radio'}
                  columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
                  options={getTranslatedOptions(BOOLEAN_SELECT_OPTIONS, t)}
                  name={'arePetsAllowed'}
                />
              </div>
            )}

            {!user && (
              <div className={styles.filterSection}>
                <Text>{t('component.organisms.searchTunnelSlides.IsSharedUsagePossible.title')}</Text>
                <InputOptionList
                  mode={'radio'}
                  columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
                  options={getTranslatedOptions(BOOLEAN_SELECT_OPTIONS, t)}
                  name={'isSharedUsagePossible'}
                />
              </div>
            )}
            {!user && (
              <div className={styles.filterSection}>
                <Text>{t('component.organisms.searchTunnelSlides.canBeSubsidized.title')}</Text>
                <InputOptionList
                  mode={'radio'}
                  columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
                  options={getTranslatedOptions(BOOLEAN_SELECT_OPTIONS, t)}
                  name={'canBeSubsidized'}
                />
              </div>
            )}

            <SplitLine size={'small'} />

            <div className={styles.filterSection}>
              <Text>{t('component.organisms.searchTunnelSlides.Rooms.title')}</Text>
              <InputOptionList
                mode={'radio'}
                name={'rooms'}
                options={numberOptions}
                columns={RADIO_INPUT_COLUMNS_COUNT.THREE}
              />
            </div>
            <div className={styles.filterSection}>
              <Text>{t('component.organisms.searchTunnelSlides.Size.title')}</Text>
              <TextInput
                name={'size'}
                pattern="[0-9]*"
                type={'number'}
                unit={`m²`}
                min={1}
                label={t('component.organisms.searchTunnelSlides.Size.placeholder')}
              />
            </div>
            <div className={styles.filterSection}>
              <Text>{t('component.organisms.searchTunnelSlides.Equipment.title')}</Text>
              <div>
                <InputOptionList
                  mode={'checkbox'}
                  name={'equipmentList'}
                  options={getTranslatedOptions(EQUIPMENT_LIST_SELECT_OPTIONS, t)}
                />
              </div>
            </div>
            {user && (
              <InfoBox
                size={'small'}
                bold={false}
                text={t('mainInformation.infoBox')}
                linkTo={r(ROUTES.tenantRoutes.account.overview.path)}
              />
            )}

            <div className={styles.buttonWrapper}>
              <CTAButton
                type={'button'}
                buttonStyle={BUTTON_STYLE.TERTIARY}
                color={TEXT_COLOR.TEXT_COLOR_DANGER}
                expand={'block'}
                rounded={false}
                buttonText={f('resetFilter')}
                onClick={() => openModal({ id: MODAL_IDENTIFIER.FILTER_RESET, data: { onClose } })}
              />

              {(user && !searchIsSaved) || !user ? (
                <CTAButton
                  icon={isSavingSearch ? faSpinner : faBookmark}
                  spinIcon={isSavingSearch}
                  rounded={false}
                  buttonStyle={BUTTON_STYLE.SECONDARY}
                  buttonText={t('saveSearch')}
                  type={'button'}
                  onClick={
                    user
                      ? addTenantSearchProfile
                      : () =>
                          openModal({
                            id: MODAL_IDENTIFIER.SIGN_UP_USER,
                            data: {
                              title: 'Erstelle ein Konto, um dich zu bewerben',
                              isProbablyTenant: true,
                            },
                          })
                  }
                />
              ) : null}

              <CTAButton type={'submit'} expand={'block'} rounded={false} buttonText={f('showResults')} />
            </div>
          </form>
        </FormContext.Provider>
      ) : (
        <SearchProfiles onClick={onClose} showTitle={false} />
      )}
    </DrawerWrapper>
  );
};

export default FilterMenu;
