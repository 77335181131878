import { FC } from 'react';
import styles from './ListWithIcons.module.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Text, { TEXT_COLOR } from 'component/atoms/typographie/Text';

export interface IListWithIconItem {
  text: string;
  icon: IconProp;
}

const ListWithIcons: FC<{ list: IListWithIconItem[]; color?: TEXT_COLOR }> = ({
  list,
  color = TEXT_COLOR.TEXT_COLOR_ACCENT,
}) => {
  return (
    <ul className={styles.list}>
      {list.map((item, index) => (
        <li key={`${item.text} ${index}`}>
          <div className={styles.icon}>
            <FontAwesomeIcon icon={item.icon} />
          </div>
          <Text color={color}>
            <Text tag={'span'}>{item.text}</Text>
          </Text>
        </li>
      ))}
    </ul>
  );
};

export default ListWithIcons;
