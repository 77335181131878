import { createContext } from 'react';
import { IUserWithRoles, USER_TYPE, ILandlordProfile, ITenantProfile } from '@wohnsinn/ws-ts-lib';
import { User } from '@firebase/auth';

const UserContext = createContext<{
  activeUserType: USER_TYPE;
  landlordProfile: ILandlordProfile | null;
  tenantProfile: ITenantProfile | null;
  user: IUserWithRoles | null;
  authUser: User;
  isPremiumUser: boolean;
}>({
  activeUserType: USER_TYPE.NONE,
  landlordProfile: null,
  tenantProfile: null,
  user: null,
  authUser: null,
  isPremiumUser: false,
});

export default UserContext;
