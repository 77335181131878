import { useTranslation } from 'react-i18next';
import { ROUTES } from 'core/const/routes';
import { Grid } from '@mui/material';
import { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import { USER_TYPE } from '@wohnsinn/ws-ts-lib';
import PricingCard from 'component/molecules/Cards/PricingCard';
import { ICheckListItem } from 'component/atoms/CheckList';
import LandlordSection from 'component/organisms/landing-page-sections/LandlordSection';
import LandingPageLayout from '../../../component/layouts/LandingPageLayout';
import ContactSection from 'component/organisms/landing-page-sections/ContactSection';
import Headline from '../../../component/atoms/typographie/Headline';

const PriceView = () => {
  const { t: r } = useTranslation('routes');
  const { t: p } = useTranslation('common', { keyPrefix: 'view.general.PricesView' });

  const CHECK_LIST_BUSINESS: ICheckListItem[] = [
    { text: p('cards.business.one') },
    { text: p('cards.business.two') },
    { text: p('cards.business.three') },
    { text: p('cards.business.four') },
  ];

  const CHECK_LIST_TENANT: ICheckListItem[] = [
    { text: p('cards.tenant.one') },
    { text: p('cards.tenant.two') },
    { text: p('cards.tenant.three') },
    { text: p('cards.tenant.four') },
  ];

  return (
    <LandingPageLayout pageTitle={r(ROUTES.landingPageRoutes.prices.title)}>
      <Grid
        container
        justifyContent={'center'}
        alignItems={'center'}
        className={'ws-full-width-bg ws-full-width-bg--white'}
        gap={6}
      >
        <Grid item xs={12} paddingTop={{ xs: 4, md: 10 }} paddingBottom={{ xs: 4, md: 10 }}>
          <Headline size={HEADLINE_SIZE.HERO} align={'center'}>
            {p('header.headline')}
          </Headline>
        </Grid>
        <>
          <Grid container direction={'row'} justifyContent={'center'} spacing={4} alignItems={'stretch'}>
            <Grid item xs={12} md={5} paddingBottom={{ xs: 4, md: 10 }}>
              <PricingCard
                userType={USER_TYPE.LANDLORD}
                subLine={p('cards.business.subLine')}
                price={p('cards.business.price')}
                title={p('cards.business.title')}
                checkListItems={CHECK_LIST_BUSINESS}
              />
            </Grid>
            <Grid item xs={12} md={5} paddingBottom={{ xs: 4, md: 10 }}>
              <PricingCard
                priceExtra={p('cards.tenant.priceExtra')}
                userType={USER_TYPE.TENANT}
                subLine={p('cards.tenant.subLine')}
                price={p('cards.tenant.price')}
                title={p('cards.tenant.title')}
                checkListItems={CHECK_LIST_TENANT}
              />
            </Grid>
          </Grid>
        </>

        <Grid
          item
          xs={12}
          className={'ws-full-width-bg ws-full-width-bg--dark'}
          paddingTop={{ xs: 4, md: 10 }}
          paddingBottom={{ xs: 4, md: 10 }}
        >
          <LandlordSection
            title={'Du bist eine Genossenschaft?'}
            subtitle={'Melde dich gerne bei uns. Wir machen dir ein individuelles Angebot.'}
            ctaText={'Kontaktiere uns'}
            ctaHref={'mailto:anfragen@wohnsinn.com'}
            ctaText2={'0221-95490428'}
            ctaLink2={'tel:022195490428'}
          />
        </Grid>
        <Grid item xs={12} paddingTop={{ xs: 4, md: 10 }} paddingBottom={{ xs: 4, md: 10 }}>
          <ContactSection />
        </Grid>
      </Grid>
    </LandingPageLayout>
  );
};

export default PriceView;
