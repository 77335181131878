import DocumentUploadDropZone, { UPLOAD_TYPE } from 'component/molecules/DocumentUploadDropZone';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import DocumentUploadFileList from 'component/molecules/DocumentUploadDropZone/DocumentUploadFileList';
import ModalContext from 'core/context/modal.context';
import UserContext from 'core/context/user.context';
import useTenantDocuments from 'core/hook/tenant-document.hook';
import { FC, useContext, useEffect, useState } from 'react';
import { IUpload } from '@wohnsinn/ws-ts-lib';
import { uniqBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ITenantFormProps } from '../TenantProfileForm';
import PageTitle from 'component/molecules/PageTitle';
import { ROUTES } from 'core/const/routes';
import styles from '../TenantIntroductionVideoForm/TenantIntroductionVideoForm.module.scss';
import CTAButton, { BUTTON_STYLE } from 'component/atoms/Buttons/CTAButton';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import InfoText from 'component/molecules/InfoText';
import FormSectionTitle from 'component/atoms/FormSectionTitle';
import Text, { TEXT_COLOR } from 'component/atoms/typographie/Text';
import Spacer, { SPACER_SIZE } from 'component/atoms/Spacer';

const TenantSchufaForm: FC<ITenantFormProps> = ({ isApplicationFolderTunnel }) => {
  const [schufaCheckDocumentUploadList, setSchufaCheckUploadList] = useState<IUpload[]>([]);
  const { tenantProfile } = useContext(UserContext);
  const { openModal, closeModal } = useContext(ModalContext);
  const { schufaCheckDocumentList } = useTenantDocuments(tenantProfile?.uid);
  const navigate = useNavigate();
  const isWogeEnvironment = process.env.REACT_APP_ENVIRONMENT === 'woge';

  const { t } = useTranslation('common', { keyPrefix: 'view.AccountView.SchufaView' });
  const { t: c } = useTranslation('common');
  const { t: r } = useTranslation('routes');

  useEffect(() => {
    setSchufaCheckUploadList(schufaCheckDocumentList);
  }, [schufaCheckDocumentList]);

  const updateUploadStateHandler = (uploadList: IUpload[]): void => {
    let uniqueUpload: IUpload[] = [];
    uniqueUpload = uniqBy<IUpload>([...schufaCheckDocumentUploadList, ...uploadList], 'fileName');
    setSchufaCheckUploadList(uniqueUpload);
  };

  const documentDeleteHandler = (upload: IUpload): void => {
    setSchufaCheckUploadList(
      uniqBy<IUpload>(
        schufaCheckDocumentUploadList.filter((u) => u.id !== upload.id),
        'fileName'
      )
    );
  };

  const CANCEL_BUTTON = {
    text: tenantProfile?.hasSchufaDocs ? c('back') : !isApplicationFolderTunnel ? c('cancel') : c('skip'),
    action: () =>
      !isApplicationFolderTunnel
        ? navigate(r(ROUTES.tenantRoutes.account.overview.path))
        : tenantProfile?.hasSchufaDocs
        ? navigate(r(ROUTES.tenantRoutes.account.applicationFolder.aboutMe))
        : navigate(r(ROUTES.tenantRoutes.account.applicationFolder.incomeProof)),
  };

  return (
    <Grid container>
      <Grid item>
        <div className={styles.pageTitleActionWrapper}>
          <PageTitle
            pageTitle={r(ROUTES.tenantRoutes.account.schufa.title)}
            secondPageTitle={
              'Erhalte den originalen SCHUFA-BonitätsCheck in nur wenigen Schritten und erhöhe deine Chancen.'
            }
            notSubmitButton={
              !isWogeEnvironment && isApplicationFolderTunnel && !tenantProfile?.hasSchufaDocs ? CANCEL_BUTTON : null
            }
          />
          <div className={styles.videoUpload}>
            {tenantProfile?.hasSchufaDocs && isApplicationFolderTunnel ? (
              <CTAButton
                expandMobile
                link={r(ROUTES.tenantRoutes.account.applicationFolder.incomeProof)}
                buttonText={c('next')}
              />
            ) : (
              <DocumentUploadDropZone
                uploadType={UPLOAD_TYPE.SCHUFA}
                onUploadButtonClick={() => openModal({ id: MODAL_IDENTIFIER.CREATE_OR_UPLOAD_SCHUFA })}
                onFileSelection={closeModal}
                onUploadListCreation={updateUploadStateHandler}
              />
            )}
          </div>
        </div>

        <Grid container spacing={4}>
          <Grid item xs={12} md={7} lg={8}>
            <Grid item xs={12}>
              <FormSectionTitle title={t('upload.title')} isFirst />
              {schufaCheckDocumentUploadList && schufaCheckDocumentUploadList.length ? (
                <DocumentUploadFileList
                  uploadType={UPLOAD_TYPE.SCHUFA}
                  documentUploadList={schufaCheckDocumentUploadList}
                  onDocumentDelete={documentDeleteHandler}
                />
              ) : (
                <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT}>Du hast bisher keine Schufa-Dokumente hochgeladen.</Text>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} lg={4}>
            <InfoText>
              <Headline size={HEADLINE_SIZE.H2}>Erhöhe deine Chancen</Headline>
              <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
                Das Hochladen eines Bonitätsnachweises schafft Vertrauen beim Vermieter, da dies eine transparente
                Darstellung deiner finanziellen Zuverlässigkeit bietet und die Sicherheit für eine erfolgreiche
                Mietvereinbarung stärkt.
              </Text>
              <Spacer size={SPACER_SIZE.MD} />
            </InfoText>
          </Grid>
        </Grid>

        <Grid item xs={12} className={styles.mediaUploadWrapper}>
          {!isWogeEnvironment && isApplicationFolderTunnel && !tenantProfile?.hasSchufaDocs && (
            <CTAButton
              type={'button'}
              expandMobile
              onClick={CANCEL_BUTTON.action}
              mobileNotRounded
              buttonStyle={BUTTON_STYLE.SECONDARY}
              buttonText={CANCEL_BUTTON.text}
            />
          )}

          {tenantProfile?.hasSchufaDocs && isApplicationFolderTunnel ? (
            <CTAButton
              expandMobile
              mobileNotRounded
              link={r(ROUTES.tenantRoutes.account.applicationFolder.incomeProof)}
              buttonText={c('next')}
            />
          ) : (
            <DocumentUploadDropZone uploadType={UPLOAD_TYPE.SCHUFA} onUploadListCreation={updateUploadStateHandler} />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TenantSchufaForm;
