import { FC, useContext, useEffect, useState } from 'react';
import useFormErrorHook from '../../../../core/hook/use-form-error.hook';
import { wohnsinnServices } from '../../../../App';
import SnackBarContext from '../../../../core/context/snackbar.context';
import { useTranslation } from 'react-i18next';
import { SUBMIT_BUTTON_MODE } from '../../../../core/enum/submit-button-mode.enum';
import { useForm } from 'react-hook-form';
import { IApartmentFreeTexts } from '@wohnsinn/ws-ts-lib';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { APARTMENT_FREE_TEXTS_SCHEMA } from '../../../../core/schemas/apartment/free-texts';
import { useQueryClient } from '@tanstack/react-query';
import { FormContext } from 'core/context/form.context';
import { Grid } from '@mui/material';
import TextEditor, { TEXT_EDITOR_SIZE } from '../../../atoms/formElement/TextEditor';
import { ICreateApartmentTunnel } from 'view/landlord/apartment/ApartmentCreateView';
import useApartment from 'core/hook/apartment.hook';
import { ROUTES } from 'core/const/routes';
import { useNavigate } from 'react-router-dom';
import { TEXT_COLOR } from 'component/atoms/typographie/Text';
import FormNavigation from 'component/molecules/FormNavigation';
import FormHeader from 'component/atoms/FormHeader';
import BackButton from 'component/atoms/Buttons/BackButton';
import useWindowSize from 'core/hook/windowsize.hook';

interface IApartmentFreeTextsWithEquipment extends IApartmentFreeTexts {
  equipment: string;
}

const ApartmentFormFreeTexts: FC<ICreateApartmentTunnel> = ({ isTunnelView }) => {
  const { handleError } = useFormErrorHook('ApartmentCostForm');
  const { apartmentService } = wohnsinnServices;
  const { handleSnackBar } = useContext(SnackBarContext);
  const queryClient = useQueryClient();
  const { apartment } = useApartment();
  const navigate = useNavigate();
  const { t: r } = useTranslation('routes');
  const { t: ac } = useTranslation('common', { keyPrefix: 'apartment.freeTexts' });
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { isSmallerMd } = useWindowSize();

  const { control, watch, reset, handleSubmit } = useForm<IApartmentFreeTextsWithEquipment>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: joiResolver(Joi.object(APARTMENT_FREE_TEXTS_SCHEMA)),
  });
  const { t } = useTranslation('common');

  useEffect(() => {
    reset({ ...apartment?.freeTexts, equipment: apartment?.equipment?.description });
  }, [apartment]);

  const onError = (err: any): void => {
    console.error(err);
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
  };

  const saveDataToDB = async (): Promise<void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);

    try {
      const freeTexts = {
        other: watch('other'),
        generellDescription: watch('generellDescription'),
        location: watch('location'),
      };

      await apartmentService.updateApartment({
        data: { freeTexts, equipment: { ...apartment.equipment, description: watch('equipment') } },
        creatorId: apartment.creatorId,
        apartmentId: apartment.id,
      });
      await queryClient.invalidateQueries({ queryKey: ['apartments'] });
      handleSnackBar('toast.success.saved', 'success');
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUCCESS);
      if (isTunnelView) {
        navigate(r(ROUTES.landlordRoutes.apartment.create.media.path).replace(':apartmentId', apartment?.id));
      } else {
        navigate(r(ROUTES.landlordRoutes.apartment.overview.path).replace(':apartmentId', apartment?.id));
      }
    } catch (e) {
      handleError(e);
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
    }
  };

  return (
    <div>
      <FormContext.Provider value={{ control }}>
        <form onSubmit={handleSubmit(saveDataToDB, onError)} noValidate autoComplete={'off'} id={'apartmentFreeTexts'}>
          {!isTunnelView && !isSmallerMd && (
            <div style={{ paddingBottom: '1rem' }}>
              <BackButton />
            </div>
          )}
          <FormHeader
            title={r(ROUTES.landlordRoutes.apartment.freeTexts.title)}
            subtitle={t('apartmentFreeTextForm.subTitle')}
          />
          <Grid container columnSpacing={2} md={6} className={'form-with-navigation'}>
            <Grid item xs={12}>
              <TextEditor
                hideToolbar={true}
                label={ac('generellDescription.label')}
                placeholder={ac('generellDescription.placeholder')}
                name={'generellDescription'}
                size={TEXT_EDITOR_SIZE.MEDIUM}
              />
            </Grid>
            <Grid item xs={12}>
              <TextEditor
                hideToolbar={true}
                label={ac('location.label')}
                placeholder={ac('location.placeholder')}
                name={'location'}
                size={TEXT_EDITOR_SIZE.MEDIUM}
              />
            </Grid>
            <Grid item xs={12}>
              <TextEditor
                hideToolbar={true}
                label={ac('equipment.label')}
                placeholder={ac('equipment.placeholder')}
                name={'equipment'}
                size={TEXT_EDITOR_SIZE.MEDIUM}
              />
            </Grid>
            <Grid item xs={12}>
              <TextEditor
                hideToolbar={true}
                label={ac('other.label')}
                placeholder={ac('other.placeholder')}
                name={'other'}
                size={TEXT_EDITOR_SIZE.MEDIUM}
              />
            </Grid>
          </Grid>
          <FormNavigation
            buttonSubmitMode={buttonSubmitMode}
            disabled={false}
            showSecondaryButtonMobile={isTunnelView}
            formId={'apartmentFreeTexts'}
            submitButtonText={isTunnelView ? t('saveAndContinue') : t('save')}
            secondaryButtonAction={() => navigate(-1)}
            secondaryButtonColor={TEXT_COLOR.TEXT_COLOR_ACCENT}
            secondaryButtonText={t('back')}
          />
        </form>
      </FormContext.Provider>
    </div>
  );
};

export default ApartmentFormFreeTexts;
