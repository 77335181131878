import { FC, useContext, useEffect } from 'react';
import UserContext from 'core/context/user.context';
import styles from './Redirect.module.scss';
import { useTranslation } from 'react-i18next';
import PageLayout from 'component/layouts/PageLayout';
import Text from 'component/atoms/typographie/Text';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import LoadingAnimation from 'component/atoms/LoadingAnimation';
import { ROUTES } from 'core/const/routes';
import { USER_TYPE } from '@wohnsinn/ws-ts-lib';
import LOCAL_STORAGE_KEYS from 'core/enum/local-storage.enum';
import { useNavigate, useParams } from 'react-router-dom';

const RedirectView: FC<{ route?: string }> = ({ route }) => {
  const { user, tenantProfile, landlordProfile } = useContext(UserContext);
  const { redirectType } = useParams<{ redirectType: string }>();
  const navigate = useNavigate();
  const { t } = useTranslation('common', { keyPrefix: 'view.RedirectView' });
  const { t: r } = useTranslation('routes');
  const { t: c } = useTranslation('common', { keyPrefix: 'component.organisms.ApartmentStackWrapper' });
  let overText;
  let title;
  let gif;
  let text;
  let isCategoryRedirect;

  switch (redirectType) {
    case 'matching':
      title = t('redirect.tunnel.title');
      text = t('redirect.tunnel.text');
      gif = t('redirect.tunnel.gif');
      break;
    case 'register':
      title = t('redirect.register.title');
      text = t('redirect.register.text');
      gif = t('redirect.register.gif');
      break;
    case 'created-apartment':
      title = t('redirect.createdApartment.title');
      text = t('redirect.createdApartment.text');
      gif = t('redirect.createdApartment.gif');
      break;
    case c('redirect.allRated.param'):
      title = c('redirect.allRated.title');
      isCategoryRedirect = true;
      break;
    case c('redirect.perfectMatches.param'):
      title = c('redirect.perfectMatches.title');
      overText = c('redirect.overText');
      isCategoryRedirect = true;
      break;
    case c('redirect.otherSuggestions.param'):
      title = c('redirect.otherSuggestions.title');
      overText = c('redirect.overText');
      isCategoryRedirect = true;
      break;
    default:
      title = t('redirect.default.title');
      text = t('redirect.default.text');
      gif = t('redirect.default.gif');
      break;
  }
  /**
   checks if there is a redirectUrl if not
   detect active user role and redirect to corresponding services page
   **/
  const redirectUser = async () => {
    setTimeout(() => {
      let redirectUrl: string;
      if (route) {
        redirectUrl = route;
      } else {
        redirectUrl = r(localStorage.getItem(LOCAL_STORAGE_KEYS.REDIRECT_URL));
      }

      if (redirectUrl) {
        return navigate(redirectUrl);
      } else {
        switch (user?.activeUserType) {
          case USER_TYPE.TENANT:
            return navigate('/');
          case USER_TYPE.LANDLORD:
            return navigate(r(ROUTES.landlordRoutes.apartment.list.path));
          default:
            return navigate('/');
        }
      }
    }, 2500);
  };

  useEffect(() => {
    redirectUser();
  }, [landlordProfile, tenantProfile, user]);

  return (
    <PageLayout hideMenu pageTitle={title ? title : 'Sie werden weitergeleitet!'} showPageTitle={false}>
      <div className={styles.wrapper}>
        {gif ? <div className={styles.imageWrapper}>{<img alt={'redirect'} src={gif} />}</div> : null}

        <div className={styles.contentWrapper}>
          <Text align={'center'} tag={'span'}>
            {overText ? overText : null}
          </Text>
          <Headline align={'center'} mobileAlign={'center'} size={HEADLINE_SIZE.H1}>
            {title ? title : null}
          </Headline>
          <Text align={'center'} tag={'p'}>
            {text ? text : null}
          </Text>
        </div>
        {isCategoryRedirect ? <LoadingAnimation size={'BIG'} /> : null}
      </div>
    </PageLayout>
  );
};
export default RedirectView;
