import { FC, PropsWithChildren } from 'react';
import AppHeader from '../../molecules/AppHeader';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import styles from './LandlordChatLayout.module.scss';

export interface ILayoutProps {
  pageTitle: string;
}

const LandlordChatLayout: FC<PropsWithChildren<ILayoutProps>> = ({ children, pageTitle }) => {
  const { t } = useTranslation('common');

  return (
    <div className={styles.appWrapper}>
      <div id="lightshow-portal" />
      <Helmet>
        <title>{`${t('appTitle')} - ${t(pageTitle)}`}</title>
      </Helmet>

      <header className={styles.appHeader}>
        <AppHeader showBackButton={false} />
      </header>

      <main>{children}</main>
    </div>
  );
};

export default LandlordChatLayout;
