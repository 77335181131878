import {
  FIRESTORE_COLLECTION_PATH,
  IAboutMe,
  IApartment,
  IHousehold,
  INotificationSettings,
  ITenantFilterParams,
  ITenantPersonalInformation,
  ITenantProfile,
  ITenantSearch,
  ITenantSearchCreate,
  IVideoUpload,
  TDocumentUpload,
} from '@wohnsinn/ws-ts-lib';
import { getDocs, Query } from 'firebase/firestore';
import LOCAL_STORAGE_KEYS from 'core/enum/local-storage.enum';
import { UserInfo } from 'firebase/auth';
import FirestoreService from 'core/service/firestore.service';
import mixpanel from 'mixpanel-browser';

class TenantService {
  constructor(private firestoreService: FirestoreService) {}

  /**
   * Create default tenantProfile depending on userInfo
   * @param user
   */
  public async createTenantProfile(user: UserInfo) {
    const apartmentId = localStorage.getItem(LOCAL_STORAGE_KEYS.APARTMENT_ID);
    const tenantProfile: Partial<ITenantProfile> = {
      email: user.email,
      displayName: user.displayName,
      uid: user.uid,
    };

    try {
      await this.firestoreService.setDbDoc<Partial<ITenantProfile>>(
        tenantProfile,
        `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.root.replace('{uid}', user.uid)}/${user.uid}`,
        true,
        {
          setUpdatedAt: true,
          setCreatedAt: true,
        }
      );
      mixpanel.people.set({
        $email: user.email,
      });
      if (apartmentId) {
        try {
          // Query the apartments collection to find the document with the specific apartmentId
          const apartmentsQuery = this.firestoreService.getCollectionGroupRef<IApartment>('apartments');
          const apartmentQueryWithParams = this.firestoreService.getCollectionRefWithParams<IApartment>(
            apartmentsQuery,
            {
              where: { fieldPath: 'id', opStr: '==', value: apartmentId },
            }
          );
          const apartmentSnap = await getDocs(apartmentQueryWithParams);
          if (!apartmentSnap.empty) {
            const apartments = apartmentSnap.docs.map((doc) => doc.data());
            if (apartments[0]) {
              const apt = apartments[0];

              // Generate a new document reference with an automatically generated ID
              const tenantSearchDocRef = this.firestoreService.getDbDocRef<ITenantSearch>(
                `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.searchProfiles
                  .replace('{uid}', user.uid)
                  .replace('{tenantId}', user.uid)}`
              );

              const tenantSearch: ITenantSearch = {
                id: tenantSearchDocRef?.id,
                notificationSettings: {
                  isPushEnabled: false,
                  isMailEnabled: true,
                  isWhatsappEnabled: false,
                },
                uid: user?.uid,
                location: {
                  radius: 10,
                  place_name: apt?.mainInformation?.address?.city,
                  center: [
                    apt?.mainInformation?.address?.coordinates?.lng,
                    apt?.mainInformation?.address?.coordinates?.lat,
                  ],
                  geoPoint: apt?.mainInformation?.address?.coordinates,
                  id: `CreatedByRegistration-${user?.uid}`,
                },
                warmRent: apt?.cost?.warmRent
                  ? Math.ceil((apt?.cost?.warmRent * 1.2) / 10) * 10
                  : apt?.cost?.coldRent
                  ? Math.ceil((apt?.cost?.coldRent * 1.2) / 10) * 10
                  : undefined,

                size: Math.floor((apt?.areas?.livingArea * 0.8) / 5) * 5,
              };

              await this.firestoreService.setDbDoc<ITenantSearch>(tenantSearch, tenantSearchDocRef.path, true, {
                setUpdatedAt: true,
                setCreatedAt: true,
              });
            }
          } else {
            console.log('No such apartment!');
          }
        } catch (e) {
          console.error('Error on createTenantProfile', e);
        }
      }
    } catch (e) {
      console.error('Error on createTenantProfile', e);
    }
  }

  public getSchufaCheckDocumentListRef(uid: string): Query<TDocumentUpload> {
    const collectionPath = FIRESTORE_COLLECTION_PATH.users.tenantProfiles.schufaCheckDocuments
      .replace('{uid}', uid)
      .replace('{tenantId}', uid);

    const colRef: Query<TDocumentUpload> = this.firestoreService.getCollectionRef<TDocumentUpload>(collectionPath);
    return this.firestoreService.getCollectionRefWithParams<TDocumentUpload>(colRef, {
      where: [{ fieldPath: 'creatorId', opStr: '==', value: uid }],
    });
  }

  public addIncomeProofDocument(uid: string, upload: TDocumentUpload): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.incomeProofDocuments
      .replace('{uid}', uid)
      .replace('{tenantId}', uid)}/${upload.fileName}`;

    return this.firestoreService.setDbDoc<TDocumentUpload>(upload, path, false);
  }

  public async addTenantSearchProfile(uid: string, tenantFilterParams: ITenantFilterParams): Promise<void> {
    let newTFP = tenantFilterParams;

    function removeUndefinedProperties(obj: ITenantFilterParams): ITenantFilterParams {
      Object.keys(obj).forEach((key) => {
        if (obj[key as keyof ITenantFilterParams] === undefined) {
          delete obj[key as keyof ITenantFilterParams];
        }
      });
      return obj;
    }

    newTFP = removeUndefinedProperties(newTFP);
    const notificationSettings: INotificationSettings = {
      isWhatsappEnabled: true,
      isMailEnabled: true,
      isPushEnabled: true,
    };

    const path = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.searchProfiles
      .replace('{uid}', uid)
      .replace('{tenantId}', uid)}`;

    await this.firestoreService.addDbDoc<ITenantSearchCreate>({ ...newTFP, notificationSettings, uid }, path, {
      setUpdatedAt: true,
      setCreatedAt: true,
    });
  }

  public async getTenantSearchProfiles(uid: string): Promise<any> {
    try {
      const path = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.searchProfiles
        .replace('{uid}', uid)
        .replace('{tenantId}', uid)}`;

      const collectionRef = this.firestoreService.getCollectionRef<ITenantSearch[]>(path, { fetchWithId: true });
      const searchProfilesListSnap = await getDocs(collectionRef);
      return searchProfilesListSnap.docs.map((d) => {
        return d.data();
      });
    } catch (e) {
      console.error('Error on getTenantSearchProfiles', e);
    }
  }

  public async deleteTenantSearch(uid: string, searchProfileId: string): Promise<void> {
    try {
      const path = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.searchProfiles
        .replace('{uid}', uid)
        .replace('{tenantId}', uid)}/${searchProfileId}`;
      await this.firestoreService.deleteDbDoc(path);
    } catch (e) {
      console.error('Error on deleteTenantSearch', e);
    }
  }

  public async updateTenantSearchProfile(uid: string, searchProfileId: string, data: ITenantSearch): Promise<void> {
    try {
      const path = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.searchProfiles
        .replace('{uid}', uid)
        .replace('{tenantId}', uid)}/${searchProfileId}`;

      await this.firestoreService.setDbDoc<ITenantSearch>(data, path, true);
    } catch (e) {
      console.error('Error on updateTenantSearchProfile', e);
    }
  }

  public async setTenantHasSchufaDocuments(uid: string, value: boolean): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.root.replace('{uid}', uid)}/${uid}`;

    await this.firestoreService.setDbDoc<{ hasSchufaDocs: boolean }>({ hasSchufaDocs: value }, path, true);
  }

  public async setTenantHasIncomeProofDocuments(uid: string, value: boolean): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.root.replace('{uid}', uid)}/${uid}`;

    await this.firestoreService.setDbDoc<{ hasIncomeProofDocs: boolean }>({ hasIncomeProofDocs: value }, path, true);
  }

  public addSchufaCheckDocument(uid: string, upload: TDocumentUpload): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.schufaCheckDocuments
      .replace('{uid}', uid)
      .replace('{tenantId}', uid)}/${upload.fileName}`;
    return this.firestoreService.setDbDoc<TDocumentUpload>(upload, path, false);
  }

  public deleteIncomeProofDocument(uid: string, upload: TDocumentUpload): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.incomeProofDocuments
      .replace('{uid}', uid)
      .replace('{tenantId}', uid)}/${upload.fileName}`;

    return this.firestoreService.deleteDbDoc(path);
  }

  public deleteSchufaCheckDocument(uid: string, upload: TDocumentUpload): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.schufaCheckDocuments
      .replace('{uid}', uid)
      .replace('{tenantId}', uid)}/${upload.fileName}`;

    return this.firestoreService.deleteDbDoc(path);
  }

  public getIncomeProofDocumentListRef(uid: string): Query<TDocumentUpload> {
    const collectionPath = FIRESTORE_COLLECTION_PATH.users.tenantProfiles.incomeProofDocuments
      .replace('{uid}', uid)
      .replace('{tenantId}', uid);

    const colRef: Query<TDocumentUpload> = this.firestoreService.getCollectionRef<TDocumentUpload>(collectionPath);
    return this.firestoreService.getCollectionRefWithParams<TDocumentUpload>(colRef, {
      where: { fieldPath: 'creatorId', opStr: '==', value: uid },
    });
  }

  public updateTenantPersonalInformation(uid: string, personalInformation: ITenantPersonalInformation): Promise<void> {
    mixpanel.people.set({
      $name: `${personalInformation?.firstName} ${personalInformation?.lastName}`,
    });
    const path = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.root.replace('{uid}', uid)}/${uid}`;
    return this.firestoreService.setDbDoc<{ personalInformation: ITenantPersonalInformation; updatedAt: Date }>(
      { personalInformation, updatedAt: new Date() },
      path
    );
  }

  public updateTenantIntroductionVideo(uid: string, introductionVideo: IVideoUpload): Promise<void> {
    type TAboutMeVideoUpdate = Omit<IAboutMe, 'description'>;

    const path = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.root.replace('{uid}', uid)}/${uid}`;
    return this.firestoreService.setDbDoc<{ aboutMe: TAboutMeVideoUpdate }>(
      {
        aboutMe: {
          introductionVideo: introductionVideo
            ? {
                ...introductionVideo,
                hdVideoRef: null,
                sdVideoRef: null,
              }
            : null,
        },
      },
      path,
      true
    );
  }

  public updateTenantHousehold(uid: string, householdData: IHousehold): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.root.replace('{uid}', uid)}/${uid}`;

    return this.firestoreService.setDbDoc<Partial<ITenantProfile>>({ household: householdData }, path);
  }

  public updateTenantProfilePicture(uid: string, photoUrl: string): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.root.replace('{uid}', uid)}/${uid}`;
    return this.firestoreService.setDbDoc<Partial<ITenantProfile>>({ photoUrl }, path);
  }
}
export default TenantService;
