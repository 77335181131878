import { useQuery } from '@tanstack/react-query';
import { getDocs } from 'firebase/firestore';
import { wohnsinnServices } from 'App';
import { useContext } from 'react';
import UserContext from 'core/context/user.context';
import { APPLICATION_STATE, IApplication } from '@wohnsinn/ws-ts-lib';

export interface ISortedApplications {
  newAndOpened: IApplication[];
  like: IApplication[];
  maybe: IApplication[];
  nope: IApplication[];
  invited: IApplication[];
  appointmentConfirmed: IApplication[];
}

export interface IApplicationListsNotifications {
  newAndOpened: number;
  like: number;
  maybe: number;
  nope: number;
  invited: number;
  appointmentConfirmed: number;
}

const useApartmentApplications = (apartmentId: string) => {
  const { applicationService } = wohnsinnServices;
  const { user } = useContext(UserContext);

  return useQuery({
    queryKey: ['apartments-applications', apartmentId],
    queryFn: async () => {
      const applicationQuery = applicationService.getLandlordApplicationListRef({
        isAdmin: user.isAdmin,
        landlordId: user.uid,
        apartmentId: apartmentId,
      });

      const apartmentListSnap = await getDocs(applicationQuery);
      return apartmentListSnap.docs.map((doc) => doc.data());
    },
    select: (data) => sortApplicationsByApplicationState(data),
  });
};

function sortApplicationsByApplicationState(applications: IApplication[]): {
  sortedApplications: ISortedApplications;
  unreadNotifications: IApplicationListsNotifications;
} {
  const sortedApplications: ISortedApplications = {
    newAndOpened: [] as IApplication[],
    nope: [] as IApplication[],
    maybe: [] as IApplication[],
    like: [] as IApplication[],
    invited: [] as IApplication[],
    appointmentConfirmed: [] as IApplication[],
  };

  const unreadNotifications: IApplicationListsNotifications = {
    newAndOpened: 0,
    nope: 0,
    maybe: 0,
    like: 0,
    invited: 0,
    appointmentConfirmed: 0,
  };

  applications.forEach((application) => {
    switch (application.applicationState) {
      case APPLICATION_STATE.NEW:
      case APPLICATION_STATE.OPENED:
        if (application.unreadTenantMessagesRef?.length > 0) {
          unreadNotifications.newAndOpened += 1;
        }
        sortedApplications.newAndOpened.push(application);
        break;
      case APPLICATION_STATE.SORTED_LIKE:
        if (application.unreadTenantMessagesRef?.length > 0) {
          unreadNotifications.like += 1;
        }
        sortedApplications.like.push(application);
        break;
      case APPLICATION_STATE.SORTED_MAYBE:
        if (application.unreadTenantMessagesRef?.length > 0) {
          unreadNotifications.maybe += 1;
        }
        sortedApplications.maybe.push(application);
        break;
      case APPLICATION_STATE.SORTED_NOPE:
        if (application.unreadTenantMessagesRef?.length > 0) {
          unreadNotifications.nope += 1;
        }
        sortedApplications.nope.push(application);

        break;
      case APPLICATION_STATE.INVITED:
        if (application.unreadTenantMessagesRef?.length > 0) {
          unreadNotifications.invited += 1;
        }
        sortedApplications.invited.push(application);
        break;
      case APPLICATION_STATE.APPOINTMENT_CONFIRMED:
        if (application.unreadTenantMessagesRef?.length > 0) {
          unreadNotifications.appointmentConfirmed += 1;
        }
        sortedApplications.appointmentConfirmed.push(application);
        break;
    }
  });

  return { sortedApplications, unreadNotifications };
}

export default useApartmentApplications;
