import ApplicationModal from 'component/organisms/ApplicationModal';
import Avatar, { AVATAR_SIZE } from 'component/atoms/Avatar';
import LandlordRatingButtons from 'component/molecules/LandlordRatingButtons';
import Text, { TEXT_COLOR, TEXT_TYPE } from 'component/atoms/typographie/Text';
import { APPLICATION_STATE, IApplication } from '@wohnsinn/ws-ts-lib';
import { FC, useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { printAge } from 'core/helper/print-age';
import { useTranslation } from 'react-i18next';
import CTAButton, { BUTTON_STYLE } from 'component/atoms/Buttons/CTAButton';
import styles from './ApplicantsTable.module.scss';
import truncateText from 'core/helper/truncate-text';
import useWindowSize from 'core/hook/windowsize.hook';
import { formatNumberGermanStyle } from 'core/helper/get-formatted-price';
import { useUpdateApplicationMutation } from 'core/hook/update-application.hook';
import ContextMenu from 'component/atoms/ContextMenu';
import { createApplicationContextMenu } from 'core/helper/create-context-menu';
import ApplicantsTableHead from 'component/organisms/Tables/ApplicantsTable/ApplicantsTableHead';
import SanitizedHTML from 'component/atoms/SanitzedHtml';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessageLines, faReply, faAddressCard } from '@fortawesome/pro-light-svg-icons';
import { wohnsinnServices } from '../../../../App';

const ApplicantsTable: FC<{
  applications: IApplication[];
  sortApplicantByIncome: () => void;
  sortAsc: boolean;
}> = ({ applications, sortApplicantByIncome, sortAsc }) => {
  const { t } = useTranslation('common');
  const [isOpen, setIsOpen] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState<IApplication>(null);
  const { isSmallerSm, isSmallerMd } = useWindowSize();
  const { mutate } = useUpdateApplicationMutation();
  const { apartmentService } = wohnsinnServices;

  const handleApplicationClick = async (application: IApplication) => {
    setIsOpen(true);
    setSelectedApplication(application);
    mutate({
      application,
      data: {
        applicationState:
          application.applicationState === APPLICATION_STATE.NEW
            ? APPLICATION_STATE.OPENED
            : application.applicationState,
      },
    });

    await apartmentService.clearNewApplicationsRefOnApartment(
      application.apartmentId,
      application.landlordId,
      application.tenantProfile.uid
    );
  };

  return (
    <>
      <ApplicationModal isOpen={isOpen} onClose={() => setIsOpen(false)} application={selectedApplication} />

      <TableContainer component={Paper}>
        <Table>
          <ApplicantsTableHead sortApplicantByIncome={sortApplicantByIncome} sortAsc={sortAsc} />
          <TableBody>
            {applications.map((application) => {
              const CONTEXT_MENU = createApplicationContextMenu(application, mutate, t);
              return (
                <TableRow hover={true} key={`${application.apartmentId}-${application.tenantProfile.uid}`}>
                  <TableCell className={styles.applicantCell} onClick={() => handleApplicationClick(application)}>
                    <div className={styles.applicant}>
                      <div className={styles.avatar}>
                        <div
                          className={`${styles.notificationBadge} ${
                            application?.applicationState === APPLICATION_STATE.NEW ? '' : styles.hide
                          } `}
                        />
                        <div className={styles.avatarWrapper}>
                          <Avatar size={AVATAR_SIZE.md} avatarUrl={application?.tenantProfile?.photoUrl} />
                        </div>
                      </div>
                      <div style={{ textAlign: 'left' }}>
                        <Text
                          className={styles.name}
                          bold={application?.applicationState === APPLICATION_STATE.NEW}
                          tag={'span'}
                        >
                          {truncateText(
                            `${application?.tenantProfile?.personalInformation?.firstName} ${application?.tenantProfile?.personalInformation?.lastName}`,
                            24
                          )}
                          ,{' '}
                          {application?.tenantProfile?.personalInformation?.dateOfBirth
                            ? printAge(application?.tenantProfile?.personalInformation?.dateOfBirth).toString()
                            : '-'}
                        </Text>
                        <br />
                        <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION} color={TEXT_COLOR.TEXT_COLOR_ACCENT} tag={'span'}>
                          {truncateText(
                            `${
                              application?.tenantProfile?.personalInformation?.customJobDescription ??
                              t(`jobStatus.${application?.tenantProfile?.personalInformation?.jobStatus}`)
                            }`,
                            isSmallerSm ? 12 : isSmallerMd ? 20 : 28
                          )}
                        </Text>
                      </div>
                    </div>

                    {application?.applicationState === APPLICATION_STATE.WILL_BE_DELETED && (
                      <>
                        <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT} tag={'span'}>
                          {`(Löschung: ${
                            application?.deleteDate
                              ? new Date(application.deleteDate).toLocaleDateString('de-DE', {
                                  day: '2-digit',
                                  month: '2-digit',
                                  year: 'numeric',
                                })
                              : ''
                          })`}
                        </Text>
                      </>
                    )}
                  </TableCell>
                  <TableCell align={'center'}>
                    <div className={styles.ratingButtons}>
                      <LandlordRatingButtons
                        centerButtons={false}
                        spaceBetween={true}
                        apartmentId={application?.apartmentId}
                        landlordRating={application?.landlordRating}
                        tenantProfileId={application?.tenantProfile?.uid}
                        currentApplicationState={application?.applicationState}
                      />
                    </div>
                  </TableCell>
                  <TableCell align={'center'}>
                    {application.isChatDisabled ? (
                      <CTAButton
                        icon={faMessageLines}
                        rounded={false}
                        expand={'block'}
                        size={'small'}
                        buttonStyle={BUTTON_STYLE.SECONDARY}
                        buttonText={t('startChat')}
                        onClick={() => handleApplicationClick(application)}
                      />
                    ) : (
                      <button className={styles.lastMessage} onClick={() => handleApplicationClick(application)}>
                        {application?.unreadTenantMessagesRef?.length ? (
                          <div className={styles.notificationBadge}>{application.unreadTenantMessagesRef.length}</div>
                        ) : null}
                        {application?.lastMessage && (
                          <div
                            className={`${styles.text} ${
                              application.lastMessageSenderId !== application.tenantProfile.uid ? styles.accent : ''
                            }`}
                          >
                            <Text
                              color={
                                application.lastMessageSenderId !== application.tenantProfile.uid
                                  ? TEXT_COLOR.TEXT_COLOR_ACCENT
                                  : TEXT_COLOR.TEXT_COLOR_DARK
                              }
                              bold={!!application?.unreadTenantMessagesRef?.length}
                              tag={'span'}
                            >
                              {application.lastMessageSenderId !== application.tenantProfile.uid ? (
                                <>
                                  <FontAwesomeIcon icon={faReply} />
                                  {` ${t('you')}: `}
                                </>
                              ) : (
                                ''
                              )}
                            </Text>
                            <SanitizedHTML
                              className={`${styles.message} ${
                                !!application?.unreadTenantMessagesRef?.length ? styles.bold : ''
                              }`}
                              dirty={application.lastMessage}
                              removeNewLines
                            />
                          </div>
                        )}
                      </button>
                    )}
                  </TableCell>
                  <TableCell align={'right'}>
                    <Text align={'right'} color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
                      {t('monthlyIncome.netto.noText', {
                        income: formatNumberGermanStyle(application?.tenantProfile?.household?.monthlyIncome),
                      })}
                    </Text>
                  </TableCell>
                  <TableCell align={'right'}>
                    <Text align={'right'} color={TEXT_COLOR.TEXT_COLOR_ACCENT} tag={'span'}>
                      {`${t('numberOfPeopleMovingInText', {
                        count: parseInt(
                          application?.tenantProfile?.household?.numberOfPeopleMovingIn as unknown as string
                        ),
                      })}`}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <CTAButton
                      icon={faAddressCard}
                      rounded={false}
                      expand={'block'}
                      size={'small'}
                      buttonStyle={BUTTON_STYLE.SECONDARY}
                      buttonText={t('watchSelfDisclosure')}
                      onClick={() => handleApplicationClick(application)}
                    />
                  </TableCell>
                  <TableCell className={styles.stickyCell}>
                    <ContextMenu menu={CONTEXT_MENU} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ApplicantsTable;
