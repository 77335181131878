import {
  addDoc,
  collection,
  collectionGroup,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  getDoc,
  getDocs,
  limit as firestoreLimit,
  orderBy as firestoreOrderBy,
  query,
  Query,
  QueryDocumentSnapshot,
  QuerySnapshot,
  setDoc,
  updateDoc,
  Timestamp,
  where as firestoreWhere,
  writeBatch,
  WriteBatch,
  UpdateData,
  WhereFilterOp,
} from 'firebase/firestore';
import { IFirestoreQueryParams } from '@wohnsinn/ws-ts-lib';
import { parseISO } from 'date-fns';
import isIsoDate from '../helper/is-iso-date';

export interface IConverterOptions {
  fetchWithId?: boolean;
  setUpdatedAt?: boolean;
  setCreatedAt?: boolean;
  setIdOnDocument?: boolean;
}

export class FirestoreService {
  private readonly converter = <T>(options?: IConverterOptions) => ({
    toFirestore: (data: T) => {
      let modifiedData: any = {
        ...data,
      };

      if (options?.setUpdatedAt) {
        modifiedData = { ...modifiedData, updatedAt: new Date() };
      }

      if (options?.setCreatedAt) {
        modifiedData = { ...modifiedData, createdAt: new Date() };
      }

      if (options?.setIdOnDocument) {
        modifiedData = { ...modifiedData, id: modifiedData.id };
      }

      return modifiedData as T;
    },

    fromFirestore: (snap: QueryDocumentSnapshot) => {
      let modifiedData: any = {
        ...snap.data(),
      };
      if (options?.fetchWithId) {
        modifiedData = { ...modifiedData, id: snap.id };
      }
      Object.keys(modifiedData).forEach((keyLvl1) => {
        if (modifiedData[keyLvl1] instanceof Timestamp) {
          modifiedData[keyLvl1] = modifiedData[keyLvl1].toDate();
        }

        if (typeof modifiedData[keyLvl1] === 'string' && isIsoDate(modifiedData[keyLvl1])) {
          modifiedData[keyLvl1] = new Date(modifiedData[keyLvl1]);
        }

        if (
          modifiedData[keyLvl1] &&
          typeof modifiedData[keyLvl1] === 'object' &&
          !Array.isArray(modifiedData[keyLvl1])
        ) {
          Object.keys(modifiedData[keyLvl1]).forEach((keyLvl2) => {
            if (modifiedData[keyLvl1][keyLvl2] instanceof Timestamp) {
              modifiedData[keyLvl1][keyLvl2] = modifiedData[keyLvl1][keyLvl2].toDate();
            }

            if (typeof modifiedData[keyLvl1][keyLvl2] === 'string' && isIsoDate(modifiedData[keyLvl1][keyLvl2])) {
              console.log(modifiedData[keyLvl1][keyLvl2], keyLvl2);
              modifiedData[keyLvl1][keyLvl2] = parseISO(modifiedData[keyLvl1][keyLvl2]);
            }
          });
        }
      });
      return { ...modifiedData } as T;
    },
  });

  constructor(private readonly firestore: Firestore) {}

  public getBatch(): WriteBatch {
    return writeBatch(this.firestore);
  }

  public getDocRef<T>(path: string, options?: IConverterOptions): DocumentReference<T> {
    return doc(this.firestore, path).withConverter(this.converter<T>(options));
  }

  public getCollectionRef<T>(path: string, options?: IConverterOptions) {
    return collection(this.firestore, path).withConverter(this.converter<T>(options));
  }

  public getCollectionGroupRef<T>(path: string, options?: IConverterOptions): Query<T> {
    return collectionGroup(this.firestore, path).withConverter(this.converter<T>(options));
  }

  public async addDbDoc<T>(data: T, path: string, options?: IConverterOptions): Promise<DocumentReference<T>> {
    const colRef: CollectionReference<T> = collection(this.firestore, path).withConverter(this.converter<T>(options));
    return addDoc<T>(colRef, data);
  }

  public async deleteDbDoc(path: string): Promise<void> {
    const docRef: DocumentReference = doc(this.firestore, path);
    return deleteDoc(docRef);
  }
  public async getCollectionSnapWithWhere<T>(
    path: string,
    whereFields: { fieldPath: string; opStr: WhereFilterOp; value: any }[]
  ): Promise<QuerySnapshot<T>> {
    const collectionRef: CollectionReference<T> = this.getCollectionRef<T>(path);

    let combinedQuery = query(collectionRef);
    whereFields.forEach((field) => {
      combinedQuery = query(combinedQuery, firestoreWhere(field.fieldPath, field.opStr, field.value));
    });

    return getDocs(combinedQuery);
  }
  public async updateDbDoc<T>(data: UpdateData<T>, path: string): Promise<void> {
    return updateDoc(this.getDocRef<T>(path), data);
  }

  public async setDbDoc<T>(data: T, path: string, merge = true, options?: IConverterOptions): Promise<void> {
    return setDoc(this.getDocRef<T>(path, options), data, { merge });
  }

  public getDbDocSnap<T>(path: string, options?: IConverterOptions): Promise<DocumentSnapshot<T>> {
    return getDoc<T>(this.getDocRef<T>(path, options));
  }

  public getCollectionSnap<T>(path: string): Promise<QuerySnapshot<T>> {
    return getDocs(this.getCollectionRef<T>(path));
  }

  public getCollectionGroupSnap<T>(path: string, options?: IConverterOptions): Promise<QuerySnapshot<T>> {
    return getDocs(this.getCollectionGroupRef<T>(path).withConverter(this.converter<T>(options)));
  }

  public async getDbDoc<T>(path: string, options?: IConverterOptions): Promise<T> {
    return this.getDbDocSnap<T>(path, options).then((doc) => doc.data());
  }

  public getCollectionRefWithParams<T>(
    referenceQuery: Query<T>,
    options: IFirestoreQueryParams = {
      where: [],
      orderBy: null,
      limit: null,
    }
  ): Query<T> {
    const { where, orderBy, limit } = options;
    let combinedQuery = query<T>(referenceQuery);
    if (where instanceof Array) {
      for (const w of where) {
        combinedQuery = query<T>(combinedQuery, firestoreWhere(w.fieldPath, w.opStr, w.value));
      }
    } else {
      combinedQuery = query<T>(combinedQuery, firestoreWhere(where.fieldPath, where.opStr, where.value));
    }

    if (orderBy) {
      if (orderBy instanceof Array) {
        for (const oB of orderBy) {
          combinedQuery = query<T>(combinedQuery, firestoreOrderBy(oB.fieldPath, oB.directionStr));
        }
      } else {
        combinedQuery = query<T>(combinedQuery, firestoreOrderBy(orderBy.fieldPath, orderBy.directionStr));
      }
    }

    if (limit) {
      combinedQuery = query<T>(combinedQuery, firestoreLimit(limit));
    }
    return combinedQuery;
  }

  public getDbDocRef<T>(path: string, options?: IConverterOptions): DocumentReference<T> {
    const colRef: CollectionReference<T> = collection(this.firestore, path).withConverter(this.converter<T>(options));
    return doc(colRef);
  }
}

export default FirestoreService;
