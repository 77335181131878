import { FC } from 'react';
import { Paper, Table, TableContainer, TableFooter, TablePagination, TableRow } from '@mui/material';
import { IApartment } from '@wohnsinn/ws-ts-lib';
import { useTranslation } from 'react-i18next';
import ApartmentsTableHead from 'component/organisms/Tables/ApartmentsTable/ApartmentsTableHead';
import ApartmentsTableBody from 'component/organisms/Tables/ApartmentsTable/ApartmentsTableBody';

const ApartmentsTable: FC<{
  apartments: IApartment[];
  totalCount: number;
  handlePageChange: any;
  handleRowsPerPageChange: any;
  page: number;
  rowsPerPage: number;
}> = ({ apartments, totalCount, handlePageChange, handleRowsPerPageChange, page, rowsPerPage }) => {
  const { t } = useTranslation('common');
  return (
    <TableContainer component={Paper}>
      <Table>
        <ApartmentsTableHead />
        <ApartmentsTableBody apartments={apartments} />
        <TableFooter>
          <TableRow>
            <td colSpan={4}>
              <TablePagination
                component={'div'}
                count={totalCount}
                page={page}
                onPageChange={handlePageChange}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleRowsPerPageChange}
                rowsPerPageOptions={[5, 10, 30, 50]}
                labelRowsPerPage={t('objectsPerPage')}
                labelDisplayedRows={({ from, to, count }) => `${from} ${t('to')} ${to} ${t('from')} ${count}`}
              />
            </td>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default ApartmentsTable;
