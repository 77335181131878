import { FC, useContext, useState } from 'react';
import PageLayout from 'component/layouts/PageLayout';
import { ROUTES } from 'core/const/routes';
import { useTranslation } from 'react-i18next';
import PageTitle from 'component/molecules/PageTitle';
import { Grid } from '@mui/material';
import TextInput from 'component/atoms/formElement/InputText';
import CTAButton, { getSubmitIcon } from 'component/atoms/Buttons/CTAButton';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { FormContext } from 'core/context/form.context';
import ModalContext from 'core/context/modal.context';
import { useNavigate } from 'react-router-dom';
import SnackBarContext from 'core/context/snackbar.context';
import TenantFilterParamsContext from 'core/context/tenant-filter-params.context';
import { wohnsinnServices } from 'App';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { AUTH_PROVIDER, ICheckIfUserExistsResponse, USER_TYPE } from '@wohnsinn/ws-ts-lib';
import FB_FUNCTION_URLS from 'core/const/fb-function-names';
import { USER_CREDENTIALS_FORM_SCHEMA } from 'component/modals/signIn/SignUpUserModal';
import AcceptPrivacyInfoText from 'component/atoms/AcceptPrivacyInfoText';

const InvitationView: FC = () => {
  const { closeModal, modalData } = useContext(ModalContext);
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const navigate = useNavigate();
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { handleSnackBar } = useContext(SnackBarContext);
  const { tenantFilterParams } = useContext(TenantFilterParamsContext);
  const { firebaseFunctionsService, registrationRequestService } = wohnsinnServices;

  const {
    control,
    handleSubmit,
    watch,
    formState: { isValid },
  } = useForm<{
    email: string;
    createLandlordAccount: boolean;
  }>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(USER_CREDENTIALS_FORM_SCHEMA),
    defaultValues: {
      email: undefined,
      createLandlordAccount: true,
    },
  });

  /**
   * Check if account with given email exists
   * If not send create a user registration request
   * If exists, output info message
   */
  const handleFormSubmit = async () => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    const email = watch().email;

    try {
      // Check if account with given email exists
      const response: { data: ICheckIfUserExistsResponse } = (await firebaseFunctionsService.callFbFunction(
        FB_FUNCTION_URLS.user.checkIfUserExists,
        {
          params: { email },
        }
      )) as { data: ICheckIfUserExistsResponse };

      // if no account exists, create a user registration request
      if (!response.data.userExists) {
        await registrationRequestService.createUserRegistrationRequest(
          email,
          USER_TYPE.LANDLORD,
          modalData?.referrer,
          tenantFilterParams
        );

        // show cool ui
        setTimeout(() => {
          setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
          navigate(r(ROUTES.staticRoutes.registration.activateAccount.path).replace(':email', email));
          closeModal();
        }, 1500);
      } else {
        // if email already exists, check an account provider and output info message
        switch (response.data.provider) {
          case AUTH_PROVIDER.PASSWORD:
            handleSnackBar('toast.signIn.emailAccountExistsAlready', 'info');
            break;
          case AUTH_PROVIDER.GOOGLE:
            handleSnackBar('toast.signIn.googleAccountExistsAlready', 'info');
            break;
          default:
            handleSnackBar('toast.signIn.emailAccountExistsAlready', 'info');
        }
        setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
      }
    } catch (e) {
      console.error('e', e);
      handleSnackBar('toast.signIn.unknown', 'info');
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
    }
  };

  return (
    <PageLayout
      showPageTitle={false}
      pageTitle={r(ROUTES.userRoutes.registration.createLandlordTunnel.title)}
      hideFooter
    >
      <Grid container>
        <Grid item xs={12}>
          <PageTitle
            pageTitle={'Willkommen bei Wohnsinn 👋'}
            secondPageTitle={'Schön, dass du dabei bist! Lass uns gemeinsam den passenden Mieter finden.'}
          />
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <FormContext.Provider value={{ control }}>
              <form onSubmit={handleSubmit(handleFormSubmit, console.error)}>
                <TextInput label={t('email.label')} name={'email'} type={'email'} required />

                <button type={'submit'} className={'hidden'}>
                  submit
                </button>
                <CTAButton
                  type={'submit'}
                  disabled={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING || !isValid}
                  icon={getSubmitIcon(buttonSubmitMode)}
                  spinIcon={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING}
                  expand={'block'}
                  buttonText={t('registerButtonText')}
                  rounded={false}
                />
              </form>
              <AcceptPrivacyInfoText align={'left'} />
            </FormContext.Provider>
          </Grid>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default InvitationView;
