import { FC, useContext } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import styles from './OptionList.module.scss';
import Text, { TEXT_COLOR } from '../../typographie/Text';
import { FormContext } from '../../../../core/context/form.context';
import { Controller } from 'react-hook-form';
import FormErrorMessage from '../../formElement/FormErrorMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface IOptionListItem {
  heading: string;
  text?: string;
  iconName?: IconProp;
  image?: string;
  imageAlt?: string;
  value: any;
}

export interface IOptionList {
  optionsList: IOptionListItem[];
  name: string;
  disabled?: boolean;
  twoColumns?: boolean;
}

const OptionList: FC<IOptionList> = ({ optionsList = [], name, disabled, twoColumns = false }) => {
  const { control } = useContext(FormContext);

  const handleOnChange = async (field: any, e: any) => {
    const value = e.target.value;
    const isTrue: boolean = value === 'true';
    let convertedValue: any;

    if (isTrue || value === 'false') {
      convertedValue = isTrue;
    } else {
      convertedValue = value;
    }

    return field.onChange(convertedValue);
  };
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div className={'floating-wrapper'}>
          <div className={'floating-field'}>
            <div className={`${styles.optionListWrapper} ${twoColumns ? styles.twoColumns : ''}`}>
              {optionsList.map((option, i) => (
                <div key={`${name}-${option.value}`}>
                  <input
                    tabIndex={i + 1}
                    onChange={(e) => handleOnChange(field, e)}
                    className={styles.input}
                    name={name}
                    disabled={disabled}
                    id={`${name}-${i}`}
                    type={'radio'}
                    value={option.value}
                  />
                  <label
                    htmlFor={`${name}-${i}`}
                    key={`${name}-${i}`}
                    className={`${styles.optionListItem} ${option.image ? styles.withImage : ''} ${
                      disabled ? styles.disabled : ''
                    } ${error ? styles.error : ''}`}
                    style={option.image ? { backgroundImage: `url('${option.image}')` } : null}
                  >
                    {option.iconName ? (
                      <div className={styles.icon}>
                        <FontAwesomeIcon icon={option.iconName} />
                      </div>
                    ) : null}
                    <div className={option.image ? styles.textWithImage : ''}>
                      <Text color={TEXT_COLOR.TEXT_COLOR_DARK} tag={'p'}>
                        {option.heading}
                      </Text>
                      {option.text ? (
                        <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT} tag={'p'}>
                          {option.text}
                        </Text>
                      ) : null}
                    </div>
                  </label>
                </div>
              ))}
              {error ? <FormErrorMessage error={error} /> : null}
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default OptionList;
