import { FC, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGrid2, faGripLines } from '@fortawesome/pro-light-svg-icons';
import styles from './GridSwitch.module.scss';

const GridSwitch: FC<{ onClick: (state: boolean) => void; defaultState?: boolean }> = ({ onClick, defaultState }) => {
  const [activeGrid, setActiveGrid] = useState<boolean>(defaultState);

  const handleClick = (state: boolean) => {
    onClick(state);
    setActiveGrid(state);
  };

  return (
    <div id={'grid-switch'} className={styles.wrapper}>
      <button onClick={() => handleClick(true)} className={`${styles.icon} ${activeGrid ? styles.active : ''}`}>
        <FontAwesomeIcon icon={faGrid2} />
      </button>
      <button onClick={() => handleClick(false)} className={`${styles.icon} ${!activeGrid ? styles.active : ''}`}>
        <FontAwesomeIcon icon={faGripLines} />
      </button>
    </div>
  );
};

export default GridSwitch;
