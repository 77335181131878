import { FC, PropsWithChildren } from 'react';
import styles from './Headline.module.scss';

export enum HEADLINE_SIZE {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  DISPLAY = 'display',
  HERO = 'hero',
}

export enum HEADLINE_COLOR {
  HEADLINE_COLOR_DARK = 'HEADLINE_COLOR_DARK',
  HEADLINE_COLOR_LIGHT = 'HEADLINE_COLOR_LIGHT',
  HEADLINE_COLOR_PRIMARY = 'HEADLINE_COLOR_PRIMARY',
}

export interface IHeadlineProps {
  size?: HEADLINE_SIZE;
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'span' | 'p';
  align?: 'center' | 'left' | 'right';
  mobileAlign?: 'center' | 'left' | 'right';
  mobileSize?: HEADLINE_SIZE;
  color?: HEADLINE_COLOR;
  breakWord?: boolean;
  underline?: boolean;
  preline?: boolean;
  noWrap?: boolean;
}
const Index: FC<PropsWithChildren<IHeadlineProps>> = ({
  children,
  size = HEADLINE_SIZE.H1,
  mobileSize,
  tag: Tag = 'h1',
  align = 'left',
  mobileAlign,
  color,
  breakWord,
  underline = false,
  preline = false,
  noWrap = false,
}) => {
  return (
    <Tag
      className={`${styles.headline} ${styles[size]} ${styles[color]} ${breakWord ? styles.breakWord : ''} ${
        preline ? styles.preline : ''
      } ${noWrap ? styles.noWrap : ''} ${underline ? styles.underline : ''} ${
        mobileSize ? styles[`mobile-${mobileSize}`] : null
      } ${styles[align]} ${mobileAlign ? styles[`mobile-${mobileAlign}`] : ''} `}
    >
      {children}
    </Tag>
  );
};

export default Index;
