import { useTranslation } from 'react-i18next';
import ModalWrapper, { IModalWrapperButtonProps } from '../ModalWrapper';
import Text, { TEXT_COLOR, TEXT_TYPE } from 'component/atoms/typographie/Text';
import ModalContext from 'core/context/modal.context';
import { useContext } from 'react';
import { ErrorCodes } from 'core/hook/desired-tenant.hook';
import Spacer from 'component/atoms/Spacer';
import { ROUTES } from 'core/const/routes';
import { useNavigate } from 'react-router-dom';
import UserContext from 'core/context/user.context';
import { createUrlFromTenantSearch } from 'core/helper/create-url-from-tenant-search';
import { useQuery } from '@tanstack/react-query';
import { wohnsinnServices } from 'App';

const DesiredTenantModal = () => {
  const { closeModal, modalData } = useContext(ModalContext);
  const navigate = useNavigate();
  const { t: r } = useTranslation('routes');
  const { t } = useTranslation('common');
  const { tenantService } = wohnsinnServices;
  const { tenantProfile } = useContext(UserContext);
  const { data } = useQuery({
    queryKey: ['fetchTenantProfile', tenantProfile?.uid],
    queryFn: () => tenantService.getTenantSearchProfiles(tenantProfile.uid),
    staleTime: 1,
  });

  const MODAL_BUTTONS: IModalWrapperButtonProps = {
    primary: {
      buttonText: t('Zeige passende Wohnungen'),
      onClick: () => {
        console.log(data[0]);
        const url = createUrlFromTenantSearch(data?.length ? data[0] : null);
        navigate(url);
        closeModal();
      },
    },
    secondary: {
      buttonText: t('Mieterprofil anpassen'),
      onClick: () => {
        navigate(r(ROUTES.tenantRoutes.account.overview.path));
        closeModal();
      },
    },
  };

  const errorMessages: { [key in ErrorCodes]: string } = {
    [ErrorCodes.PetsNotAllowed]: 'du Haustiere mitnehmen möchtest',
    [ErrorCodes.NumberOfPeopleExceeded]: 'du mit zu vielen Personen einziehen möchtest',
    [ErrorCodes.SharedUsageNotAllowed]: 'die Wohnung nicht WG-geeignet ist',
    [ErrorCodes.HousingCertificateRequired]: 'für die Wohnung ein WBS benötigt wird',
  };

  return (
    <ModalWrapper title={t('desiredTenant.notMatchingCriteria.title')} buttons={MODAL_BUTTONS}>
      <Text align={'center'} tag={'p'}>
        {t('desiredTenant.notMatchingCriteria.text')}
      </Text>

      <Spacer />
      {modalData?.length > 0 ? (
        <ul>
          {modalData.map((error: ErrorCodes, index: number) => (
            <li key={index}>
              <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION} color={TEXT_COLOR.TEXT_COLOR_DANGER} tag={'p'}>
                - {errorMessages[error]}
              </Text>
            </li>
          ))}
        </ul>
      ) : (
        <p>The tenant meets all desired criteria.</p>
      )}

      <Spacer />
      <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION} align={'center'} tag={'p'}>
        {t('desiredTenant.notMatchingCriteria.hint')}
      </Text>
    </ModalWrapper>
  );
};

export default DesiredTenantModal;
