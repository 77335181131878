import { FC, useEffect, useRef, useState } from 'react';
import { ROUTES } from 'core/const/routes';
import { useTranslation } from 'react-i18next';
import { Drawer, Grid, IconButton } from '@mui/material';
import styles from './LandlordChatView.module.scss';
import ApplicationModal from 'component/organisms/ApplicationModal';
import { APPLICATION_STATE, IApplication, USER_TYPE } from '@wohnsinn/ws-ts-lib';
import LandlordChatLayout from 'component/layouts/LandlordChatLayout';
import Chat from 'component/organisms/Chat';
import useWindowSize from 'core/hook/windowsize.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import ChatSender from 'component/molecules/ChatSender';
import ContextMenu from 'component/atoms/ContextMenu';
import useLandlordChats from 'core/hook/landlord-chats.hook';
import { useUpdateApplicationMutation } from 'core/hook/update-application.hook';
import Badge, { BADGE_COLORS } from 'component/atoms/Badge';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import { createApplicationContextMenu } from 'core/helper/create-context-menu';
import LoadingAnimation from 'component/atoms/LoadingAnimation';
import PageLayout from 'component/layouts/PageLayout';
import CTACard from 'component/molecules/Cards/CTACard';
import { useNavigate, useParams } from 'react-router-dom';
import ChatListItem from 'component/molecules/ChatListItem';
import { getTimeOrDate } from '../../../core/helper/nice-date-chat';

const LandlordChatView: FC = () => {
  const { tenantId, apartmentId } = useParams<{ tenantId: string; apartmentId: string }>();
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const [showSelfDisclosure, setShowSelfDisclosure] = useState(false);
  const [showMobileChat, setShowMobileChat] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState<IApplication>(null);
  const { isSmallerMd } = useWindowSize();
  const { data: applications, isLoading } = useLandlordChats();
  const { mutate } = useUpdateApplicationMutation();
  const initialApplication = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (applications?.length && !initialApplication.current) {
      const application =
        tenantId && apartmentId
          ? applications.find((a) => {
              return a.tenantProfile.uid === tenantId && a.apartmentId === apartmentId;
            })
          : applications[0];
      setSelectedApplication(application);
      initialApplication.current = applications[0];
      setTimeout(() => {
        mutate({
          application,
        });
      }, 1200);
    } else if (applications?.length && selectedApplication) {
      // update selectedApplication when application did change
      const newSelectedApplication = applications?.find((a) => a.id === selectedApplication.id);
      setSelectedApplication({ ...newSelectedApplication });
    }
  }, [applications]);

  const handleApplicationClick = (application: IApplication) => {
    if (isSmallerMd) {
      setShowMobileChat(true);
    }
    setSelectedApplication(application);
    console.log(application.id);

    if (tenantId && apartmentId) {
      navigate(r(ROUTES.landlordRoutes.chat.path));
    }

    if (application?.unreadTenantMessagesRef?.length) {
      mutate({
        application,
      });
    }
  };

  const getApplicationStateText = (state: APPLICATION_STATE) => {
    switch (state) {
      case APPLICATION_STATE.APPOINTMENT_CONFIRMED:
        return t('appointmentConfirmed');
      case APPLICATION_STATE.INVITED:
        return t('invited');
      case APPLICATION_STATE.NEW:
      case APPLICATION_STATE.OPENED:
        return t('unsorted');
      case APPLICATION_STATE.SORTED_LIKE:
        return t('favorites');
      case APPLICATION_STATE.SORTED_MAYBE:
        return t('maybe');
      case APPLICATION_STATE.SORTED_NOPE:
        return t('notInterested');
    }
  };

  const ChatHeader: FC<{ isMobile?: boolean }> = ({ isMobile }) => {
    return (
      <>
        <div className={styles.chatHeader}>
          {isMobile ? (
            <IconButton
              onClick={() => {
                setShowMobileChat(false);
                setSelectedApplication(null);
              }}
              className={styles.backButton}
            >
              <FontAwesomeIcon icon={faChevronLeft} size={'1x'} />
            </IconButton>
          ) : null}

          <ChatSender
            clickHandler={() => setShowSelfDisclosure(true)}
            imageUrl={selectedApplication?.tenantProfile?.photoUrl}
            fullAddress={
              selectedApplication.address.hasPublishedAddress
                ? `${selectedApplication.address.street} ${selectedApplication.address.houseNumber}`
                : `${selectedApplication.address.postalCode} ${selectedApplication.address.city}`
            }
            userType={USER_TYPE.LANDLORD}
            senderName={`${selectedApplication?.tenantProfile?.personalInformation?.firstName} ${selectedApplication?.tenantProfile?.personalInformation?.lastName}`}
          />
          <div className={styles.headerEnd}>
            {!isMobile ? (
              <Badge text={getApplicationStateText(selectedApplication.applicationState)} color={BADGE_COLORS.MEDIUM} />
            ) : null}
            <ContextMenu menu={createApplicationContextMenu(selectedApplication, mutate, t)} />
          </div>
        </div>
      </>
    );
  };

  const checkIfIsActive = (apartmentId: string, tenantId: string) => {
    return selectedApplication?.tenantProfile?.uid === tenantId && selectedApplication?.apartmentId === apartmentId;
  };

  if (!isLoading && !applications?.length) {
    return (
      <PageLayout showPageTitle={false} pageTitle={r(ROUTES.landlordRoutes.chat.title)}>
        <div className={styles.emptyList}>
          <Grid container spacing={0} justifyContent={'center'}>
            <Grid item xs={12} md={5}>
              <CTACard
                title={t('landlord.chat.empty.title')}
                text={t('landlord.chat.empty.text')}
                imgSrc={t('pictogram.chatMessage')}
                imgAltText={t('noObjectsAvailable')}
                ctaText={t('goToMyObject')}
                link={r(ROUTES.landlordRoutes.apartment.list.path)}
              />
            </Grid>
          </Grid>
        </div>
      </PageLayout>
    );
  }

  return (
    <LandlordChatLayout pageTitle={r(ROUTES.landlordRoutes.chat.title)}>
      <div className={styles.wrapper}>
        <div className={styles.listWrapper}>
          <div className={styles.listTitle}>
            <Headline size={HEADLINE_SIZE.H2}>{t('messages')}</Headline>
          </div>
          <ul className={styles.list}>
            {isLoading ? (
              <LoadingAnimation />
            ) : (
              applications?.map((application) => {
                const address = application.address;
                const fullAddress = `${address.street} ${address.houseNumber}, ${address.city}`;

                return (
                  <ChatListItem
                    userType={USER_TYPE.LANDLORD}
                    key={application.apartmentId + application.tenantProfile.uid}
                    mediaUrl={application?.tenantProfile?.photoUrl}
                    onClick={() => handleApplicationClick(application)}
                    isActive={checkIfIsActive(application.apartmentId, application.tenantProfile.uid)}
                    showNotificationBadge={!!application.unreadTenantMessagesRef?.length}
                    senderName={`${application?.tenantProfile?.personalInformation?.firstName} ${application?.tenantProfile?.personalInformation?.lastName}`}
                    date={getTimeOrDate(application?.lastMessageSent)}
                    preMessage={
                      application.lastMessageSenderId !== application.tenantProfile.uid ? `${t('you')}: ` : ''
                    }
                    subline={fullAddress}
                    message={application.lastMessage}
                  />
                );
              })
            )}
          </ul>
        </div>
        <div className={styles.chat}>
          {selectedApplication ? (
            <Chat header={<ChatHeader isMobile={false} />} application={selectedApplication} />
          ) : null}
        </div>
      </div>
      <Drawer anchor={'right'} open={showMobileChat} onClose={() => setShowMobileChat(false)}>
        <div className={styles.chatDrawer}>
          {selectedApplication ? <Chat header={<ChatHeader isMobile />} application={selectedApplication} /> : null}
        </div>
      </Drawer>

      <ApplicationModal
        hideChat
        isOpen={showSelfDisclosure}
        onClose={() => setShowSelfDisclosure(false)}
        application={selectedApplication}
      />
    </LandlordChatLayout>
  );
};

export default LandlordChatView;
