// noinspection HttpUrlsUsage

const getFunctionURLByEnv = (functionName: string): string => {
  if (process.env.REACT_APP_USE_EMULATOR === 'true') {
    return `http://${process.env.REACT_APP_EMULATOR_HOST}${process.env.REACT_APP_EMULATOR_FUNCTION_URL_PREFIX}${functionName}`;
  }

  return `https://${functionName}${process.env.REACT_APP_FUNCTION_URL_POSTFIX}`;
};
const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;

const FB_FUNCTION_URLS = {
  stripe: {
    createCheckout: getFunctionURLByEnv('createCheckoutSession'),
    createPortalLink: `https://europe-west3-${projectId}.cloudfunctions.net/ext-firestore-stripe-payments-createPortalLink`,
  },
  apartments: {
    deletedNotification: getFunctionURLByEnv('sendapartmentdeleted'),
    publishedChangeNotification: getFunctionURLByEnv('sendapartmentpublishedchangenotification'),
    editorList: {
      update: getFunctionURLByEnv('removeLandlordFromOrganizationEditorLists'),
    },
    getGeoCoordinates: getFunctionURLByEnv('getgeocoordinates'),
  },
  perfectMatches: getFunctionURLByEnv('getpaginatedmatches'),
  getTopApartments: getFunctionURLByEnv('gettopapartments'),
  getGeoInformation: getFunctionURLByEnv('getgeoinformation'),
  media: {
    resize: getFunctionURLByEnv('resizesingleimage'),
  },
  messaging: {
    sms: {
      triggerVerificationCode: getFunctionURLByEnv('triggerverificationcode'),
      verifyVerificationCode: getFunctionURLByEnv('verifyverificationcode'),
    },
    requestSale: getFunctionURLByEnv('sendSaleRequest'),
  },
  registrationRequest: {
    verifyRegistrationRequest: getFunctionURLByEnv('verifyRegistrationRequest'),
    deleteRegistrationRequest: getFunctionURLByEnv('deleteregistrationrequest'),
    createUserRegistrationRequest: getFunctionURLByEnv('createregistrationrequest'),
    sendRegistrationRequestMail: getFunctionURLByEnv('sendregistrationrequestmail'),
  },
  user: {
    checkIfUserExists: getFunctionURLByEnv('checkifuserexists'),
    createUserWithoutPassword: getFunctionURLByEnv('createuserwithoutpassword'),
    delete: getFunctionURLByEnv('deleteuser'),
    requestFTPDataMail: getFunctionURLByEnv('requestftpdatamail'),
    sendRegistrationRequestMail: getFunctionURLByEnv('sendregistrationrequestmail'),
    sendPasswordResetMail: getFunctionURLByEnv('sendpasswordresetmail'),
    sendUserInvitationMail: getFunctionURLByEnv('senduserinvitationmail'),
    sendUserEmailVerificationMail: getFunctionURLByEnv('sendemailverificationmail'),
    updateNotificationSettings: getFunctionURLByEnv('updatenotificationsettings'),
    updatePassword: getFunctionURLByEnv('updatepassword'),
    aboutMe: {
      update: getFunctionURLByEnv('updatetenantaboutme'),
    },
  },
  version: {
    get: getFunctionURLByEnv('getversion'),
  },
};

export default FB_FUNCTION_URLS;
