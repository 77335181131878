import { useContext, useEffect, useState } from 'react';
import UserContext from '../context/user.context';
import { IApplication, MATCHING_MODE, USER_TYPE } from '@wohnsinn/ws-ts-lib';
import { onSnapshot, Unsubscribe } from 'firebase/firestore';
import { wohnsinnServices } from '../../App';

interface IUseLandlordApplicationListHookParams {
  apartmentId?: string;
  tenantId?: string;
}

const useLandlordApplicationListHook = (params: IUseLandlordApplicationListHookParams) => {
  const { user } = useContext(UserContext);
  const { applicationService } = wohnsinnServices;
  const [applicationList, setApplicationList] = useState<IApplication[]>([]);
  const [likeApplicationList, setLikeApplicationList] = useState<IApplication[]>([]);
  const [maybeApplicationList, setMaybeApplicationList] = useState<IApplication[]>([]);
  const [nopeApplicationList, setNopeApplicationList] = useState<IApplication[]>([]);
  const [newApplicationList, setNewApplicationList] = useState<IApplication[]>([]);
  const [unreadChatNotifications, setUnreadChatNotifications] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    setIsLoading(true);

    if (user?.uid && user?.activeUserType === USER_TYPE.LANDLORD) {
      const applicationQuery = applicationService.getLandlordApplicationListRef({
        isAdmin: user.isAdmin,
        landlordId: user.uid,
        apartmentId: params.apartmentId,
        tenantId: params.tenantId,
      });
      (async () => {
        unsubscribe = onSnapshot(
          applicationQuery,
          (applicationSnap) => {
            let unreadChatNotifications = 0;

            const applicationList: IApplication[] = applicationSnap.docs.map((doc) => {
              const application = doc.data();
              if (application?.unreadTenantMessagesRef?.length) {
                unreadChatNotifications += application.unreadTenantMessagesRef.length;
              }
              return application;
            });

            if (applicationList.length > 1 && params.tenantId && params.apartmentId) {
              throw Error(
                `Found more than 1 matching application for: landlordId: ${user.uid} tenantId: ${params.tenantId} apartmentId: ${params.apartmentId} `
              );
            }

            const { newApplications, likeApplications, maybeApplications, nopeApplications } =
              sortApplicationsByLandlordRating(applicationList);
            setNewApplicationList(newApplications);

            setLikeApplicationList(likeApplications);
            setMaybeApplicationList(maybeApplications);
            setNopeApplicationList(nopeApplications);
            setApplicationList(applicationList);

            if (!params.apartmentId && !params.tenantId) {
              setUnreadChatNotifications(unreadChatNotifications);
            }
          },
          (err) => console.error('Error on useApplicationHook ', err)
        );
      })();

      setIsLoading(false);
    }
    return unsubscribe;
  }, []);

  return {
    applicationList,
    likeApplicationList,
    maybeApplicationList,
    newApplicationList,
    nopeApplicationList,
    unreadChatNotifications,
    isLoading,
  };
};

const sortApplicationsByLandlordRating = (applications: IApplication[]) => {
  const sortedApplications = {
    newApplications: [] as IApplication[],
    likeApplications: [] as IApplication[],
    maybeApplications: [] as IApplication[],
    nopeApplications: [] as IApplication[],
  };

  applications.forEach((application) => {
    switch (application.landlordRating) {
      case MATCHING_MODE.NONE:
        sortedApplications.newApplications.push(application);
        break;
      case MATCHING_MODE.LIKE:
        sortedApplications.likeApplications.push(application);
        break;
      case MATCHING_MODE.MAYBE:
        sortedApplications.maybeApplications.push(application);
        break;
      case MATCHING_MODE.NOPE:
        sortedApplications.nopeApplications.push(application);
        break;
      default:
        break;
    }
  });

  return sortedApplications;
};

export default useLandlordApplicationListHook;
