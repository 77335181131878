import { useTranslation } from 'react-i18next';
import ModalWrapper, { IModalWrapperButtonProps } from '../ModalWrapper';
import ModalContext from 'core/context/modal.context';
import { useContext, useEffect } from 'react';
import { Grid } from '@mui/material';
import SplitLine from 'component/atoms/SplitLine';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import TextInput from 'component/atoms/formElement/InputText';
import { FormContext } from 'core/context/form.context';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { wohnsinnServices } from 'App';
import Text, { TEXT_COLOR } from 'component/atoms/typographie/Text';
import { IMedia } from '@wohnsinn/ws-ts-lib';

const CHANGE_DESCRIPTION_FORM_ID = 'CHANGE_DESCRIPTION_FORM_ID';

const ChangeMediaDescriptionModal = () => {
  const { closeModal, modalData } = useContext(ModalContext);
  const { t: c } = useTranslation('common');
  const { apartmentService } = wohnsinnServices;

  const {
    control,
    formState: { isValid },
    handleSubmit,
    setValue,
    getValues,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      description: modalData?.media[modalData?.index]?.alt,
    },
    resolver: joiResolver(Joi.object({ description: Joi.string().required() })),
  });

  const handleDescriptionChange = async () => {
    const value = getValues();
    const newApartmentMedia: IMedia[] = [...modalData?.media];
    newApartmentMedia[modalData?.index] = { ...newApartmentMedia[modalData?.index], alt: value.description };

    if (modalData?.type === 'layout') {
      await apartmentService.updateApartmentMediaList(modalData?.apartment, newApartmentMedia, 'layout');
    }

    if (modalData?.type === 'media') {
      await apartmentService.updateApartmentMediaList(modalData?.apartment, newApartmentMedia, 'media');
    }

    if (modalData?.type === 'document') {
      await apartmentService.updateApartmentMediaList(modalData?.apartment, newApartmentMedia, 'document');
    }

    closeModal();
  };

  useEffect(() => {
    document.getElementById('description').focus();
  }, []);

  const MODAL_BUTTONS: IModalWrapperButtonProps = {
    primary: {
      buttonText: c('save'),
      type: 'submit',
      id: CHANGE_DESCRIPTION_FORM_ID,
      onClick: handleDescriptionChange,
      disabled: !isValid,
    },
    third: {
      buttonText: c('cancel'),
      onClick: closeModal,
    },
  };

  const setDefault = (value: string) => {
    setValue('description', value, { shouldValidate: true });
    document.getElementById('description').focus();
  };

  return (
    <ModalWrapper title={c('description')} buttons={MODAL_BUTTONS} subtitle={c('upload.documents.subTitle')}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormContext.Provider value={{ control }}>
            <form id={CHANGE_DESCRIPTION_FORM_ID} onSubmit={handleSubmit(handleDescriptionChange)}>
              <TextInput
                id={'description'}
                name={'description'}
                placeholder={modalData?.type === 'media' ? c('example.bathroom') : 'z. B. Grundriss'}
              />
            </form>
          </FormContext.Provider>
        </Grid>
        <Grid item xs={12}>
          <SplitLine size={'none'} />
        </Grid>
        <Grid item xs={12}>
          <Headline size={HEADLINE_SIZE.H3}>Oft verwendet:</Headline>
          <div>
            {modalData?.type === 'document' ? (
              <ul>
                <button onClick={() => setDefault(c('Dokument'))}>
                  <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT}>{c('Dokument')}</Text>
                </button>
              </ul>
            ) : null}
            {modalData?.type === 'layout' ? (
              <ul>
                <button onClick={() => setDefault(c('Grundriss'))}>
                  <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT}>{c('Grundriss')}</Text>
                </button>
              </ul>
            ) : null}

            {modalData?.type === 'media' ? (
              <ul>
                <li>
                  <button onClick={() => setDefault(c('livingRoom'))}>
                    <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT}>{c('livingRoom')}</Text>
                  </button>
                </li>
                <li>
                  <button onClick={() => setDefault(c('bedRoom'))}>
                    <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT}>{c('bedRoom')}</Text>
                  </button>
                </li>
                <li>
                  <button onClick={() => setDefault(c('bathRoom'))}>
                    <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT}>{c('bathRoom')}</Text>
                  </button>
                </li>
                <li>
                  <button onClick={() => setDefault(c('kitchen'))}>
                    <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT}>{c('kitchen')}</Text>
                  </button>
                </li>
                <li>
                  <button onClick={() => setDefault(c('balcony'))}>
                    <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT}>{c('balcony')}</Text>
                  </button>
                </li>
              </ul>
            ) : null}
          </div>
        </Grid>
      </Grid>
    </ModalWrapper>
  );
};

export default ChangeMediaDescriptionModal;
