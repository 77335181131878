import { useTranslation } from 'react-i18next';
import { ROUTES } from 'core/const/routes';
import { Grid } from '@mui/material';
import Text from 'component/atoms/typographie/Text';
import LandingPageLayout from '../../../component/layouts/LandingPageLayout';

const ImprintView = () => {
  const { t: r } = useTranslation('routes');
  return (
    <LandingPageLayout pageTitle={r(ROUTES.landingPageRoutes.imprint.title)}>
      <Grid item xs={12} paddingY={{ xs: 5, md: 10 }} className={'ws-full-width-bg ws-full-width-bg--white'}>
        <Grid container>
          <Grid item xs={12}>
            <Text bold>Firmendaten:</Text>
            <Text>Wohnsinn GmbH</Text>
            <Text>Martinsfeld 12</Text>
            <Text>50676 Köln</Text>
            <Text>Telefon: +49 221 954 904 28</Text>
            <Text>
              E-Mail: <a href="mailto:kontakt@wohnsinn.com">kontakt@wohnsinn.com</a>
            </Text>
            <Text>Vertretungsberechtigte Geschäftsführer: Christian Philippi & Felix Weiß</Text>
            <Text>Handelsregister: Amtsgericht Köln</Text>
            <Text>Registernummer: HRB 105211</Text>
          </Grid>
        </Grid>
      </Grid>
    </LandingPageLayout>
  );
};

export default ImprintView;
