import CompleteApplicationFolderModal from './CompleteApplicationFolderModal';
import CreateOrUploadSchufaModal from './CreateOrUploadSchufaModal';
import CreateUserInvitationModal from './CreateUserInvitationModal';
import CropImageModal from './CropImageModal';
import DeleteModal from './DeleteModal';
import FilterLocationModal from './filter/FilterLocationModal';
import InfoTextModal from './InfoTextModal';
import ModalContext from 'core/context/modal.context';
import NotificationsModal from './NotificationsModal';
import PublishApartmentModal from './PublishApartmentModal';
import SignInModal from './signIn/SignInModal';
import VirtualTourModal from './VirtualTourModal';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import React, { useContext } from 'react';
import FilterResetModal from './filter/FilterResetModal';
import BeforeApplicationProfileOverviewModal from './BeforeApplicationProfileOverviewModal';
import ResetPasswordModal from './signIn/ResetPasswordModal';
import { Dialog, Slide } from '@mui/material';
import { Box } from '@mui/system';
import { TransitionProps } from '@mui/material/transitions';
import ShareApartmentLinkModal from './ShareApartmentLinkModal';
import SwitchUserTypeModal from './SwitchUserTypeModal';
import SignUpUserModal from 'component/modals/signIn/SignUpUserModal';
import CompleteDocumentFolderModal from 'component/modals/CompleteDocumentFolderModal';
import DesiredTenantModal from 'component/modals/DesiredTenantModal';
import ChangeMediaDescriptionModal from './ChangeMediaDescriptionModal';
import FoundTenantModal from 'component/modals/PublishApartmentModal/FoundTenantModal';
import SelectUserTypeModal from 'component/modals/signIn/SelectUserTypeModal';
import RejectApplicationsModal from 'component/modals/RejectApplicationsModal';
import DeleteApplicationsModal from 'component/modals/DeleteApplicationsModal';
import UploadProfileImageModal from 'component/modals/UploadProfileImageModal';

const ModalLoader = () => {
  const { currentModalId, closeModal, onDismiss } = useContext(ModalContext);

  const renderModal = () => {
    switch (currentModalId) {
      case MODAL_IDENTIFIER.BEFORE_APPLICATION_OVERVIEW:
        return <BeforeApplicationProfileOverviewModal />;
      case MODAL_IDENTIFIER.CHANGE_MEDIA_DESCRIPTION:
        return <ChangeMediaDescriptionModal />;
      case MODAL_IDENTIFIER.COMPLETE_APPLICATION_FOLDER:
        return <CompleteApplicationFolderModal />;
      case MODAL_IDENTIFIER.COMPLETE_DOCUMENT_FOLDER:
        return <CompleteDocumentFolderModal />;
      case MODAL_IDENTIFIER.CREATE_OR_UPLOAD_SCHUFA:
        return <CreateOrUploadSchufaModal />;
      case MODAL_IDENTIFIER.CREATE_USER_INVITATION:
        return <CreateUserInvitationModal />;
      case MODAL_IDENTIFIER.CROP_IMAGE:
        return <CropImageModal />;
      case MODAL_IDENTIFIER.DELETE_APPLICATIONS:
        return <DeleteApplicationsModal />;
      case MODAL_IDENTIFIER.DELETE_MODAL:
        return <DeleteModal />;
      case MODAL_IDENTIFIER.DESIRED_TENANT:
        return <DesiredTenantModal />;
      case MODAL_IDENTIFIER.FILTER_LOCATION:
        return <FilterLocationModal />;
      case MODAL_IDENTIFIER.FILTER_RESET:
        return <FilterResetModal />;
      case MODAL_IDENTIFIER.FOUND_TENANT:
        return <FoundTenantModal />;
      case MODAL_IDENTIFIER.INFO_TEXT:
        return <InfoTextModal />;
      case MODAL_IDENTIFIER.NOTIFICATIONS:
        return <NotificationsModal />;
      case MODAL_IDENTIFIER.PUBLISH_APARTMENT:
        return <PublishApartmentModal />;
      case MODAL_IDENTIFIER.REJECT_APPLICATIONS:
        return <RejectApplicationsModal />;
      case MODAL_IDENTIFIER.RESET_PASSWORD:
        return <ResetPasswordModal />;
      case MODAL_IDENTIFIER.SELECT_USER_TYPE:
        return <SelectUserTypeModal />;
      case MODAL_IDENTIFIER.SHARE_LINK:
        return <ShareApartmentLinkModal />;
      case MODAL_IDENTIFIER.SIGN_IN:
        return <SignInModal />;
      case MODAL_IDENTIFIER.SIGN_UP_USER:
        return <SignUpUserModal />;
      case MODAL_IDENTIFIER.SWITCH_USER_TYPE:
        return <SwitchUserTypeModal />;
      case MODAL_IDENTIFIER.UPLOAD_PROFILE_IMAGE:
        return <UploadProfileImageModal />;
      case MODAL_IDENTIFIER.VIRTUAL_TOUR:
        return <VirtualTourModal />;
      default:
        return <div></div>;
    }
  };

  const onDismissHandler = () => {
    if (onDismiss) {
      onDismiss();
    }
    closeModal();
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          borderRadius: '10px',
          margin: '10px',
          width: 'calc(100vw - 20px)',
        },
      }}
      TransitionComponent={Transition}
      onClose={onDismissHandler}
      open={!!currentModalId}
    >
      <Box style={{ height: '100%' }}>{renderModal()}</Box>
    </Dialog>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return (
    <Slide direction={'up'} ref={ref} {...props}>
      {props.children}
    </Slide>
  );
});

export default ModalLoader;
