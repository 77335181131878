import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faChevronLeft, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import useWindowSize from '../../../core/hook/windowsize.hook';
import { ROUTES } from '../../../core/const/routes';
import MobileHeaderButton from '../../../component/atoms/Buttons/MobileHeaderButton';
import ThreeColumnsLayoutContext from '../../../core/context/three-columns-layout-context';
import TenantChatWrapper from '../../../component/organisms/Chat/TenantChatWrapper';
import ThreeColumnsPageLayout from '../../../component/layouts/ThreeColumnsPageLayout';
import { IconButton } from '@mui/material';
import styles from 'component/layouts/ThreeColumnsPageLayout/ThreeColumnsPageLayout.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ChatView: FC = () => {
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const [showLeftSideBar, setShowLeftSideBar] = useState<boolean>(true);
  const [showRightSideBar, setShowRightSideBar] = useState<boolean>(false);
  const [showBackButton, setShowBackButton] = useState<boolean>(true);
  const { isSmallerMd, isSmallerLg } = useWindowSize();
  const [showInfoButton, setShowInfoButton] = useState<boolean>(isSmallerMd);
  const [pageTitle, setPageTitle] = useState<string>(r(ROUTES.tenantRoutes.chat.title));

  useEffect(() => {
    setShowBackButton(false);
    setShowInfoButton(!isSmallerMd);
  }, [isSmallerMd]);

  const ShowInfoButton = (): JSX.Element => {
    const handleInfoButton = () => {
      setShowRightSideBar(!showRightSideBar);
    };

    return (
      <MobileHeaderButton
        hideText
        text={t('showInfo')}
        onClick={handleInfoButton}
        icon={showRightSideBar ? faTimes : faHome}
      />
    );
  };

  const showLeft = (): void => {
    setShowRightSideBar(false);
    setShowLeftSideBar(true);
    setShowBackButton(false);
    setShowInfoButton(false);
  };
  const showMiddle = (): void => {
    setShowRightSideBar(false);
    setShowLeftSideBar(false);
    setShowBackButton(true);
    setShowInfoButton(true);
  };
  const showRight = (): void => {
    setShowRightSideBar(true);
    setShowLeftSideBar(false);
  };

  const CustomBackButton = () => {
    return (
      <IconButton onClick={() => showLeft()} className={styles.backButton}>
        <FontAwesomeIcon icon={faChevronLeft} size={'1x'} />
      </IconButton>
    );
  };

  return (
    <ThreeColumnsPageLayout
      backButton={showLeftSideBar || !isSmallerMd ? null : <CustomBackButton />}
      headerEndButton={showInfoButton && isSmallerLg ? <ShowInfoButton /> : null}
      pageTitle={pageTitle}
    >
      <ThreeColumnsLayoutContext.Provider
        value={{
          showLeftSideBar,
          showRightSideBar,
          showBackButton,
          setShowBackButton,
          setShowInfoButton,
          setPageTitle,
          showLeft,
          showRight,
          showMiddle,
        }}
      >
        <TenantChatWrapper />
      </ThreeColumnsLayoutContext.Provider>
    </ThreeColumnsPageLayout>
  );
};

export default ChatView;
