import { useState } from 'react';
import { SUBMIT_BUTTON_MODE } from '../../../../core/enum/submit-button-mode.enum';
import { useTunnelContext } from 'core/context/create-tenant-from-apartment-apply-tunnel.context';
import SearchTunnelStepLayout from 'component/organisms/searchTunnel/SearchTunnelStepLayout';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useTranslation } from 'react-i18next';
import RadioGroupButtons, { IRadioGroupOption } from 'component/atoms/RadioGroupButtons';

const IS_SHARED_USAGE_ALLOWED_STEP = 'IS_SHARED_USAGE_ALLOWED_STEP';

const IsSharedUsageAllowedStep = () => {
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { household, setHousehold, goToNextStep, goToPreviousStep } = useTunnelContext();
  const { t } = useTranslation('common');

  const { control, handleSubmit, formState } = useForm<{
    isSharedUsagePossible: boolean;
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: joiResolver(
      Joi.object({
        isSharedUsagePossible: Joi.boolean().required(),
      })
    ),
    defaultValues: {
      isSharedUsagePossible: household?.isSharedUsagePossible ?? null,
    },
  });

  const handleFormSubmit = (data: { isSharedUsagePossible: any }) => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    setHousehold({ ...household, isSharedUsagePossible: data.isSharedUsagePossible });
    goToNextStep();
  };

  const options: IRadioGroupOption[] = [
    { key: 'Ja, WG gründen', value: true },
    { key: 'Nein, keine WG', value: false },
  ];

  return (
    <SearchTunnelStepLayout
      formId={IS_SHARED_USAGE_ALLOWED_STEP}
      control={control}
      disabled={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING || !formState.isValid}
      buttonSubmitMode={buttonSubmitMode}
      onSubmit={handleSubmit(handleFormSubmit, console.error)}
      title={'Möchtest du eine WG gründen?'}
      backAction={() => goToPreviousStep()}
    >
      <RadioGroupButtons name={'isSharedUsagePossible'} options={options} />
    </SearchTunnelStepLayout>
  );
};

export default IsSharedUsageAllowedStep;
