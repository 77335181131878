import { Grid } from '@mui/material';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import Text, { TEXT_COLOR, TEXT_TYPE } from 'component/atoms/typographie/Text';
import { IMAGES } from 'core/const/images';
import TextBlockSection from 'component/molecules/TextBlockSection';
import CheckList from 'component/atoms/CheckList';
import Spacer, { SPACER_SIZE } from 'component/atoms/Spacer';
import { useTranslation } from 'react-i18next';
import Step from 'component/atoms/Step';

const ProSectionLandlord = () => {
  const { t: i } = useTranslation('images');
  return (
    <>
      <Grid container justifyContent={'center'}>
        <Grid item xs={12} md={8} paddingTop={{ xs: 4, md: 10 }} paddingBottom={{ xs: 4, md: 10 }}>
          <Headline size={HEADLINE_SIZE.DISPLAY} align={'center'}>
            Warum Wohnsinn für die Mietersuche nutzen?
          </Headline>
        </Grid>
      </Grid>

      <Grid container alignItems={'center'} paddingTop={6} rowSpacing={4} columnSpacing={24}>
        <Grid item xs={12} sm={6} alignItems={'center'}>
          <TextBlockSection
            headlineSize={HEADLINE_SIZE.H1}
            title={'Wir übernehmen die'}
            titleTwo={'Vorauswahl - automatisch.'}
          >
            <Spacer size={SPACER_SIZE.MD} />
            <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_ACCENT} type={TEXT_TYPE.TEXT_TYPE_LANDING}>
              Erhalte nur noch qualifizierte Anfragen, die genau zu deinen Anforderungen passen.
            </Text>
          </TextBlockSection>

          <Spacer size={SPACER_SIZE.LG} />

          <CheckList accentColor items={AUTO_SELECT} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <img src={i(IMAGES.pros.landlord.autoSelect.src)} alt={i(IMAGES.pros.landlord.autoSelect.alt)} />
        </Grid>
      </Grid>

      <Grid container alignItems={'center'} paddingTop={12} rowSpacing={4} columnSpacing={24}>
        <Grid item xs={12} sm={6}>
          <TextBlockSection
            headlineSize={HEADLINE_SIZE.H1}
            title={'Schluss mit dem Nachfordern'}
            titleTwo={'von Dokumenten.'}
          >
            <Spacer size={SPACER_SIZE.MD} />

            <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_ACCENT} type={TEXT_TYPE.TEXT_TYPE_LANDING}>
              Bei uns erhältst du bereits im Vorfeld alle relevanten Mietunterlagen, sodass du keine zusätzlichen
              Informationen anfordern musst.
            </Text>
          </TextBlockSection>

          <Spacer size={SPACER_SIZE.LG} />

          <CheckList accentColor items={NO_MORE_DOCUMENTS} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <img src={i(IMAGES.pros.landlord.noMoreDocs.src)} alt={i(IMAGES.pros.landlord.noMoreDocs.alt)} />
        </Grid>
      </Grid>

      <Grid container alignItems={'center'} paddingTop={12} rowSpacing={4} columnSpacing={24}>
        <Grid item xs={12} sm={6}>
          <TextBlockSection
            headlineSize={HEADLINE_SIZE.H1}
            title={'Deine Zeit ist zu wertvoll'}
            titleTwo={'für monotone Arbeiten.'}
          >
            <Spacer size={SPACER_SIZE.MD} />

            <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_ACCENT} type={TEXT_TYPE.TEXT_TYPE_LANDING}>
              Durch die Automatisierung des gesamten Vermietungsprozesses sparst du Zeit bei der Auswahl der Mieter.
            </Text>
          </TextBlockSection>

          <Spacer size={SPACER_SIZE.LG} />

          <CheckList accentColor items={SAFE_TIME} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <img src={i(IMAGES.pros.landlord.safeTime.src)} alt={i(IMAGES.pros.landlord.safeTime.alt)} />
        </Grid>
      </Grid>

      <Grid container paddingTop={{ xs: 4, md: 10 }}>
        <Grid item xs={12}>
          <Headline size={HEADLINE_SIZE.DISPLAY} align={'center'}>
            Und so einfach geht&apos;s
          </Headline>
        </Grid>
      </Grid>
      <Grid container spacing={4} paddingTop={{ xs: 4, md: 10 }}>
        <Grid item xs={12} md={3}>
          <Step number={1} />
          <Spacer size={SPACER_SIZE.MD} />
          <Headline size={HEADLINE_SIZE.H2}>Definiere deinen Wunschmieter</Headline>
          <Spacer size={SPACER_SIZE.MD} />
          <Text>
            Definiere bei der Inseratserstellung deine Wunschmieter-Kriterien, um Mietinteressenten zu erreichen, die
            genau zu deinen Vorstellungen passen.
          </Text>
        </Grid>
        <Grid item xs={12} md={3}>
          <Step number={2} />
          <Spacer size={SPACER_SIZE.MD} />
          <Headline size={HEADLINE_SIZE.H2}>Veröffentliche dein Inserat</Headline>
          <Spacer size={SPACER_SIZE.MD} />
          <Text>
            Nach Veröffentlichung wird unsere umfangreiche Mieter-Datenbank informiert. Zudem veröffentlichen wir dein
            Inserat auf Kleinanzeigen, um die Reichweite zu maximieren.
          </Text>
        </Grid>
        <Grid item xs={12} md={3}>
          <Step number={3} />
          <Spacer size={SPACER_SIZE.MD} />
          <Headline size={HEADLINE_SIZE.H2}>Erhalte passende Anfragen</Headline>
          <Spacer size={SPACER_SIZE.MD} />
          <Text>
            Unser Matching-Algorithmus übernimmt automatisch die Vorauswahl für dich, sodass du nur noch passende
            Bewerbungen bearbeiten musst.
          </Text>
        </Grid>
        <Grid item xs={12} md={3}>
          <Step number={4} />
          <Spacer size={SPACER_SIZE.MD} />
          <Headline size={HEADLINE_SIZE.H2}>Koordiniere Besichtigungen</Headline>
          <Spacer size={SPACER_SIZE.MD} />
          <Text>
            Mit Wohnsinn kannst du ganz einfach Besichtigungstermine vereinbaren. Zusätzlich erinnern wir alle
            eingeladenen Mieter, um die Zahl der Nichterscheinungen zu verringern.
          </Text>
        </Grid>
      </Grid>
    </>
  );
};
const SAFE_TIME = [
  {
    text: 'Keine monotonen Aufgaben',
    bold: false,
    invert: false,
  },
  {
    text: 'Mehr Zeit für Kundenservice',
    bold: false,
    invert: false,
  },
  {
    text: 'Weniger unnötige Besichtigungen',
    bold: false,
    invert: false,
  },
];

const NO_MORE_DOCUMENTS = [
  {
    text: 'Kein Papierkram',
    bold: false,
    invert: false,
  },
  {
    text: 'Verifizierte Dokumente',
    bold: false,
    invert: false,
  },
  {
    text: 'Keine ausgedruckten Bewerbermappen',
    bold: false,
    invert: false,
  },
];

const AUTO_SELECT = [
  {
    text: 'Qualität statt Quantität',
    bold: false,
    invert: false,
  },
  {
    text: 'Keine unnötigen Anfragen bearbeiten',
    bold: false,
    invert: false,
  },
  {
    text: 'Mehr Zeit für das Wesentliche',
    bold: false,
    invert: false,
  },
];

export default ProSectionLandlord;
