import AddressFinderInput from 'component/molecules/AddressFinderInput';
import Joi from 'joi';
import styles from './SearchAddressForm.module.scss';
import { FormContext } from 'core/context/form.context';
import { ILocationSelection } from '@wohnsinn/ws-ts-lib';
import { ROUTES } from 'core/const/routes';
import { joiResolver } from '@hookform/resolvers/joi';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const SEARCH_LOCATION_FORM_SCHEMA = Joi.object({
  location: Joi.any(),
});

const SearchAddressForm = () => {
  const { t: r } = useTranslation('routes');
  const navigate = useNavigate();

  const { control, handleSubmit, watch, formState } = useForm<{
    location: ILocationSelection;
  }>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(SEARCH_LOCATION_FORM_SCHEMA),
    defaultValues: {
      location: undefined,
    },
  });

  const handleFormSubmit = () => {
    const location = watch('location');
    const url = new URL(window.location.href);

    if (location) {
      url.searchParams.set('location', JSON.stringify(location));
    }
    navigate(`${r(ROUTES.staticRoutes.search.path)}/${url.search}`);
  };

  return (
    <div className={styles.wrapper}>
      <FormContext.Provider value={{ control }}>
        <form onSubmit={handleSubmit(handleFormSubmit, console.error)}>
          <AddressFinderInput name={'location'} />
        </form>
      </FormContext.Provider>
    </div>
  );
};

export default SearchAddressForm;
