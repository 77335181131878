import { APPLICATION_STATE, IApplication } from '@wohnsinn/ws-ts-lib';
import { IContextMenuItem } from 'component/atoms/ContextMenu';
import { IMutationParams } from '../hook/update-application.hook';

export const createApplicationContextMenu = (
  application: IApplication,
  mutate: (params: IMutationParams) => void,
  t: any
): IContextMenuItem[] => {
  const CONTEXT_MENU: IContextMenuItem[] = [];

  if (application?.applicationState !== APPLICATION_STATE.INVITED) {
    CONTEXT_MENU.push({
      onClick: () =>
        mutate({
          application,
          data: { applicationState: APPLICATION_STATE.INVITED },
        }),
      text: t('moveTo.invited'),
    });
  }

  if (application?.applicationState !== APPLICATION_STATE.APPOINTMENT_CONFIRMED) {
    CONTEXT_MENU.push({
      onClick: () =>
        mutate({
          application,
          data: { applicationState: APPLICATION_STATE.APPOINTMENT_CONFIRMED },
        }),
      text: t('moveTo.appointmentAccepted'),
    });
  }

  return CONTEXT_MENU;
};
