import { DateField as DateMUI } from '@mui/x-date-pickers';
import { FC, useContext } from 'react';
import { Controller } from 'react-hook-form';
import { FormContext } from '../../../../core/context/form.context';
import dayjs from 'dayjs';
export interface IDateInputProps {
  label?: string;
  name?: any;
  required?: boolean;
}

const DateInput: FC<IDateInputProps> = ({ label, name, required }) => {
  const { control } = useContext(FormContext);

  const valueHandler = (field: any, e: any) => {
    console.log(dayjs(e));
    field.onChange(dayjs(e).toDate());
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <div className={'floating-wrapper floating-wrapper-input'}>
            <div className={'floating-field'}>
              <DateMUI
                required={required}
                sx={{ width: '100%', borderColor: error ? '#ff4961' : '#d7d8da' }}
                format={'DD.MM.YYYY'}
                name={name}
                label={label}
                value={field?.value ? dayjs(field.value) : null}
                onChange={(e) => valueHandler(field, e)}
              />
            </div>
          </div>
        );
      }}
    />
  );
};

export default DateInput;
