import ModalWrapper from '../ModalWrapper';
import { FC, useContext } from 'react';
import ModalContext from 'core/context/modal.context';
import Text from 'component/atoms/typographie/Text';
import { useTranslation } from 'react-i18next';
import SanitizedHTML from 'component/atoms/SanitzedHtml';

const InfoTextModal: FC = () => {
  const { closeModal, modalData } = useContext(ModalContext);
  const { t } = useTranslation('common');

  return (
    <ModalWrapper
      title={modalData?.title ? modalData.title : 'Info'}
      buttons={{
        primary: {
          buttonText: t('close'),
          onClick: closeModal,
        },
      }}
    >
      {modalData?.text ? <Text>{modalData.text}</Text> : null}
      {modalData?.sanitizeText ? (
        <Text preLine={true}>
          <SanitizedHTML dirty={modalData.sanitizeText} />
        </Text>
      ) : null}
    </ModalWrapper>
  );
};

export default InfoTextModal;
