export enum MODAL_IDENTIFIER {
  BEFORE_APPLICATION_OVERVIEW = 'BEFORE_APPLICATION_OVERVIEW',
  CHANGE_MEDIA_DESCRIPTION = 'CHANGE_MEDIA_DESCRIPTION',
  COMPLETE_APPLICATION_FOLDER = 'COMPLETE_APPLICATION_FOLDER',
  COMPLETE_DOCUMENT_FOLDER = 'COMPLETE_DOCUMENT_FOLDER',
  CREATE_OR_UPLOAD_SCHUFA = 'CREATE_OR_UPLOAD_SCHUFA',
  CREATE_USER_INVITATION = 'CREATE_USER_INVITATION',
  CROP_IMAGE = 'CROP_IMAGE',
  DELETE_APPLICATIONS = 'DELETE_APPLICATIONS',
  DELETE_MODAL = 'DELETE_MODAL',
  DESIRED_TENANT = 'DESIRED_TENANT',
  FILTER_LOCATION = 'FILTER_LOCATION',
  FILTER_RESET = 'FILTER_RESET',
  FOUND_TENANT = 'FOUND_TENANT',
  INFO_TEXT = 'INFO_TEXT',
  NONE = 'NONE',
  NOTIFICATIONS = 'NOTIFICATIONS',
  PUBLISH_APARTMENT = 'PUBLISH_APARTMENT',
  REJECT_APPLICATIONS = 'REJECT_APPLICATIONS',
  RESET_PASSWORD = 'RESET_PASSWORD',
  SELECT_USER_TYPE = 'SELECT_USER_TYPE',
  SHARE_LINK = 'SHARE_LINK',
  SIGN_IN = 'SIGN_IN',
  SIGN_UP_USER = 'SIGN_UP_USER',
  SWITCH_USER_TYPE = 'SWITCH_USER_TYPE',
  UPLOAD_PROFILE_IMAGE = 'UPLOAD_PROFILE_IMAGE',
  VIRTUAL_TOUR = 'VIRTUAL_TOUR',
}
