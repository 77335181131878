import { FC, PropsWithChildren } from 'react';
import styles from './SearchTunnelStepLayout.module.scss';
import FormNavigation from '../../../molecules/FormNavigation';
import { SUBMIT_BUTTON_MODE } from '../../../../core/enum/submit-button-mode.enum';
import { Grid } from '@mui/material';
import { FormContext } from 'core/context/form.context';
import { useTranslation } from 'react-i18next';
import Headline, { HEADLINE_SIZE } from '../../../atoms/typographie/Headline';
import Spacer, { SPACER_SIZE } from 'component/atoms/Spacer';

const SearchTunnelStepLayout: FC<
  PropsWithChildren<{
    formId: string;
    control: any;
    disabled: boolean;
    buttonSubmitMode: SUBMIT_BUTTON_MODE;
    onSubmit: any;
    title: string;
    submitButtonText?: string;
    backAction?: () => void;
  }>
> = ({ children, formId, control, disabled, buttonSubmitMode, onSubmit, title, submitButtonText, backAction }) => {
  const { t } = useTranslation('common');
  return (
    <Grid container>
      <Grid item xs={12}>
        <Spacer size={SPACER_SIZE.LG} />
        <Headline size={HEADLINE_SIZE.H2} mobileAlign={'center'}>
          {title}
        </Headline>
        <Spacer size={SPACER_SIZE.LG} />

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item xs={12}>
                <div className={styles.wrapper}>
                  {formId && (
                    <FormContext.Provider value={{ control }}>
                      <form id={formId} onSubmit={onSubmit}>
                        {children}
                      </form>
                    </FormContext.Provider>
                  )}

                  <FormNavigation
                    secondaryButtonAction={backAction}
                    secondaryButtonText={'Zurück'}
                    buttonSubmitMode={buttonSubmitMode}
                    disabled={disabled}
                    formId={formId}
                    submitButtonText={submitButtonText ?? t('next')}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SearchTunnelStepLayout;
