import { ROUTES } from 'core/const/routes';
import { useTranslation } from 'react-i18next';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import Text, { TEXT_COLOR } from 'component/atoms/typographie/Text';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import LandingPageLayout from '../../../component/layouts/LandingPageLayout';

const AGBView = () => {
  const { t: r } = useTranslation('routes');
  return (
    <LandingPageLayout pageTitle={'Allgemeine Geschäftsbedingungen für Wohnungsanbieter'}>
      <Grid item xs={12} paddingY={{ xs: 5, md: 10 }} className={'ws-full-width-bg ws-full-width-bg--white'}>
        <Grid container>
          <Grid item>
            <Headline size={HEADLINE_SIZE.H3}>AGB für Wohnungsanbieter</Headline>
            <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>
              Bitte lesen Sie diese Allgemeinen Geschäftsbedingungen (im Folgenden: „AGB“) aufmerksam durch, bevor Sie
              die Dienste dieser Website in Anspruch nehmen. Bei Inanspruchnahme der Dienste akzeptieren Sie diese AGB.
              Wenn Sie diese AGB nicht akzeptieren, dürfen Sie die Dienste dieser Website nicht in Anspruch nehmen. Die
              Erwähnung nur eines Geschlechts in diesen AGB bezieht sich stets auf alle Geschlechter.
              <br />
              <br />
              Bitte lesen Sie auch unsere{' '}
              <Link to={r(ROUTES.landingPageRoutes.privacy.path) as string}>Datenschutzerklärung</Link> sorgfältig
              durch, um zu erfahren, welche Daten wir von Ihnen erheben und weiterverarbeiten sowie zu deren Zugang
              gemäß Art. 9 der Verordnung (EU) 2019/1150.
            </Text>
          </Grid>
          <Grid item>
            <Headline size={HEADLINE_SIZE.H3}>1. Geltungsbereich, Begriffsbestimmungen, abweichende AGB</Headline>
            <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>
              1.1 Diese AGB gelten für alle über die Website www.wohnsinn.com oder entsprechende (mobile) Applikationen
              (im Folgenden: „Plattform“) geschlossenen Verträge zwischen der Wohnsinn GmbH, Martinsfeld 12, 50676 Köln
              (im Folgenden: „wir“, „uns“ oder „Wohnsinn“) und Ihnen als unserem Kunden (im Folgenden: „Sie“, „Ihnen“
              oder „Wohnungsanbieter“) in der jeweils gültigen Fassung.
              <br />
              <br />
              1.2 Unsere Plattform richtet sich nur an Sie, wenn Sie Mieter für ein oder mehrere Wohnobjekte suchen,
              volljährig und voll geschäftsfähig sind - und soweit für eine Gesellschaft handelnd, die entsprechende
              Handlungsbefugnis haben. Für Wohnungssuchende gelten ausschließlich unsere AGB für Mietinteressenten
              (LINK).
              <br />
              <br />
              1.3 Abweichende Bedingungen des Wohnungsanbieters akzeptieren wir nicht. Dies gilt auch, wenn wir der
              Einbeziehung nicht ausdrücklich widersprechen.
            </Text>
          </Grid>
          <Grid item>
            <Headline size={HEADLINE_SIZE.H3}>2. Dienste von Wohnsinn </Headline>
            <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>
              2.1 Registrierte Wohnungsanbieter können auf der Plattform Wohnungsinserate für provisionsfreie
              Mietobjekte sowie Soll-Profile von Mietern einstellen. Im Rahmen eines „Matchings“ können Wohnungsanbieter
              Inhalte von passenden Wohnungsinteressenten einsehen, mit diesen in Kontakt treten und
              Wohnungsmietverträge abschließen. Diese Nutzung der Plattform ist teilweise kostenpflichtig.
              <br />
              <br />
              2.2 Wohnsinn gibt ausschließlich die Gelegenheit zum Abschluss von Wohnungsmietverträgen zwischen
              Wohnungsanbietern und Mietinteressenten und beteiligt sich nicht inhaltlich an der Kommunikation zwischen
              diesen Beteiligten.
              <br />
              <br />
              2.3 Weitere Leistungen auf der Plattform (z.B. die Erstellung von 3D-Touren) unterliegen für
              Wohnungsanbieter ebenfalls diesen AGB, sofern nicht ausdrücklich etwas anderes geregelt ist.
              <br />
              <br />
              2.4 Wohnsinn kann jederzeit Dienste auf der Plattform ändern, von ihnen abweichen oder entfernen, soweit
              für Wohnungsanbieter unter Berücksichtigung der Interessen von Wohnsinn zumutbar. Sofern dadurch eine
              Änderung der AGB erforderlich wird, findet der Abschnitt zu Änderungen dieser AGB Anwendung.
            </Text>
          </Grid>
          <Grid item>
            <Headline size={HEADLINE_SIZE.H3}>
              3. Registrierung durch Wohnungsanbieter, Sicherheit, Vertragsschluss{' '}
            </Headline>
            <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>
              3.1 Zur Registrierung ist die Erstellung eines Kontos auf der Plattform erforderlich, was über die Eingabe
              der Email-Adresse des Wohnungsanbieters und Wahl eines Passwortes erfolgt. Änderungen der Email-Adresse
              des Wohnungsanbieters sind uns unverzüglich mitzuteilen.
              <br />
              <br />
              3.2 Das von Ihnen gewählte Passwort für die Plattform darf nicht mehrfach verwendet werden, ist von Ihnen
              geheim zu halten und darf nicht an Dritte weitergegeben werden. Falls Sie den Verdacht haben, dass Ihr
              Konto nicht mehr sicher ist, informieren Sie uns hierüber unverzüglich und ändern das Passwort
              unverzüglich und unaufgefordert.
              <br />
              <br />
              3.3 Mit Abschluss der Registrierung für ein Konto auf der Plattform und Übermittlung der vorgesehenen
              Inhalte geben Sie ein Angebot für den Abschluss eines Vertrages über die zeitlich begrenzte Nutzung der
              Plattform ab, welches wir mit Aufnahme der Inhalte auf der Plattform annehmen. § 312i Abs. 1 Satz 1 Nr.
              1-3 und Satz 2 BGB finden keine Anwendung.
            </Text>
          </Grid>
          <Grid item>
            <Headline size={HEADLINE_SIZE.H3}>4. Pflichten des Wohnungsanbieters</Headline>
            <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>
              4.1 Der Wohnungsanbieter stellt ausschließlich Wohnungsinserate für Mietwohnungen auf der Plattform ein.
              Wohnungsinserate, die nicht mehr verfügbar sind, werden durch den Wohnungsanbieter unverzüglich
              deaktiviert. Eine Deaktivierung kann auch durch uns erfolgen, wenn ein Wohnungsinserat in missbräuchlicher
              Weise eingestellt wird, insbesondere wenn (i) erkennbar falsche Angaben enthalten sind, (ii) darin Werbung
              für den Wohnungsanbieter oder Dritte enthalten ist, (iii) weitere Informationen für Wohnungsinteressenten
              an die Registrierung beim Wohnungsanbieter/Dritten oder mit Kosten für Wohnungsinteressenten (z.B.
              Maklerkosten, kostenpflichtige Rufnummern) verbunden ist oder (iv) Kontaktdaten des Wohnungsanbieters auf
              den eingestellten Bildern enthalten sind.
              <br />
              <br />
              4.2 Für Ihre eingestellten Inhalte (inklusive Wohnungsinserate) auf der Plattform und die Kommunikation
              mit Dritten über die Plattform sind Sie verantwortlich. Das Einstellen von Inhalten durch Sie
              (einschließlich Wohnungsinformationen und Bildmaterial) und Kommunikation auf der Plattform erfolgen stets
              wahrheitsgemäß und nach bestem Wissen und Gewissen. Sie stellen insbesondere keine Inhalte oder
              Kommunikation ein, die gegen die guten Sitten verstoßen, rechtswidrig sind, Rechte Dritter verletzen,
              Schadsoftware beinhalten oder sonstwie geeignet sind, die Verfügbarkeit oder Sicherheit der Plattform ganz
              oder teilweise zu gefährden.
              <br />
              <br />
              4.3 Eventuell erforderliche Einwilligungen Dritter in Bezug auf eingestellte Inhalte und Kommunikation
              holen Sie rechtzeitig und ordnungsgemäß ein.
              <br />
              <br />
              4.4 Wohnungsanbieter dürfen Wohnungsinserate oder sonstige Angebote von Wohnsinn nicht weiterverkaufen.
              Sie dürfen ihr Konto Dritten nicht zur (Mit-)Nutzung zur Verfügung stellen. Bei Wohnsinn erstellte Exposés
              dürfen nur an entsprechende Interessenten weitergegeben werden.
              <br />
              <br />
              4.5 Wohnsinn ist nicht zur Prüfung verpflichtet, ob der Wohnungsanbieter gegen Ziffern 4.1 bis 4.4
              verstößt. Bei einem Verdacht bezüglich eines möglichen Verstoßes darf Wohnsinn entsprechende Nachweise
              verlangen. Im Falle eines Verstoßes sind wir berechtigt, im Rahmen von Ziffer 5 entsprechende Inhalte oder
              Kommunikation nicht anzunehmen, zu sperren, zu löschen oder andere geeignete Maßnahmen zu treffen.
              <br />
              <br />
              4.6 Rechte des Wohnungsanbieters aus dem Vertrag mit Wohnsinn sind nicht übertragbar.
              <br />
              <br />
              4.7 Die Pflicht zur Zahlung der vereinbarten Vergütung durch den Wohnungsanbieter an Wohnsinn besteht
              unabhängig von (i) etwaigen nach dieser Ziffer getroffenen Maßnahmen und (ii) dem Nichtvorhandensein oder
              späterem Wegfall etwaig erforderlicher Voraussetzungen für die Wohnungsvermietung beim Wohnungsanbieter.
            </Text>
          </Grid>
          <Grid item>
            <Headline size={HEADLINE_SIZE.H3}>5. Beschränkung und Beendigung von Nutzungsverhältnissen</Headline>
            <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>
              5.1 Sofern wir nach Ziffer 4.5 die Nutzung der Plattform durch einen Wohnungsanbieter beschränken (z.B.
              durch Sperrung oder Löschung von Wohnungsinseraten), übermitteln wir dem Wohnungsanbieter vor oder
              gleichzeitig mit dem Wirksamwerden der Aussetzung oder Einschränkung eine Begründung dieser Entscheidung.
              Sollten wir in diesem Zusammenhang die Nutzung beenden, übermitteln wir mindestens 30 Tage vor dem
              Wirksamwerden der Beendigung eine Begründung dieser Entscheidung.
              <br />
              <br />
              5.2 Die in Ziffer 5.1 genannte Frist gilt nicht in den Fällen des Art. 4 Abs. 4 Verordnung (EU) 2019/1150.
              In den Fällen des Art. 4 Abs. 5 Unterabsatz 2 Verordnung (EU) 2019/1150 sind wir nicht verpflichtet, eine
              Begründung abzugeben.
            </Text>
          </Grid>
          <Grid item>
            <Headline size={HEADLINE_SIZE.H3}>
              6. Sortierung der Inserate; Datenzugang; Zugriff nach Vertragsende
            </Headline>
            <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>
              6.1 Bei der Standardsortierung werden zunächst Wohnungsinserate mit vorhandenen 3D-Touren angezeigt
              (innerhalb dieser Gruppe neueste zuerst), anschließend solche ohne 3D-Touren (innerhalb dieser Gruppe
              neueste zuerst).
              <br />
              <br />
              6.2 Wohnungsanbieter können während der Vertragslaufzeit die von den Mietinteressenten bereitgestellten
              Information und Dokumente sowie ihre eingestellten Wohnungsinserate, Kontaktdaten sowie sonstige von Ihnen
              eingestellte Inhalte einsehen.
              <br />
              <br />
              6.3 Nach Vertragsende werden aktive Wohnungsinserate deaktiviert, neue Wohnungsinserate können nicht
              veröffentlicht werden. Der Wohnungsanbieter kann deaktivierte Wohnungsinserate weiterhin bearbeiten, deren
              Mietinteressenten für 60 Tage einsehen und innerhalb dieser Zeit einen Mietinteressenten auswählen. Danach
              werden die Anfragen der Mietinteressenten automatisch gelöscht.
            </Text>
          </Grid>
          <Grid item>
            <Headline size={HEADLINE_SIZE.H3}>7. Verfügbarkeit der Plattform</Headline>
            <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>
              7.1 Wohnsinn wird nach besten Kräften versuchen, die Plattform uneingeschränkt zur Verfügung zu stellen.
              Der Wohnungsanbieter ist sich jedoch bewusst, dass eine hundertprozentige Verfügbarkeit technisch nicht zu
              realisieren ist.
              <br />
              <br />
              7.2 Der Wohnungsanbieter verhält sich so, dass eine mögliche temporäre Nichtverfügbarkeit der Plattform
              für ihn nicht zu finanziellen Nachteilen führt (z.B. weil der Wohnungsanbieter eine zeitkritische
              Mitteilung an einen Wohnungsinteressenten nicht übermitteln kann und dieser nicht mehr an einer Anmietung
              interessiert ist).
            </Text>
          </Grid>
          <Grid item>
            <Headline size={HEADLINE_SIZE.H3}>8. Nutzungsrechte</Headline>
            <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>
              8.1 An Ihren auf der Plattform eingestellten Inhalten räumen Sie uns das nicht-ausschließliche,
              dauerhafte, weltweit gültige Recht ein, diese Inhalte unentgeltlich und so zu nutzen, wie es für die
              Erbringung der Dienste auf der Plattform einschließlich dem Abruf durch Dritte (insbesondere
              Wohnungsinteressenten) erforderlich ist. Insbesondere können wir diese Inhalte hierzu vervielfältigen,
              verbreiten, öffentlich zugänglich machen und bearbeiten bzw. verändern, insbesondere auch im Rahmen von
              Werbeanzeigen und Retargeting. Ihre Inhalte können auch - für Sie kostenlos - über Online-Angebote (z.B.
              Websites, Apps, Social Media) oder Offline (z.B. Plakatwerbung) verbreitet werden.
              <br />
              <br />
              8.2 Wir sind berechtigt, Ihre Inhalte und Kommunikation im Rahmen des anwendbaren Datenschutzrechts für
              Zwecke der Weiterentwicklung der Plattform zu verarbeiten.
            </Text>
          </Grid>
          <Grid item>
            <Headline size={HEADLINE_SIZE.H3}>9. Rechte an der Plattform</Headline>
            <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>
              9.1 Wohnsinn ist Rechteinhaber an den Inhalten der Plattform. Sämtliche Marken-, Urheber- und sonstigen
              Schutzrechte an der Plattform und den dort eingestellten Inhalten stehen ausschließlich Wohnsinn zu. Eine
              Verwendung der Inhalte ist ohne unsere ausdrückliche Zustimmung nicht gestattet. Etwaige Rechte unserer
              Partner, der Wohnungsanbieter und sonstiger Dritter an von diesen bereitgestellten Inhalten bleiben
              hiervon unberührt.
              <br />
              <br />
              9.2 Der Wohnungsanbieter ist berechtigt, ausschließlich unter Verwendung der von Wohnsinn zur Verfügung
              gestellten Suchmaske einzelne Datensätze auf seinem Bildschirm sichtbar zu machen und zur dauerhaften
              Sichtbarmachung einen Ausdruck zu fertigen. Automatisierte Abfragen durch Skripte, Crawler o.ä. oder durch
              Umgehung der Suchmaske sind nicht gestattet.
              <br />
              <br />
              9.3 Der Wohnungsanbieter ist nicht berechtigt, die abgefragten Daten vollständig oder teilweise für (i)
              eine eigene Datenbank in jeglicher Form, (ii) die gewerbliche Datenverarbeitung oder Auskunftserteilung zu
              verwenden. Die Verlinkung, Integration oder sonstige Verknüpfung der Plattform oder Teile der Plattform
              mit anderen Datenbanken oder Meta-Datenbanken ist unzulässig. 9.4 Ziffer 9.3 gilt nicht für vom
              Wohnungsanbieter außerhalb der Plattform genutzte Systeme (z.B. ERP- oder CRM-Systeme), deren Anbindung an
              die Plattform von uns vorab ausdrücklich freigegeben worden ist.
            </Text>
          </Grid>
          <Grid item>
            <Headline size={HEADLINE_SIZE.H3}>10. Preise und Zahlungsmöglichkeiten</Headline>
            <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>
              10.1 Es gelten die jeweils im Zeitpunkt der Auftragserteilung gültigen Preise.
              <br />
              <br />
              10.2 Zahlungen mit anderen Zahlungsmitteln als Lastschrift sind sofort fällig. Soweit Lastschrift
              angeboten wird, erfolgt die Abbuchung frühestens am zweiten Tag nach Zugang der Rechnung (sog.
              Pre-Notification).
            </Text>
          </Grid>
          <Grid item>
            <Headline size={HEADLINE_SIZE.H3}>11. Keine Gewährleistung für Inhalte Dritter </Headline>
            <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>
              Wohnsinn übernimmt keine Verantwortung für die bereitgestellten Inhalte und Kommunikation von
              Wohnungsanbietern auf der Plattform oder sonstigen Personen, die die Plattform nutzen, insbesondere nicht
              für deren Aktualität oder Wahrheitsgehalt sowie für die Identität und Integrität anderer Teilnehmer.
            </Text>
          </Grid>
          <Grid item>
            <Headline size={HEADLINE_SIZE.H3}>12. Haftung, Pflicht des Wohnungsanbieters zur Sicherung </Headline>
            <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>
              12.1 Für Schäden wegen der Verletzung des Lebens, des Körpers, der Gesundheit sowie bei Vorsatz oder
              grober Fahrlässigkeit von Wohnsinn, eines gesetzlichen Vertreters oder Erfüllungsgehilfen, haftet Wohnsinn
              nach den gesetzlichen Vorschriften.
              <br />
              <br />
              12.2 Für alle nicht unter Ziffer
              <br />
              <br />
              12.1 fallende Schäden haftet Wohnsinn bei leichter Fahrlässigkeit nur auf den Ersatz der vertragstypischen
              und vorhersehbaren Schäden und nur, soweit durch Wohnsinn, einen gesetzlichen Vertreter oder
              Erfüllungsgehilfen eine Pflicht, deren ordnungsgemäße Erfüllung die Durchführung dieses Vertrages
              überhaupt erst ermöglicht und auf deren Einhaltung der Wohnungsanbieter vertrauen durfte
              (Kardinalpflicht), verletzt worden ist. Im Übrigen ist die Haftung, soweit gesetzlich zulässig,
              ausgeschlossen. Ansprüche des Wohnungsanbieters aufgrund zwingender gesetzlicher Vorschriften,
              insbesondere des Produkthaftungsgesetzes sowie im Falle der Übernahme einer Garantie, bleiben unberührt.
              <br />
              <br />
              12.3 Für den Verlust von Daten haftet Wohnsinn im Rahmen der vorstehenden Bestimmungen nur in dem Umfang,
              wie wenn der Wohnungsanbieter die ihm verfügbaren Daten jeweils im jüngsten Bearbeitungsstand in
              maschinenlesbarer Form gesichert hätte.
              <br />
              <br />
              12.4 Ein etwaiges Mitverschulden des Wohnungsanbieters ist stets zu berücksichtigen.
              <br />
              <br />
              12.5 Wohnsinn haftet nicht für Pflichtverletzungen aus einem zwischen dem Wohnungsanbieter und einem
              Mietinteressenten geschlossenen Vertrag oder sonstwie gearteten Rechtsverhältnis.
            </Text>
          </Grid>
          <Grid item>
            <Headline size={HEADLINE_SIZE.H3}>13. Freistellung</Headline>
            <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>
              13.1 Der Wohnungsanbieter wird Wohnsinn (i) freistellen in Bezug auf jegliche Ansprüche,
              Verbindlichkeiten, Schäden, Verluste und Aufwendungen (zusammen „Freistellungsansprüche“), die im
              Zusammenhang mit der Nutzung der Plattform durch den Wohnungsanbieter oder der Verletzung von Gesetzen
              oder Rechten Dritter durch den Wohnungsanbieter entstehen und (ii) bei der Rechtsverteidigung, zu der
              Wohnsinn berechtigt, aber nicht verpflichtet ist, in notwendiger Weise unterstützen und die notwendigen
              Kosten der Rechtsverteidigung von Wohnsinn übernehmen.
              <br />
              <br />
              13.2 Die Freistellungsverpflichtung in Ziffer 13.1 gilt nur, soweit Wohnsinn den Wohnungsanbieter
              unverzüglich schriftlich oder per Email über geltend gemachte Freistellungsansprüche informiert, keine
              unabgesprochenen Zugeständnisse in irgendeiner Form macht und dem Wohnungsanbieter ermöglicht, auf seine
              Kosten alle gerichtlichen und außergerichtlichen Verhandlungen über diese Freistellungsansprüche zu
              führen.
              <br />
              <br />
              13.3 Die Geltendmachung weiterer Ansprüche durch Wohnsinn ist hierdurch nicht ausgeschlossen.
            </Text>
          </Grid>
          <Grid item>
            <Headline size={HEADLINE_SIZE.H3}>14. Beendigung des Vertrages</Headline>
            <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>
              Die Laufzeit des Vertrages zwischen dem Wohnungsanbieter und der Plattform richtet sich nach den
              ausgewählten Leistungen. Das Recht zur außerordentlichen Kündigung für beide Seiten bleibt unberührt.
              Kündigungen per Textform (Email) sind ausreichend. Soweit keine der Parteien den Vertrag mit einer Frist
              von drei Monaten zum Ende der Vertragslaufzeit kündigt, verlängert sich der Vertrag um weitere zwölf
              Monate.
            </Text>
          </Grid>
          <Grid item>
            <Headline size={HEADLINE_SIZE.H3}>15. Änderungen dieser AGB</Headline>
            <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>
              15.1 Wohnsinn kann diese AGB jederzeit nach Maßgabe dieser Ziffer ändern. Bei Änderungen werden die
              geänderten AGB auf der Plattform mit entsprechendem Zeitstempel veröffentlicht. Zudem informieren wir Sie
              rechtzeitig über diese Änderungen, mindestens 15 Tage vor dem Zeitpunkt des Inkrafttretens der neuen AGB
              im angemessenen Umfang (z.B. per Email). Eine längere Frist wird eingeräumt, sofern dies für
              Wohnungsanbieter erforderlich ist, um aufgrund der Änderung notwendige Anpassungen vorzunehmen.
              <br />
              <br />
              15.2 Wenn Sie mit den geänderten AGB nicht einverstanden sind, haben Sie das Recht, den Vertrag mit
              Wohnsinn vor Ablauf der jeweiligen Frist aus Ziffer 15.1 mit Wirkung zum Ablauf dieser Frist zu kündigen,
              sofern für den Vertrag keine kürzere Frist gilt. Andernfalls gelten die geänderten AGB als von Ihnen
              angenommen. Sie können auf diese Frist jederzeit verzichten. Das Einstellen neuer Dienstleistungen auf der
              Plattform vor Ablauf dieser Frist gilt als eindeutige bestätigende Handlung zu einem solchen Verzicht,
              sofern keine Frist im Sinne von Ziffer 15.1 Satz 4 vorliegt.
              <br />
              <br />
              15.3 Die in Ziffer 15.2 genannte Frist gilt nicht in Fällen des Art. 3 Abs. 4 Verordnung (EU) 2019/1150.
              15.4 Wir werden die AGB nicht rückwirkend ändern, es sei denn, dies geschieht in Erfüllung einer
              gesetzlichen oder behördlich angeordneten Verpflichtung oder die rückwirkenden Änderungen sind für die
              Wohnungsanbieter von Vorteil.
            </Text>
          </Grid>
          <Grid item>
            <Headline size={HEADLINE_SIZE.H3}>16. Anwendbares Recht, Gerichtsstand, salvatorische Klausel</Headline>
            <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>
              16.1 Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts. Wenn Sie Ihren
              gewöhnlichen Aufenthalt in einem Mitgliedstaat der EU außerhalb Deutschlands haben, gilt ebenfalls die
              Anwendbarkeit des deutschen Rechts, wobei zwingende Bestimmungen des Staates, in dem Sie Ihren
              gewöhnlichen Aufenthalt haben, unberührt bleiben.
              <br />
              <br />
              16.2 Gerichtsstand für alle Streitigkeiten ist der Sitz unseres Unternehmens, sofern der Wohnungsanbieter
              Kaufmann oder eine juristische Person des öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen
              ist. Dasselbe gilt, wenn der Wohnungsanbieter keinen allgemeinen Gerichtsstand in Deutschland oder der EU
              hat oder Wohnsitz oder gewöhnlicher Aufenthalt im Zeitpunkt der Klageerhebung nicht bekannt sind.
              <br />
              <br />
              16.3 Sollten einzelne Bestimmungen dieser AGB unwirksam sein, so werden hierdurch die AGB im Übrigen nicht
              berührt. <br />
            </Text>
          </Grid>
          <Grid item>
            <Headline size={HEADLINE_SIZE.H1}>Allgemeine Geschäftsbedingungen für Mietinteressenten </Headline>
            <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>
              Bitte lesen Sie diese Allgemeinen Geschäftsbedingungen (im Folgenden: „AGB“) aufmerksam durch, bevor Sie
              die Dienste dieser Website in Anspruch nehmen. Bei Inanspruchnahme der Dienste akzeptieren Sie die AGB.
              Wenn Sie diese AGB nicht akzeptieren, dürfen Sie die Dienste dieser Website nicht in Anspruch nehmen. Die
              Erwähnung nur eines Geschlechts in diesen AGB bezieht sich stets auf alle Geschlechter. Bitte lesen Sie
              auch unsere
              <Link to={r(ROUTES.landingPageRoutes.privacy.path) as string}>Datenschutzerklärung</Link> sorgfältig
              durch, um zu erfahren, welche Daten wir von Ihnen erheben und weiterverarbeiten.
            </Text>
          </Grid>
          <Grid item>
            <Headline size={HEADLINE_SIZE.H3}>1. Geltungsbereich, Begriffsbestimmungen, abweichende AGB </Headline>
            <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>
              1.1 Diese AGB gelten für alle über die Website www.wohnsinn.com oder entsprechende (mobile) Applikationen
              (im Folgenden: „Plattform“) geschlossenen Verträge zwischen der Wohnsinn GmbH, Martinsfeld 12, 50676 Köln
              (im Folgenden: „wir“, „uns“ oder „Wohnsinn“) und Ihnen als unserem Kunden (im Folgenden: „Sie“, „Ihnen“
              oder „Mietinteressent“) in der zum Zeitpunkt des Vertragsschlusses jeweils gültigen Fassung.
              <br />
              <br />
              1.2 Unsere Plattform richtet sich nur an Sie, wenn Sie volljährig, voll geschäftsfähig und Verbraucher im
              Sinne des § 13 BGB sind.
              <br />
              <br />
              1.3 Abweichende Bedingungen des Mietinteressenten akzeptieren wir nicht. Dies gilt auch, wenn wir der
              Einbeziehung nicht ausdrücklich widersprechen.
            </Text>
          </Grid>
          <Grid item>
            <Headline size={HEADLINE_SIZE.H3}>2. Dienste von Wohnsinn </Headline>
            <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>
              2.1 Mietinteressenten können anhand von eigenen Angaben auf der Plattform passende Wohnungsinserate im
              Rahmen eines „Matchings“ suchen lassen und ein daraufhin angezeigtes Wohnungsinserat bewerten.
              Registrierte Mietinteressenten können sich passende Wohnungsinserate anzeigen lassen, diese bewerten und
              mit den jeweiligen Wohnungsanbietern in Kontakt treten. Die Nutzung der Plattform ist für (registrierte)
              Mietinteressenten kostenlos. Etwaige kostenpflichtige Zusatzleistungen sind besonders gekennzeichnet.
              <br />
              <br />
              2.2 Wohnsinn gibt ausschließlich die Gelegenheit zum Abschluss von Wohnungsmietverträgen zwischen
              Mietinteressenten und Wohnungsanbietern und beteiligt sich nicht inhaltlich an der Kommunikation zwischen
              diesen Beteiligten. 2.3 Weitere Leistungen auf der Plattform (z.B. die Erstellung von Vorstellungsvideos)
              unterliegen für Mietinteressenten ebenfalls diesen AGB, sofern nicht ausdrücklich etwas anderes geregelt
              ist. 2.4 Wohnsinn kann jederzeit Dienste auf der Plattform ändern, von ihnen abweichen oder entfernen,
              soweit für Mietinteressenten unter Berücksichtigung der Interessen von Wohnsinn zumutbar.
            </Text>
          </Grid>
          <Grid item>
            <Headline size={HEADLINE_SIZE.H3}>
              3. Registrierung durch Mietinteressenten, Sicherheit, Vertragsschluss
            </Headline>
            <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>
              3.1 Zur Registrierung ist die Erstellung eines Kontos auf der Plattform erforderlich, was über die Eingabe
              der E-Mail-Adresse des Mietinteressenten und Wahl eines Passwortes oder über einen Social Media-Login
              erfolgen kann. Änderungen der E-Mail-Adresse des Mietinteressenten sind uns unverzüglich mitzuteilen.
              <br />
              <br />
              3.2 Das Konto darf nur vom jeweils registrierten Mietinteressenten verwendet werden. Das von Ihnen
              gewählte Passwort für die Plattform darf nicht mehrfach verwendet werden, ist von Ihnen geheim zu halten
              und darf nicht an Dritte weitergegeben werden. Falls Sie den Verdacht haben, dass Ihr Konto nicht mehr
              sicher ist, informieren Sie uns hierüber unverzüglich und ändern das Passwort unverzüglich und
              unaufgefordert.
              <br />
              <br />
              3.3 Mit Abschluss der Registrierung für ein Konto auf der Plattform geben Sie ein Angebot für den
              Abschluss eines Vertrages über die Nutzung der Plattform ab, welches wir mit Freischaltung des Kontos
              annehmen.
            </Text>
          </Grid>
          <Grid item>
            <Headline size={HEADLINE_SIZE.H3}>4. Pflichten des Mietinteressenten</Headline>
            <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>
              4.1 Für Ihre eingestellten Inhalte auf der Plattform und die Kommunikation mit Dritten über die Plattform
              sind Sie verantwortlich. Das Einstellen von Inhalten durch Sie (einschließlich Dokumente, Fotos und
              Videos) und Kommunikation auf der Plattform erfolgen stets wahrheitsgemäß und nach bestem Wissen und
              Gewissen. Sie stellen insbesondere keine Inhalte oder Kommunikation ein, die gegen die guten Sitten
              verstoßen, rechtswidrig sind, Rechte Dritter verletzen, Schadsoftware beinhalten oder sonstwie geeignet
              sind, die Verfügbarkeit oder Sicherheit der Plattform ganz oder teilweise zu gefährden.
              <br />
              <br />
              4.2 Eventuell erforderliche Einwilligungen Dritter in Bezug auf eingestellte Inhalte und Kommunikation
              holen Sie rechtzeitig und ordnungsgemäß ein.
              <br />
              <br />
              4.3 Wohnsinn ist nicht zur Prüfung verpflichtet, ob der Mietinteressent gegen Ziffer 4.1 oder 4.2
              verstößt. Im Falle eines Verstoßes sind wir jedoch berechtigt, entsprechende Inhalte oder Kommunikation
              nicht anzunehmen, zu sperren oder zu löschen. Hierüber und über die Gründe werden wir Sie schnellstmöglich
              informieren. Zudem sind wir berechtigt, nach vorheriger Abmahnung – bei Vorliegen besonderer Umstände
              unter Abwägung beiderseitiger Interessen auch ohne vorherige Abmahnung – den Vertrag mit dem
              Mietinteressenten fristlos aus wichtigem Grund zu kündigen und/oder das Konto sofort zu sperren.
              <br />
              <br />
              4.4 Rechte des Mietinteressenten aus dem Vertrag mit Wohnsinn sind nicht übertragbar.
            </Text>
          </Grid>
          <Grid item>
            <Headline size={HEADLINE_SIZE.H3}>5. Verfügbarkeit der Plattform </Headline>
            <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>
              5.1 Wohnsinn wird nach besten Kräften versuchen, die Plattform uneingeschränkt zur Verfügung zu stellen.
              Der Mietinteressent ist sich jedoch bewusst, dass eine hundertprozentige Verfügbarkeit technisch nicht zu
              realisieren ist. Eine solche ist auch nicht geschuldet und der Mietinteressent kann im Zusammenhang mit
              einer fehlenden Verfügbarkeit der Plattform keine Ansprüche gegen Wohnsinn ableiten.
              <br />
              <br />
              5.2 Der Mietinteressent verhält sich so, dass eine mögliche temporäre Nichtverfügbarkeit der Plattform für
              ihn nicht zu finanziellen Nachteilen führt (z.B. weil der Mietinteressent eine zeitkritische Mitteilung an
              einen Wohnungsanbieter nicht übermitteln kann und die Wohnung infolgedessen anderweitig vermietet wird).
            </Text>
          </Grid>
          <Grid item>
            <Headline size={HEADLINE_SIZE.H3}>6. Nutzungsrechte</Headline>
            <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>
              6.1 An Ihren auf der Plattform eingestellten Inhalten räumen Sie uns das nicht-ausschließliche, dauerhafte
              und weltweit gültige Recht ein, diese Inhalte unentgeltlich und so zu nutzen, wie es für die Erbringung
              der Dienste auf der Plattform einschließlich dem Abruf durch Dritte (insbesondere Wohnungsanbieter)
              erforderlich ist. Insbesondere können wir diese Inhalte hierzu vervielfältigen, verbreiten, öffentlich
              zugänglich machen und bearbeiten bzw. verändern.
              <br />
              <br />
              6.2 Zudem sind wir berechtigt, Ihre Inhalte und Kommunikation im Rahmen des anwendbaren Datenschutzrechts
              für Zwecke der Weiterentwicklung der Plattform zu verarbeiten.
            </Text>
          </Grid>
          <Grid item>
            <Headline size={HEADLINE_SIZE.H3}>7. Widerruf </Headline>
            <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>
              Wenn Sie als Verbraucher eine Leistung kostenpflichtig bestellen, haben Sie das folgende Widerrufsrecht:
              <br />
              <br />
              <i>Widerrufsbelehrung</i>
              <br />
              <br />
              Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.
              <br />
              <br />
              Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsabschlusses.
              <br />
              <br />
              Um Ihr Widerrufsrecht auszuüben, müssen Sie uns Wohnsinn GmbH Martinsfeld 12 50676 Köln Tel.-Nr.: +49 221
              95 49 04 28 kontakt@wohnsinn.com mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter
              Brief oder via E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür
              das beigefügte{' '}
              <a
                target={'_blank'}
                rel={'noreferrer noopener'}
                href="https://www.bmjv.de/SharedDocs/Archiv/Downloads/VerbraucherVertragsRechte_Muster_Widerruf.pdf;jsessionid=B08FFF5818764339036214B1DD75ECFF.1_cid324?__blob=publicationFile&v=3"
              >
                Muster-Widerrufsformular
              </a>{' '}
              verwenden, das jedoch nicht vorgeschrieben ist. Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die
              Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
              <br />
              <br />
              Folgen des Widerrufs Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen
              erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus
              ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung
              gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die
              Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden
              wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit
              Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung
              Entgelte berechnet.
              <br />
              <br />
              Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen soll, so haben Sie uns
              einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der
              Ausübung des Widerrufsrechts hinsichtlich dieses Vertrags unterrichten, bereits erbrachten
              Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.
              <br />
              <br />
              Hinweis zum vorzeitigen Erlöschen des Widerrufsrechts Ihr Widerrufsrecht erlischt bei einem Vertrag zur
              Erbringung von Dienstleistungen vorzeitig, wenn wir die Dienstleistung vollständig erbracht haben und mit
              der Ausführung der Dienstleistung erst begonnen haben, nachdem Sie dazu Ihre ausdrückliche Zustimmung
              gegeben haben und gleichzeitig Ihre Kenntnis davon bestätigt haben, dass Sie Ihr Widerrufsrecht bei
              vollständiger Vertragserfüllung durch uns verlieren. Ende der Widerrufsbelehrung
              <br />
              <br />
              <i>Ende der Widerrufsbelehrung</i>
            </Text>
          </Grid>
          <Grid item>
            <Headline size={HEADLINE_SIZE.H3}>8. Rechte an der Plattform</Headline>
            <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>
              8.1 Wohnsinn ist Rechteinhaber an den Inhalten der Plattform. Sämtliche Marken-, Urheber- und sonstigen
              Schutzrechte an der Plattform und den hier eingestellten Inhalten stehen ausschließlich Wohnsinn zu. Eine
              Verwendung der Inhalte ist ohne unsere ausdrückliche Zustimmung nicht gestattet. Etwaige Rechte unserer
              Partner, der Mietinteressenten und sonstiger Dritter an von diesen bereitgestellten Inhalten bleiben
              hiervon unberührt.
              <br />
              <br />
              8.2 Der Mietinteressent ist berechtigt, ausschließlich unter Verwendung der von uns zur Verfügung
              gestellten Suchmaske einzelne Datensätze auf seinem Bildschirm sichtbar zu machen und zur dauerhaften
              Sichtbarmachung einen Ausdruck zu fertigen. Automatisierte Abfragen durch Skripte, Crawler o.ä. oder durch
              Umgehung der Suchmaske sind nicht gestattet.
              <br />
              <br />
              8.3 Der Mietinteressent ist nicht berechtigt, die abgefragten Daten vollständig oder teilweise für (i)
              eine eigene Datenbank in jeglicher Form, (ii) die gewerbliche Datenverarbeitung oder Auskunftserteilung
              verwenden. Die Verlinkung, Integration oder sonstige Verknüpfung der Plattform oder Teile der Plattform
              mit anderen Datenbanken oder Meta-Datenbanken ist unzulässig.
            </Text>
          </Grid>
          <Grid item>
            <Headline size={HEADLINE_SIZE.H3}>9. Keine Gewährleistung für Inhalte Dritter </Headline>
            <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>
              Wohnsinn übernimmt keine Verantwortung für die bereitgestellten Inhalte und Kommunikation von
              Mietinteressenten oder sonstigen Personen, die die Plattform nutzen, insbesondere nicht für deren
              Aktualität oder Wahrheitsgehalt sowie für die Identität und Integrität anderer Teilnehmer.
            </Text>
          </Grid>
          <Grid item>
            <Headline size={HEADLINE_SIZE.H3}>10. Haftung, Pflicht des Mietinteressenten zur Sicherung </Headline>
            <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>
              10.1 Für Schäden wegen der Verletzung des Lebens, des Körpers, der Gesundheit sowie bei Vorsatz oder
              grober Fahrlässigkeit von Wohnsinn, eines gesetzlichen Vertreters oder Erfüllungsgehilfen, haftet Wohnsinn
              nach den gesetzlichen Vorschriften.
              <br />
              <br />
              10.2 Für alle nicht unter Ziffer 10.1 fallende Schäden haftet Wohnsinn bei leichter Fahrlässigkeit nur auf
              den Ersatz der vertragstypischen und vorhersehbaren Schäden und nur, soweit durch Wohnsinn, einen
              gesetzlichen Vertreter oder Erfüllungsgehilfen eine Pflicht, deren ordnungsgemäße Erfüllung die
              Durchführung dieses Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der Mietinteressent
              vertrauen durfte (Kardinalpflicht), verletzt worden ist. Im Übrigen ist die Haftung, soweit gesetzlich
              zulässig, ausgeschlossen. Ansprüche des Mietinteressenten aufgrund zwingender gesetzlicher Vorschriften,
              insbesondere des Produkthaftungsgesetzes sowie im Falle der Übernahme einer Garantie, bleiben unberührt.
              <br />
              <br />
              10.3 Für den Verlust von Daten haftet Wohnsinn im Rahmen der vorstehenden Bestimmungen nur in dem Umfang,
              wie wenn der Mietinteressent die ihm verfügbaren Daten jeweils im jüngsten Bearbeitungsstand in
              maschinenlesbarer Form gesichert hätte.
              <br />
              <br />
              10.4 Ein etwaiges Mitverschulden des Mietinteressenten ist stets zu berücksichtigen. 10.5 Wohnsinn haftet
              nicht für Pflichtverletzungen aus einem zwischen dem Mietinteressenten und einem Wohnungsanbieter
              geschlossenen Vertrag oder sonstwie gearteten Rechtsverhältnis.
            </Text>
          </Grid>
          <Grid item>
            <Headline size={HEADLINE_SIZE.H3}>11. Freistellung </Headline>
            <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>
              11.1 Der Mietinteressent wird Wohnsinn (i) freistellen in Bezug auf jegliche Ansprüche, Verbindlichkeiten,
              Schäden, Verluste und Aufwendungen (zusammen „Freistellungsansprüche“), die im Zusammenhang mit der
              Nutzung der Plattform durch den Mietinteressenten oder der Verletzung von Gesetzen oder Rechten Dritter
              durch den Mietinteressenten entstehen und (ii) bei der Rechtsverteidigung, zu der Wohnsinn berechtigt,
              aber nicht verpflichtet ist, in notwendiger Weise unterstützen und die notwendigen Kosten der
              Rechtsverteidigung von Wohnsinn übernehmen.
              <br />
              <br />
              11.2 Die Freistellungsverpflichtung in Ziffer 11.1 gilt nur, soweit Wohnsinn den Mietinteressent
              unverzüglich schriftlich oder per Email über geltend gemachte Freistellungsansprüche informiert, keine
              unabgesprochenen Zugeständnisse in irgendeiner Form macht und dem Wohnungsanbieter ermöglicht, auf seine
              Kosten alle gerichtlichen und außergerichtlichen Verhandlungen über diese Freistellungsansprüche zu
              führen.
              <br />
              <br />
              11.3 Die Geltendmachung weiterer Ansprüche durch Wohnsinn ist hierdurch nicht ausgeschlossen
            </Text>
          </Grid>
          <Grid item>
            <Headline size={HEADLINE_SIZE.H3}>12. Änderungen dieser AGB </Headline>
            <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>
              12.1 Wohnsinn kann diese AGB jederzeit nach Maßgabe dieser Ziffer ändern. Bei Änderungen werden die
              geänderten AGB auf der Plattform mit entsprechendem Zeitstempel veröffentlicht. Zudem informieren wir Sie
              rechtzeitig über diese Änderungen, in der Regel mindestens sechs Wochen vor dem Zeitpunkt des
              Inkrafttretens der neuen AGB im angemessenen Umfang (zB per E-Mail oder durch Veröffentlichung auf der
              Plattform).
              <br />
              <br />
              12.2 Sofern Sie innerhalb von sechs Wochen ab Mitteilung nicht widersprechen, gelten die geänderten AGB
              als von Ihnen angenommen. In der Ankündigung der Änderung wird auf diese Folge gesondert hingewiesen.
              <br />
              <br />
              12.3 Bei fristgemäßem Widerspruch sind wir unter Wahrung Ihrer berechtigten Interessen berechtigt, den
              Vertrag mit Ihnen zu dem Zeitpunkt zu kündigen, zu dem die Änderungen in Kraft treten. Ihre Inhalte werden
              anschließend von der Plattform gelöscht. Hieraus können Sie keine Ansprüche gegen uns geltend machen.
            </Text>
          </Grid>
          <Grid item>
            <Headline size={HEADLINE_SIZE.H3}>13. Anwendbares Recht, salvatorische Klausel </Headline>
            <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>
              13.1 Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts. Wenn Sie Ihren
              gewöhnlichen Aufenthalt in einem Mitgliedstaat der Europäischen Union außerhalb Deutschlands haben, gilt
              ebenfalls die Anwendbarkeit des deutschen Rechts, wobei zwingende Bestimmungen des Staates, in dem Sie
              Ihren gewöhnlichen Aufenthalt haben, unberührt bleiben.
              <br />
              <br />
              13.2 Für Mietinteressenten als Verbraucher besteht ein Gerichtsstand am jeweiligen Wohnsitz des
              Mietinteressenten sowie als zusätzlicher Gerichtsstand der Sitz unseres Unternehmens. Soweit Sie bei
              Abschluss des Vertrags Ihren Wohnsitz oder gewöhnlichen Aufenthalt in Deutschland hatten und entweder zum
              Zeitpunkt der Klageerhebung durch uns aus Deutschland verlegt haben oder Ihr Wohnsitz oder Ihr
              gewöhnlicher Aufenthaltsort zu diesem Zeitpunkt unbekannt ist, ist Gerichtsstand für alle Streitigkeiten
              der Sitz unseres Unternehmens.
              <br />
              <br />
              13.3 Sollten einzelne Bestimmungen dieser AGB unwirksam sein, so werden hierdurch die AGB im Übrigen nicht
              berührt.
            </Text>
          </Grid>
          <Grid item>
            <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>Stand dieser AGB: Januar 2022</Text>
          </Grid>
        </Grid>
      </Grid>
    </LandingPageLayout>
  );
};

export default AGBView;
