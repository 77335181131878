import { Grid } from '@mui/material';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import Text, { TEXT_COLOR, TEXT_TYPE } from 'component/atoms/typographie/Text';
import { IMAGES } from 'core/const/images';
import TextBlockSection from 'component/molecules/TextBlockSection';
import CheckList from 'component/atoms/CheckList';
import Spacer, { SPACER_SIZE } from 'component/atoms/Spacer';
import { useTranslation } from 'react-i18next';

const CooperativeProsSection = () => {
  const { t: i } = useTranslation('images');
  return (
    <>
      <Grid container justifyContent={'center'}>
        <Grid item xs={12} md={8} paddingTop={{ xs: 4, md: 10 }} paddingBottom={{ xs: 4, md: 10 }}>
          <Headline size={HEADLINE_SIZE.DISPLAY} align={'center'}>
            Warum Wohnsinn für die Mietersuche nutzen?
          </Headline>
        </Grid>
      </Grid>

      <Grid container alignItems={'center'} paddingTop={6} rowSpacing={4} columnSpacing={24}>
        <Grid item xs={12} sm={6} alignItems={'center'}>
          <TextBlockSection
            headlineSize={HEADLINE_SIZE.H1}
            title={'Wir sortieren für dich aus -'}
            titleTwo={'ganz automatisch.'}
          >
            <Spacer size={SPACER_SIZE.MD} />
            <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_ACCENT} type={TEXT_TYPE.TEXT_TYPE_LANDING}>
              Bilde Vergabekriterien ab und versende auf Knopfdruck Angebote an passende Mitglieder und Interessenten.
              Wie der gesamte Prozess, erfolgt die Rückmeldung digital und du weißt sofort, ob ein konkretes
              Mietinteresse vorliegt.
            </Text>
          </TextBlockSection>

          <Spacer size={SPACER_SIZE.LG} />

          <CheckList accentColor items={AUTO_SELECT} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <img src={i(IMAGES.pros.landlord.autoSelect.src)} alt={i(IMAGES.pros.landlord.autoSelect.alt)} />
        </Grid>
      </Grid>

      <Grid container alignItems={'center'} paddingTop={12} rowSpacing={4} columnSpacing={24}>
        <Grid item xs={12} sm={6}>
          <TextBlockSection headlineSize={HEADLINE_SIZE.H1} title={'Nie wieder fehlende Dokumente anfordern.'}>
            <Spacer size={SPACER_SIZE.MD} />

            <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_ACCENT} type={TEXT_TYPE.TEXT_TYPE_LANDING}>
              Bei uns erhältst du bereits im Vorfeld alle relevanten Mietunterlagen, sodass du keine zusätzlichen
              Informationen anfordern musst.
            </Text>
          </TextBlockSection>

          <Spacer size={SPACER_SIZE.LG} />

          <CheckList accentColor items={NO_MORE_DOCUMENTS} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <img src={i(IMAGES.pros.landlord.noMoreDocs.src)} alt={i(IMAGES.pros.landlord.noMoreDocs.alt)} />
        </Grid>
      </Grid>

      <Grid container alignItems={'center'} paddingTop={12} rowSpacing={4} columnSpacing={24}>
        <Grid item xs={12} sm={6}>
          <TextBlockSection headlineSize={HEADLINE_SIZE.H1} title={'Gesuche einfach & effizient verwalten.'}>
            <Spacer size={SPACER_SIZE.MD} />

            <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_ACCENT} type={TEXT_TYPE.TEXT_TYPE_LANDING}>
              Die manuelle Pflege von Excel-Listen entfällt komplett. So behältst du auch bei einer Vielzahl an
              Mitgliedern den Überblick und das System sorgt dafür, dass keine Karteileichen entstehen.
            </Text>
          </TextBlockSection>

          <Spacer size={SPACER_SIZE.LG} />

          <CheckList accentColor items={SAFE_TIME} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <img src={i(IMAGES.pros.landlord.safeTime.src)} alt={i(IMAGES.pros.landlord.safeTime.alt)} />
        </Grid>
      </Grid>
    </>
  );
};
const SAFE_TIME = [
  {
    text: 'Keine monotonen Aufgaben',
    bold: false,
    invert: false,
  },
  {
    text: 'Mehr Zeit für Kundenservice',
    bold: false,
    invert: false,
  },
  {
    text: 'Weniger unnötige Besichtigungen',
    bold: false,
    invert: false,
  },
];

const NO_MORE_DOCUMENTS = [
  {
    text: 'Kein Papierkram',
    bold: false,
    invert: false,
  },
  {
    text: 'Verifizierte Dokumente',
    bold: false,
    invert: false,
  },
  {
    text: 'Keine ausgedruckten Bewerbermappen',
    bold: false,
    invert: false,
  },
];

const AUTO_SELECT = [
  {
    text: 'Qualität statt Quantität',
    bold: false,
    invert: false,
  },
  {
    text: 'Keine unnötigen Anfragen bearbeiten',
    bold: false,
    invert: false,
  },
  {
    text: 'Mehr Zeit für das Wesentliche',
    bold: false,
    invert: false,
  },
];

export default CooperativeProsSection;
