import { Grid } from '@mui/material';
import PageTitle from '../../../molecules/PageTitle';
import { FormContext } from 'core/context/form.context';
import Dropdown from 'component/atoms/formElement/Dropdown';
import InputText from 'component/atoms/formElement/InputText';
import ChangeUser from 'component/molecules/ChangeUser';
import FormNavigation from 'component/molecules/FormNavigation';
import { useContext, useState } from 'react';
import UserContext from 'core/context/user.context';
import { useForm } from 'react-hook-form';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { ISelectOption, REGISTRATION_REFERRER, REGISTRATION_STATES } from '@wohnsinn/ws-ts-lib';
import { wohnsinnServices } from 'App';
import { useTranslation } from 'react-i18next';

const HOW_DID_YOU_HEAR_ABOUT_US_FORM = 'HOW_DID_YOU_HEAR_ABOUT_US_FORM';

const HowDidYouHearAboutWohnsinnScreen = () => {
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { userService } = wohnsinnServices;
  const [selectedOption, setSelectedOption] = useState<REGISTRATION_REFERRER>(null);
  const { landlordProfile, tenantProfile } = useContext(UserContext);
  const email = landlordProfile ? landlordProfile?.email : tenantProfile?.email;
  const { t } = useTranslation('common');

  const HOW_DID_YOU_HEAR_ABOUT_US_OPTIONS_LIST: Array<ISelectOption<REGISTRATION_REFERRER>> = [
    {
      value: REGISTRATION_REFERRER.RECOMMENDATION,
      label: t(REGISTRATION_REFERRER.RECOMMENDATION),
    },
    {
      value: REGISTRATION_REFERRER.HUG_BONN,
      label: t(REGISTRATION_REFERRER.HUG_BONN),
    },
    {
      value: REGISTRATION_REFERRER.ADVERTISEMENT,
      label: t(REGISTRATION_REFERRER.ADVERTISEMENT),
    },
    {
      value: REGISTRATION_REFERRER.GOT_A_CALL,
      label: t(REGISTRATION_REFERRER.GOT_A_CALL),
    },
    {
      value: REGISTRATION_REFERRER.CUSTOM,
      label: t(REGISTRATION_REFERRER.CUSTOM),
    },
    {
      value: REGISTRATION_REFERRER.LINKEDIN,
      label: t(REGISTRATION_REFERRER.LINKEDIN),
    },
    {
      value: REGISTRATION_REFERRER.FACEBOOK,
      label: t(REGISTRATION_REFERRER.FACEBOOK),
    },
    {
      value: REGISTRATION_REFERRER.INSTAGRAM,
      label: t(REGISTRATION_REFERRER.INSTAGRAM),
    },
    {
      value: REGISTRATION_REFERRER.NONE,
      label: t(REGISTRATION_REFERRER.NONE),
    },
  ];

  const { control, watch } = useForm<{
    referrer: REGISTRATION_REFERRER;
    custom?: string;
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const handleOptionChange = (option: REGISTRATION_REFERRER): void => {
    setSelectedOption(option);
  };
  const customInput = watch('custom') ?? '';

  const handleNextStep = () => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    try {
      setTimeout(async () => {
        await userService.updateUserData(
          {
            registrationState: REGISTRATION_STATES.FINISH_LOADING_SCREEN,
            referrer: selectedOption,
            customReferrer: customInput,
          },
          landlordProfile.uid
        );

        setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUCCESS);
      }, 500);
    } catch (e) {
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
      console.error('Error on handleNextStep', e);
    }
  };

  const getInputTextLabel = (selectedOption: REGISTRATION_REFERRER): string => {
    switch (selectedOption) {
      case REGISTRATION_REFERRER.CUSTOM:
        return 'Eigene Angabe';
      case REGISTRATION_REFERRER.HUG_BONN:
        return 'Mitgliedsnummer (optional)';
      case REGISTRATION_REFERRER.RECOMMENDATION:
        return 'Empfohlen durch';
      default:
        return 'Weiteres';
    }
  };

  const renderInput = () => {
    if (!selectedOption) return null;

    return (
      (selectedOption === REGISTRATION_REFERRER.CUSTOM ||
        selectedOption === REGISTRATION_REFERRER.HUG_BONN ||
        selectedOption === REGISTRATION_REFERRER.RECOMMENDATION) && (
        <InputText label={getInputTextLabel(selectedOption)} name={'custom'} />
      )
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageTitle
          pageTitle={'Wie bist Du auf uns aufmerksam geworden?'}
          secondPageTitle={'Wir freuen uns über dein Feedback, um Wohnsinn noch bekannter zu machen'}
        />
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item xs={12}>
                <div style={{ paddingBottom: 48 }}>
                  <FormContext.Provider value={{ control }}>
                    <form id={HOW_DID_YOU_HEAR_ABOUT_US_FORM}>
                      <Dropdown
                        label={'Bekannt durch'}
                        name={'software'}
                        optionList={HOW_DID_YOU_HEAR_ABOUT_US_OPTIONS_LIST}
                        onChange={(e: any) => handleOptionChange(e)}
                        required
                      />
                      {renderInput()}
                    </form>
                  </FormContext.Provider>

                  <ChangeUser email={email} />

                  <FormNavigation
                    primaryAction={() => handleNextStep()}
                    submitButtonText={t('next')}
                    disabled={selectedOption === null}
                    buttonSubmitMode={buttonSubmitMode}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HowDidYouHearAboutWohnsinnScreen;
