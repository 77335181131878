import AppHeader from 'component/molecules/AppHeader';
import Footer from 'component/molecules/Footer';
import PageTitle from 'component/molecules/PageTitle';
import styles from './PageLayout.module.scss';
import { BREAKPOINTS } from 'core/const/breakpoints';
import { FC, PropsWithChildren } from 'react';
import { Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { REGISTRATION_REFERRER } from '@wohnsinn/ws-ts-lib';

export interface ILayoutProps {
  backButtonRoute?: string;
  pageTitle: string;
  titleButton?: any;
  secondPageTitle?: string;
  showBackButton?: boolean;
  showPageTitle?: boolean;
  headerEndButton?: any;
  hideMenu?: boolean;
  tunnelMode?: boolean;
  hideFooter?: boolean;
  badge?: string;
  showStaticMenu?: boolean;
  showTitleBackButton?: boolean;
  referrer?: REGISTRATION_REFERRER;
}

const PageLayout: FC<PropsWithChildren<ILayoutProps>> = ({
  backButtonRoute,
  children,
  pageTitle,
  secondPageTitle,
  showBackButton = false,
  showPageTitle = true,
  headerEndButton,
  hideMenu = false,
  hideFooter = false,
  showStaticMenu = false,
  tunnelMode = false,
  showTitleBackButton = false,
  referrer,
  badge,
  titleButton,
}) => {
  const { t } = useTranslation('common');

  return (
    <div className={styles.appWrapper}>
      <div id="lightshow-portal" />
      <Helmet>
        <title>{`${t('appTitle')} - ${t(pageTitle)}`}</title>
      </Helmet>

      <header className={styles.appHeader}>
        <AppHeader
          tunnelMode={tunnelMode}
          showStaticMenu={showStaticMenu}
          headerEndButton={headerEndButton}
          hideMenu={hideMenu}
          showBackButton={showBackButton}
          backButtonRoute={backButtonRoute}
          referrer={referrer}
        />
      </header>

      <main className={styles.appContent}>
        <Grid
          container
          alignSelf={'center'}
          marginX={'auto'}
          maxWidth={{ lg: BREAKPOINTS.xl }}
          paddingX={2}
          justifyContent="center"
        >
          {showPageTitle ? (
            <Grid item xs={titleButton ? 9 : 12}>
              <PageTitle
                pageTitle={pageTitle}
                secondPageTitle={secondPageTitle}
                showBackButton={showTitleBackButton}
                backButtonRoute={backButtonRoute}
                badgeText={badge}
              />
            </Grid>
          ) : null}

          {titleButton ? (
            <Grid item xs={3}>
              <div className={styles.titleButtonWrapper}>{titleButton}</div>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </main>
      {!hideFooter ? <Footer /> : null}
    </div>
  );
};

export default PageLayout;
